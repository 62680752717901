import HtmHelper from '../../../utils/HtmHelper';
import Validator from '../../../utils/Validator';
import XtwRowTemplateItem from './XtwRowTemplateItem';
import XtwTooltipListenerExtension from '../util/XtwTooltipListenerExtension';
import XtwUtils from '../util/XtwUtils';
import Color from '../../../utils/Color';

const ITEM_SPECIFIC_TEXT_COLOR = "--rtp-item-specific-text-color";
const ITEM_SPECIFIC_BACKGROUND_COLOR = "--rtp-item-specific-background-color";
const ITEM_SPECIFIC_SELECT_BGC = '--rtp-item-specific-select-bgc';

export default class XtwRowTemplateExtendedItem extends XtwRowTemplateItem {

	create() {
		super.create();
		if ( !Validator.isObject( window.pisasales ) ||
			!Validator.isFunction( window.pisasales.bind ) ) return;
		this._onTooltipTimer = window.pisasales.bind( this, this.onTooltipTimer );
		new XtwTooltipListenerExtension( this );
	}

	get parameters() {
		return Validator.isObjectPath( this,
				"this._creationParameters.parameters" ) ?
			this._creationParameters.parameters : void 0;
	}

	get columnDescriptor() {
		const parameters = this.parameters;
		return Validator.isObjectPath( parameters, "parameters.rtpCol" ) ?
			parameters.rtpCol : void 0;
	}

	get condition() {
		const parameters = this.parameters;
		return Validator.isObject( parameters ) ? parameters.condition : void 0;
	}

	get operands() {
		const parameters = this.parameters;
		return Validator.isObject( parameters ) ? parameters.operands : void 0;
	}

	get isVisible() {
		if ( Validator.isObjectPath( this, "this._creationParameters.parameters" ) &&
			Validator.isBoolean( this._creationParameters.parameters.visible ) )
			return this._creationParameters.parameters.visible
		const descriptor = this.columnDescriptor;
		if ( !Validator.isObject( descriptor ) ) return false;
		if ( !descriptor.rtpDscVis ) return false;
		if ( descriptor.rtpDscStdMode == "-" ) return false;
		return true;
	}

	get alignment() {
		const descriptor = this.columnDescriptor;
		if ( !Validator.isObject( descriptor ) ) return void 0;
		const alignment = descriptor.rtpDscAlign;
		return Validator.isString( alignment ) ? alignment : void 0;
	}

	setHyperlink() {
		if ( !this.isRendered ) return;
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) ||
			parameters.isLink !== true ) return;
		this.element.classList.add( "rtp-link" );
	}

	setLineHeight() {
		if ( !this.isRendered ) {
			return;
		}
		const columnDescriptor = this.columnDescriptor;
		if ( !Validator.isObject( columnDescriptor ) ||
			!Validator.isPositiveNumber( columnDescriptor.rtpDscHeight, false ) ||
			columnDescriptor.rtpDscHeight == 0 ||
			columnDescriptor.rtpDscHeight == "0" ||
			columnDescriptor.rtpDscHeight == "0px" ) {
			return;
		}
		const css_height = '' + columnDescriptor.rtpDscHeight + 'px';
		this.element.style.height = css_height;
		this.element.style.maxHeight = css_height;
		let parentElement = this.parentObjectElement;
		if ( !( parentElement instanceof HTMLElement ) ) {
			parentElement = this.element.parentElement;
		}
		if ( !( parentElement instanceof HTMLElement ) ) {
			return;
		}
		let currentMaxHeight = 0;
		let maxHeightValue = String( parentElement.style.maxHeight ).match( /\d+[\d\.]\d+/g );
		if ( Validator.isArray( maxHeightValue, true ) ) {
			currentMaxHeight = Number( maxHeightValue[ 0 ] );
			if ( !currentMaxHeight || !Validator.isPositiveNumber( currentMaxHeight ) ) {
				currentMaxHeight = 0;
			}
		}
		if ( Number( columnDescriptor.rtpDscHeight ) > currentMaxHeight ) {
			parentElement.style.maxHeight = css_height;
			// parentElement.style.lineHeight = css_height;
		}
	}

	setPadding() {
		if ( !this.isRendered ) {
			return false;
		}
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) ||
			!Validator.isString( parameters.padding ) ) {
			return false;
		}
		this.element.style.padding = parameters.padding;
		return true;
	}

	getEffectiveFont( prioFont ) {
		if ( prioFont && ( Validator.isObject( prioFont ) || Validator.isArray( prioFont ) ) ) {
			return prioFont;
		}
		const columnDescriptor = this.columnDescriptor;
		if ( Validator.isObjectPath( columnDescriptor, "columnDescriptor.rtpDscFont" ) ) {
			return columnDescriptor.rtpDscFont;
		}
		const parameters = this.parameters;
		if ( Validator.isObjectPath( parameters, "parameters.columnObject.dataFont" ) ) {
			return parameters.columnObject.dataFont;
		}
		return null;
	}

	setElementFont( prioFont ) {
		if ( !this.isRendered ) {
			return;
		}
		const eff_font = this.getEffectiveFont( prioFont );
		this.itmMgr.setFnt( this.element, eff_font );
	}

	get hasOwnBgc() {
		return Validator.isObject(this.columnDescriptor) && Validator.isArray(this.columnDescriptor.rtpDscBgc, 4);
	}

	getEffectiveColor( prioClr, bgc ) {
		if ( prioClr && ( Validator.isObject( prioClr ) || Validator.isArray( prioClr ) ) ) {
			return prioClr;
		}
		const columnDescriptor = this.columnDescriptor;
		if ( bgc ) {
			if ( Validator.isObjectPath( columnDescriptor, 'columnDescriptor.rtpDscBgc' ) ) {
				return columnDescriptor.rtpDscBgc;
			}
		} else {
			if ( Validator.isObjectPath( columnDescriptor, 'columnDescriptor.rtpDscFgc' ) ) {
				return columnDescriptor.rtpDscFgc;
			}
			const parameters = this.parameters;
			if ( Validator.isObjectPath( parameters, "parameters.columnObject.dataColor" ) ) {
				return parameters.columnObject.dataColor;
			}
		}
		return null;
	}

	setElementColor( prioClr, isBackgroundColor ) {
		if ( !this.isRendered ) {
			return;
		}
		const style = this.element.style;
		const effectiveColor = this.getEffectiveColor( prioClr, isBackgroundColor );
		const rgbaColor = XtwUtils.colorArrayToRgba( effectiveColor );
		if ( isBackgroundColor ) {
			let has_sel_bgc = false;
			style.setProperty( ITEM_SPECIFIC_BACKGROUND_COLOR, rgbaColor );
			const has_specific_bgc = this.hasOwnBgc && 
										!Color.isTransparent(this.columnDescriptor.rtpDscBgc) && 
										Validator.isArray(effectiveColor, 4) && 
										!Color.isWhite(effectiveColor) && 
										!Color.isBlack(effectiveColor);
			if ( has_specific_bgc ) {
				const sel_rgba = [];
				sel_rgba.push(effectiveColor[0]);
				sel_rgba.push(effectiveColor[1]);
				sel_rgba.push(effectiveColor[2]);
				sel_rgba.push(105);		// set alpha to ~41%
				const sel_bgc = Color.fromRgba(sel_rgba);
				if ( sel_bgc instanceof Color ) {
					style.setProperty(ITEM_SPECIFIC_SELECT_BGC, sel_bgc.stringify());
					has_sel_bgc = true;
				}
			}
			if ( !has_sel_bgc ) {
				style.removeProperty(ITEM_SPECIFIC_SELECT_BGC);
			}
		} else {
			style.setProperty( ITEM_SPECIFIC_TEXT_COLOR, rgbaColor );
		}
	}

	setElementAlignment() {
		if ( !this.isRendered ) return;
		const columnDescriptor = this.columnDescriptor;
		if ( !Validator.isObject( columnDescriptor ) ||
			!Validator.isString( columnDescriptor.rtpDscAlign ) ) return;
		this.element.style.alignItems = columnDescriptor.rtpDscAlign;
		this.element.style.alignSelf = columnDescriptor.rtpDscAlign;
		this.element.style.textAlign = columnDescriptor.rtpDscAlign;
	}

	setElementAdditionalStyle() {
		if ( !this.isRendered ) {
			return;
		}
		const columnDescriptor = this.columnDescriptor;
		if ( !Validator.isObject( columnDescriptor ) ||
			!Validator.isString( columnDescriptor.rtpDscStyle ) ) {
			return;
		}
		const descriptorStyle = columnDescriptor.rtpDscStyle.toUpperCase();
		if ( descriptorStyle.indexOf( "R" ) >= 0 ) {
			this._setRoundBorders();
		}
		if ( descriptorStyle.indexOf( "M" ) >= 0 ) {
			this._setMultiline();
		} else {
			this._removeLineHeight();
		}
	}

	setOverflow() {
		if ( !this.isRendered ) return;
		const columnDescriptor = this.columnDescriptor;
		const isHead = !Validator.isObject( columnDescriptor ) ? false :
			!!columnDescriptor.rtpDscHead;
		isHead ? this.element.classList.add( "no-overflow" ) :
			this.element.classList.remove( "no-overflow" );
	}

	setFlexGrow() {
		if ( !this.isRendered ) {
			return false;
		}
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) ||
			!Validator.isPositiveInteger( parameters.flexGrow ) ) {
			this.element.style.flexGrow = "";
			return false;
		}
		this.element.style.flexGrow = parameters.flexGrow;
		return true;
	}

	_setRoundBorders() {
		if ( !this.isRendered ) return;
		this.element.classList.add( "rtp-round-borders-container" );
	}

	_setMultiline() {
		if ( !this.isRendered ) return;
		this.element.style.whiteSpace = "pre-line";
		const parameters = this.parameters;
		if ( !Validator.isObject( parameters ) ||
			!Validator.isPositiveNumber( parameters.lineHeight, false ) )
			return this._removeLineHeight();
		if ( !this._setLineHeight( parameters.lineHeight ) )
			return this._removeLineHeight();
	}

	_setLineHeight( lineHeightValue ) {
		if ( !this.isRendered ) return false;
		if ( Validator.isValidNumber( lineHeightValue ) && lineHeightValue > 0 )
			lineHeightValue = `${ lineHeightValue }px`;
		if ( !Validator.isString( lineHeightValue ) ) return false;
		this.element.style.lineHeight = lineHeightValue;
		return true;
	}

	_removeLineHeight() {
		HtmHelper.removeStyleProperty( this.element, "lineHeight" );
		HtmHelper.removeStyleProperty( this.element, "line-height" );
	}

}

console.debug( 'widgets/xtw/rtp/XtwRowTemplateExtendedItem.js loaded.' );

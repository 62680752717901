import { EditorListener, PisaEditor } from "pisa-cke5-v2";
import CkEdt5V2 from "./CkEdt5V2";

/**
 * the editor listener implementation
 */
export default class CkEdt5Listener extends EditorListener {

    /**
     * constructs a new instance
     * @param {CkEdt5V2} cke the editor host instance
     */
    constructor(cke) {
        super();
        this._cke = cke;
    }

    /**
     * @returns {CkEdt5V2} the target editor host instance
     */
    get cke() {
        return this._cke;
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor who's content was modified
     */
    onChanged(editor) {
        this.cke.onChanged(editor);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor who's content was modified
     * @param {Boolean} focus true if the editor gained the focus; false if it lost the focus
     */
    onFocus(editor, focus) {
        this.cke.onFocus(editor, focus);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     * @param {Boolean} plain new "plain text" state
     */
    onToggleType(editor, plain) {
        this.cke.onToggleType(editor, plain);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     * @param {String} name the name of the object link command
     */
    onObjectLinkCommand(editor, name) {
        this.cke.onObjectLinkCommand(editor, name);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     * @param {String} href the clicked link
     */
    onObjectLinkClicked(editor, href) {
        this.cke.onObjectLinkClicked(editor, href);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     */
    onInsertImage(editor) {
        this.cke.onInsertImage(editor);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     */
    onMaximize(editor) {
        this.cke.onMaximize(editor);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     * @param {String} name property name
     * @param {*} value new value
     */
    onPropertyChange(editor, name, value) {
        this.cke.onPropertyChange(editor, name, value);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     */
    onDeleteAll(editor) {
        this.cke.onDeleteAll(editor);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     */
    onPlaceholdersDropdown(editor) {
        this.cke.onPlaceholdersDropdown(editor);       
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     * @param {Number} mode new placeholder mode
     */
    onPlaceholdersMode(editor, mode) {
        this.cke.onPlaceholdersMode(editor, mode);
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     */
    onPlaceholdersRefresh(editor) {
        this.cke.onPlaceholdersRefresh(editor);
    }
}
import PSA from '../../../psa';
import ItmMgr from '../../../gui/ItmMgr';
import XtwHead from '../XtwHead';
import Validator from '../../../utils/Validator';
import Warner from '../../../utils/Warner';
import EventListenerManager from '../../../utils/EventListenerManager';
import ColumnWidthManager from '../impl/column/ColumnWidthManager';
import ColumnDataColorManager from '../impl/column/ColumnDataColorManager';
import HtmHelper from '../../../utils/HtmHelper';
import XtwUtils from '../util/XtwUtils';
import XtwColTooltipExtension from '../impl/tooltip/XtwColTooltipExtension';
import CellCtt from '../model/CellCtt';
import ListenerItem from '../../../utils/ListenerItem';
import XtwBody from '../XtwBody';
import DomEventHelper from '../../../utils/DomEventHelper';
import Color from '../../../utils/Color';

export const SORT_ARROW_WIDTH = 11;
export const EDITING_PEN_WIDTH = 14;

const COL_WDTOFS = 6;
const OWN_BACKGROUND_COLOR = "--rtp-own-background-color";
const OWN_TEXT_COLOR = "--rtp-own-text-color";
const DO_LOG = false;

const MULTINE_TITLE_HEADER_VERTICAL_SPACE = 8;

/**
 * class XtwCol - a column descriptor class of the eXtended Table Widget
 */
export default class XtwCol extends ListenerItem {

	/**
	 * constructs a new instance
	 * @param {XtwHead} hdr the header widget
	 * @param {Number} id column ID
	 * @param {Object} args additional parameters
	 */
	constructor( hdr, id, args ) {
		super('widgets.xtw.XtwCol')
		this._psa = PSA.getInst();
		this.xthWdg = hdr;
		Object.defineProperties(this, {
			_id: {
				value: id,
				writable: false,
				configurable: false
			},
			_dsc: {
				value: args.dsc || '',
				writable: false,
				configurable: false
			}
		});
		this.available = true;
		if ( args.avl !== undefined ) {
			this.available = !!args.avl;
		}
		this.visible = true;
		if ( args.vis !== undefined ) {
			this.visible = !!args.vis;
		}
		if ( !this.available ) {
			this.visible = false;
		}
		this.select = !!args.select;
		this.cellbgc = this.select ? ( args.cellbgc || null ) : null;
		this.fix = !!args.fix || this.select;
		this.type = args.type || 0;
		this.dropdown = Validator.isBoolean( args.dropdown ) ? args.dropdown : false;
		this.ctt = args.ctt || CellCtt.EMPTY_CONTENT;
		this.align = args.aln || '';
		this.ttip = args.ttip || '';
		this.image = args.image || null;
		this.hasEditingPen = args.hasEditingPen || false;
		this.width = args.width || 0;
		this.font = args.font || null;
		this.link = !this.select && !!args.link;
		this.defSortDir = args.direction || false;
		this.dataFont = args.dataFont || null;
		this.dataAlign = null;
		this.hasOwnBgc = false;
		this._columnDataColorManager = new ColumnDataColorManager();
		new ColumnWidthManager( this );
		new XtwColTooltipExtension( this );
		this.clrBrd = null;
		this.secImg = null;
		this.element = null;
		this.spans = {};
		this.sortedDescending;
		const defWidth  = Validator.isPositiveNumber( args.defWidth ) ? args.defWidth : 0;
		const tagHeight = Validator.isPositiveNumber( args.height ) ? args.height : 0;
		const isBlob = !!args.isBlob;
		const maxCharacterCount = Validator.isPositiveInteger( args.maxCharacterCount ) ? args.maxCharacterCount : 999;
		const allowsMultilineTitle = !!args.allowsMultilineTitle;
		const contentBreakMode = args.contentBreakMode || {};
		Object.defineProperties( this, {
			_defWidth: {
				value: defWidth,
				writable: false,
				configurable: false
			},
			_tagHeight: {
				value: tagHeight,
				writable: false,
				configurable: false
			},
			_isBlob: {
				value: isBlob,
				writable: false,
				configurable: false
			},
			_maxCharacterCount: {
				value: maxCharacterCount,
				writable: false,
				configurable: false
			},
			_allowsMultilineTitle: {
				value: allowsMultilineTitle,
				writable: false,
				configurable: false
			},
			_contentBreakMode: {
				value: contentBreakMode,
				writable: false,
				configurable: false
			}
		} );

		const self = this;
		[ "mainFontColor", "alternativeFontColor", "mainBackgroundColor", "alternativeBackgroundColor"].forEach( property => {
			Object.defineProperty( self, property, {
				get: () => {
					return self.columnDataColorManager[ property ];
				},
				set: ( color ) => {
					self.columnDataColorManager[ property ] = color;
				},
				configurable: false
			} );
		} );

	}

	/**
	 * called on destruction
	 * @override
	 */
	doDestroy() {
		this.removeAllListeners();
		if ( this._mdnLsr ) {
			this.spans.wa.removeEventListener( 'mousedown', this._mdnLsr );
		}
		if ( this._clkLsr ) {
			this.element.removeEventListener( 'click', this._clkLsr );
		}
		delete this._mdnLsr;
		delete this._clkLsr;
		delete this.cellbgc;
		delete this.clrBrd;
		delete this.ctt;
		delete this.dataFont;
		delete this.font;
		delete this.image;
		delete this.secImg;
		delete this.element;
		delete this.spans;
		delete this.xthWdg;
		super.doDestroy();
	}

	/**
	 * @returns {Number} the column ID
	 */
	get id() {
		return this._id;
	}

	/**
	 * @returns {String} the column's tag descriptor name
	 */
	get dsc() {
		return this._dsc;
	}

	/**
	 * @returns {Number} the default width in pixels
	 */
	get defWidth() {
		return this._defWidth;
	}

	/**
	 * @returns {Number} the height of the DAT tag in pixels
	 */
	get tagHeight() {
		return this._tagHeight;
	}

	/**
	 * @returns {Boolean} true if this column is a BLOB column
	 */
	get isBlob() {
		return this._isBlob;
	}

	/**
	 * @returns {Number} the maximal allowed content length of a data cell
	 */
	get maxCharacterCount() {
		return this._maxCharacterCount;
	}

	/**
	 * @returns {Boolean} true if a multi-line column title is allowed
	 */
	get allowsMultilineTitle() {
		return this._allowsMultilineTitle;
	}

	/**
	 * @returns {Object} the text break mode specification
	 */
	get contentBreakMode() {
		return this._contentBreakMode;
	}

	/**
	 * @return {ColumnDataColorManager} the column data color manager
	 */
	get columnDataColorManager() {
		return this._columnDataColorManager;
	}

	/**
	 * returns the visibility flag
	 * @returns {Boolean} true if this column is visible; false otherwise
	 */
	isVisible() {
		return this.visible;
	}

	/**
	 * returns the effective width of this column (returns 0 for invisible columns)
	 * @returns {Number} the effective width of this column
	 */
	getWidth() {
		return this.visible ? this.width : 0;
	}

	/**
	 * returns the font to be used for data cells
	 * @returns {Object} the font descriptor
	 */
	getDataFont() {
		return this.dataFont;
	}

	get blobHeightDefined() {
		return !!this.isBlob && Validator.isPositiveNumber( this.tagHeight, false );
	}

	/**
	 * renders this column
	 * @param {HTMLElement} ce the main HTML element of this column
	 */
	render( ce ) {
		this.element = ce;
		if ( !this.select ) {
			const dsc = this.dsc;
			if ( Validator.isString(dsc) ) {
				ce.dataset.testid = `psa-XTW-${dsc}`;
			}
		} else {
			ce.dataset.testid = 'psa-XTW-SELECT';
		}
		if ( this.select ) {
			this.renderSelectColumnHeaderCell();
			this.updateSelectColumnHeaderCell();
		} else {
			this.renderRegularColumnHeaderCell();
			this.updateRegularColumnHeaderCell();
		}
		// initialize column event handling
		this._initEvt();
		// add listeners to enable column movement
		this.addColumnMoveListeners();
		// add listeners for context context menu
		this.addContextMenuListeners();
		// add other listeners
		this.addGeneralListeners();
		// add column tooltip listeners
		this.addTooltipListeners();
		// add width-related listeners
		this.addWidthAdjustmentSpanDoubleClickListener();
		this.addWidthAdjustmentSpanMouseDownListener();
	}

	renderRegularColumnHeaderCell() {
		if ( !( this.element instanceof HTMLElement ) ) {
			return false;
		}
		// basic setup
		const marker = 'column:' + this.id;
		this.element.__psanfo = marker;
		this.element.__cid = this.id;
		// store spans
		this.spans.st = this.newTextSpan;
		this.spans.si = this.newIconSpan;
		this.spans.ss = this.newSecondaryIconSpan;
		this.spans.sa = this.newSortingArrowSpan;
		this.spans.sp = this.newEditingPenIconSpan;
		this.spans.wa = this.newWidthAdjustmentSpan;
		// mark spans
		[ this.spans.si, this.spans.st, this.spans.ss ].forEach( span => { span.__psanfo = marker; } );
		const ss = this.spans.ss;
		if ( ss instanceof HTMLElement ) {
			const self = this;
			ss.addEventListener('click', (e) => self.onSecondIconClicked(e) );
		}
		// add spans
		this._addSpans(false);
		return true;
	}

	renderSelectColumnHeaderCell() {
		if ( !( this.element instanceof HTMLElement ) ) {
			return false;
		}
		// basic setup
		const marker = 'column:' + this.id;
		this.element.__psanfo = marker;
		this.element.__cid = this.id;
		this.element.classList.add( 'xtwcellselect' );
		const span = this.newSelectAllSpan;
		span.appendChild( this.newSelectColumnBorderSpan );
		this.element.appendChild( span );
		return true;
	}

	/**
	 * updates the column icon
	 * @param {Boolean} pen "editing pen" flag
	 * @param {Object|null} icon image descriptor
	 */
	updateIcon(pen, icon) {
		if ( !this.select ) {
			const changed = (this.hasEditingPen !== pen) || (this.image !== icon);
			this.hasEditingPen = !!pen;
			if ( !this.hasEditingPen ) {
				this.image = icon;
			} else {
				this.image = null;
			}
			if ( changed ) {
				this.update();
			}
		}
	}

	update() {
		this.select ? this.updateSelectColumnHeaderCell() : this.updateRegularColumnHeaderCell();
	}

	updateRegularColumnHeaderCell() {
		if ( !this.element ) {
			// not yet rendered
			return;
		}
		const im = ItmMgr.getInst();
		// set basic style
		const ce = this.element;
		im.setFlexWdt( ce, this.width, true );
		XtwUtils.syncZeroWidthClass( ce, this.width );
		ce.title = this.ttip;
		// set font
		im.setFnt( ce, this.font );
		// create images
		let iwd = COL_WDTOFS;

		const si = this.spans.si;
		const icw = this._setImg( si, this.image, true, true );
		iwd += icw;

		const ss = this.spans.ss;
		const siw = this._setImg( ss, this.secImg, false, false );
		iwd += siw;

		const sa = this.spans.sa;
		const arrowSpanWidth = sa instanceof HTMLElement ? SORT_ARROW_WIDTH : 0;
		iwd += arrowSpanWidth;

		if ( this.spans.sp instanceof HTMLElement ) {
			const editingPenSpan = this.spans.sp;
			editingPenSpan.innerHTML = '';
			editingPenSpan.style.width = '0';
			if ( this.hasEditingPen ) {
				editingPenSpan.appendChild( this.newPenIcon );
				editingPenSpan.style.width = `${EDITING_PEN_WIDTH}px`;
				iwd += EDITING_PEN_WIDTH;
			}
		}

		// set column style and content
		this.setColumnTitleContent();
		// set main text's alignment
		const st = this.spans.st;
		if ( this.ctt && this._psa.isStr( this.ctt.text ) ) {
			// st.style.width = '' + ( this.width - iwd ) + 'px';
			st.style.textAlign = this.align ? this.align : '';
			HtmHelper.justifyContentBasedOnTextAlignment( st );
		} else {
			// no text available - we should expand the icon...
			st.innerHTML = '';
			st.style.width = '0px';
			si.style.textAlign = this.align ? this.align : '';
			si.style.width = '' + ( this.width - siw - COL_WDTOFS ) + 'px';
			HtmHelper.justifyContentBasedOnTextAlignment( si );
		}
		ce.__fix = this.fix;
		this._syncArrowWithSortState();
		ce.style.display = this.visible ? 'flex' : 'none';
		XtwUtils.syncZeroWidthClass( ce, this.getWidth() );
	}

	updateSelectColumnHeaderCell() {
		if ( !this.element ) {
			// not yet rendered
			return;
		}
		const im = ItmMgr.getInst();
		// set basic style
		const ce = this.element;
		im.setFlexWdt( ce, this.width, true );
		XtwUtils.syncZeroWidthClass( ce, this.width );
		ce.title = this.ttip;
		if ( this.ctt ) {
			const properties = this.ctt.prop || {};
			im.setBkgClr( ce, properties.bgc, false );
		}
		ce.__fix = true;
		ce.style.display = this.visible ? 'flex' : 'none';
		XtwUtils.syncZeroWidthClass( ce, this.getWidth() );
	}

	setColumnTitleContent() {
		if ( !Validator.isObject( this.spans ) ||
			!Validator.isObject( this.ctt ) ) {
			return;
		}
		const ce = this.element;
		if ( !( ce instanceof HTMLElement ) ) {
			return;
		}
		const properties = this.ctt.prop;
		const backgroundColor = Validator.isObject( properties ) ? properties.bgc : null;
		const rgbaBackgroundColor = XtwUtils.colorArrayToRgba( backgroundColor );
		if ( Validator.isString( rgbaBackgroundColor ) ) {
			ce.style.setProperty( OWN_BACKGROUND_COLOR, rgbaBackgroundColor );
		}
		const color = Validator.isObject( properties ) ? properties.txc : null;
		const rgbaColor = XtwUtils.colorArrayToRgba( color );
		if ( Validator.isString( rgbaColor ) ) {
			ce.style.setProperty( OWN_TEXT_COLOR, rgbaColor );
		}
		this.configureMainTextSpan( properties );
		// the following code is only useful for header height self adjustment
		// CallbackManager.executeAsync( () => {
		// 	this.adjustHeaderHeight();
		// } );
	}

	configureMainTextSpan( properties ) {
		// this.multilineHeaderContent = false;
		if ( !Validator.isObject( this.spans ) ||
			!( this.spans.st instanceof HTMLElement ) ) {
			return false;
		}
		const itemManager = ItmMgr.getInst();
		const mainTextSpan = this.spans.st;
		if ( Validator.is( itemManager, "ItmMgr" ) ) {
			const font = Validator.isObject( properties ) ? properties.font : null;
			itemManager.setFnt( mainTextSpan, font );
		}
		let mainSpanContent = this.ctt.text;
		if ( !Validator.isString( mainSpanContent ) ) {
			return true;
		}
		const numberOfContentLines = Validator.getMatchesCount( mainSpanContent, "\n" ) + 1;
		if ( numberOfContentLines < 2 ) {
			mainTextSpan.innerHTML = mainSpanContent;
			return true;
		}
		if ( !this.allowsMultilineTitle ) {
			Warner.traceIf( DO_LOG, `The title for ${ this.dsc } does not allow` +
				` multilne content through it's style tag. However, the title` +
				` content has ${ numberOfContentLines } lines that will be` +
				` displayed inline.` );
			mainTextSpan.innerHTML = mainSpanContent;
			return true;
		}
		mainSpanContent = mainSpanContent.replace( /\n/g, "<br/>" );
		mainTextSpan.innerHTML = mainSpanContent;
		// this.multilineHeaderContent = true;
		return true;
	}

	/**
	 * @deprecated since 12.01.2022
	 * the following code is only useful for header height self adjustment
	 */
	adjustHeaderHeight() {
		if ( !this.multilineHeaderContent || !Validator.isObject( this.spans ) || !( this.spans.st instanceof HTMLElement ) ) {
			return false;
		}
		const headClientHeight = this.xthWdg.headClientHeight;
		if ( !Validator.isPositiveNumber( headClientHeight ) ) {
			return false;
		}
		const textSpanMeasurements = ItmMgr.getInst()
			.measureText( this.spans.st, true, null, true );
		let measuredContentHeight =
			Validator.isObjectPath( textSpanMeasurements, ".wts" ) ?
			textSpanMeasurements.wts.cy : void 0;
		if ( !measuredContentHeight ) {
			measuredContentHeight =
				Validator.isObjectPath( textSpanMeasurements, ".sts" ) ?
				textSpanMeasurements.sts.cy : void 0;
		}
		if ( !Validator.isPositiveNumber( measuredContentHeight ) ) {
			return false;
		}
		if ( headClientHeight >=
			measuredContentHeight + MULTINE_TITLE_HEADER_VERTICAL_SPACE ) {
			return true;
		}
		Warner.traceIf( true, measuredContentHeight );
		if ( !Reflect.has( this.xthWdg, "headClientHeight" ) ) {
			return false;
		}
		this.xthWdg.headClientHeight = measuredContentHeight + MULTINE_TITLE_HEADER_VERTICAL_SPACE;
		return true;
	}

	setDataFontColor( args ) {
		const mainFontColor = Validator.isObject( args ) && Validator.isArray( args.txc, 4 ) ? Color.fromRgba( args.txc ) : null;
		const got_new = mainFontColor instanceof Color;
		const has_prv = this.mainFontColor instanceof Color;

		const changed = (got_new !== has_prv) || (got_new && has_prv && !mainFontColor.equals(this.mainFontColor));

		this.mainFontColor = mainFontColor;
		this.alternativeFontColor = mainFontColor;

		// TODO: the font color might be adjusted in the future, but for now it should be the same

		return changed;
	}

	setDataBackgroundColor( args ) {
		const mainBackgroundColor = Validator.isObject( args ) && Validator.isArray( args.bgc, 4 ) ? Color.fromRgba( args.bgc ) : null;
		
		const got_new = mainBackgroundColor instanceof Color;
		const has_prv = this.mainBackgroundColor instanceof Color;
		const changed = (got_new !== has_prv) || (got_new && has_prv && !mainBackgroundColor.equals(this.mainBackgroundColor));

		this.mainBackgroundColor = mainBackgroundColor;
		this.alternativeBackgroundColor = mainBackgroundColor;
		if ( mainBackgroundColor instanceof Color ) {
			this.adjustDataBackgroundColor();
			this.hasOwnBgc = true;
		} else {
			this.hasOwnBgc = false;
		}

		return changed;
	}

	adjustDataColors() {
		// TODO: the font color might be adjusted in the future, but for now it should be the same
		this.adjustDataBackgroundColor();
	}

	adjustDataBackgroundColor() {
		if ( !Validator.isObjectPath( this.xthWdg, "xthWdg.xtdTbl.cssProperties" ) ||
			!( this.xthWdg.xtdTbl.cssProperties.mainPyjamaColor instanceof Color ) ||
			!( this.xthWdg.xtdTbl.cssProperties.secondPyjamaColor instanceof Color ) ) {
			return false;
		}
		const mainBackgroundColor = this.mainBackgroundColor;
		if ( !( mainBackgroundColor instanceof Color ) ) {
			return true;
		}
		const mainPyjamaColor = this.xthWdg.xtdTbl.cssProperties.mainPyjamaColor;
		const secondPyjamaColor = this.xthWdg.xtdTbl.cssProperties.secondPyjamaColor;
		const adjustment = secondPyjamaColor._getDifferenceTo( mainPyjamaColor );
		this.alternativeBackgroundColor = mainBackgroundColor.adjustSelf( adjustment );
		return true;
	}

	/**
	 * evaluates an image descriptor and creates an image object or removes the image object from
	 * the hosting element
	 * @param {HTMLElement} span spand that hosts the image
	 * @param {Object} ids image descriptor
	 * @param {Boolean} first flag whether this is the first (main) icon
	 * @param {Boolean} setw flag whether to set the width of the DOM element
	 * @returns {Number} the required width in pixels for the image
	 */
	_setImg( span, ids, first, setw ) {
		let iwd = 0;
		if ( ids ) {
			const isz = ids.isz || {};
			let cx = ( isz.cx || 0 );
			// drop old content (if any)
			span.innerHTML = '';
			// create image element
			const img = ItmMgr.getInst().creImg( ids, cx, cx >= 16 );
			if ( img instanceof HTMLElement ) {
				// ok, append image element
				if ( ids.typ === 'DSC' ) {
					img.style.verticalAlign = 'middle';
				}
				if ( first ) {
					// just add some space for the icon, since the CSS adds a left padding - see rule "div.xtwhead-container div.xtwhead div[title] span:first-child"
					cx += 4;
				}
				if ( this._psa.isStr( span.__psanfo ) ) {
					img.__psanfo = span.__psanfo;
				}
				span.appendChild( img );
				span.style.paddingRight = '2px';
				if ( setw ) {
					cx += 2;
					span.style.width = cx + 'px';
				} else {
					span.style.width = '';
				}
			} else {
				// what is this?! Invalid image descriptor!
				cx = 0;
				span.style.width = '0';
			}
			iwd += cx;
		} else {
			// no image, no width, nothing, rien, niente, nada, nix
			span.innerHTML = '';
			span.style.width = '0';
		}
		return iwd;
	}

	_initEvt() {
		if ( !this.select ) {
			this._clkLsr = null;
			const wa = this.spans.wa;
			const lsr = this._psa.bind( this, this.onWidthAdjustmentSpanMouseDown );
			this._mdnLsr = lsr;
			wa.addEventListener( 'mousedown', lsr );
		} else {
			this._mdnLsr = null;
			const ce = this.element;
			const lsr = this._psa.bind( this, this.onClick );
			this._clkLsr = lsr;
			ce.addEventListener( 'click', lsr );
		}
	}

	/**
	 * adds the child span elements to the cell's DOM element
	 * @param {Boolean} rf "remove first" flag
	 */
	_addSpans(rf) {
		const csa = [];
		if ( this.align !== 'right') {
			// default (left) or centered alignment
			csa.push(this.spans.sp);
			csa.push(this.spans.si);
			csa.push(this.spans.st);
			csa.push(this.spans.ss);
			csa.push(this.spans.sa);
			csa.push(this.spans.wa);
		} else {
			// right alignment
			csa.push(this.spans.sp);
			csa.push(this.spans.si);
			csa.push(this.spans.ss);
			csa.push(this.spans.sa);
			csa.push(this.spans.st);
			csa.push(this.spans.wa);
		}
		const ce = this.element;
		if ( rf ) {
			// remove all elements first from the main element
			csa.forEach( span => {
				if ( rf && span.parentElement ) {
					ce.removeChild(span);
				}
			});
		}
		// append spans to the main element
		csa.forEach( span => { 
			ce.appendChild( span );
		} );

		// update style of sorting arrow span
		if ( this.spans.sa ) {
			const right = this.align === 'right';
			const sa = this.spans.sa;
			sa.style.paddingLeft = right ? '0px' : '2px';
			sa.style.paddingRight = right ? '2px' : '0px';
		}
	}

	/**
	 * gets & returns the "rendered" status of this item, which is true when this
	 * item has a valid HTML element and false otherwise
	 * @return {Boolean} true if element is present & valid, false otherwise
	 */
	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	/**
	 * gets & returns the HTML span element of this item that contains the text
	 * (title), has the biggest width (takes the most place) and therefore is
	 * considered/viewed as the "main" span element
	 * @return {HTMLSpanElement} the main span element, if present; "undefined"
	 * otherwise
	 */
	get mainSpan() {
		if ( !Validator.isObject( this.spans ) ) return void 0;
		return this.spans.st instanceof HTMLElement ? this.spans.st : void 0;
	}

	/**
	 * returns a string that can be used in menu items for some kind of column selection menus etc.
	 * @returns {String} a text suitable for menu items
	 */
	get menuText() {
		if ( this._psa.isStr( this.ctt.text ) ) {
			// we've got a title - that's to be used here
			return this.ctt.text;
		} else if ( this._psa.isStr( this.ttip ) ) {
			// we've got no title but a tooltip, so we use this...
			const parts = this.ttip.split( /\s|\:|,|\.|;|\?|\!/g );
			let text = '';
			let cnt = 0;
			for ( let i = 0;
				( i < parts.length ) && ( cnt < 3 ); ++i ) {
				const p = parts[ i ];
				if ( this._psa.isStr( p ) ) {
					if ( cnt > 0 ) {
						text += ' ';
					}
					text += p;
					++cnt;
				}
			}
			if ( cnt < parts.length ) {
				text += '...';
			}
			return text;
		} else {
			// we're lost
			return '';
		}
	}

	get sortArrowSpan() {
		if ( !Validator.isObject( this.spans ) ) return void 0;
		return this.spans.sa instanceof HTMLElement ? this.spans.sa : void 0;
	}

	get editingPenSpan() {
		if ( !Validator.isObject( this.spans ) ) return void 0;
		return this.spans.sp instanceof HTMLElement ? this.spans.sp : void 0;
	}

	/**
	 * changes the aligment setting of the column header
	 * @param {String} aln new column header alignment
	 */
	setAlignment(aln) {
		if ( this.align !== aln ) {
			this.align = aln || '';
			if ( this.element ) {
				// re-order heander cell's spans
				this._addSpans(true);
				// update column header
				this.update();
			}
		}
	}

	/**
	 * explicitly sets the sorting state
	 * @param {Boolean} desc "descending" flag; if null the this column is not the sorting column
	 */
	setSortingState( desc ) {
		if ( desc !== null ) {
			this.sortedDescending = !!desc;
		} else {
			this._voidSort();
		}
		this._syncArrowWithSortState();
	}

	toggleSortingState() {
		const isSortedDescending = this._toggleSort();
		this._syncArrowWithSortState();
		return isSortedDescending;
	}

	voidSortingState() {
		const isSortedDescending = this._voidSort();
		this._syncArrowWithSortState();
		return isSortedDescending;
	}

	_toggleSort() {
		if ( typeof this.sortedDescending !== 'boolean' ) {
			this.sortedDescending = !!this.defSortDir;
			return this.sortedDescending;
		}
		this.sortedDescending = !this.sortedDescending;
		return this.sortedDescending;
	}

	_voidSort() {
		this.sortedDescending = void 0;
		return this.sortedDescending;
	}

	_syncArrowWithSortState() {
		const sortArrowSpan = this.sortArrowSpan;
		if ( !( sortArrowSpan instanceof HTMLElement ) ) {
			return false;
		}
		sortArrowSpan.innerHTML = "";
		sortArrowSpan.style.width = '0px';
		if ( !Validator.isBoolean( this.sortedDescending ) ) {
			return true;
		}
		const arrowIcon = !!this.sortedDescending ? this.newArrowDownIcon : this.newArrowUpIcon;
		if ( !( arrowIcon instanceof HTMLElement ) ) {
			return false;
		}
		sortArrowSpan.appendChild( arrowIcon );
		sortArrowSpan.style.width = '' + SORT_ARROW_WIDTH + 'px';
		return true;
	}

	get newArrowWrapper() {
		const wrapper = document.createElement('span');
		wrapper.className = 'xtwsawrapper';
		wrapper.style.paddingLeft = '0';
		wrapper.style.paddingRight = '0';
		return wrapper;
	}

	get newArrowDownIcon() {
		const wrapper = this.newArrowWrapper;
		const icon = window.document.createElement( "i" );
		[ "far", "fa-angle-down" ].forEach( className => {
			icon.classList.add( className );
		} );
		icon.style.verticalAlign = "middle";
		wrapper.appendChild(icon);
		return wrapper;
	}

	get newArrowUpIcon() {
		const wrapper = this.newArrowWrapper;
		const icon = window.document.createElement( "i" );
		[ "far", "fa-angle-up" ].forEach( className => {
			icon.classList.add( className );
		} );
		icon.style.verticalAlign = "middle";
		wrapper.appendChild(icon);
		return wrapper;
	}

	get newIconSpan() {
		const iconSpan = document.createElement( 'span' );
		iconSpan.className = 'xtwcolmi';
		if ( iconSpan.dataset instanceof DOMStringMap ) {
			iconSpan.dataset.content = "main icon";
		}
		return iconSpan;
	}

	get newTextSpan() {
		const itemManager = ItmMgr.getInst();
		const textSpan = document.createElement( 'span' );
		textSpan.className = 'xtwcoltx';
		itemManager.setTxtOvrFlw( textSpan, true );
		if ( textSpan.dataset instanceof DOMStringMap ) {
			textSpan.dataset.content = "text";
		}
		return textSpan;
	}

	get newSecondaryIconSpan() {
		const secondaryIconSpan = document.createElement( 'span' );
		secondaryIconSpan.className = 'xtwcolsi';
		if ( secondaryIconSpan.dataset instanceof DOMStringMap ) {
			secondaryIconSpan.dataset.content = "secondary icon";
		}
		return secondaryIconSpan;
	}

	get newEditingPenIconSpan() {
		const editingPenIconSpan = document.createElement( "span" );
		editingPenIconSpan.className = 'xtwcoled';
		editingPenIconSpan.style.paddingLeft = '2px';
		if ( editingPenIconSpan.dataset instanceof DOMStringMap ) {
			editingPenIconSpan.dataset.content = "editing pen icon";
		}
		return editingPenIconSpan;
	}

	get newSortingArrowSpan() {
		const sortingArrowSpan = document.createElement( "span" );
		sortingArrowSpan.className = 'xtwcolsa';
		if ( sortingArrowSpan.dataset instanceof DOMStringMap ) {
			sortingArrowSpan.dataset.content = "sorting arrow";
		}
		sortingArrowSpan.style.width = '0px';
		sortingArrowSpan.style.maxWidth = '' + SORT_ARROW_WIDTH + 'px';
		sortingArrowSpan.style.fontSize = "14px";
		return sortingArrowSpan;
	}

	get newWidthAdjustmentSpan() {
		const widthAdjustmentSpan = document.createElement( 'span' );
		if ( widthAdjustmentSpan.dataset instanceof DOMStringMap ) {
			widthAdjustmentSpan.dataset.content = "width adjustment";
		}
		widthAdjustmentSpan.className = 'xtwcolwa';
		widthAdjustmentSpan.innerHTML = '&nbsp;';
		return widthAdjustmentSpan;
	}

	get newPenIcon() {
		const wrapper = document.createElement('span');
		wrapper.className = 'xtwpenwrapper';
		wrapper.style.paddingLeft = '0';
		const italicTag = window.document.createElement( "i" );
		italicTag.classList.add( "fal", "fa-pen" );
		italicTag.style.fontSize = "10px";
		wrapper.appendChild(italicTag);
		return wrapper;
	}

	get newSelectAllSpan() {
		const selectAllSpan = window.document.createElement( "span" );
		selectAllSpan.style.backgroundColor = 'transparent';
		return selectAllSpan;
	}

	get newSelectColumnBorderSpan() {
		const span = window.document.createElement( 'span' );
		span.className = 'xtwselectborder';
		// span.style.height = '50%';	// we *must* set this here, the rule provided by the class gets overruled by other CSS classes; keep in sync. with "spa.xtwcolwa"
		return span;
	}

	/**
	 * @returns {XtwHead} the table header widget
	 */
	get xtwHead() {
		return this.xthWdg;
	}


	/**
	 * @returns {XtwBody} the table body
	 */
	get xtwBody() {
		return this.xthWdg.xtwBody;
	}

	/**
	 * @deprecated
	 */
	get selectionManager() {
		this.warn('The property "selectionManager" is deprecated!');
		return null;
	}

	/**
	 * @deprecated
	 */
	get firstSelectedXRowItem() {
		this.warn('The property "firstSelectedXRowItem" is deprecated!');
		return null;
	}

	/**
	 * @deprecated
	 */
	get firstVisibleXRowItem() {
		this.warn('The property "firstVisibleXRowItem" is deprecated!');
		return null;
	}


	addContextMenuListeners() {
		return this.addListener('contextmenu', 'onContextMenu');
	}

	notifyServer( notificationCode, parameters = {} ) {
		if ( !Validator.isString( notificationCode ) ) {
			return false;
		}
		if ( !Validator.isObject( parameters ) ) {
			parameters = {};
		}
		parameters.idc = this.id;
		this.xthWdg._nfySrv( notificationCode, parameters );
		return true;
	}

	onContextMenu( evt ) {
		evt.preventDefault();
		evt.stopPropagation();
		this.xthWdg.onHeaderContextMenu( evt, this );
	}

	addGeneralListeners() {
		return this.addListener('mousedown', 'onMouseDown');
	}

	/**
	 * adds listeners to this item's element and to this item's "main span"
	 * element that are meant to assist the "column movement" process
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	addColumnMoveListeners() {
		const mouseUpListenerAdded = this.addListener('mouseup', 'onMouseUp');
		const mainSpan = this.mainSpan;
		if ( !Validator.isObject( mainSpan ) ) {
			return mouseUpListenerAdded;
		}
		const mouseDownListenerAdded = this.addPrefixListener('mousedown', 'onMainSpanMouseDown', 'MainSpan', mainSpan, false);
		return mouseDownListenerAdded && mouseUpListenerAdded;
	}

	removeAdditionalListeners() {
		EventListenerManager.removeListener( this, "mousedown", this.mainSpan, "MainSpan" );
		[ "mouseup", "mousedown", "contextmenu" ].forEach( eventName => {
			EventListenerManager.removeListener( this, eventName );
		} );
	}

	/**
	 * handles the "mousedown" event when it happens on the "main span" of this
	 * item
	 * @param {MouseEvent} evt the "mousedown" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onMainSpanMouseDown( evt ) {
		if ( !( evt instanceof MouseEvent ) || evt.button !== 0 ) {
			return false;
		}
		return this.xthWdg.spanMouseDown( evt, this );
	}

	onMouseDown( evt ) {
		if ( !( evt instanceof MouseEvent ) ) {
			return false;
		}
		this.xthWdg.mouseDownEvent = evt;
		return true;
	}

	/**
	 * handles the "mouseup" event when it happens on this item's element
	 * @param {MouseEvent} evt the "mouseup" event
	 * @return {Boolean} true if the operation was successful, false otherwise
	 */
	onMouseUp( evt ) {
		if ( !( evt instanceof MouseEvent ) || evt.button !== 0 ) {
			return false;
		}
		const tgt = evt.target;
		const ss = this.spans.ss;
		if ( ( ss instanceof HTMLElement ) && ( tgt instanceof HTMLElement ) ) {
			if ( ( ss == tgt ) || ss.contains( tgt ) ) {
				// that's a click on the secondary (language) icon
				DomEventHelper.stopEvent(evt);
				return true;
			}
		}
		return this.xthWdg.columnTitleMouseUp( evt, this );
	}

	onWidthAdjustmentSpanMouseDown( evt ) {
		if ( !( evt instanceof MouseEvent ) || evt.button !== 0 ) {
			return false;
		}
		this.xthWdg.mouseDownEvent = evt;
		this.xthWdg.onColumnDrag( evt, this, this.spans.wa, false );
		return true;
	}

	onClick( evt ) {
		if ( evt.button === 0 ) {
			return this.xthWdg.onSelClick( evt );
		}
		return false;
	}

	onSecondIconClicked(evt) {
		DomEventHelper.stopEvent(evt);
		const tgt = evt.target;
		const ss = this.spans.ss;
		if ( ( ss instanceof HTMLElement ) && ( tgt instanceof HTMLElement ) ) {
			if ( ( ss == tgt ) || ss.contains( tgt ) ) {
				// that's a click on the secondary (language) icon
				this.xthWdg.onSecondIconClicked( this );
			}
		}
	}
}

import Base from '../../base/base';
import MnuMgr from './MnuMgr';
import MnuItm from './MnuItm';
import ItmMgr from '../ItmMgr';
import ObjReg from '../../utils/ObjReg';
import EscHandler from '../../key/EscHandler';

/** "out of sight" position :-) */
const OUT_POS = '-100000px';
/** height in pixels of the scroll buttons */
export const BTN_HGT = 24;
/** "scroll up" arrow */
const SCR_UP = '<i class="far fa-angle-up" style="font-size:14px;"></i>';
/** "scroll down" arrow */
const SCR_DWN = '<i class="far fa-angle-down" style="font-size:14px;"></i>';

/**
 * an [Esc] handler class for menus
 */
class MenuEscHandler extends EscHandler {

	/**
	 * constructs a new instance
	 * @param {MnuObj} menu 
	 */
	constructor(menu) {
		super();
		this.tgtMenu = menu;
	}

    /**
     * handles [Esc] key strokes
     * @param {KeyboardEvent} e the keyboard event
     * @returns {Boolean} true if the event has been handled and should not be propagated any further; false for common event handling
	 * @override
     */
    handleEsc(e) {
		if ( this.tgtMenu && this.tgtMenu.alive ) {
			if ( (e.type === 'keydown') && (e.key === 'Escape') ) {
				this.tgtMenu.lock(false);
				this.tgtMenu.mnuMgr.hideMenu(this.tgtMenu);
				return true;
			}
		}
        return false;
    }
}

/**
 * a menu object
 */
export default class MnuObj extends Base {

	/**
	 * constructs a new instance
	 * @param {MnuMgr} mgr the menu manager
	 * @param {MnuItm} par parent menu item
	 * @param {Number} idm menu ID
	 * @param {Array} items the menu item descriptors
	 */
	constructor( mgr, par, idm, items ) {
		super();
		this._psa = mgr.psa;
		this._im = ItmMgr.getInst();
		// the main div
		const mnu_div = document.createElement( 'div' );
		mnu_div.id = '_psa_popmnu_' + idm;
		mnu_div.className = 'psamnuobj';
		mnu_div.style.display = 'none';
		mnu_div.style.top = OUT_POS;
		mnu_div.style.left = OUT_POS;
		this._im.setFnt( mnu_div, mgr.mnuFnt );
		this._im.setBkgClr( mnu_div, mgr.mnuBgc, false );
		this._im.setFgrClr( mnu_div, mgr.mnuTxc );
		// [Esc] key handler
		this.escHandler = new MenuEscHandler(this);

		// scroll buttons
		const btn_up = this._creScrBtn( mgr, true );
		const btn_dwn = this._creScrBtn( mgr, false );
		// scrolling host
		const scr_host = document.createElement( 'div' );
		scr_host.style.overflow = 'hidden';
		this.scrHeight = 0;
		this.scrIdx = 0;
		this.scrPos = 0;

		// item host
		const itm_host = document.createElement( 'div' );
		itm_host.style.display = 'flex';
		itm_host.style.flexDirection = 'column';
		itm_host.style.position = 'relative';
		itm_host.style.top = '0px';
		this.onMouseWheel = this._psa.bind( this, this._onMouseWheel );
		const opts = this._psa.canPassiveListeners ? { passive: true } : false;
		itm_host.addEventListener( 'wheel', this.onMouseWheel, opts );

		// create DOM sub tree
		scr_host.appendChild( itm_host );
		mnu_div.appendChild( btn_up );
		mnu_div.appendChild( scr_host );
		mnu_div.appendChild( btn_dwn );

		this.mnuID = idm;
		this.mnuMgr = mgr;
		this.parItm = par;
		this.mnuDiv = mnu_div;
		this.btnUp = btn_up;
		this.btnDwn = btn_dwn;
		this.scrHost = scr_host;
		this.itmHost = itm_host;
		this.mnuItems = new ObjReg();
		this.mnuHgt = 0;
		this.orgWdt = null;
		this.pfrLft = false;
		this.canScroll = false;
		this._creItems( items );
		this.popIdm = 0;
		this._locked = false;
	}

	/**
	 * @returns {Boolean} true if this menu object is locked; false otherwise
	 */
	get locked() {
		return this._locked;
	}

	/**
	 * destructor method
	 * @override
	 */
	doDestroy() {
		if ( this.alive ) {
			this.mnuItems.destroy();
			this.itmHost.removeEventListener( 'wheel', this.onMouseWheel );
			delete this.onMouseWheel;
			delete this.itmHost;
			delete this.btnUp;
			delete this.btnDwn;
			delete this.scrHost;
			delete this.escHandler;
			this._psa.rmvDomElm( this.mnuDiv );
			delete this.mnuItems;
			delete this.parItm;
			delete this.mnuDiv;
			delete this.mnuMgr;
		}
		super.doDestroy();
	}

	/**
	 * returns the underlying DOM element
	 * @returns {HTMLElement} the DOM element
	 */
	getElm() {
		return this.mnuDiv;
	}

	/**
	 * returns the DOM element hosting the menu items
	 * @returns {HTMLElement} the DOM element for the menu items
	 */
	getItmElm() {
		return this.itmHost;
	}

	/**
	 * returns the required height in pixels to show all menu items
	 * @returns {Number} the required height in pixels
	 */
	getMnuHgt() {
		return this.mnuHgt;
	}

	/**
	 * @returns {Boolean} true if popup direction towards the left side is preferred; false towards right side
	 */
	isPfrLft() {
		return !!this.pfrLft;
	}

	/**
	 * changes the "preferred towards left" flag
	 * @param {Boolean} lft new value of the "preferred towards left" flag
	 */
	setPfrLft( lft ) {
		this.pfrLft = !!lft;
	}

	/**
	 * @returns {Number} the original menu width in pixels
	 */
	getOrgWdt() {
		return this.orgWdt ? this.orgWdt : 0;
	}

	/**
	 *
	 * @param {Number} vsh visible height in pixels
	 */
	setVisHgt( vsh ) {
		const rqh = this.getMnuHgt();
		const hgt = rqh <= vsh ? rqh : vsh;
		if ( rqh <= vsh ) {
			// we're fully visible and do not need any scrolling
			this.btnUp.style.display = 'none';
			this.btnDwn.style.display = 'none';
			this.canScroll = false;
		} else {
			// turn on scrolling
			this.btnUp.style.display = 'flex';
			this.btnDwn.style.display = 'flex';
			this.canScroll = true;
		}
		this.itmHost.style.top = '0px';
		this.mnuDiv.style.height = '' + hgt + 'px';
		this.scrPos = 0;
		this.scrIdx = 0;
		this.scrHeight = this.scrHost.getBoundingClientRect().height;
	}

	/**
	 * updates the status of a menu item
	 * @param {Number} id menu item ID
	 * @param {Object} args new properties
	 * @returns {Boolean} true if the specified item was found and successfully updated; false otherwise
	 */
	updMnuItm( id, args ) {
		let res = false;
		const mni = this.mnuItems.getObj( id );
		if ( mni ) {
			// one of our direct items
			mni.updMnuItm( args );
			res = true;
		} else {
			const items = this.mnuItems.getValues();
			for ( let i = 0; !res && ( i < items.length ); ++i ) {
				res = items[ i ].updSubItm( id, args );
			}
		}
		return res;
	}

	/**
	 * unchecks all menu items and checks the one with the specified ID
	 * @param {Number} id menu item ID
	 */
	checkOne( id ) {
		this.mnuItems.forEach( ( mi ) => {
			const chk = mi.id === id;
			mi.setChecked( chk );
		} );
	}

	/**
	 * changes the "checked" state if a menu item
	 * @param {Number} id menu item ID
	 * @param {Boolean} chk new "checked" state of the specified menu item
	 */
	checkItem( id, chk ) {
		const mni = this.mnuItems.getObj( id );
		if ( mni ) {
			mni.setChecked( !!chk );
		}
	}

	/**
	 * locks or unlocks this menu object
	 * @param {Boolean} l new locked state
	 */
	lock(l) {
		this._locked = !!l;
	}

	/**
	 * shows this menu
	 */
	show() {
		// reset scrolling
		this.canScroll = false;
		this.itmHost.style.top = '0px';
		// hide scroll buttons
		this.btnUp.style.display = 'none';
		this.btnDwn.style.display = 'none';
		// register [Esc] handler
		this._psa.getKeyHdl().addEscHandler(this.escHandler);
		// show the menu
		this.mnuMgr.getHostElm().appendChild( this.mnuDiv );
		this.mnuDiv.style.display = 'flex';
		if ( this.parItm ) {
			this.parItm.getParMnu().onSubPop( this.mnuID );
		}
		if ( this.orgWdt === null ) {
			this.orgWdt = this.mnuDiv.getBoundingClientRect().width;
		}
	}

	/**
	 * hides this menu
	 */
	hide() {
		if ( this.alive ) {
			// unregister [Esc] handler
			this._psa.getKeyHdl().rmvEscHandler(this.escHandler);
			// hide sub menus
			if ( this.mnuItems && (this.mnuItems instanceof ObjReg) ) {
				// this was added due to js throwing
				// Uncaught TypeError: Cannot read property 'isEmpty' of undefined
				if ( !this.mnuItems.isEmpty() ) {
					this.mnuItems.forEach( ( i ) => {
						i.hideSub();
					} );
				}
			}
			// hide scroll buttons
			this.btnUp.style.display = 'none';
			this.btnDwn.style.display = 'none';
			// hide the menu at all
			const div = this.mnuDiv;
			div.style.top = OUT_POS;
			div.style.left = OUT_POS;
			div.style.display = 'none';
			this._psa.rmvDomElm( div );
			if ( this.parItm ) {
				this.parItm.getParMnu().onSubHide( this.mnuID );
			}
		}
	}

	/**
	 * called if a sub menu was popped up
	 * @param {Number} idm menu ID of the sub menu
	 */
	onSubPop( idm ) {
		if ( idm !== this.popIdm ) {
			if ( this.popIdm ) {
				const idp = this.popIdm;
				this.popIdm = 0;
				const old = this.mnuItems.getObj( idp );
				if ( old ) {
					old.hideSub();
				}
			}
		}
		this.popIdm = idm;
	}

	/**
	 * called if a sub menu was hidden
	 * @param {Number} idm menu ID of the sub menu
	 */
	onSubHide( idm ) {
		if ( this.popIdm === idm ) {
			this.popIdm = 0;
		}
	}

	/**
	 * creates the menu items
	 * @param {Array} items the menu item descriptors
	 */
	_creItems( items ) {
		let hgt = 0;
		items.forEach( ( dsc ) => {
			hgt += this._creOneItm( dsc );
		} );
		this.mnuHgt = hgt;
	}

	/**
	 * creates a menu item
	 * @param {Object} dsc menu item descriptor
	 * @returns {Number} the height in pixels of the created item
	 */
	_creOneItm( dsc ) {
		const id = dsc.id || 0;
		const mni = new MnuItm( this.mnuMgr, this, dsc );
		this.mnuItems.addObj( id, mni );
		return mni.getHeight();
	}

	/**
	 * creates a scroll button
	 * @param {MnuMgr} mgr the menu manager
	 * @param {Boolean} up "upwards" flag
	 * @returns {HTMLElement} the button element
	 */
	_creScrBtn( mgr, up ) {
		// create button element
		const btn = document.createElement( 'div' );
		const _im = this._im;
		_im.setFlexHgt( btn, BTN_HGT, true );
		const btn_brd = '1px solid ' + ( this._psa.UIUtil.getCssRgb( mgr.mnuTxc ) || '#e1e1e1' );
		if ( up ) {
			btn.style.borderBottom = btn_brd;
			btn.style.paddingTop = '2px';
		} else {
			btn.style.borderTop = btn_brd;
			btn.style.paddingTop = '4px';
		}
		btn.style.display = 'none';
		btn.style.flexDirection = 'column';
		btn.style.alignItems = 'center';
		const span = document.createElement( 'span' );
		span.innerHTML = up ? SCR_UP : SCR_DWN;
		btn.appendChild( span );
		// add event listeners
		const hbc = mgr.mnuHlc;
		const htc = mgr.mnuStc;
		btn.addEventListener( 'mouseenter', () => {
			_im.setBkgClr( btn, hbc, false );
			_im.setFgrClr( btn, htc );
		}, false );
		btn.addEventListener( 'mouseleave', () => {
			_im.setBkgClr( btn, null, false );
			_im.setFgrClr( btn, null );
		}, false );
		btn.addEventListener( 'click', () => {
			this._onScroll( up );
		}, false );
		// done
		return btn;
	}

	/**
	 * handles "wheel" events
	 * @param {MouseEvent} e the mouse event
	 */
	_onMouseWheel( e ) {
		if ( this.canScroll ) {
			if ( e.deltaY ) {
				// we don't care about the mode, we just want the direction
				const up = e.deltaY < 0;
				this._onScroll( up );
			}
		}
	}

	/**
	 * scrolls the item area
	 * @param {Boolean} up direction flag
	 */
	_onScroll( up ) {
		if ( this.canScroll && ( this.scrHeight > 0 ) ) {
			const nix = this.scrIdx + ( up ? -1 : 1 );
			if ( nix >= 0 ) {
				const items = this.mnuItems.getValues();
				const cix = up ? nix : this.scrIdx;
				if ( cix < items.length ) {
					const cxi = items[ cix ];
					let pos = Math.max( this.scrPos + ( up ? -cxi.getHeight() : cxi.getHeight() ), 0 );
					if ( up ) {
						// limit upwards
						pos = Math.max( pos, 0 );
					} else {
						// limit downwards
						pos = Math.min( pos, this.getMnuHgt() - this.scrHeight );
					}
					if ( pos != this.scrPos ) {
						this.scrPos = pos;
						this.scrIdx = nix;
						this.itmHost.style.top = '' + ( -pos ) + 'px';
					}
				}
			}
		}
	}
}

console.debug( 'gui/menu/MnuObj.js loaded.' );

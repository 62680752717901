import LoggingBase from "../../base/loggingbase";

/**
 * global mouse wheel listener support
 */
export default class WheelLsr extends LoggingBase {

    /**
     * constructs a new instance
     */
    constructor() {
        super('gui.misc.WheelLsr');
    }

    /**
     * returns the singleton instance
     * @returns {WheelLsr} the singleton instance
     */
    static getInstance() {
        return singleton;
    }

    /**
     * initializes global wheel listeners
     * @param {Boolean} passive flag whether passive listeners are supported
     */
    initWheelLsr(passive) {
        const body = document.body;
        const opts = passive ? { passive: true } : false;
        const self = this;
        body.addEventListener('wheel', (we) => self._onMouseWheel(we), opts);
    }

    /**
     * handles "wheel" events
     * @param {WheelEvent} we the mouse wheel event
     */
    _onMouseWheel(we) {
        if ( this.isTraceEnabled() ) {
            this.trace('Got a wheel event', we);
        }
        // nothing else so far
    }
}

const singleton = new WheelLsr();

import ItmMgr from "../../gui/ItmMgr";
import MenuHandler from "../../gui/menu/MenuHandler";
import MnuObj from "../../gui/menu/MnuObj";
import PSA from "../../psa";
import DomEventHelper from "../../utils/DomEventHelper";
import HtmHelper from "../../utils/HtmHelper";
import Validator from "../../utils/Validator";
import GroupItem from "./GroupItem";
import TWProps, { TW_CLOSE_WIDTH, TW_ICON_WIDTH, TW_SPACER_WIDTH, TW_TAB_MAX_WIDTH, TW_TAB_MIN_WIDTH, TW_TAB_PADDING, TW_TAB_TEXT_OFFS, TW_TTP_OFFSET, TAB_MENU_ID, TW_EXTRA_WIDTH, DROP_DATA_PREFIX, DRAG_DATA_TYPE } from "./TWProps";
import TabEvents from "./TabEvents";
import TabItem from "./TabItem";
import TabMenuItem from "./TabMenuItem";

const CLOSE_ICON_NORM = { cssCls: 'fp', icoNam: 'fp-close-2', icoSiz: 11, icoClr: null, fntNam: null };
const CLOSE_ICON_HOVER = { cssCls: 'fp', icoNam: 'fp-close-3', icoSiz: 11, icoClr: null, fntNam: null };
const DROPDOWN_ICON_NORM = { cssCls: 'far', icoNam: 'fa-chevron-down', icoSiz: 10, icoClr: null, fntNam: null };
const DROPDOWN_ICON_HOVER = { cssCls: 'fas', icoNam: 'fa-chevron-down', icoSiz: 10, icoClr: null, fntNam: null };
const CSC_NORMAL = 'ptgNormal';
const CSC_ACTIVE = 'ptgActive';
const LOGGER_NAME = 'widgets.tabwdg.TabGroup';

/**
 * menu handler class
 */
class TGMenuHandler extends MenuHandler {
    
    /**
     * constructs a new instance
     * @param {TabGroup} the target tab group
     */
    constructor(tg) {
        super(LOGGER_NAME);
        this._tabGroup = tg;
    }

    /**
     * @inheritdoc
     * @override
     * @param {Number} id 
     * @param {MnuObj} menu 
     * @param {Number} what 
     */
    onMenuItem(id, menu, what) {
        menu.lock(false);
        this._tabGroup._onMenuItem(id);
    }

    /**
     * @inheritdoc
     * @override
     * @param {MnuObj} menu 
     */
    onMenuClose(menu) {
        menu.lock(false);
        menu.destroy();
        this.destroy();
    }
}


/**
 * tab widget's tab group class
 */
export default class TabGroup extends GroupItem {

    /**
     * constructs a new instance
     * @param {TabEvents} eh tab event handler
     * @param {Number} id group identifier
     * @param {String|null} name group name
     * @param {TWProps} props tab properties
     * @param {TabItem} item the initial tab item
     */
    constructor(eh, id, name, props, item) {
        super(id, name, item, LOGGER_NAME);
        if ( !(eh instanceof TabEvents) ) {
            this.throwError('Invalid tab event handler!', eh);
        }
        this._eh = eh;
		// create our DOM element
        this._icon = null;
        this._text = null;
        this._dropdown = null;
        this._marker = null;
        this._spacers = [];
        this._ttpElement = null;
        this._hasCloseBtn = false;
        this._hasIcon = false;
        this._hasTooltip = false;
        this._dropSupport = !!props.dropSupport;
        this._ttpDelay = props.ttpDelay;
		const de = this._createDOMElement(name, props);
		this._element = de;
        this._visible = false;
        this._ttpTimer = 0;
        this._ttpVisible = false;
        // render!
        this.setCurrentItem(this.currentItem);
    }

    /**
     * @inheritdoc
     * @override
     * @returns {String}
     */
    get className() {
        return 'TabGroup';
    }

    /**
     * @returns {TabEvents} the tab event handler
     */
    get eventHdl() {
        return this._eh;
    }

	/**
	 * @returns {HTMLElement|null} the main DOM element
	 */
	get element() {
		return this._element;
	}

	/**
	 * @returns {Boolean} true if the DOM element is valid; false otherwise
	 */
	get hasElement() {
		return (this._element instanceof HTMLElement);
	}

    /**
     * @returns {Boolean} true if a close button is rendered
     */
    get hasCloseBtn() {
        return this._hasCloseBtn;
    }

    /**
     * @returns {HTMLElement} the icon element
     */
    get icon() {
        return this._icon;
    }

    /**
     * @returns {Boolean} true if an icon is present
     */
    get hasIcon() {
        return this._hasIcon;
    }

    /**
     * @returns {HTMLElement} the text element
     */
    get text() {
        return this._text;
    }

    get dropdown() {
        return this._dropdown;
    }

    /**
     * @returns {HTMLElement} the marker element
     */
    get marker() {
        return this._marker;
    }

    /**
     * @override
     * @inheritdoc
     * @returns {Boolean}
     */
    get visible() {
        return this.alive && this._visible;
    }

    /**
     * @returns {Boolean} true if drag'n'drop support is active; false otherwise
     */
    get dropSupport() {
        return this.alive && this._dropSupport;
    }

    /**
     * @returns {Boolean} true if a tooltip text is available; false otherwise
     */
    get hasTooltip() {
        return this.alive && this._hasTooltip;
    }

    /**
     * @returns {HTMLElement} the tooltip element
     */
    get ttpElement() {
        return this._ttpElement;
    }

    /**
     * @override
     */
    doDestroy() {
        this._stopTooltip();
        const te = this.ttpElement;
        HtmHelper.rmvDomElm(te);
        const de = this.element;
        this._element = null;
        this._icon = null;
        this._text = null;
        this._marker = null;
        this._spacers = [];
        this._dropdown = null;
        this._ttpElement = null;
        HtmHelper.rmvDomElm(de);
        super.doDestroy();
    }

    /**
     * creates a silent group item without UI
     * @returns {GroupItem|null} the created group item or null if this instance is not longer alive
     */
    createDataItem() {
        return TabGroup.createClone(this, true, null);
    }

    /**
     * creates a clone of the given group
     * @param {GroupItem} group the source group
     * @param {Boolean} silent silent flag; a silent group has no UI
     * @param {*} options additional options for groups with UI
     * @returns {GroupItem|null} the created group item or null if source instance is not longer alive
     */
    static createClone(group, silent, options) {
        if ( !(group instanceof GroupItem) || !group.alive ) {
            return null;
        }
        const ti = group.currentItem;
        const cg = silent ? new GroupItem(group.id, group.name, ti.clone(), LOGGER_NAME) : new TabGroup(options.eventHdl, group.id, group.name, options.closeBtn, ti.clone());
        cg._tms = group.tms;
        cg.itemWidth = group.itemWidth;
        const items = group.items;
        if ( items.size > 1 ) {
            for ( let item of items.values() ) {
                if ( item !== ti ) {
                    cg.addItem(item.clone(), false);
                }
            }
        }
        return cg;
    }

    /**
     * shows or hides this group item
     * @param {Boolean} show true to show this group item; false to hide it
     */
    showGroup(show) {
        if ( this.alive && (this.visible !== show) ) {
            this._visible = !!show;
            this.element.style.display = this.visible ? '' : 'none';
        }
    }

    /**
     * @override
     * @inheritdoc
     * @param {Boolean} active
     */
    setActive(active) {
        super.setActive(active);
        const de = this.element;
        if ( de instanceof HTMLElement ) {
            if ( active ) {
                de.classList.remove(CSC_NORMAL);
                de.classList.add(CSC_ACTIVE);
            } else {
                de.classList.remove(CSC_ACTIVE);
                de.classList.add(CSC_NORMAL);
            }
            this._updateSpacers(active);
            if ( this.isDebugEnabled() ) {
                // this is only required for DEBUG mode because the activation timestamp is included into the tooltip text
                this._updateTooltipText();
            }
        }
    }

    /**
     * @inheritdoc
     * @override
     * @param {TabItem} item 
     */
    tabItemUpdated(item) {
        if ( this.alive ) {
            const ci = this.currentItem;
            if ( (ci instanceof TabItem) && (ci === item) ) {
                // just call setCurrentItem() - it does all what has to be done
                this.setCurrentItem(item);
            }
        }
    }

    /**
     * @inheritdoc
     * @override
     */
    updateLayout() {
        if ( this.alive ) {
            this._stopTooltip();
            this._calculateWidth();
            this._updateTooltipText();
        }
    }

    /**
     * makes this group to the "just before the active group"
     */
    setBefore() {
        this._updateSpacers(true);
    }

    /**
     * @override
     * @inheritdoc
     * @param {TabItem} item
     * @param {Boolean} sci
     */
    addItem(item, sci) {
        super.addItem(item, sci);
        if ( this.alive ) {
            this._updateDropdownBtn();
        }
    }

    /**
     * @override
     * @inheritdoc
     * @param {TabItem} item
     */
    rmvItem(item) {
        super.rmvItem(item);
        if ( this.alive ) {
            this._updateDropdownBtn();
        }
    }

    /**
     * @override
     * @inheritdoc
     * @param {TabItem} item
     */
    setCurrentItem(item) {
        super.setCurrentItem(item);
        if ( this.hasElement && (item instanceof TabItem) ) {
            const icon = item.icon;
            this.icon.innerHTML = '';
            if ( icon ) {
                this.icon.appendChild(ItmMgr.getInst().creImg(icon, 0, true));
                this.icon.style.display = '';
                this._hasIcon = true;
            } else {
                this.icon.style.display = 'none';
                this._hasIcon = false;
            }
            this.text.innerHTML = item.title;
            this.marker.style.display = item.marked ? '' : 'none';
            this.updateLayout();
        }
    }

    /**
     * calculates the width of a tab item
     * @param {TabItem} item the tab item
     * @param {HTMLElement} element the DOM element required to calculate the text width
     * @param {Boolean} cb flag whether the "close" button is present
     * @returns {Number} the width in pixels of the given item; returns 0 if calculation is not possible
     */
    static calculateItemWidth(item, element, cb) {
        if ( item.itemWidth > 0 ) {
            return item.itemWidth;
        }
        if ( element instanceof HTMLElement ) {
            let width = TW_TAB_PADDING + TW_EXTRA_WIDTH;
            width += cb ? TW_CLOSE_WIDTH : 0;
            width += item.hasIcon ? TW_ICON_WIDTH : 0;
            width += TW_SPACER_WIDTH;
            let txw = 0;
            if ( item.hasTextWidth ) {
                txw = item.textWidth;
            } else {
                const res = ItmMgr.getInst().getTextSize(element, item.title, false, 0, false);
                txw = res.sts.cx + TW_TAB_TEXT_OFFS;
                if ( (width + txw) > TW_TAB_MAX_WIDTH ) {
                    txw = Math.max(0, TW_TAB_MAX_WIDTH - width);
                }
                item.textWidth = txw;
            }
            width += txw;
            width = Math.max(TW_TAB_MIN_WIDTH, width);
            // store the item width
            item.itemWidth = width;
            // done
            return width;
        }
        return 0;
    }

	/**
	 * creates the main DOM element
     * @param {String} name group name
     * @param {TWProps} props tab properties
	 * @returns {HTMLElement} the main DOM element
	 */
	_createDOMElement(name, props) {
        const self = this;
		const de = document.createElement('div');
        de.className = 'pisaTabGroup';
        de.classList.add(CSC_NORMAL);
        // set the data properties
        if ( Validator.isString(name) ) {
            de.dataset.testid = `psa-${name}`;
        }
        de.dataset.ptg = 'true';
        de.dataset.gid = `${this.id}`;
        // make it draggable
        de.draggable = 'true';
        // create both, tab item and extras containers
        const container = document.createElement('div');
        container.className = 'ptgContainer';
        const extras = document.createElement('div');
        extras.className = 'ptgExtras';
        // create icon and text elements
        const icon = document.createElement('div');
        icon.className = 'ptgIcon';
        const text = document.createElement('div');
        text.className = 'ptgText';
        this._icon = icon;
        this._text = text;
        if ( props.closeBtn ) {
            // create close button
            const btn = this._createCloseBtn();
            container.appendChild(btn);
            this._hasCloseBtn = true;
        }
        container.appendChild(icon);
        container.appendChild(text);
        // create "extra" filler
        const ef = document.createElement('div');
        ef.className = 'ptgExtrasFiller';
        extras.appendChild(ef);
        // create marker box and marker
        const marker_box = document.createElement('div');
        marker_box.classList.add('ptgExtrasBox');
        const marker = document.createElement('div');
        marker.classList.add('ptgMarker');
        marker.classList.add('mainTabEditMarker');
        marker.style.display = 'none';
        marker_box.appendChild(marker);
        this._marker = marker;
        // create dropdown button
        const dropdown = this._createDropdownBtn();
        this._dropdown = dropdown;
        // add extra items to extras container
        extras.appendChild(marker_box);
        extras.appendChild(dropdown);
        // create spacers
        [ extras, container ].forEach(e => {
            const spacer = document.createElement('div');
            spacer.className = 'ptgSpacer';
            e.appendChild(spacer);
            this._spacers.push(spacer);
        })
        // add all child elements
        de.appendChild(extras);
        de.appendChild(container);
        // create tooltip element
        const tooltip = this._createTooltip();
        this._ttpElement = tooltip;
        document.body.appendChild(tooltip);
        // add event listeners
        de.addEventListener('click', (e) => {
            self._onTabClicked(e);
        });
        de.addEventListener('contextmenu', (e) => {
            self._onTabContextMenu(e);
        });
        de.addEventListener('dblclick', (e) => {
            self._onTabDblClick(e);
        });
        de.addEventListener('mouseenter', (e) => {
            self._onMouseEnter(e);
        });
        de.addEventListener('mouseleave', (e) => {
            self._onMouseLeave(e);
        });
        de.addEventListener('dragstart', (e) => {
            self._onDragStart(e);
        }, true);
        de.addEventListener('dragend', (e) => {
            self._onDragEnd(e);
        }, true);
		return de;
	}

    /**
     * creates the close button
     * @returns {HTMLElement} the close button element
     */
    _createCloseBtn() {
        const self = this;
        const im = ItmMgr.getInst();
        const btn = document.createElement('div');
        btn.className = 'ptgCloseBtn';
        const icon_norm = im.creDscIco(CLOSE_ICON_NORM, null, false);
        const icon_hover = im.creDscIco(CLOSE_ICON_HOVER, null, false);
        icon_hover.style.display = 'none';
        btn.addEventListener('click', (e) => {
            self._onCloseButton(e);
        });
        btn.addEventListener('mouseenter', (e) => {
            self._toggleIcons(icon_hover, icon_norm);
        });
        btn.addEventListener('mouseleave', (e) => {
            self._toggleIcons(icon_norm, icon_hover);
        });
        btn.appendChild(icon_norm);
        btn.appendChild(icon_hover);
        return btn;
    }

    /**
     * creates the dropdown button
     * @returns {HTMLElement} the dropdown button element
     */
    _createDropdownBtn() {
        const self = this;
        const im = ItmMgr.getInst();
        const btn = document.createElement('div');
        btn.className = 'ptgExtrasBox';
        btn.style.display = 'none';
        const icon_norm = im.creDscIco(DROPDOWN_ICON_NORM, null, false);
        const icon_hover = im.creDscIco(DROPDOWN_ICON_HOVER, null, false);
        icon_hover.style.display = 'none';
        btn.addEventListener('click', (e) => {
            self._onDropdownButton(e);
        });
        btn.addEventListener('mouseenter', (e) => {
            self._toggleIcons(icon_hover, icon_norm);
        });
        btn.addEventListener('mouseleave', (e) => {
            self._toggleIcons(icon_norm, icon_hover);
        });
        btn.appendChild(icon_norm);
        btn.appendChild(icon_hover);
        return btn;
    }

    /**
     * creates the tooltip element
     * @returns {HTMLElement} the tooltip element
     */
    _createTooltip() {
        const ttp = document.createElement('span');
        ttp.role = 'tooltip';
        ttp.classList.add('ptgTooltip');
        ttp.style.display = 'none';
        ttp.style.visibility = 'hidden';
        return ttp;
    }

    /**
     * updates the spacer elements
     * @param {Boolean} transparent flag whether to make the spacers transparent
     */
    _updateSpacers(transparent) {
        if ( this._alive ) {
            this._spacers.forEach(spacer => {
                if ( spacer instanceof HTMLElement ) {
                    spacer.style.backgroundColor = transparent ? 'unset' : '';
                }
            });
        }
    }

    /**
     * updates the visibility of the dropdown button
     */
    _updateDropdownBtn() {
        if ( this.alive ) {
            const db = this.dropdown;
            if ( db instanceof HTMLElement ) {
                db.style.display = this.items.size > 1 ? '' : 'none';
            }
        }
    }

    /**
     * calculates the width of a tab item
     * @param {TabItem} item the tab item
     * @returns {Number} the width in pixels of the given item; returns 0 if calculation is not possible
     */
    _calculateItemWidth(item) {
        this.checkTabItem(item);
        if ( item.itemWidth > 0 ) {
            return item.itemWidth;
        }
        const de = this.element;
        if ( (de instanceof HTMLElement) && (de.parentElement instanceof HTMLElement) ) {
            return TabGroup.calculateItemWidth(item, this.text, this.hasCloseBtn);
        }
        return 0;
    }

    /**
     * calculates the required width
     */
    _calculateWidth() {
        const ci = this.currentItem;
        if ( ci instanceof TabItem ) {
            const width = this._calculateItemWidth(ci);
            if ( width > 0 ) {
                const txw = ci.textWidth;
                const tws = `${txw}px`;
                const text = this.text;
                text.style.width = tws;
                text.style.maxWidth = tws;
                const wds = `${width}px`;
                const de = this.element;
                de.style.width = wds;
                de.style.maxWidth = wds;
                this.itemWidth = width;
            }
        }
    }

    /**
     * called if a tab is clicked
     * @param {MouseEvent} e the event
     */
    _onTabClicked(e) {
        this._stopTooltip();
        DomEventHelper.stopPropagation(e);
        this.eventHdl.onTabActivate(this.id, this._currentID);
    }

    /**
     * called on a context menu request
     * @param {MouseEvent} e the DOM event
     */
    _onTabContextMenu(e) {
        this._stopTooltip();
        DomEventHelper.stopEvent(e);
        this.eventHdl.onTabContextMenu(this.id);
    }

    /**
     * called if the "close" button was clicked
     * @param {MouseEvent} e the event
     */
    _onCloseButton(e) {
        this._stopTooltip();
        DomEventHelper.stopEvent(e);
        this.eventHdl.onTabClose(this.id, this._currentID);
    }

    /**
     * called if the dropdown button was clicked
     * @param {MouseEvent} e the event
     */
    _onDropdownButton(e) {
        this._stopTooltip();
        DomEventHelper.stopEvent(e);
        if ( !this.active ) {
            this._onTabClicked(e);
        }
        if ( this.alive && (this.items.size > 1) ) {
            const db = this.dropdown;
            if ( db instanceof HTMLElement ) {
                const items = [];
                for ( let ti of this.items.values() ) {
                    items.push(new TabMenuItem(ti));
                }
                const mmg = PSA.getInst().getMnuMgr();
                const menu = mmg.createMenu(TAB_MENU_ID, items);
                menu.lock(true);
                mmg.showMenu(menu, { element: db, rect: null, outside: true }, new TGMenuHandler(this), true, true);
            }
        }
    }

    /**
     * called if a menu item was selected
     * @param {Number} id item ID
     */
    _onMenuItem(id) {
        if ( this.alive ) {
            const ti = this.items.get(id);
            if ( (ti instanceof TabItem) && (ti !== this.currentItem) ) {
                this.setCurrentItem(ti);
                this.eventHdl.onTabActivate(this.id, ti.id);
            }
        }
    }

    /**
     * called if a tab is double clicked
     * @param {MouseEvent} e the event
     */
    _onTabDblClick(e) {
        DomEventHelper.stopPropagation(e);
        this.eventHdl.onTabDblClick(this.id, this._currentID);
    }

    /**
     * handles "dragstart" events
     * @param {DragEvent} e the drag event
     */
    _onDragStart(e) {
        // stop any tooltip!
        this._stopTooltip();
        // common event handling
        e.stopImmediatePropagation();
        if ( this.alive ) {
            e.dataTransfer.dropEffect = 'move';
            // set dragged data
            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData(DRAG_DATA_TYPE, `${DROP_DATA_PREFIX}${this.id}`);
            // notify event handler
            this.eventHdl.onStartGroupDrag(this.id);
        } else {
            e.dataTransfer.dropEffect = 'none';
            e.preventDefault();
        }
    }

    /**
     * handles "dragend" events
     * @param {DragEvent} e the drag event
     */
    _onDragEnd(e) {
        // common event handling
        e.stopImmediatePropagation();
        if ( this.alive ) {
            // notify event handler
            this.eventHdl.onEndGroupDrag(this.id);
        }
    }

    /**
     * called on "mouseenter" events
     * @param {MouseEvent} e the event
     */
    _onMouseEnter(e) {
        if ( this.alive ) {
            const down = Validator.isPositiveInteger(e.buttons, false);
            if ( down ) {
                if ( this.dropSupport && !this.active ) {
                    if ( (e.buttons & 0x01) === 0x01 ) {
                        // this is a drag'n'drop operation (educated guess) so we want to become active
                        this.eventHdl.onTabActivate(this.id, this._currentID);
                    }
                }
            } else {
                this._startTtpTimer();
            }
        }
    }

    /**
     * called on "mouseleave" events
     * @param {MouseEvent} e the event
     */
    _onMouseLeave(e) {
        this._stopTooltip();
    }

    /**
     * updates tooltip text
     */
    _updateTooltipText() {
        let tooltip = '';
        const ci = this.currentItem;
        if ( ci instanceof TabItem ) {
            tooltip = Validator.ensureString(ci.tooltip);
            if ( this.isDebugEnabled() ) {
                tooltip = new Date(this.tms).toISOString() + '<br/>' + tooltip;
            }
        }
        const dataset = this.element.dataset;
        if ( Validator.isString(tooltip) ) {
            dataset.title = tooltip;
            this._hasTooltip = true;
        } else {
            this._hasTooltip = false;
            delete dataset.title;
        }
        const te = this.ttpElement;
        if ( te instanceof HTMLElement ) {
            te.innerHTML = tooltip;
        }
    }

    /**
     * starts the tooltip delay timer
     */
    _startTtpTimer() {
        this._stopTooltip();
        if ( this.hasTooltip ) {
            const self = this;
            this._ttpTimer = window.setTimeout(() => {
                self._showTooltip(true);
            }, this._ttpDelay);
        }
    }

    /**
     * stops any tooltip cycle
     */
    _stopTooltip() {
        this._showTooltip(false);
        if ( this._ttpTimer ) {
            const id = this._ttpTimer;
            this._ttpTimer = 0;
            window.clearTimeout(id);
        }
    }

    /**
     * shows or hides the tooltip
     * @param {Boolean} flag whether to show or to hide the tooltip element
     */
    _showTooltip(show) {
        if ( this._ttpVisible !== show ) {
            this._ttpVisible = show;
            if ( show ) {
                this._ttpTimer = 0;
            }
            const te = this.ttpElement;
            if ( te instanceof HTMLElement ) {
                te.style.display = show ? '' : 'none';
                te.style.visibility = show ? '' : 'hidden';
                if ( show ) {
                    // place the tooltip
                    this._placeTooltip();
                }
            }
        }
    }

    /**
     * places the tooltip element
     */
    _placeTooltip() {
        const bounds = document.body.getBoundingClientRect();
        const tab_rect = this.element.getBoundingClientRect();
        const te = this.ttpElement;
        const ttp_rect = this.ttpElement.getBoundingClientRect();
        let left = Math.max(bounds.left, (tab_rect.left + tab_rect.right) / 2 - ttp_rect.width / 2);
        if ( (left + tab_rect.width) > bounds.right ) {
            left = Math.max(bounds.left, bounds.right - (tab_rect.width + TW_TTP_OFFSET));
        }
        let top = Math.max(bounds.top, tab_rect.bottom + TW_TTP_OFFSET);
        if ( (top + ttp_rect.height) > bounds.bottom ) {
            top = Math.max(bounds.top, bounds.bottom - (tab_rect.height + TW_TTP_OFFSET));
        }
        te.style.left = `${left}px`;
        te.style.top = `${top}px`;
    }

    /**
     * toggles the visibility of button icons
     * @param {HTMLElement} show the icon to be shown
     * @param {HTMLElement} hide the icon to be hidden
     */
    _toggleIcons(show, hide) {
        hide.style.display = 'none';
        show.style.display = '';
    }
}
import LoggingBase from "../../base/loggingbase";
import { EditorOptions, PisaCKEditor, PisaEditor, NO_POSITION } from "pisa-cke5-v2";
import Utils from "../../utils/Utils";
import HtmHelper from "../../utils/HtmHelper";
import ItmMgr from "../../gui/ItmMgr";
import CkEdt5Listener from "./CkEdt5Listener";
import Validator from "../../utils/Validator";
import KeyHdl from "../../key/KeyHdl";
import DomEventHelper from "../../utils/DomEventHelper";
import CkEdt5Register from "./CkEdt5Register";
import PSA from "../../psa";

/** the global PisaCKEditor instance */
const PCE = PisaCKEditor.getInstance();

/** bubbling events that should not leave the editor */
const BUBBLING_EVENTS_NAMES = [ 'mousedown',
        // 'mouseup',
        'mouseover', 'wheel',
        'selectstart', 'selectionchange', 'dragstart', 'dragover', 'keydown',
        'keyup', 'keypress'
    ];
// mouseup events are necessary in order to trigger the resize of the modal
// window containing the editor, which reacts to mouseup;
// so the propagation of the "mouseup" event should not be stopped

/** the common notification code */
const NOTIFICATION = 'CKEDT5V2_NFY';

/** spellchecking property */
const PISA_SPELLCHECKING = 'pisaSpellchecking';


/**
 * stops the bubbling of an event
 * @param {Event} evt the event that should not bubble further up in the DOM tree
 */
function stopBubbling( evt ) {
	evt.stopPropagation();
}

/**
 * adds the required "stop bubbling" listeners to make the editor work...
 * @param {HTMLElement} container the container div
 */
function setupCke5ContainerListeners( container ) {
	BUBBLING_EVENTS_NAMES.forEach( eventName => {
		container.addEventListener( eventName, stopBubbling );
	} );
}

/**
 * removes the required "stop bubbling" listeners to make the editor work...
 * @param {HTMLElement} container the container div
 */
function removeCke5ContainerListeners( container ) {
	BUBBLING_EVENTS_NAMES.forEach( eventName => {
		container.removeEventListener( eventName, stopBubbling );
	} );
}


export default class CkEdt5V2 extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {Object} properties initialization properties
	 */
	constructor( properties ) {
        super('widgets.ckedt5v2.CkEdt5V2');
        // bind event handlers
        Utils.bindAll(this, [ 'layout', 'onReady', 'onRender' ]);

		const idp = properties.parent;
		this._wdgId = idp;
		this.parent = rap.getObject( idp );
        this.element = document.createElement('div');
        this.parent.append(this.element);
        this.wdgReady = false;
        this.edtReady = false;
        this.txtFont = null;
        this.container = null;
        this._editor = null;
        this._iniCtt = null;
        this._dirty = false;
        this._changeNotified = false;
        this._inSetCtt = false;
        this._hasFocus = false;
        this._secondary = false;
        this._plainText = false;
        this._spellChecking = Validator.isTrue(PSA.getInst().getStorageVal(PISA_SPELLCHECKING));
        this._uuid = null;
        // create the editor instance
		const cwd = this.parent.getData('pisasales.CSTPRP.CWD') || {};
        this._createEditor(cwd);
        // remaining initalization
        const parent = this.parent;
        const self = this;
        // add "render" listener (RAP event)
        rap.on('render', this.onRender );
        // add resize listener
        parent.addListener('Resize', this.layout);
        // add focus listeners
        parent.addListener('FocusIn', () => self._onRapFocus(true));
        parent.addListener('FocusOut', () => self._onRapFocus(false));
    }   

    /**
     * @returns {String} the widget ID
     */
    get wdgId() {
        return this._wdgId;
    }

    /**
     * @returns {String} the UUID of this editor instance
     */
    get uuid() {
        return this._uuid;
    }

    /**
     * @returns {Boolean} true if this instance is fully set up and running; false otherwise
     */
    get ready() {
        return this.wdgReady && this.edtReady;
    }

    /**
     * @returns {PisaEditor | null} the editor
     */
    get editor() {
        return this._editor;
    }

    /**
     * returns the current "dirty" state;
     * @returns {Boolean} the current "dirty" state
     */
    get dirty() {
        return this._dirty;
    }

    /**
     * sets the "dirty" state;
     * @param {Boolean} d new value
     */
    set dirty(d) {
        this._dirty = !!d;
    }

    /**
     * @returns {Boolean} true if the editor has the focus, false otherwise
     */
    get hasFocus() {
        return this._hasFocus;
    }

    /**
     * @returns {Boolean} true if this is a secondary editor instance; false otherwise
     */
    get secondary() {
        return this._secondary;
    }

    /**
     * @returns {Boolean} true if the editor is set to "plain text only"; false otherwise
     */
    get plainText() {
        return this._plainText;
    }

    /**
     * @inheritdoc
     * @override
     */
    destroy() {
        super.destroy();
    }

    /**
     * @inheritdoc
     * @override
     */
    doDestroy() {
        CkEdt5Register.getInstance().rmvEditor(this);
        this.wdgReady = this.edtReady = false;
        delete this._iniCtt;
        const editor = this.editor;
        if ( editor ) {
            this._editor = null;
            editor.destroy();
        }
        const container = this.container;
        if ( container instanceof HTMLElement ) {
            this.container = null;
            removeCke5ContainerListeners(container);
            HtmHelper.rmvDomElm(container);
        }
        this.element = null;
        super.doDestroy();
    }

    layout() {
        if ( this.element && this.wdgReady ) {
            const area = this.parent.getClientArea();
            const elm = this.element;
            elm.style.left = '' + area[0] + 'px';
            elm.style.top = '' + area[1] + 'px';
            elm.style.width = '' + area[2] + 'px';
            elm.style.height = '' + Math.max(0, area[3]-1) + 'px';
        }
    }

    onReady() {
        const elm = this.element;
        if ( (elm instanceof HTMLElement) && (elm.parentElement instanceof HTMLElement) ) {
            // set additional CSS properties to make sure editor's UI elements
            // that should go outside of DIV container's "limit"/"margin" (such
            // as color dropdowns) aren't "cut" by the DIV container's "limit"/
            // "margin" (the widget DIV has fixed width and absolute position)
            const pe = elm.parentElement;
            const zid = Number(pe.style.zIndex);
            pe.style.zIndex = String(zid + 10);
            pe.classList.add('cke5-container-widget');
            this.wdgReady = true;
            this.layout();
        }
    }

    onRender() {
        rap.off('render', this.onRender);
        this.onReady();
    }

    /**
     * called if editor's content was changed
     * @param {PisaEditor} editor the editor who's content was changed
     */
    onChanged(editor) {
        if ( this.isTraceEnabled() ) {
            this.trace('Got a CHANGED notification. "in set ctt"=', this._inSetCtt);
        }
        if ( !this._inSetCtt && !this.dirty && (this.editor === editor) ) {
            if ( this.isDebugEnabled() ) {
                this.debug('Editor reported CHANGED content.');
            }
            this.dirty = true;
            this._notifyChanged();
        }
    }

    /**
     * called on focus changes
     * @param {PisaEditor} editor the editor who's content was modified
     * @param {Boolean} focus true if the editor gained the focus; false if it lost the focus
     */
    onFocus(editor, focus) {
        if ( this.alive && (this.editor === editor) ) {
            this.log("New editor focus:", focus);
            if ( this.hasFocus !== focus ) {
                this._hasFocus = focus;
                const par = { focus: this.hasFocus };
                if ( !focus && this.dirty ) {
                    this._getCttParam(par);
                    this._dirty = false;
                    this._changeNotified = true;
                }
                this._notifyBE('focus', par );
            }
        } else {
            console.log("Instance is dead, cannot handle focus changes anymore!", focus, editor);
        }
    }

    /**
     * called if an object link command was selected by the user
     * @param {PisaEditor} editor the editor firing this event
     * @param {String} name the name of the object link command
     */
    onObjectLinkCommand(editor, name) {
        if ( this.alive && (this.editor === editor) ) {
            this._notifyBE('objectLinkCommand', this._getCttParam({ tag: name }));
        }
    }

    /**
     * called if an object link was clicked
     * @param {PisaEditor} editor the editor firing this event
     * @param {String} href the link that was clicked
     */
    onObjectLinkClicked(editor, href) {
        if ( this.alive && (this.editor === editor) ) {
            this._notifyBE('objectLinkClicked', this._getCttParam({ href: href }));
        }
    }

    /**
     * called if the "insert image" command was invoked
     * @param {PisaEditor} editor the editor firing this event
     */
    onInsertImage(editor) {
        if ( this.alive && (this.editor === editor) ) {
            this._notifyBE('insertImage', this._getCttParam(null));
            this._dirty = false;
            this._changeNotified = true;
        }
    }

    /**
     * called if the "toggle text type" event was fired
     * @param {PisaEditor} editor the editor firing this event
     * @param {Boolean} plain new "plain text" state
     */
    onToggleType(editor, plain) {
        const pt = !!plain;
        if ( this.alive && (this.editor === editor) && (this.plainText !== pt) ) {
            // the backend must do the conversion!
            const par = { plain: plain };
            this._notifyBE('toggleType', this._getCttParam(par));
            this._dirty = false;
            this._changeNotified = true;
        }
    }

    /**
     * called if the user has clicked the "maximize" command
     * @param {PisaEditor} editor the editor firing this event
     */
    onMaximize(editor) {
        if ( this.alive && (this.editor === editor) ) {
            this._notifyBE('maximize', this._getCttParam(null));
        }
    }

    /**
     * called if a property change event was fired
     * @param {PisaEditor} editor the editor firing this event
     * @param {String} name name of property
     * @param {*} value new value
     */
    onPropertyChange(editor, name, value) {
        if ( this.alive && (this.editor === editor) ) {
            if ( this.isDebugEnabled() ) {
                this.debug(`Property changed: "${name}" = "${value}".`);
            }
            switch ( name ) {
                case PISA_SPELLCHECKING:
                    this._spellChecking = !!value;
                    PSA.getInst().setStorageVal(PISA_SPELLCHECKING, this._spellChecking);
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * called if the "delete all" command was triggered
     * @param {PisaEditor} editor the editor firing this event
     */
    onDeleteAll(editor) {
        if ( this.alive && (this.editor === editor) ) {
            if ( this.isDebugEnabled() ) {
                this.debug('Delete all content requested');
            }
            this._notifyBE('deleteAll', this._getCttParam(null));
        }
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     */
    onPlaceholdersDropdown(editor) {
        if ( this.alive && (this.editor === editor) ) {
            if ( this.isDebugEnabled() ) {
                this.debug('Placeholder insert requested.');
            }
            this._notifyBE('insertPlaceholder', this._getCttParam(null));
        }
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     * @param {Number} mode new placeholder mode
     */
    onPlaceholdersMode(editor, mode) {
        if ( this.alive && (this.editor === editor) ) {
            if ( this.isDebugEnabled() ) {
                this.debug('Placeholder mode changed:', mode);
            }
            // TODO - ignore the very next "changed" notification
        }
    }

    /**
     * @override
     * @inheritdoc
     * @param {PisaEditor} editor the editor firing this event
     */
    onPlaceholdersRefresh(editor) {
        if ( this.alive && (this.editor === editor) ) {
            if ( this.isDebugEnabled() ) {
                this.debug('Placeholders refresh requested.');
            }
            this._notifyBE('refreshPlaceholders', this._getCttParam(null));
        }
    }

    /**
     * called by the backend to set a new text font
     * @param {*} args font properties
     */
    setTextFont(args) {
        this.txtFont = args || null;
        if ( this.container ) {
            ItmMgr.getInst().setFnt(this.container, this.txtFont);
        }
    }

    /**
     * called by the framework to set the current "dirty" state
     * @param {Boolean} dirty new "dirty" state
     */
    setDirty(dirty) {
        this.dirty = !!dirty;
    }

    setCttTypeInfo(info) {
        if ( this.ready ) {
            const mode = Validator.isPositiveInteger(info.phMode) ? info.phMode : 0;
            this.editor.setPlaceholderMode(mode)
        }
    }

    /**
     * called by the backend to set new content
     * @param {*} args new content
     */
    newContent(args) {
        const html = !!args.html;
        const ctt = this._processContent(args.ctt || '', !html);
        const processed = !!args.processed;
        const insert = !!args.insert;
        if ( this.isTraceEnabled() ) {
            this.trace('new content: ', 'html:', html, `; ctt: "${ctt}"`);
        }
        if ( this.ready ) {
            this._setContent(ctt, !html, processed, false);
        } else {
            this._iniCtt = { ctt: ctt, html: html, processed: processed, insert: insert };
        }
    }

    /**
     * inserts content into the current text
     * @param {*} args content to be inserted
     */
    insertContent(args) {
        const set = !!args.set;
        if ( set ) {
            args.insert = true;
            this.newContent(args);
            return;
        }
        const html = !!args.html;
        const ctt = this._processContent(args.ctt || '', !html);
        const processed = !!args.processed;
        const keep_lbr = !html && !processed && !!args.keep_lbr;
        const line = Validator.isNumber(args.line) ? args.line : NO_POSITION;
        const start = Validator.isNumber(args.start) ? args.start : NO_POSITION;
        const end = Validator.isNumber(args.end) ? args.end : NO_POSITION;
        if ( this.ready ) {
            try {
                const editor = this.editor;
                const eff_ctt = keep_lbr ? editor.maskLineBreaks(ctt) : ctt;
                editor.insertContent(eff_ctt, !html, processed, line, start, end, this.txtFont);
            } finally {
                // finally, sent back "content inserted" notification
                this._notifyInserted(true);
            }
        } else {
            this._iniCtt = { ctt: ctt, html: html, processed: processed, insert: true };
        }
    }

    /**
     * deletes all content
     */
    deleteAllContent() {
        if ( this.ready ) {
            this.editor.deleteAllContent();
        }
    }

    /**
     * inserts an object link
     * @param {*} args object link properties
     */
    insertObjectLink(args) {
        if ( this.ready ) {
            const href = args.href;
            const title = args.title;
            if ( Validator.isString(href) && Validator.isString(title) ) {
                if ( this.isTraceEnabled() ) {
                    this.trace(`Inserting object link "${title}" --> ${href}.`);
                }
                this.editor.insertLink(href, title);
            } else {
                this.warn('Insert object link: Invalid arguments!', args);
            }
        } else {
            // ?!?!
        }
    }

    /**
     * inserts an image
     * @param {*} args image properties
     */
    insertImage(args) {
        if ( this.ready ) {
            if ( this.isDebugEnabled() ) {
                this.debug('Inserting image:', args);
            }
            const url = args.url || '';
            const name = args.alt || '';
            const width = Validator.isNumber(args.width) ? args.width : -1;
            const height = Validator.isNumber(args.height) ? args.height : -1;
            const last = !!args.last;
            if ( Validator.isString(url) && Validator.isString(name) && (width > 0) && (height > 0) ) {
                this.editor.insertImage(url, name, width, height, last);
                if ( last ) {
                    this._changeNotified = false;
                    this._dirty = true;
                    this._notifyChanged();
                }
            } else {
                this.warn('Insert image: Invalid arguments!', args);
            }
        }
    }

    /**
     * selects a text range
     * @param {*} args selection arguments
     */
    selectRange(args) {
        if ( this.ready ) {
            try {
                if ( this.isDebugEnabled() ) {
                    this.debug('New selection:', args);
                }
                const range = args.range || {};
                const focus = !!args.focus;
                const line = Validator.isNumber(range.row) ? range.row : NO_POSITION;
                const start = Validator.isNumber(range.begin) ? range.begin : NO_POSITION;
                const end = Validator.isNumber(range.end) ? range.end : NO_POSITION;
                if ( (line !== NO_POSITION) && (start !== NO_POSITION) && (end !== NO_POSITION) ) {
                    this.editor.setSelectionRange(line, start, end, focus);
                } else {
                    this.warn('Invalid selection arguments:', args);
                }
            } finally {
                this._notifyInserted(false);
            }
        }
    }

    /**
     * called by the backend after the content type was toggled
     * @param {*} args new content
     */
    toggleContentType(args) {
        if ( this.ready ) {
            const toggled = !!args.toggled;
            if ( toggled ) {
                const ctt = args.ctt || '';
                const html = !!args.html;
                const plain = !!args.plain;
                const processed = !!args.processed;
                if ( html !== plain ) {
                    // ok, proceed
                    this._plainText = plain;
                    if ( plain ) {
                        // there's changed content
                        this._setContent(ctt, plain, processed, false);
                    } else {
                        // just change the "plain text" mode
                        this.editor.setPlainText(plain);
                    }
                } else {
                    // what's this?!
                    this.error('Invalid request sent by the backend!', args);
                }
            } else {
                // backend has rejected this - noting to do so far...
            }
        }
    }

    /**
     * inserts or updates placeholders
     * @param {*} args placeholders data
     */
    setPlaceholders(args) {
        if ( this.ready ) {
            const insert = !!args.insert;
            const selected = args.selected || '';
            const entries = args.entries || [];
            if ( insert && Validator.isString(selected) ) {
                // insert placeholder
                const ph = entries.find((item) => selected === item.key) || {};
                const key = ph.key || '';
                const value = ph.value || '';
                const html = !!ph.html;
                const singleline = !html;
                if ( Validator.isString(key) ) {
                    this.editor.insertPlaceholder(key, value, singleline, html, false);
                }
            } else if ( !insert ) {
                // refresh placeholders
                entries.forEach((e) => e.singleline = !e.html);
                this.editor.refreshPlaceholders(entries);
            }
        }
    }

    /**
     * called by the backend if the framework set the focus to this widget
     */
    forceFocus() {
        if ( this.ready ) {
            this._hasFocus = true;
            this.editor.focus();
        }
    }

    /**
     * causes this editor to loose the focus
     */
    blur() {
        if ( this.ready ) {
            this._hasFocus = false;
            this.editor.blur();
        }
    }

    /**
     * called by the backend if edit mode was terminated
     * @param {*} args new content
     */
    endEditMode(args) {
        try {
            this.newContent(args);
        } finally {
            // always reset "dirty" and "notified"
            this._dirty = false;
            this._changeNotified = false;
        }
    }

    /**
     * called by the backend to activate or deactivate monitoring of data changes
     * @param {*} args argument object providing parameters
     */
    monitorChanges(args) {
        const monitor = !!args.monitor;
        // this is the opposite of "notified changed"
        this._changeNotified = !monitor;
    }

    /**
     * called by the backend to request the current content
     */
    requestContent() {
        if ( this.ready ) {
            // we return the current content unconditionally!
            this._changeNotified = true;
            this._dirty = false;
            this._notifyBE('contentRequested', this._getCttParam(null));
        }
    }

    /**
     * creates the editor instance
     * @param {*} cwd custom widget data
     */
    _createEditor(cwd) {
        const container = document.createElement('div');
		container.id = 'cke5cnt_' + this.wdgId;
		container.className = 'cke5container';
        this.element.appendChild(container);
        setupCke5ContainerListeners(container);
        if ( this.txtFont ) {
            ItmMgr.getInst().setFnt(container, this.txtFont);
        }
        this.container = container;
        this._secondary = !!cwd.secondary;
        const tlb = !!cwd.tlb;
        const extended = !!cwd.extended;
        const plain = !cwd.html;
        const objmenu = cwd.objmenu || [];
        const self = this;
        const props = {};
        const uuid = Validator.ensureString(cwd.uuid);
        this._uuid = uuid;
        const language = Validator.ensureString(cwd.lng);
        props.imageUploadUrl = Validator.ensureString(cwd.imageUploadUrl);
        const rsc = cwd.resources || {};
        if ( rsc.copyFormatCursor ) {
            props.copyFormatCursor = rsc.copyFormatCursor;
        }
        const def_font = {};
        if ( this.txtFont ) {
            def_font.fontFamily = this.txtFont.ffm || '';
            const size = this.txtFont.fsz;
            if ( Validator.isPositiveNumber(size) ) {
                def_font.fontSize = size;
            }
        }
        props.imageBLOBSel = !!cwd.imageBLOBSel;
        props.defaultFont = def_font;
        const options = PCE.createOptions( {
            logger: self,
            language: language,
            toolbar: tlb,
            extended: extended,
            resize: true,
            plaintext: plain,
            objmenu: objmenu,
            phMode: cwd.phMode,
            properties: props
        } );
        const cbf = (editor, err) => {
            if ( editor ) {
                self._onEdtReady(editor, options);
            } else {
                self.error('Failed to create editor instance!', err);
            }
        };
        // create the editor instance
        PCE.createEditor(uuid, container, options, cbf, new CkEdt5Listener(this));
    }

    /**
     * called if the editor was fully initialized
     * @param {PisaEditor} editor the editor instance
     * @param {EditorOptions} options editor options
     */
    _onEdtReady(editor, options) {
        if ( !(editor instanceof PisaEditor) ) {
            this.error('Invalid editor instance!', editor);
            throw new Error("Invalid editor instance!");
        }
        this._editor = editor;
        this.edtReady = true;
        const area = this.parent.getClientArea();
        const height = area[3];
        if ( height > 0 ) {
            // set initial height!
            editor.setEditorHeight(height);
        }
        const ee = editor.element;
        if ( ee instanceof HTMLElement ) {
            const self = this;
            ee.addEventListener('keydown', (e) => self._onEditorCaptureKeyDown(e), true);
            ee.addEventListener('keyup', (e) => self._onEditorKeyUp(e), false);
        }
        if ( this._iniCtt ) {
            const ini_ctt = this._iniCtt;
            this._iniCtt = null;
            this._setContent(ini_ctt.ctt, !ini_ctt.html, !!ini_ctt.processed, !!ini_ctt.insert);
        }
        if ( !!options.plaintext ) {
            // freeze editor to "plain text" mode
            editor.setPlainTextFix();
        }
        // register this instance
        CkEdt5Register.getInstance().addEditor(this);
    }

    /**
     * handles keydown events in capture phase
     * @param {KeyboardEvent} e the keyboard event
     */
    _onEditorCaptureKeyDown(e) {
        let stop = false;
        const ctrl = e.ctrlKey || e.metaKey;
        const alt = e.altKey;
        const shift = e.shiftKey;
        if ( ctrl && !alt && !shift ) {
            switch ( e.key ) {
                case 's':
                case 'S':
                    // prevent the browser from interfering!
                    stop = true;
                    break;
                default:
                    break;
            }
        }
        if ( stop ) {
            DomEventHelper.stopEvent(e);
        }
    }

    /**
     * handles keyboard events sent to the editor
     * @param {KeyboardEvent} e the keyboard event
     */
    _onEditorKeyUp(e) {
        if ( this.isTraceEnabled() ) {
            this.trace('KEYUP', e);
        }
        let handled = false;
        let action = null;
        const ctrl = e.ctrlKey || e.metaKey;
        const alt = e.altKey;
        const shift = e.shiftKey;
        if ( ctrl ) {
            const dsc = KeyHdl.getInstance().getKeyDscFromDomEvent(e);
            const par = { dsc: dsc };
            this._getCttParam(par);
            this._notifyBE('hotkey', par);
            this._dirty = false;
            this._changeNotified = true;
            handled = true;
        } else {
            switch ( e.key ) {
                case 'F2':
                    handled = !alt && !shift && !this.secondary;
                    if ( handled ) {
                        const self = this;
                        action = () => {
                            self.onMaximize(self.editor);
                        };
                    }
                    break;
                default:
                    break;
            }
        }
        if ( handled ) {
            DomEventHelper.stopEvent(e);
        }
        if ( action ) {
            action();
        }
    }

    _onRapFocus(focus) {
        if ( this.alive ) {
            this.log("New RAP focus", focus);
            if ( focus && (this.hasFocus !== focus) ) {
                this.forceFocus();
            }
        } else {
            console.log("Instance is dead, cannot handle focus changes anymore!", focus);
        }
    }

    /**
     * processes the content sent by the backend
     * @param {String} org original content as sent by the backend
     * @param {Boolean} plain plain text flag
     * @returns {String} the adjusted content, if required; otherwise the original content
     */
    _processContent(org, plain) {
        if ( plain && Validator.isString(org) && org.endsWith('\n') ) {
            return org.substring(0, org.length-1);
        }
        return org;
    }

    /**
     * passes new content to the editor
     * @param {String} ctt new content
     * @param {Boolean} plain plain text flag
     * @param {Boolean} processed flag whether the content was already processed to match the "plain text" setting
     * @param {Boolean} insert flag whether this method was called as consequence of an insert operation
     */
    _setContent(ctt, plain, processed, insert) {
        const isc = this._inSetCtt;
        try {
            this._inSetCtt = true;
            this._plainText = !!plain;
            this.editor.setContent(ctt, this.plainText, !!processed, this.txtFont);
            this.editor.setSpellchecking(this._spellChecking, true);
            if ( insert ) {
                this._notifyInserted(true);
            }
        } finally {
            this._inSetCtt = isc;
            this._dirty = false;
            this._changeNotified = false;
        }
    }

    /**
     * creates / fills an argument object with current content properties
     * @param {*} args current argument object
     * @returns {*} the filled argument object
     */
    _getCttParam(args) {
        if ( this.isDebugEnabled() ) {
            this.debug('Sending current content along with the notification code to the backend.');
        }
        const param = args || {};
        const editor = this.editor;
        param.ctt = editor.getContent();
        param.html = !editor.plainText;
        return param;
    }

    /**
     * send notifications to the backend
     * @param {String} code notification code
     * @param {*} par notification parameters
     */
    _notifyBE(code, par) {
        if ( this.wdgReady ) {
            const tms = Date.now();
            const param = {};
            param.cod = code;
            param.par = par;
            param.tms = tms;
            rap.getRemoteObject(this).notify(NOTIFICATION, param);
        }
    }

    _notifyChanged() {
        if ( this.dirty && !this._changeNotified ) {
            if ( this.isDebugEnabled() ) {
                this.debug('Notify backend about CONTENT CHANGED.');
            }
            this._changeNotified = true;
            this._dirty = false;
            this._notifyBE('changed', this._getCttParam(null));
        }
    }

    _notifyInserted(send_ctt) {
        if ( this.isDebugEnabled() ) {
            this.debug('Notify backend about CONTENT INSERTED.');
        }
        const param = { with_ctt: !!send_ctt };
        if ( send_ctt ) {
            this._changeNotified = true;
            this._dirty = false;
            this._getCttParam(param);
        }
        this._notifyBE('contentInserted', param);
    }

	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget CkEdt5V2.');
		rap.registerTypeHandler( "psawidget.CkEdt5V2", {
			factory: function( properties ) {
				return new CkEdt5V2( properties );
			},
			destructor: 'destroy',
			properties: [ 'textFont', 'dirty', 'cttTypeInfo' ],
			methods: [ 'newContent', 'toggleContentType', 'insertContent', 'deleteAllContent', 'insertObjectLink', 'insertImage', 'selectRange', 'forceFocus', 'endEditMode', 'monitorChanges', 'requestContent', 'setPlaceholders' ],
			events: [ NOTIFICATION, 'HTM_EDR_IMG' ]
		} );
	}

}
/**
 * class PSA - provides some generally useful utility methods
 */

import { BUILTIN_PSAWEBCLI_VER } from './version';
import Utils from './utils/Utils';
import Validator from './utils/Validator';
import InternalLogger from './internal/intlogger';
import INFO from './internal/info';
import ObjReg from './utils/ObjReg';
import UIUtil from './gui/uiutil';
import ItmMgr from './gui/ItmMgr';
import ThemeMgr from './gui/ThemeMgr';
import WakeLock from './ssn/WakeLock';
import CpyClpCfd from './gui/misc/CpyClpCfd';
import WndAcv from './gui/misc/WndAcv';
import TimMgr from './ssn/TimMgr';
import KeyHdl from './key/KeyHdl';
import GlbKeyLis from './key/glbkeylis';
import WdgKeyLsr from './key/WdgKeyLsr';
import MnuMgr from './gui/menu/MnuMgr';
import DskNfy from './ssn/DskNfy';
import FileDropHandler from './dnd/FileDropHandler';
import FileDropTarget from './dnd/FileDropTarget';
import BscMgr from './gui/BscMgr';
import QvwMgr from './gui/qvw/QvwMgr';
import ConMgr from './ssn/ConMgr';
import VieTglBtn from './gui/misc/VieTglBtn';
import FloMsgMgr from './gui/misc/FloMsgMgr';
import JsRect from './utils/JsRect';
import JsSize from './utils/JsSize';
import JsPoint from './utils/JsPoint';
import RequestHolder from './utils/RequestHolder';

import registerCellRenderers from './gui/misc/CllRnd';
import HtmHelper from './utils/HtmHelper';
import DomEventHelper from './utils/DomEventHelper';
import WheelLsr from './gui/misc/WheelLsr';
import CkEdt5Register from './widgets/ckedt5v2/CkEdt5Register';

/** common "PiSA sales" utility class */
export default class PSA {

	/**
	 * constructs a new instance
	 */
	constructor() {
		PSA.instance = this;			// use this for old (deprecated!) "window.pisasales" based access
		this._dbgMode = false;
		this._mnuMgr = null;
		this._bscMgr = null;
		this._qvwMgr = null;
		this._usrLng = '';
		this._jsFeatures = new Set();
		this._logger = new InternalLogger( true );
		this._info = INFO.getInstance();
		this._brwOk = this._info.isBrwOk();
		this._uiutil = UIUtil.getInstance();
		this._glbWdgReg = new ObjReg();
		this._im = ItmMgr.getInst();
		this._keyHdl = new KeyHdl( this );
		this._glbKeyLis = new GlbKeyLis();
		this._cke5Reg = new ObjReg();
		this._canPassive = this._testPassive();
		this._requestHolder = null;
	}

	/**
	 * @returns {PSA} the singleton instance
	 */
	static getInst() {
		return singleton;
	}

	static getInstance() {
		return PSA.getInst();
	}

	/**
	 * @returns {InternalLogger} the internal logging helper
	 */
	get Log() {
		return this._logger;
	}

	/**
	 * @returns {INFO} the info provider
	 */
	get Info() {
		return this._info;
	}

	/**
	 * @returns {Boolean} the "browser is ok" flag
	 */
	get brwOk() {
		return this._brwOk;
	}

	/**
	 * @returns {UIUtil} the UI utility helper
	 */
	get UIUtil() {
		return this._uiutil;
	}

	/**
	 * @returns {ObjReg} the global widget registry
	 */
	get glbWdgReg() {
		return this._glbWdgReg;
	}

	/**
	 * @returns {String} the user language
	 */
	get usrLng() {
		return this._usrLng;
	}

	/**
	 * sets the user language
	 * @param {String} lng the user language
	 */
	set usrLng( lng ) {
		this._usrLng = lng || '';
	}

	/**
	 * indicates whether the browser supports passive event listeners;
	 * see https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
	 * @returns {Boolean} true if this browser support passive event listeners; false otherwise
	 */
	 get canPassiveListeners() {
		return this._canPassive;
	}

	/**
	 * returns the built-in web client version
	 * @returns {String} the built-in web client version
	 */
	getBuiltInVersion() {
		return BUILTIN_PSAWEBCLI_VER;
	}

	/**
	 * returns the web client version
	 * @returns {String} the web client version
	 */
	getWebClientVersion() {
		return this.getBuiltInVersion();
	}

	/**
	 * returns the global DEBUG mode flag
	 * @returns {Boolean} true if global DEBUG mode is active; false otherwise
	 */
	isDbgMode() {
		return this._dbgMode;
	}

	/**
	 * sets / updates the global DEBUG mode
	 * @param {Boolean} mode new global DEBUG mode
	 */
	setDbgMode( mode ) {
		this._dbgMode = !!mode;
	}

	/**
	 * sets the active JS features
	 * @param {String[]} jsf a string array specifying the active JS features
	 */
	setJsFeatures( jsf ) {
		const set = this._jsFeatures;
		set.clear();
		jsf.forEach( ( f ) => {
			set.add( f );
		} );
	}

	/**
	 * checks whether a JS feature is active
	 * @param {String} f the feature to be checked
	 * @returns {Boolean} true if the feature is activated; false otherwise
	 */
	hasJsFeature( f ) {
		return this._jsFeatures.has( f );
	}

	/**
	 * creates a function that is bound to a specific context (i.e. an instance)
	 * @param {Object} context the target object
	 * @param {Function} method the method to be bound to the specified context
	 * @returns {Function} a function that binds the specified method to the specified context
	 */
	bind( context, method ) {
		return Utils.bind(context, method);
	}

	/**
	 * binds all specified methods to the given instance
	 * @param {Object} context the target object
	 * @param {String[]} methodNames the names of the methods to be bound to the specified context
	 */
	bindAll( context, methodNames ) {
		Utils.bindAll(context, methodNames);
	}

	/**
	 * asynchronously executes the specified method for the given context (instance)
	 * @param {Object} context the target object
	 * @param {Function} func the method to be executed asynchronously for the given context
	 */
	async ( context, func ) {
		window.setTimeout( function() {
			func.apply( context );
		}, 0 );
	}

	/**
	 * Debounce function, inspired by (copied from) David Walsh.
	 * See here (https://davidwalsh.name/javascript-debounce-function).
	 * Lodash.debounce was too long.
	 * @param {Function} func the function to debounce
	 * @param {Number} wait the number of milliseconds to wait for repeated calls before the function is triggered
	 * @param {Boolean} immediate triggers the function on the 'leading' call, instead of the 'tail' end of the series of calls
	 * @return {Function} the debounced function
	 */
	debounce( func, wait, immediate ) {
		return Utils.debounce(func, wait, immediate);
	}

	/**
	 * calls function 'f' for each own property of object 'o'; * the signature of 'f' is f(name, value)
	 * @param {Object} o the target object
	 * @param {Function} f the function to be called
	 */
	forEachProp( o, f ) {
		Utils.forEachProp(o, f);
	}

	/**
	 * reads a value from local storage
	 * @param {String} name key name
	 * @returns {*} the associated value with the given key or null if there's no such value
	 */
	getStorageVal(name) {
		if ( Validator.isString(name) ) {
			const ls = window.localStorage || false;
			if ( ls ) {
				try {
					return ls.getItem(name);
				} catch ( err ) {
					console.error(err);
				}
			}
		}
		return null;
	}

	/**
	 * stores a value in the local storage; if the given value is undefined then a value
	 * with the given key is removed
	 * @param {String} name key name
	 * @param {String|undefined} value the value to be stored
	 */
	setStorageVal(name, value) {
		if ( Validator.isString(name) ) {
			const ls = window.localStorage || false;
			if ( ls ) {
				try {
					if ( value !== undefined ) {
						ls.setItem(name, '' + value);
					} else {
						ls.removeItem(name);
					}
				} catch ( err ) {
					console.error(err);
				}
			}
		}
	}

	/**
	 * inserts an array into the target array at the specified position
	 * @param {Array} target The array to be split up into a head and tail.
	 * @param {Array} body The array to be inserted between the head and tail.
	 * @param {Number} startIndex Where to split the target array.
	 * @returns {Array} the resulting array
	 */
	insertArray( target, body, startIndex ) {
		if ( startIndex < 0 ) {
			throw new Error( "The value for startIndex cannot be less than zero (0)." );
		}
		const LIMIT = target.length - 1;
		if ( startIndex > LIMIT ) {
			return [].concat( target, body );
		} else {
			return [].concat( target, body, target.splice( startIndex ) );
		}
	}

	/**
	 * checks a string
	 * @param {String} s the string to be checked
	 * @returns {Boolean} true if the specified object is a non-empty string; false otherwise
	 */
	isStr( s ) {
		return Validator.isString( s );
	}

	/**
	 * escapes (quotes) HTML code so that it will be displayed as literal text
	 * @param {String} s the source string
	 * @returns {String} a probably string with quoted angle brackets
	 */
	escHtml( s ) {
		return Utils.escHtml(s);
	}

	/**
	 * link check function
	 * @param {String} href the link to be checked
	 * @returns {Boolean} true if the specified link is PiSA object link; false otherwise
	 */
	isPsaObjLink( href ) {
		return Validator.isString( href ) && href.includes( '/psaobj' ) && href.includes( 'rpsids=' ) && href.includes( 'psacbk=' );
	}

	/**
	 * returns a pseudo random integer in interval [0...max)
	 * @param {Number} max the maximum value
	 * @returns {Number} the pseudo random integer in interval [0...max)
	 */
	getRandomInt( max ) {
		return Math.floor( Math.random() * Math.floor( max ) );
	}

	/**
	 * Maps the PiSA language encoding to ISO 639-1 codes that are generally
	 * accepted by JS libraries.
	 * @param {String} pisaLanguage the PiSA language encoding
	 * @return {String} the ISO 639-1 language encoding
	 */
	toIsoLanguage( pisaLanguage ) {
		switch ( pisaLanguage ) {
			case "ger":
				return "de";
			case "eng":
				return "en";
			case "fra":
				return "fr";
			case "ita":
				return "it";
			default:
				// extend when/if necessary
		}
		return pisaLanguage;
	}


	/**
	 * stops the further handling and propagation of a DOM event
	 * @param {Event} e the DOM event to be stopped
	 */
	stopEvent( e ) {
		return DomEventHelper.stopEvent(e);
	}

	/**
	 * removes a DOM element
	 * @param {HTMLElement} element  element to be removed
	 */
	rmvDomElm( element ) {
		HtmHelper.rmvDomElm(element);
	}

	/**
	 * checks whether an HTMLelement is a child of another HTMLElement
	 * @param {HTMLElement} element the element to check
	 * @param {HTMLElement} parent the possible parent element
	 * @returns {Boolean} true if the specified DOM element is a child (a descendant) of the specified parent element; false otherwise
	 */
	isChildOf( element, parent ) {
		return HtmHelper.isChildOf(element, parent);
	}

	/**
	 * forces the block screen to be shown or hidden
	 * @param {Boolean} vis block screen visibility flag
	 */
	frcBlkScr( vis ) {
		const bsc = document.getElementById( 'PSA.blkScr' );
		if ( bsc ) {
			bsc.style.display = vis ? 'flex' : 'none';
		}
	}

	/**
	 * shortcut method to set the "block screen request" flag
	 */
	setBscRqu() {
		if ( this._bscMgr ) {
			this._bscMgr.setBscRqu();
		}
	}

	/**
	 * forces a DOM element to be redrawn
	 * @param {HTMLElement} element  element to be redrawn
	 * @param {Boolean} hard flag whether to to a hard redraw which may cause flicker
	 */
	forceRedraw( element, hard ) {
		if ( hard ) {
			const tn = document.createTextNode( ' ' );
			const disp = element.style.display;
			element.appendChild( tn );
			element.style.display = 'none';
			setTimeout( function() {
				element.style.display = disp;
				tn.parentNode.removeChild( tn );
			}, 20 );
		} else {
			const re = new UIEvent( 'resize', { view: window } );
			document.dispatchEvent( re );
		}
	}

	/**
	 * parses the URL's query condition to a JSON object
	 * @param {String} url the URL
	 * @param {Boolean} hashBased flag whether to parse URL's query parameters
	 * @returns {Object} the parsed object
	 */
	getJsonFromUrl( url, hashBased ) {
		let query = '';
		if ( hashBased ) {
			const pos = url.indexOf( "?" );
			if ( pos == -1 ) {
				return {};
			}
			query = url.substr( pos + 1 );
		} else {
			query = location.search.substr( 1 );
		}
		const result = {};
		query.split( "&" ).forEach( ( _p ) => {
			if ( !_p ) {
				return;
			}
			const part = _p.replace( "+", " " );
			const eq = part.indexOf( "=" );
			let key = eq > -1 ? part.substr( 0, eq ) : part;
			const val = eq > -1 ? decodeURIComponent( part.substr( eq + 1 ) ) : "";
			const from = key.indexOf( "[" );
			if ( from == -1 ) {
				result[ decodeURIComponent( key ) ] = val;
			} else {
				const to = key.indexOf( "]" );
				const index = decodeURIComponent( key.substring( from + 1, to ) );
				key = decodeURIComponent( key.substring( 0, from ) );
				if ( !result[ key ] ) {
					result[ key ] = [];
				}
				if ( !index ) {
					result[ key ].push( val );
				} else {
					result[ key ][ index ] = val;
				}
			}
		} );
		return result;
	}

	/**
	 * checks whether a drag'n'drop operation is active
	 */
	isDNDActive() {
		return !!rwt.event.DragAndDropHandler.getInstance().__dragCache;
	}

	/**
	 * We override the RAP context menu restriction rules, so we can show the browser context
	 * menu on the HTML-Editor (i.p. Plain Text), which is based on a composite.
	 * @param {widget} target the target widget, for which we obtain the menu restrictions
	 * @param {node} domTarget the target DOM-element on client side
	 */
	static getAllowContextMenu( target, domTarget ) {
		let result = false;
		switch ( target.classname ) {
			case "rwt.widgets.Composite":
				const wm = rwt.remote.WidgetManager.getInstance();
				const wid = wm.findIdByWidget( target );
				const wob = rap.getObject( wid );
				if ( wob ) {
					const cwd = wob.getData( 'pisasales.CSTPRP.CWD' );
					if ( cwd ) {
						result = [ 'de.pisa.webcli.cstwdg.htmedr.HtmEdr',
								'de.pisa.webcli.cstwdg.xtdtbl.impl.XtwTbl',
								'de.pisa.webcli.cstwdg.xtdtbl.impl.XtwBody'
							]
							.indexOf( cwd.className ) >= 0;
					}
				}
				break;
			case "rwt.widgets.Label":
			case "rwt.widgets.Text":
			case "rwt.widgets.base.GridRowContainer":
			case "rwt.widgets.ListItem":
			case "rwt.widgets.base.BasicText":
			case "qx.ui.form.TextArea":
				// NOTE: "enabled" can be "inherit", so it is not always a boolean
				if ( target.getEnabled() !== false ) {
					if ( rwt.widgets.Menu._hasNativeMenu( domTarget ) ) {
						result = target.getContextMenu() == null;
					}
				}
				break;
		}
		return result;
	}

	/**
	 * completes the initialization
	 * @returns {Boolean} true if everything is ok; false if we must stop this session right now
	 */
	completeInitialization() {
		if ( this.brwOk ) {
			// We are replacing the context menu restriction of rap - sure we do!
			rwt.event.EventHandler.setAllowContextMenu( PSA.getAllowContextMenu );
			// register cell renderers
			registerCellRenderers();
			// initialize global wheel listeners
			WheelLsr.getInstance().initWheelLsr(this.canPassiveListeners);
		}
		return this.brwOk;
	}

	/**
	 * creates an object registry instance
	 * @returns {ObjReg} the created instance
	 */
	creObjReg() {
		return new ObjReg();
	}

	/**
	 * creates and show a "copy to clipboard" confirmation dialog
	 * @param {String} data data string to be copied to the clipboard
	 * @param {String} ico icon descriptor
	 * @param {*} par additional parameters
	 * @returns {CpyClpCfd} the "copy to clipboard" confirmation dialog instance
	 */
	cpyClpCfd( data, ico, par ) {
		const cfd = new CpyClpCfd( data, ico, par );
		cfd.show();
		return cfd;
	}

	/**
	 * @returns {ItmMgr} the item manager
	 */
	getItmMgr() {
		return this._im;
	}

	/**
	 * @returns {ThemeMgr} the theme manager instance
	 */
	getThemeMgr() {
		return ThemeMgr.getInstance();
	}

	/**
	 * @returns {WakeLock} the wake lock instance
	 */
	getWakeLock() {
		return WakeLock.getInstance();
	}

	/**
	 * creates a new window activation tracker
	 * @param {CliCbkWdg} cbw callback widget
	 * @returns {WndAcv} the created window activation tracker
	 */
	creWndAcv( cbw ) {
		return new WndAcv( cbw );
	}

	/**
	 * creates a new timer manager instance
	 * @param {CliCbkWdg} cbw callback widget
	 * @returns {TimMgr} the timer manager instance
	 */
	creTimMgr( cbw ) {
		return new TimMgr( cbw );
	}

	/**
	 * @returns {KeyHdl} the global key handler
	 */
	get keyHdl() {
		return this._keyHdl;
	}

	/**
	 * @returns {KeyHdl} the global key handler
	 */
	getKeyHdl() {
		return this.keyHdl;
	}

	/**
	 * @returns {MnuMgr} the menu manager
	 */
	getMnuMgr() {
		return this._mnuMgr;
	}

	/**
	 * creates the menu manager
	 * @param {CliCbkWdg} cbw callback widget
	 * @returns {MnuMgr} the menu manager
	 */
	creMnuMgr( cbw ) {
		if ( !!this._mnuMgr ) {
			throw new Error( 'Menu manager already created!' );
		}
		if ( !cbw ) {
			throw new Error( 'Missing reference to callback widget!' );
		}
		this._mnuMgr = new MnuMgr( this, cbw );
		return this._mnuMgr;
	}

	/**
	 * returns the QuickView manager instance
	 * @returns {QvwMgr} the QuickView manager instance
	 */
	getQvwMgr() {
		return this._qvwMgr;
	}

	/**
	 * creates a new QuickView manager instance
	 * @param {CliCbkWdg} cbw callback widget
	 * @returns {QvwMgr} the QuickView manager instance
	 */
	creQvwMgr( cbw ) {
		if ( !!this._qvwMgr ) {
			throw new Error( 'QuickView manager already created!' );
		}
		if ( !cbw ) {
			throw new Error( 'Missing reference to callback widget!' );
		}
		this._qvwMgr = new QvwMgr( cbw );
		return this._qvwMgr;
	}

	/**
	 * returns the block screen manager instance
	 * @returns {BscMgr} the block screen manager instance
	 */
	getBscMgr() {
		return this._bscMgr;
	}

	/**
	 * creates a new block screen manager instance
	 * @param {CliCbkWdg} cbw callback widget
	 * @param {*} image block screen image descriptor
	 * @returns {BscMgr} the block screen manager
	 */
	creBscMgr( cbw, image ) {
		if ( !!this._bscMgr ) {
			throw new Error( 'Block screen manager already created!' );
		}
		if ( !cbw ) {
			throw new Error( 'Missing reference to callback widget!' );
		}
		this._bscMgr = new BscMgr(cbw, image);
		return this._bscMgr;
	}

	/**
	 * creates a new connection manager instance
	 * @param {CliCbkWdg} cbw callback widget
	 * @returns {ConMgr} the connection manager
	 */
	creConMgr( cbw ) {
		return new ConMgr( cbw );
	}

	/**
	 * creates a new view toggle button instance
	 * @param {CliCbkWdg} cbw callback widget
	 * @returns {VieTglBtn} the view toggle button
	 */
	creVieTglBtn( cbw ) {
		return new VieTglBtn( cbw );
	}

	/**
	 * creates a new view "floating messages manager" instance
	 * @param {CliCbkWdg} cbw callback widget
	 * @returns {FloMsgMgr} the "floating messages manager" instance
	 */
	creFloMsgMgr( cbw ) {
		return new FloMsgMgr();
	}

	/**
	 * creates the desktop notification support
	 * @param {Boolean} adm_off flag whether desktop notifications are turned off by administration
	 */
	creDskNfy( adm_off ) {
		return new DskNfy( adm_off );
	}

	/**
	 * indicates whether desktop notification is generally available
	 * @returns {Boolean} true if desktop notification is generally available; false otherwise
	 */
	isDskNfyAvl() {
		return DskNfy.isDskNfyAvl();
	}

	/**
	 * constructs a new file drop target
	 * @param {HTMLElement} element the target DOM element
	 * @param {FileDropHandler} handler the file drop handler
	 * @returns {FileDropTarget} the file drop target
	 */
	creFilDrpTgt( element, handler ) {
		return new FileDropTarget( element, handler );
	}

	/**
	 * creates a JsRect instance
	 * @param {Object} args arguments
	 * @returns {JsRect} the JsRect instance
	 */
	creJsRect( args ) {
		const ea = arguments;
		if ( ea.length === 4 ) {
			return new JsRect( ea[ 0 ], ea[ 1 ], ea[ 2 ], ea[ 3 ] );
		} else {
			return new JsRect( args );
		}
	}

	/**
	 * creates a JsSize instance
	 * @param {Object} args arguments
	 * @returns {JsSize} the JsSize instance
	 */
	creJsSize( args ) {
		const ea = arguments;
		if ( ea.length === 2 ) {
			return new JsSize( ea[ 0 ], ea[ 1 ] );
		} else {
			return new JsSize( args );
		}
	}

	/**
	 * creates a JsPoint instance
	 * @param {Object} args arguments
	 * @returns {JsPoint} the JsPoint instance
	 */
	creJsPoint( args ) {
		const ea = arguments;
		if ( ea.length === 2 ) {
			return new JsPoint( ea[ 0 ], ea[ 1 ] );
		} else {
			return new JsPoint( args );
		}
	}

	/**
	 * adds a widget key listener to the specified widget
	 * @param {Object} args arguments
	 * @returns {WdgKeyLsr} the key listener instance
	 */
	addWdgKeyLsr( args ) {
		return WdgKeyLsr.addWdgKeyLsr( args );
	}

	/**
	 * initializes the global request holder instance
	 * @returns {RequestHolder} the global request holder instance
	 */
	 initRequestHolder() {
		if ( this._requestHolder ) {
			throw new Error( 'Cannot create more than one request holder instance.' );
		}
		this._requestHolder = new RequestHolder(true);
		return this.getRequestHolder();
	}

	/**
	 * destroys the global request holder
	 */
	destroyRequestHolder() {
		if ( this._requestHolder ) {
			const rh = this._requestHolder;
			delete this._requestHolder;
			rh.destroy();
		}
	}

	/**
	 * returns the global request holder instance
	 * @returns {RequestHolder} the global request holder instance
	 */
	getRequestHolder() {
		return this._requestHolder;
	}

	/**
	 * @deprecated
     * @returns {Boolean} true on success; false otherwise
	 */
	blurAllCke5Editors() {
		return this.blurAllEditors();
	}

    /**
     * blurs all known editor instances
     * @returns {Boolean} true on success; false otherwise
     */
	blurAllEditors() {
		return CkEdt5Register.getInstance().blurAllEditors();
	}

	/**
	 * tests whether the browser supports passive event listeners;
	 * see https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
	 * @returns {Boolean} true if this browser support passive event listeners; false otherwise
	 */
	_testPassive() {
		let supportsPassive = false;
		try {
			let opts = Object.defineProperty({}, 'passive', {
				get: function() {
					supportsPassive = true;
				}
			});
			window.addEventListener("testPassive", null, opts);
			window.removeEventListener("testPassive", null, opts);
		} catch ( e ) {
			console.warn(e);
		}
		return supportsPassive;
	}

	/**
	 * Sets a list of files as upload targets for the file picker. The client will
	 * copy those to clipboard when the selection is triggered and the user can then
	 * paste the files in the selection dialog.
	 * @param {String} files base64 encoded string list of files to be selected by the user
	 */
	fillRapFileDlg( files ) {
		// check presence of clipboard API
		if ( !Clipboard ) {
			return;
		}
		// assume there is only one upload form, get the form
		const form = document.querySelector( 'form[target^="FileUpload"]' );
		if ( !form ) {
			return;
		}
		const input = form.querySelector( 'input[type="file"]' );
		if ( !input ) {
			return;
		}
		input.dataset.clipboardText = atob( files );
		new Clipboard( input );
	}
}

// the one and only instance
const singleton = new PSA();

console.debug( 'psa.js loaded.' );

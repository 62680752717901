import LoggingBase from '../base/loggingbase';
import HtmHelper from '../utils/HtmHelper';
import Utils from '../utils/Utils';
import Validator from '../utils/Validator';

const AVL_KEYS	= 'ABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890';
const KEY_UID	= 'Unidentified'
	
const KEY_BKSP	= 'Backspace';
const KEY_ESC	= 'Escape';
const KEY_RET	= 'Enter';
const KEY_DEL	= 'Delete';
const KEY_TAB	= 'Tab';

const KEY_LFT	= 'Left';
const KEY_RGT	= 'Right';
const KEY_HOM	= 'Home';
const KEY_END	= 'End';
const KEY_PUP	= 'PageUp';
const KEY_PDN	= 'PageDown';
const KEY_UP	= 'Up';
const KEY_DWN	= 'Down';
const KEY_SPC	= 'Space';

const MOD_SFT	= 0x01;
const MOD_CTR	= 0x02;
const MOD_ALT	= 0x04;
    
/**
 * key listener class for RAP widgets;
 * it supports several options that specify which key strokes are effectively reported to the web server
 */
export default class WdgKeyLsr extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {String} idw widget ID
	 * @param {Widget} wdg the target widget
	 * @param {Object} opt options
	 * @param {CliCbkWdg} cbw the callback widget
	 * 
	 * we support the following options:
	 * dom - use DOM event handler instead of RAP event handler
	 * capture - use DOM event handler in capture phase
	 * up  - listen on "keyup" instead of "keydown"
	 * bsc - use common block screen behavior; if not set then key events are sent without triggering the block screen
	 * pch - printable characters; i.e. key events that generate real input
	 * bks - backspace
	 * tab - tab key
	 * esc - Escape key
	 * ret - Return / Enter key
	 * del - delete key
	 * csr - cursor key
	 * udo - cursor up and cursor down keys only
	 * mod - consider modifier keys <Ctrl>, <Alt>, <Shift>
	 */
	constructor(idw, wdg, opt, cbw) {
		super('key.WdgKeyLsr');
		const self = this;
		this.onRender = Utils.bind(self, self._onRender);

		this.wdgID = idw;
		this.tgtWdg = wdg;
		this.cbkWdg = cbw;

		// evaluate options
		const dom = !!opt.dom;
		const capture = dom && !!opt.capture;
		const up = !!opt.up;
		this.keyUp = up;
		this.captureLsr = capture;
		this.useBsc = !!opt.bsc;
		this.prnChr = !!opt.pch;
		this.bksKey = !!opt.bks;
		this.tabKey = !!opt.tab;
		this.escKey = !!opt.esc;
		this.retKey = !!opt.ret;
		this.delKey = !!opt.del;
		this.csrKys = !!opt.csr;
		this.csrUdo = !!opt.udo;
		this.modKey = !!opt.mod;

		if ( dom ) {
			// add DOM listener
			rap.on('render', this.onRender);
		} else {
			// add RAP key listener
			this.tgtWdg.addEventListener(up ? 'keyup' : 'keydown', this._onRapKeyEvent, self);
		}
	}

	/**
	 * called in RAP's render phase
	 */
	_onRender() {
		rap.off('render', this.onRender);
		const element = this.tgtWdg._element;
		if ( element instanceof HTMLElement ) {
			const self = this;
			const capture = !!this.captureLsr;
			element.addEventListener(this.keyUp ? 'keyup' : 'keydown', (e) => {
				self._onDomKeyEvent(e, element);
			}, capture);
		}
	}

	/**
	 * handles a keyboard event
	 * @param {rwt.event.KeyEvent | KeyboardEvent} evt the original RAP or DOM event
	 * @param {KeyboardEvent} de the DOM event
	 * @param {String} key key identifier
	 * @param {boolean} ctr "Ctrl" flag
	 * @param {boolean} sft "Shift" flag
	 * @param {boolean} alt "Alt" flag
	 */
	_handleKeyEvent(evt, de, key, ctr, sft, alt) {
		if ( !this.modKey && (ctr || alt) ) {
			// some hotkey or anything else out of interest
			return;
		}
		let dsc = '';
		let hky = false;
		let csr = false;
		let mod = 0;
		if ( sft ) {
			mod |= MOD_SFT;
		}
		if ( ctr ) {
			mod |= MOD_CTR;
		}
		if ( alt ) {
			mod |= MOD_ALT;
		}
		switch ( key ) {
			case KEY_BKSP:
				if ( this.bksKey ) {
					dsc = key;
					hky = true;
				}
				break;
			case KEY_DEL:
				if ( this.delKey ) {
					dsc = key;
					hky = true;
				}
				break;
			case KEY_ESC:
				if ( this.escKey ) {
					dsc = key;
					hky = true;
				}
				break;
			case KEY_RET:
				if ( this.retKey ) {
					dsc = key;
					hky = true;
				}
				break;
			case KEY_TAB:
				if ( this.tabKey ) {
					dsc = key;
					hky = true;
					csr = true;
				}
				break;
			case KEY_LFT:
			case KEY_RGT:
			case KEY_UP:
			case KEY_DWN:
			case KEY_PUP:
			case KEY_PDN:
			case KEY_HOM:
			case KEY_END:
				if ( this.csrKys ) {
					dsc = key;
					hky = true;
					csr = true;
				} else if ( this.csrUdo ) {
					if ( key === KEY_DWN || key === KEY_UP ) {
						dsc = key;
						hky = true;
						csr = true;
					}
				}
				break;
			case KEY_SPC:
				if ( this.prnChr ) {
					dsc = ' ';
					hky = false;
				}
				break;
			default:
				if ( this.prnChr ) {
					// check for printable characters
					if ( KEY_UID === key ) {
						// do a bit more investigation
						if ( de && Validator.isString(de.key) && (de.key.length === 1) ) {
							dsc = de.key;
						}
					}
					else if ( AVL_KEYS.indexOf(key) >= 0 ) {
						// a supported key
						dsc = key;
					}
				}
				break;
		}
		if ( Validator.isString(dsc) ) {
			// ok, notify web server
			evt.stopPropagation();
			evt.preventDefault();
			const par = {};
			par.key = dsc;
			par.hky = hky;
			par.csr = csr;
			par.mod = mod;
			this.cbkWdg.nfySrv(this.wdgID, 'keyevent', par, this.useBsc);
		}
	}

	/**
	 * "keydown" / "keyup" RAP event handler
	 * @param {KeyboardEvent} ke the DOM keyboard event
	 * @param {HTMLElement} target 
	 */
	_onDomKeyEvent(ke, target) {
		if ( !HtmHelper.isSameOrChildOf(ke.target, target) ) {
			return;
		}
		this._handleKeyEvent(ke, ke, ke.key, ke.ctrlKey, ke.shiftKey, ke.altKey || ke.metaKey);
	}

	/**
	 * "keydown" / "keyup" RAP event handler
	 * @param {rwt.event.KeyEvent} evt RAP key event
	 */
	_onRapKeyEvent(evt) {
		if ( !evt || (this.tgtWdg !== evt.getTarget()) ) {
			// not for us
			return;
		}
		this._handleKeyEvent(evt, evt.getDomEvent(), evt.getKeyIdentifier(), evt.isCtrlPressed(), evt.isShiftPressed(), evt.isAltPressed());
	}

	/**
	 * adds a widget key listener to the specified widget
	 * @param {Object} args arguments
	 * @returns {WdgKeyLsr} the key listener instance
	 */
	static addWdgKeyLsr(args) {
		const idw = args.idw || '';
		const cbw = args.__cbw || null;
		if ( Validator.isString(idw) && cbw ) {
			const wdg = rwt.remote.ObjectRegistry.getObject(idw);
			if ( wdg ) {
				const opt = args.opt || {};
				return new WdgKeyLsr(idw, wdg, opt, cbw);
			}
		}
		return null;
	}
}

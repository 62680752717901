import FocusHolder from "../../../../gui/FocusHolder";
import Validator from "../../../../utils/Validator";
import EditRequest from "./EditRequest";
import { ROWID_DUMMY } from "../../model/XtwModel";
import XCellItem from "../../parts/XCellItem";

export default class AbstractEditable extends FocusHolder {

    /**
     * constructs a new instance
     * @param {Number} _cid the column ID
     * @param {Number} _rid the row ID
     * @param {String} ln logger name
     */
    constructor(_cid, _rid, ln) {
        super(ln || 'widgets.xtw.editing.AbstractEditable');
        if ( !Validator.isNumber(_cid) ) {
            throw new Error(`Expected a number for the column ID but got "${_cid}" (${typeof _cid}).`);
        }
        if ( !Validator.isNumber(_rid) ) {
            throw new Error(`Expected a number for the row ID but got "${_rid}" (${typeof _rid}).`);
        }
        this._rid = _rid;
        this._cid = _cid;
        this._keepForced = false;
    }

    /**
     * @returns {Number} the column ID
     */
    get columnId() {
        return this._cid;
    }

    /**
     * @returns {Number} the row ID
     */
    get rowId() {
        return this._rid;
    }

    /**
     * @returns {Boolean} true if this instance is connected to the "dummy" row; false otherwise
     */
    get isOnDummyRow() {
        return this._rid === ROWID_DUMMY;
    }

    /**
     * @inheritdoc
     * @override
     */
    get isKeepForced() {
        return this.alive && this._keepForced;
    }

    /**
     * @override
     */
    doDestroy() {
        delete this._rid;
        delete this._cid;
        super.doDestroy();
    }

	/**
	 * returns an internal type info string
	 * @returns {String} an internal type info string
	 */
    getType() {
		return 'abstract';
	}

    /**
     * indicates whether this input element is an edit control
     * @returns {Boolean} true if this input element is an edit control; false otherwise
     */
    isEditCtrl() {
        return false;
    }

	/**
     * indicates whether this editable is a checkbox
     * @returns {boolean} true if this editable is a checkbox; false otherwise
     */
    isCheckbox() {
        // abstract
        return false;
    }

    /**
     * registers this instance with table body
     * @returns {Boolean} true if editable was registered; false otherwise
     */
    register() {
        // abstract
        return false;
    }

    /**
     * un-registers this instance with table body
     * @returns {Boolean} true if editable was un-registered; false otherwise
     */
    unregister() {
        // abstract
        return false;
    }

	/**
	 * updates the row ID
	 * @param {Number} idr new row ID
	 */
	updateIdr(idr) {
		this._rid = idr;
	}

    /**
     * @override
     */
    lock() {
        super.lock();
        if ( this.isDebugEnabled() ) {
            this.log(`LOCK ${this.iid}: ${this.lockCount}`);
        }
    }

    /**
     * @override
     */
    release() {
        if ( this.lockCount > 0 ) {
            super.release();
        } else {
            this.log(`RELEASE of ${this.iid} rejected because it's not locked!`);
        }
        if ( this.isDebugEnabled() ) {
            this.log(`RELEASE ${this.iid}: ${this.lockCount}`);
        }
    }

    /**
     * sets the "keep forced" flag
     * @param {Boolean} keep new value
     */
    setKeepForced(keep) {
        this._keepForced = !!keep;
    }

    /**
     * @inheritdoc
     * @override
     */
    applyChanges() {
        if ( this.alive ) {
            this.informAboutContentChange();
        }
    }

    /**
     * renders this editable
     */
    render() {
        // abstract
    }

    /**
     * destroys this editable and restores the table cell
	 * @returns {Boolean} true if successful; false otherwise
     */
    destroySelfAndRestoreCell() {
        // abstract
        return true;
    }

    /**
     * indicates whether the input element needs to process an arrow key
     * @param {Boolean} horz true: horizontal arrow key (left or right); false: vertical arrow key (up or down)
     * @param {Boolean} upleft true: direction is up or left; false: direction is down or right
     * @param {KeyboardEvent} ke the keyboard event
     * @returns {Boolean} true if the input element needs the arrow key; false if the key can trigger cell navigation
     */
    needsArrowKey(horz, upleft, ke) {
        return false;
    }

    /**
     * sets the editing permission
     * @param {Boolean} editingAllowed true if editing is allowed; false otherwise
     * @returns {Boolean} true if successful; false otherwise
     */
    setEditingPermission(editingAllowed) {
        return true;
    }

    /**
     * resets the input element's value to the value of the table cell
     * @returns {Boolean} true if successful; false otherwise
     */
	resetInput() {
        // abstract
        return false;
    }

	/**
	 * sets the "override" original value
	 * @param {String} ovr new "override" original value
	 */
	setOvrOrgValue(ovr) {
        // abstract
	}

    /**
     * triggers a "dropdown open" if supported
     */
    triggerDropdown() {
        // abstract
    }

    /**
     * informs the editable element about the dropdown state
     * @param {Boolean} open flag whether the dropdown is shown (true) or closed (false)
     * @param {String} wdgId widget ID of the main dropdown widget
     */
    setDropdownOpenState( open, wdgId ) {
        // abstract
    }

    /**
     * synchronizes the input value and the optional dropdown lit with new content
     * @param {*} args arguments object
     */
    syncInputContentWithDropdown(args) {
        // abstract
    }

	/**
	 * called if the content was changed
	 * @returns {Boolean} true if changed content was sent to the web server; false otherwise
	 */
	informAboutContentChange() {
		// abstract
		return false;
	}

    /**
     * informs this instance about a "cancel" request
     * @param {*} parameters 
     * @param {Boolean} blockScreenRequest 
	 * @returns {Boolean} true if successful
     */
    informAboutCancel( parameters = {}, blockScreenRequest = false ) {
        // abstract
        return false;
    }

    /**
     * informs this instance about a "modified" request
     * @param {*} parameters 
     * @param {Boolean} blockScreenRequest 
	 * @returns {Boolean} true if successful
     */
    informAboutModified( parameters = {}, blockScreenRequest = false ) {
        // abstract
        return false;
    }

    /**
     * informs this instance about a "save" request
     * @param {*} parameters 
     * @param {Boolean} blockScreenRequest 
	 * @returns {Boolean} true if successful
     */
    informAboutSave( parameters = {}, blockScreenRequest = false ) {
        // abstract
        return false;
    }

    /**
     * informs this instance about a "full save" request
     * @param {*} parameters 
     * @param {Boolean} blockScreenRequest 
	 * @returns {Boolean} true if successful
     */
    informAboutFullSave( parameters = {}, blockScreenRequest = false ) {
        // abstract
        return false;
    }

    /**
     * creates an edit request that represents this editable
     * @returns {EditRequest} an edit request that represents this editable
     */
    createEditRequest() {
        return null;
    }

    /**
     * re-binds this editable to another cell
     * @param {XCellItem} cell the new cell
     */
    rebindToCell(cell) {
        if ( !(cell instanceof XCellItem) ) {
            throw new Error(`Invalid argument "${cell}"!`);
        }
        if ( this.alive ) {
            this._cid = cell.idc;
            this._rid = cell.idr;
        }
    }

	/**
	 * @returns {Boolean} true if the current edit mode is an "insert" mode; false otherwise
	 */
	get insertMode() {
        // abstract
        return false;
	}

    /**
     * @returns {Boolean} true if this editable is in "dropped down" state; false otherwise
     */
    get droppedDown() {
        // abstract
        return false;
    }
}
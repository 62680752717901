import AttachmentObject from '../../../../utils/AttachmentObject';
import Color from '../../../../utils/Color';
import DomEventHelper from '../../../../utils/DomEventHelper';
import HtmHelper from '../../../../utils/HtmHelper';
import Validator from '../../../../utils/Validator';
import BscMgr from '../../../../gui/BscMgr';
import PSA from '../../../../psa';
import Utils from '../../../../utils/Utils';
import XtwCol from '../../parts/XtwCol';
import MenuHandler from '../../../../gui/menu/MenuHandler';
import MnuObj from '../../../../gui/menu/MnuObj';
import XtwHead from '../../XtwHead';

import { MI_ANY_CLICKED, MI_TEXT_CLICKED } from '../../../../gui/menu/MenuHandler';
import UIRefresh from '../../../../utils/UIRefresh';

const ID_SELECTALL = -1000;
const HEADER_CONTEXT_MENU_CLASSNAME = "rtp-header-contextmenu";
const FOCUSED_HEAD_CONTEXT_MENU_ITEM_CLASSNAME = "rtp-focused";

class XHMenuHandler extends MenuHandler {

	/**
	 * constructs a new instance
	 * @param {XtwHead} xth the table header
	 */
	constructor(xth) {
		super('widgets.xtw.XHMenuHandler');
		this._xth = xth;
	}

	/**
	 * @returns {XtwHead} the table header widget
	 */
	get xth() {
		return this._xth;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	doDestroy() {
		this._xth = null;
		super.doDestroy();
	}

	/**
	 * @param {Number} id the ID of the menu item that was clicked
	 * @param {MnuObj} menu the menu that contains this item
	 * @param {Number} what an indicator which part was clicked:
	 * @inheritdoc
	 * @override
	 */
	onMenuItem(id, menu, what) {
		if ( this.alive ) {
			this.xth.onMenuItem(id, menu, what);
		}
	}

	/**
     * @param {MnuObj} menu the menu that was closed
	 * @inheritdoc
	 * @override
	 */
	onMenuClose(menu) {
		if ( this.alive ) {
			this.xth.onMenuClose(menu);
		}
	}
}


export default class XtwHeadContextMenuExtension extends AttachmentObject {

	constructor( parentObject ) {
		super( parentObject );
		// any getters and setters declared in the constructor after calling this
		// function will not be mirrored/assigned
		this.assignGettersAndSettersTo( parentObject );
		// we do not want this constructor to be hanging on the host object,
		// because the host object has his own prototype and this is supposed to
		// be a one-time assignment
		parentObject.constructor = void 0;
		delete parentObject.constructor;
		// create menu handler
		parentObject._menuHandler = new XHMenuHandler(parentObject);
	}

	/**
	 * @returns {MenuHandler} the menu handler
	 */
	get menuHandler() {
		return this._menuHandler;
	}

	get allColumns() {
		if ( !Validator.isObject( this.columns ) || !Validator.isMap( this.columns._objReg ) ) {
			return [];
		}
		return [ ...this.columns._objReg.values() ];
	}

	/**
	 * @returns {Array<XtwCol>} an array of all available columns
	 */
	get allAvailableColumns() {
		return this.allColumns.filter( column => Validator.isObject( column ) && !!column.available && !column.select );
	}

	get allAvailableVisibleColumns() {
		return this.allColumns.filter( column => Validator.isObject( column ) && !!column.available && !!column.visible && !column.select );
	}

	get allAvailableHiddenColumns() {
		return this.allColumns.filter( column => Validator.isObject( column ) && !!column.available && !column.visible && !column.select );
	}

	get allAvailableColumnsAreVisible() {
		return this.allAvailableColumns.every( column => column.visible );
	}

	get headerContextMenuElement() {
		return Validator.isObject( this.fieldMenu ) && this.fieldMenu.mnuDiv instanceof HTMLElement ? this.fieldMenu.mnuDiv : null;
	}

	get contextMenuElementContainer() {
		const headerContextMenuElement = this.headerContextMenuElement;
		return !!headerContextMenuElement ? [ ...headerContextMenuElement.children ].find( element => element.style.overflow == "hidden" ) : null;
	}

	get headerContextMenuItems() {
		return !Validator.isObjectPath( this.fieldMenu, "fieldMenu.mnuItems" ) ||
			!Validator.isMap( this.fieldMenu.mnuItems._objReg ) ?
			null : [ ...this.fieldMenu.mnuItems._objReg.values() ].filter( item => Validator.is( item, "MnuItm" ) && !item.inactive && !item.sep );
	}

	get visibleHeaderContextMenuItems() {
		return !Validator.isObject( this.fieldMenu ) || !( "visibleMenuItems" in this.fieldMenu ) ? null : this.fieldMenu.visibleMenuItems;
	}

	get currentlyFocusedHeaderContextMenuItem() {
		if ( !Validator.isPositiveInteger(this.currentlyFocusedHeadContextMenuItemId ) || !Validator.isFunctionPath( this.fieldMenu, "fieldMenu.mnuItems.getObj" ) ) {
			return null;
		}
		const focusedMenuItem = this.fieldMenu.mnuItems.getObj( this.currentlyFocusedHeadContextMenuItemId );
		return Validator.isObject( focusedMenuItem ) ? focusedMenuItem : null;
	}

	get lastHeaderContextMenuClickEventWasRightButton() {
		return Validator.isObjectPath( this.fieldMenu, "fieldMenu.mnuMgr" ) && !!this.fieldMenu.mnuMgr.lastHeaderContextMenuClickEventWasRightButton;
	}

	set lastHeaderContextMenuClickEventWasRightButton( newValue ) {
		if ( !Validator.isObjectPath( this.fieldMenu, "fieldMenu.mnuMgr" ) ) {
			return false;
		}
		this.fieldMenu.mnuMgr.lastHeaderContextMenuClickEventWasRightButton = !!newValue;
		return true;
	}

	ignoreNextMouseEvents() {
		if ( !Validator.isObjectPath( this.fieldMenu, "fieldMenu.mnuMgr" ) ) {
			return false;
		}
		this.fieldMenu.mnuMgr.ignoreNextMouseEnterEvent = true;
		this.fieldMenu.mnuMgr.ignoreNextMouseLeaveEvent = true;
		return true;
	}

	voidLastClickEventRightButtonFlag() {
		if ( !Validator.isObjectPath( this.fieldMenu, "fieldMenu.mnuMgr" ) ) {
			return false;
		}
		this.fieldMenu.mnuMgr.lastHeaderContextMenuClickEventWasRightButton = null;
		delete this.fieldMenu.mnuMgr.lastHeaderContextMenuClickEventWasRightButton;
		return true;
	}

	addContextMenuListener() {
		return this.addPrefixListener("contextmenu", "onContextMenu", "XtwHeadContextMenu");
	}

	removeContextMenuListener() {
		return this.removePrefixListener("contextmenu", "XtwHeadContextMenu");
	}

	/**
	 * handles context menu events
	 * @param {MouseEvent} domEvent the context menu event
	 */
	onContextMenu( domEvent ) {
		if ( !Validator.isObject( this.xtwBody ) ) {
			return false;
		}
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const menu = this._getFieldMenu( DomEventHelper.isCtrlEvent( domEvent ) );
		if ( !Validator.isObject( menu ) ) {
			return false;
		}
		const menuManager = PSA.getInst().getMnuMgr();
		menuManager.showMenu( menu, {
			element: this.element,
			rect: null
		}, this.menuHandler, true, true );
		this.refreshHeaderContextMenuSearchInputPlaceholder();
		this.focusHeaderContextMenu();
		return true;
	}

	focusHeaderContextMenuSearchInput() {
		return Validator.isFunctionPath( this.fieldMenu, "fieldMenu.focusHeaderContextMenuSearchInput" ) ? this.fieldMenu.focusHeaderContextMenuSearchInput() : false;
	}

	focusHeaderContextMenu() {
		const headerContextMenuElement = this.headerContextMenuElement;
		if ( !headerContextMenuElement ) {
			return false;
		}
		headerContextMenuElement.classList.add( HEADER_CONTEXT_MENU_CLASSNAME );
		headerContextMenuElement.tabIndex = 0;
		headerContextMenuElement.focus();
		return true;
	}

	refreshHeaderContextMenuSearchInputPlaceholder() {
		return Validator.isFunctionPath( this.fieldMenu, "fieldMenu.refreshInputPlaceholder" ) ? this.fieldMenu.refreshInputPlaceholder() : false;
	}

	/**
	 * handles context menu events
	 * @param {MouseEvent} evt the context menu event
	 * @param {XtwCol} column the column that causes this call
	 */
	onHeaderContextMenu( evt, column ) {
		if ( this.xtwBody && column && column.element ) {
			const menu = this._getFieldMenu( DomEventHelper.isCtrlEvent( evt ) );
			if ( menu ) {
				PSA.getInst().getMnuMgr().showMenu( menu, { element: column.element, rect: null }, this.menuHandler, true, false );
				this.refreshHeaderContextMenuSearchInputPlaceholder();
				this.focusHeaderContextMenu();
				this.focusHeaderContextMenuSearchInput();
			}
		}
	}

	/**
	 * menu handler callback: called if a menu is closed
	 * @param {MnuObj} menu the menu that was closed
	 */
	onMenuClose( menu ) {
		// drop the field menu
		this._dropFieldMenu();
		// verify, that at least one column is visible
		const cols = this.columns;
		const avl_cols = new Map();
		const vis_cols = new Map();
		const fix_cols = new Map();
		try {
			cols.forEach( ( col ) => {
				if ( col.available && !col.select ) {
					avl_cols.set( col.id, col );
					if ( col.visible ) {
						vis_cols.set( col.id, col );
					}
					if ( col.fix ) {
						fix_cols.set( col.id, col );
					}
				}
			} );
			if ( vis_cols.size === 0 ) {
				// ooops?!
				let idc = null;
				if ( fix_cols.size > 0 ) {
					idc = fix_cols.values().next().value.id;
				} else if ( avl_cols.size > 0 ) {
					idc = avl_cols.values().next().value.id;
				}
				if ( idc ) {
					this.onMenuItem( idc, null, MI_ANY_CLICKED );
				}
			}
		} finally {
			fix_cols.clear();
			vis_cols.clear();
			avl_cols.clear();
		}
	}

	/**
	 * menu handler callback: called if a menu item was clicked
	 * @param {Number} id ID of selected menu item
	 * @param {MnuObj} menu the active menu
     * @param {Number} what an indicator which part of the menu item was clicked:
     *                      0 - unspecified;
     *                      1 - the icon was clicked;
     *                      2 - the text was clicked
	 */
	onMenuItem( id, menu, what ) {
		let success = false;
		const self = this;
		const showBlockScreen = new Promise( ( resolve, reject ) => {
			BscMgr.showBlockScreen( true );
			resolve( true );
		} );
		setTimeout( () => {
			const menuItemToggled = showBlockScreen.then( () => {
				UIRefresh.runDelayed( () => {
					success = id === ID_SELECTALL ?
						self.toggleAllContextMenuItems( menu ) :
						self.toggleOneContextMenuItem( id, menu, what );
				} );
			} );
			menuItemToggled.then( () => BscMgr.hideBlockScreen() );
		}, 0 );
		return success;
	}

	toggleOneContextMenuItem( columnId, menu, what ) {
		if ( !Validator.isFunctionPath( this.columns, "columns.getObj" ) ) {
			return false;
		}
		const targetColumn = this.columns.getObj( columnId );
		if ( !(targetColumn instanceof XtwCol) || !targetColumn.available ) {
			return false;
		}
		const columnVisibleBeforeToggle = !!targetColumn.visible;
		const all_cols = this.allAvailableColumns;
		let vc = 0;
		all_cols.forEach(c => {
			if ( c.visible ) {
				++vc;
			}
		});
		if ( (vc === 1) && columnVisibleBeforeToggle ) {
			// No, Sir! At least one column should remain visible
			return false;
		}
		if ( what === MI_TEXT_CLICKED ) {
			// clicked on the text
			if ( !columnVisibleBeforeToggle ) {
				// make the column visible!
				this.toggleColumnVisibility( columnId );
			}
			// else: do *nothing*! just inform the web server
		} else {
			// *not* clicked on the text --> just toggle visibility
			this.toggleColumnVisibility( columnId );
		}
		const allAvailableColumnsAreVisible = this.allAvailableColumnsAreVisible;
		if ( Validator.isObject( menu ) && !!menu.alive ) {
			menu.checkItem( columnId, !!targetColumn.visible );
			menu.checkItem( ID_SELECTALL, allAvailableColumnsAreVisible );
		}
		this._nfySrv( 'columnVisibility', {
			idc: columnId,
			all: false,
			mode: what,
			org: columnVisibleBeforeToggle,
			vis: targetColumn.visible
		} );
		const self = this;
		self._setRfrCallback(() => {
			self.refreshUiAfterColumnVisibilityChange(!columnVisibleBeforeToggle ? targetColumn : null);
		});
		return true;
	}

	toggleAllContextMenuItems( menu ) {
		const setAllColumnsToVisible = !this.allAvailableColumnsAreVisible;
		const targetedColumns = setAllColumnsToVisible ? this.allAvailableHiddenColumns : this.allAvailableVisibleColumns;
		if ( !Validator.isArray( targetedColumns, true ) ) {
			return false;
		}
		const onlyVisibleColumn = !setAllColumnsToVisible ? targetedColumns.shift() : null;
		const menuIsValid = Validator.isObject( menu ) && !!menu.alive;
		for ( let column of targetedColumns ) {
			this.toggleColumnVisibility( column.id );
			if ( menuIsValid ) {
				menu.checkItem( column.id, column.visible );
			}
		}
		this._nfySrv( 'columnVisibility', {
			idc: -1,
			all: true,
			vis: setAllColumnsToVisible
		} );
		if ( !setAllColumnsToVisible && onlyVisibleColumn ) {
			this._nfySrv( 'columnVisibility', {
				idc: onlyVisibleColumn.id,
				all: false,
				org: false,		// that's what the backend assumes at this point
				vis: true
			} );
		}
		if ( menuIsValid ) {
			menu.checkItem( ID_SELECTALL, setAllColumnsToVisible );
		}
		const self = this;
		self._setRfrCallback(() => {
			self.refreshUiAfterColumnVisibilityChange(null);
		});
		return true;
	}

	/**
	 * refreshes the UI after the visibility of a column was changed
	 * @param {XtwCol | null} columnToScrollTo the column to scroll to
	 * @param {Number} dist the scrolling distance
	 */
	refreshUiAfterColumnVisibilityChange( columnToScrollTo ) {
		this._renderAllCols();
		this.xtwBody.rebuildAllRows();
		if ( (columnToScrollTo instanceof XtwCol) && !!columnToScrollTo.visible ) {
			this.scrollToColumn( columnToScrollTo, false );
			if ( this.lastHeaderContextMenuClickEventWasRightButton ) {
				this.xtwBody.setupAfterScrollViewUpdateCallback(
					"refreshUiAfterColumnVisibilityChange-", () => {
						this.focusHeaderContextMenu();
					} );
			}
		}
		if ( this.xtdTbl ) {
			// "auto fit" columns if required
			this.xtdTbl.autoFitColumns();
		}
		const scbHorz = this.scbHorz;
		if ( Validator.isObject( scbHorz ) ) {
			// force the table body to update the scrolling position
			this.xtwBody.resetHScroll();
			// at this point the scrollbar widget is already updated - we force a scroll notification
			const current = scbHorz._selection;
			scbHorz._selection = current + 1;
			scbHorz.setSelection( current );
		}
		this.voidLastClickEventRightButtonFlag();
	}

	_dropFieldMenu() {
		if ( this.fieldMenu ) {
			this.removeHeaderContextMenuKeyListeners();
			const mnu = this.fieldMenu;
			this.fieldMenu = null;
			mnu.destroy();
		}
	}

	/**
	 * returns the field menu; creates it if necessary
	 * @param {Boolean} use_dsc flag whether to use field descriptors instead of regular field names
	 * @returns {MnuObj} the field menu
	 */
	_getFieldMenu( use_dsc ) {
		if ( this.fieldMenu || !this.xtwBody ) {
			return this.fieldMenu;
		}
		const selectAllItemTitle = this.selAllTtl;
		const allAvailableColumnsAreVisible = this.allAvailableColumnsAreVisible;
		const post_create = ( items ) => {
			this.doAfterCreatingHeaderContextMenuItems(items, selectAllItemTitle, allAvailableColumnsAreVisible );
		};
		this.fieldMenu = this.xtwBody.createFieldMenu( false, null, null, post_create, !!use_dsc );
		this.addHeaderContextMenuKeyListeners();
		this.decorateHeaderContextMenuItemsWithFocusControls(this.headerContextMenuItems );
		return this.fieldMenu;
	}

	removeHeaderContextMenuKeyListeners() {
		const headerContextMenuElement = this.headerContextMenuElement;
		if ( !headerContextMenuElement ) {
			return false;
		}
		this.removePrefixListener("keyup", "HeaderContextMenu", headerContextMenuElement);
		this.removePrefixListener("keydown", "HeaderContextMenu", headerContextMenuElement);
		return true;
	}

	addHeaderContextMenuKeyListeners() {
		const headerContextMenuElement = this.headerContextMenuElement;
		if ( !headerContextMenuElement ) {
			return false;
		}
		this.removeHeaderContextMenuKeyListeners();
		this.focusHeaderContextMenu();

		const keyUpListenerAdded = this.addPrefixListener("keyup", "onHeaderContextMenuKeyUp", "HeaderContextMenu", headerContextMenuElement);
		const keyDownListenerAdded = this.addPrefixListener("keydown", "onHeaderContextMenuKeyDown", "HeaderContextMenu", headerContextMenuElement);
		return keyUpListenerAdded && keyDownListenerAdded;
	}

	onHeaderContextMenuKeyUp( domEvent ) {
		if ( !this._handleContextMenuKeyUp( domEvent ) ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		return true;
	}

	_handleContextMenuKeyUp( domEvent ) {
		DomEventHelper.stopIf( domEvent );
		if ( DomEventHelper.keyIs( domEvent, "Enter" ) ) {
			return this.onHeaderContextMenuEnter();
		}
		if ( !![ " ", "Space", ].some(
				key => DomEventHelper.keyIs( domEvent, key ) ) ) {
			return this.onHeaderContextMenuSpace();
		}
		if ( DomEventHelper.keyIs( domEvent, "Home" ) ) {
			return this.onHeaderContextMenuHome();
		}
		if ( DomEventHelper.keyIs( domEvent, "End" ) ) {
			return this.onHeaderContextMenuEnd();
		}
		if ( DomEventHelper.isPageUp( domEvent ) ||
			DomEventHelper.isArrowUp( domEvent ) ) {
			return this.onHeaderContextMenuArrowUp();
		}
		if ( DomEventHelper.isPageDown( domEvent ) ||
			DomEventHelper.isArrowDown( domEvent ) ) {
			return this.onHeaderContextMenuArrowDown();
		}
		if ( DomEventHelper.isPrintableKey( domEvent ) ||
			DomEventHelper.keyIs( domEvent, "Backspace" ) ) {
			return this.onHeaderContextMenuPrintableKey( domEvent );
		}
		return false;
	}

	onHeaderContextMenuPrintableKey( domEvent ) {
		if ( Validator.isFunctionPath( this.fieldMenu,
				"fieldMenu.onHeaderContextMenuPrintableKey" ) ) {
			return this.fieldMenu.onHeaderContextMenuPrintableKey( domEvent );
		}
		return false;
	}

	onHeaderContextMenuSpace() {
		const menuItems = this.visibleHeaderContextMenuItems;
		if ( !menuItems || menuItems.length < 1 ) {
			return false;
		}
		const focusedItem = menuItems.find( item => item.focused );
		if ( !Validator.isObject( focusedItem ) ) {
			return false;
		}
		this.lastHeaderContextMenuClickEventWasRightButton = true;
		this.onMenuItem( focusedItem.id, this.fieldMenu );
		return true;
	}

	onHeaderContextMenuHome() {
		if ( !Validator.isObject( this.fieldMenu ) ||
			!( this.fieldMenu.itmHost instanceof HTMLElement ) ) {
			return false;
		}
		const menuItems = this.visibleHeaderContextMenuItems;
		if ( !menuItems || menuItems.length < 1 ) {
			return false;
		}
		const firstMenuItem = menuItems[ 0 ];
		if ( !Validator.isObject( firstMenuItem ) ) {
			return false;
		}
		this.unfocusFocusedHeaderContextMenuItem();
		this.unfocusAllMenuItems();
		this.focusHeaderContextMenu();
		this.fieldMenu.scrPos = 0;
		this.fieldMenu.scrIdx = 0;
		this.fieldMenu.itmHost.style.top = "0px";
		this.scrollToHeaderContextMenuItem( firstMenuItem, true );
		firstMenuItem.focused = true;
		this.currentlyFocusedHeadContextMenuItemId = firstMenuItem.id;
		this.ignoreNextMouseEvents();
		return true;
	}

	onHeaderContextMenuEnd() {
		if ( !Validator.isObject( this.fieldMenu ) ||
			!( this.fieldMenu.itmHost instanceof HTMLElement ) ) {
			return false;
		}
		const menuItems = this.visibleHeaderContextMenuItems;
		if ( !menuItems || menuItems.length < 1 ) {
			return false;
		}
		const lastMenuItem = menuItems[ menuItems.length - 1 ];
		if ( !Validator.isObject( lastMenuItem ) ) {
			return false;
		}
		const allVisibleMenuItemsTotalHeight = this.fieldMenu.mnuHgt;
		if ( !Validator.isPositiveNumber( allVisibleMenuItemsTotalHeight, false ) ) {
			return false;
		}
		const contextMenuElementContainer = this.contextMenuElementContainer;
		if ( !contextMenuElementContainer ) {
			return false;
		}
		const containerRect = contextMenuElementContainer.getBoundingClientRect();
		const topScrollingPosition =
			allVisibleMenuItemsTotalHeight - containerRect.height;
		if ( !Validator.isPositiveNumber( topScrollingPosition ) ) {
			return false;
		}
		this.unfocusFocusedHeaderContextMenuItem();
		this.unfocusAllMenuItems();
		this.fieldMenu.scrPos = topScrollingPosition;
		this.fieldMenu.itmHost.style.top = `-${ topScrollingPosition }px`;
		this.scrollToHeaderContextMenuItem( lastMenuItem, true );
		lastMenuItem.focused = true;
		if ( !( lastMenuItem.element instanceof HTMLElement ) ) {
			return true;
		}
		const itemRect = lastMenuItem.element.getBoundingClientRect();
		const amountOfVisibleItems = parseInt( containerRect.height / itemRect.height );
		if ( !Validator.isPositiveInteger( amountOfVisibleItems ) ) {
			return true;
		}
		this.fieldMenu.scrIdx = menuItems.length - 1 - amountOfVisibleItems;
		lastMenuItem.focused = true;
		this.currentlyFocusedHeadContextMenuItemId = lastMenuItem.id;
		this.ignoreNextMouseEvents();
		return true;
	}

	onHeaderContextMenuArrowUp() {
		const menuItems = this.visibleHeaderContextMenuItems;
		if ( !menuItems || menuItems.length < 1 ) {
			return false;
		}
		let focusedItemIndex = menuItems.findIndex( item => item.focused );
		if ( !Validator.isPositiveInteger( focusedItemIndex ) ) {
			focusedItemIndex = menuItems.length - 1;
		}
		const focusedItem = menuItems[ focusedItemIndex ];
		this.scrollToHeaderContextMenuItem( focusedItem, true );
		if ( focusedItemIndex <= 0 ) {
			this.unfocusAllMenuItems();
			if ( this.focusHeaderContextMenuSearchInput() ) {
				return true;
			}
			focusedItem.focused = true;
			return true;
		}
		const previousFocusedItem = menuItems[ focusedItemIndex - 1 ];
		focusedItem.focused = false;
		previousFocusedItem.focused = true;
		this.scrollToHeaderContextMenuItem( previousFocusedItem, true );
		return true;
	}

	onHeaderContextMenuArrowDown() {
		const menuItems = this.visibleHeaderContextMenuItems;
		if ( !menuItems || menuItems.length < 1 ) {
			return false;
		}
		let focusedItemIndex = menuItems.findIndex( item => item.focused );
		if ( !Validator.isPositiveInteger( focusedItemIndex ) ) {
			focusedItemIndex = 0;
		}
		const focusedItem = menuItems[ focusedItemIndex ];
		this.scrollToHeaderContextMenuItem( focusedItem, false );
		if ( focusedItemIndex >= menuItems.length - 1 ) {
			focusedItem.focused = true;
			return true;
		}
		const nextFocusedItem = menuItems[ focusedItemIndex + 1 ];
		focusedItem.focused = false;
		nextFocusedItem.focused = true;
		this.scrollToHeaderContextMenuItem( nextFocusedItem, false );
		return true;
	}

	onHeaderContextMenuEnter() {
		const menuItems = this.visibleHeaderContextMenuItems;
		if ( !menuItems || menuItems.length < 1 ) {
			return false;
		}
		const focusedItem = menuItems.find( item => item.focused );
		if ( !Validator.isFunctionPath( focusedItem, "focusedItem._onClick" ) ) {
			return false;
		}
		focusedItem._onClick( new MouseEvent( "click" ) );
		return true;
	}

	scrollToHeaderContextMenuItem( item, scrollUp = true ) {
		if ( !Validator.isFunctionPath( item, "item.parMnu._onScroll" ) ||
			!( item.element instanceof HTMLElement ) ) {
			return false;
		}
		const contextMenuElementContainer = this.contextMenuElementContainer;
		if ( !contextMenuElementContainer ) {
			return false;
		}
		const containerRect = contextMenuElementContainer.getBoundingClientRect();
		const itemRect = item.element.getBoundingClientRect();
		if ( !!scrollUp && HtmHelper
			.isFirstRectOverflowingOnTopOfSecondRect( itemRect, containerRect ) ) {
			this.ignoreNextMouseEvents();
			item.parMnu._onScroll( true );
		} else if ( !scrollUp && HtmHelper
			.isFirstRectOverflowingOnBottomOfSecondRect( itemRect, containerRect ) ) {
			this.ignoreNextMouseEvents();
			item.parMnu._onScroll( false );
		}
		return true;
	}

	getMenuItemFocusedStatus( menuItem ) {
		return Validator.isObject( menuItem ) &&
			menuItem.id === this.currentlyFocusedHeadContextMenuItemId &&
			menuItem.element instanceof HTMLElement &&
			menuItem.element.classList.contains(
				FOCUSED_HEAD_CONTEXT_MENU_ITEM_CLASSNAME );
	}

	setMenuItemFocusedStatus( menuItem, focusedValue ) {
		return !!focusedValue ? this.focusHeaderContextMenuItem( menuItem ) :
			this.unfocusHeaderContextMenuItem( menuItem );
	}

	unfocusFocusedHeaderContextMenuItem() {
		const focusedMenuItem = this.currentlyFocusedHeaderContextMenuItem;
		if ( !focusedMenuItem ) {
			return false;
		}
		return this.unfocusHeaderContextMenuItem( focusedMenuItem );
	}

	unfocusAllMenuItems() {
		const menuItems = this.headerContextMenuItems;
		if ( !menuItems || menuItems.length < 1 ) {
			return false;
		}
		menuItems.forEach( item => {
			item.focused = false;
		} );
		return true;
	}

	unfocusHeaderContextMenuItem( menuItem ) {
		if ( !Validator.isObject( menuItem ) ) {
			return false;
		}
		if ( Validator.isFunction( menuItem._onOriginalMouseLeave ) ) {
			menuItem._onOriginalMouseLeave();
		}
		if ( this.currentlyFocusedHeadContextMenuItemId === menuItem.id ) {
			this.currentlyFocusedHeadContextMenuItemId = void 0;
			delete this.currentlyFocusedHeadContextMenuItemId;
		}
		if ( !( menuItem.element instanceof HTMLElement ) ) {
			return false;
		}
		menuItem.element.classList.remove( FOCUSED_HEAD_CONTEXT_MENU_ITEM_CLASSNAME );
		return true;
	}

	focusHeaderContextMenuItem( menuItem ) {
		this.focusHeaderContextMenu();
		if ( !Validator.isObject( menuItem ) ) {
			return false;
		}
		if ( Validator.isFunction( menuItem._onOriginalMouseEnter ) ) {
			menuItem._onOriginalMouseEnter();
		}
		this.currentlyFocusedHeadContextMenuItemId = menuItem.id;
		if ( !( menuItem.element instanceof HTMLElement ) ) {
			return false;
		}
		menuItem.element.classList.add( FOCUSED_HEAD_CONTEXT_MENU_ITEM_CLASSNAME );
		return true;
	}

	onHeaderContextMenuKeyDown( domEvent ) {
		if ( !DomEventHelper.isNavigationEvent( domEvent ) ) {
			return false;
		}
		domEvent.stopPropagation();
		domEvent.preventDefault();
		return true;
	}

	doAfterCreatingHeaderContextMenuItems(
		menuItems, selectAllItemTitle, areAllColumnsVisible ) {
		if ( !Validator.isIterable( menuItems ) ) {
			return false;
		}
		// this.decorateHeaderContextMenuItemsWithFocusControls( menuItems );
		menuItems.forEach( menuItem => {
			const column = this.columns.getObj( menuItem.id );
			if ( column && column.visible ) {
				menuItem.chk = true;
			}
			menuItem.rgtclk = true; // we want right click events!
		} );
		this.addTwoSpecialHeaderContextMenuItems(
			menuItems, selectAllItemTitle, areAllColumnsVisible );
		return true;
	}

	decorateHeaderContextMenuItemsWithFocusControls( menuItems ) {
		if ( !Validator.isIterable( menuItems ) ) {
			return false;
		}
		menuItems.forEach( menuItem => {
			this.decorateHeaderContextMenuItemWithFocusControls( menuItem );
		} );
		return true;
	}

	decorateHeaderContextMenuItemWithFocusControls( menuItem ) {
		const methodsAdded = this.addFocusMethodsToHeaderContextMenuItem( menuItem );
		const stylesAdded = this.addFocusedStylesToHeaderContextMenuItem( menuItem );
		const mouseEnterOverridden = this.overrideMouseEnterMethodOnHeaderContextMenuItem( menuItem );
		const mouseLeaveOverridden = this.overrideMouseLeaveMethodOnHeaderContextMenuItem( menuItem );
		const onClickOverridden = this.overrideOnClickMethodOnHeaderContextMenuItem( menuItem );
		return methodsAdded && stylesAdded && mouseEnterOverridden && mouseLeaveOverridden && onClickOverridden;
	}

	addFocusMethodsToHeaderContextMenuItem( menuItem ) {
		if ( !Validator.isObject( menuItem ) ) {
			return false;
		}
		const xtwHead = this;
		Object.defineProperty( menuItem, "focused", {
			get: () => {
				return xtwHead.getMenuItemFocusedStatus( menuItem );
			},
			set: ( focusedValue ) => {
				xtwHead.setMenuItemFocusedStatus( menuItem, focusedValue );
			},
			configurable: false
		} );
		return true;
	}

	addFocusedStylesToHeaderContextMenuItem( menuItem ) {
		if ( !Validator.isObject( menuItem ) ||
			!( menuItem.element instanceof HTMLElement ) ) {
			return false;
		}
		menuItem.element.style.setProperty( "--rtp-background-focused",
			Color.fromRgba( menuItem.hotBgc ).stringify() );
		menuItem.element.style.setProperty( "--rtp-text-focused",
			Color.fromRgba( menuItem.hotTxc ).stringify() );
		return true;
	}

	overrideMouseEnterMethodOnHeaderContextMenuItem( menuItem ) {
		if ( !Validator.isFunctionPath( menuItem, "menuItem._onMouseEnter" ) ) {
			return false;
		}
		if ( Validator.isFunction( menuItem._onOriginalMouseEnter ) ) {
			return true;
		}
		menuItem._onOriginalMouseEnter = menuItem._onMouseEnter;
		menuItem._onMouseEnter = () => {
			if ( Validator.isObject( menuItem.mnuMgr ) &&
				menuItem.mnuMgr.ignoreNextMouseEnterEvent ) {
				menuItem.mnuMgr.ignoreNextMouseEnterEvent = false;
				return;
			}
			this.unfocusFocusedHeaderContextMenuItem();
			this.unfocusAllMenuItems();
			menuItem.focused = true;
		}
		menuItem.element.removeEventListener( "mouseenter", menuItem.lsrMse );
		this.lsrMse = void 0;
		delete this.lsrMse;
		menuItem.lsrMse = Utils.bind( menuItem, menuItem._onMouseEnter );
		menuItem.element.addEventListener( "mouseenter", menuItem.lsrMse, false );
		return true;
	}

	overrideMouseLeaveMethodOnHeaderContextMenuItem( menuItem ) {
		if ( !Validator.isFunctionPath( menuItem, "menuItem._onMouseLeave" ) ) {
			return false;
		}
		if ( Validator.isFunction( menuItem._onOriginalMouseLeave ) ) {
			return true;
		}
		menuItem._onOriginalMouseLeave = menuItem._onMouseLeave;
		menuItem._onMouseLeave = () => {
			if ( Validator.isObject( menuItem.mnuMgr ) &&
				menuItem.mnuMgr.ignoreNextMouseLeaveEvent ) {
				menuItem.mnuMgr.ignoreNextMouseLeaveEvent = false;
				return;
			}
			this.unfocusFocusedHeaderContextMenuItem();
			this.unfocusAllMenuItems();
			menuItem.focused = false;
		}
		menuItem.element.removeEventListener( "mouseleave", menuItem.lsrMlv );
		this.lsrMlv = void 0;
		delete this.lsrMlv;
		menuItem.lsrMlv = Utils.bind( menuItem, menuItem._onMouseLeave );
		menuItem.element.addEventListener( "mouseleave", menuItem.lsrMlv, false );
		return true;
	}

	overrideOnClickMethodOnHeaderContextMenuItem( menuItem ) {
		if ( !Validator.isFunctionPath( menuItem, "menuItem._onClick" ) ) {
			return false;
		}
		if ( Validator.isFunction( menuItem._onOriginalClick ) ) {
			return true;
		}
		menuItem._onOriginalClick = menuItem._onClick;
		menuItem._onClick = domEvent => {
			if ( !Validator.isObject( menuItem ) ) {
				return false;
			}
			const isRightClick = domEvent instanceof MouseEvent && domEvent.button === 2;
			const onOriginalClickFunctionIsValid =
				Validator.isFunction( menuItem._onOriginalClick );
			if ( Validator.isObject( menuItem.mnuMgr ) ) {
				if ( !onOriginalClickFunctionIsValid || !isRightClick ) {
					menuItem.mnuMgr.lastHeaderContextMenuClickEventWasRightButton = void 0;
					delete menuItem.mnuMgr.lastHeaderContextMenuClickEventWasRightButton;
				} else {
					menuItem.mnuMgr.lastHeaderContextMenuClickEventWasRightButton = true;
				}
			}
			if ( !onOriginalClickFunctionIsValid ) {
				return false;
			}
			menuItem._onOriginalClick( domEvent );
			return true;
		}
		menuItem.element.removeEventListener( "click", menuItem.lsrClk );
		this.lsrClk = void 0;
		delete this.lsrClk;
		menuItem.lsrClk = Utils.bind( menuItem, menuItem._onClick );
		menuItem.element.addEventListener( "click", menuItem.lsrClk, false );
		return true;
	}

	addTwoSpecialHeaderContextMenuItems(
		menuItems, selectAllItemTitle, areAllColumnsVisible ) {
		if ( !Validator.isIterable( menuItems ) ||
			!Validator.isString( selectAllItemTitle ) ) {
			return false;
		}
		menuItems.unshift( {
			id: ID_SELECTALL,
			txt: selectAllItemTitle,
			img: null,
			ttlImg: null,
			ina: false,
			stc: false,
			ena: true,
			chk: !!areAllColumnsVisible,
			rgtclk: true,
			items: null
		}, { id: -1, stc: true } );
		return true;
	}

}

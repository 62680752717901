import PSA from '../../psa';
import IBody from './ifcs/IBody'
import ItmMgr from '../../gui/ItmMgr';
import UIRefresh from '../../utils/UIRefresh';
import BscMgr from '../../gui/BscMgr';

import EventListenerManager from '../../utils/EventListenerManager';
import ExternalEventsTransferManager from '../../utils/ExternalEventsTransferManager';
import HtmHelper from '../../utils/HtmHelper';
import CallbackManager from '../../utils/CallbackManager';
import Validator from '../../utils/Validator';
import Warner from '../../utils/Warner';

import ViewToggleButton from './parts/ViewToggleButton';
import XRowItem, { XROWITEM_CLASS } from './parts/XRowItem';
import XtwBodyScrollingExtension from './impl/scrolling/XtwBodyScrollingExtension';
import XtwMgr from './util/XtwMgr';
import XtwHead from './XtwHead';
import XtwModel from './model/XtwModel';
import { ROWID_DUMMY, ROWID_NONE } from './model/SelectionMgr';
import XtwRtpIdManager from './rtp/XtwRtpIdManager';
import XtwSortFlap from './parts/sort/XtwSortFlap';
import XtwUtils from './util/XtwUtils';
import MGroup from './model/MGroup';
import FocusHolder from '../../gui/FocusHolder';
import XtwBodyKeyEventHandler from './impl/selection/XtwBodyKeyEventHandler';
import MRowItem from './model/MRowItem';
import EditTarget from './impl/editing/EditTarget';
import EditRequest from './impl/editing/EditRequest';
import XtwCol from './parts/XtwCol';
import XCellItem from './parts/XCellItem';
import XtwMnuItm from './parts/sort/XtwMnuItm';
import SearchableMenuObject from './impl/contextmenu/SearchableMenuObject';
import UIUtil from '../../gui/uiutil';
import Utils from '../../utils/Utils';
import JsPoint from '../../utils/JsPoint';
import DomEventHelper from '../../utils/DomEventHelper';
import { DEF_HDR_HGT, DEF_ROW_HGT } from './XtwBodyConst';
import { ROW_TEMPLATE_BODY_CLASS, TABLE_BODY_CLASS } from './XtwBodyConst';
import { ROW_HOVERED_BACKGROUND_COLOR, ROW_HOVERED_TEXT_COLOR, ROW_SELECTED_BACKGROUND_COLOR, ROW_SELECTED_TEXT_COLOR } from './XtwBodyConst';
import { UI_REFRESH_REQUEST, UI_ROWUPDATE_REQUEST, UI_SCROLL_REQUEST, UI_STATUS_REQUEST } from './XtwBodyConst';
import { MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY } from './XtwBodyConst';
import { NO_NFY_FOCUS, NO_NFY_SELECTION } from './XtwBodyConst';
import { AFTER_SCROLLING_CALLBACKS, AFTER_MODELDATA_CALLBACKS } from './XtwBodyConst';
import XtwTbl, { ROW_HEIGHT_CSS_VARIABLE } from './XtwTbl';
import MDataRow from './model/MDataRow';
import PendingOp from './util/PendingOp';
import { CODE_MODEL, CODE_INSERT, CODE_DELETE } from './util/PendingOp';
import ClipboardMgr from '../../utils/ClipboardMgr';
import MCell from './model/MCell';
import TextBuilder from '../../utils/TextBuilder';
import CellCtt from './model/CellCtt';
import ClipboardDataHdl from '../../utils/ClipboardDataHdl';
import LoggingBase from '../../base/loggingbase';
import AbstractEditable from './impl/editing/AbstractEditable';

/** field menu ID */
let ID_FIELD_MENU = 100000000;

const ROW_ITEMS_SPARE = 2;
const KEYUP_DELAY = 100;

class XtwBodyFocusHolder extends FocusHolder {

	/**
	 * constructs a new instance
	 * @param {XtwBody} xtb the body widget
	 */
	constructor(xtb) {
		super('widgets.xtw.XtwBody.XtwBodyFocusHolder');
		this._body = xtb;
	}

	/**
	 * @returns {XtwBody} the target body widget
	 */
	get body() {
		return this._body;
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	setFocus() {
		if ( this.body.alive ) {
			this.body.setRapFocus(true);
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	forceBlur(unlock = false) {
		// nothing to do
	}

	/**
	 * @inheritdoc
	 * @override
	 */
	applyChanges() {
		// nothing to flush
	}
}


class XtwBodyClipboardHdl extends ClipboardDataHdl {

	/**
	 * constructs a new instance
	 * @param {XtwBody} xtb the body widget
	 * @param {Boolean} plain plain text flag
	 */
	constructor(xtb, plain) {
		super('widgets.xtw.XtwBody.XtwBodyNotificationHdl');
		this._body = xtb;
		this._plain = !!plain;
	}

	/**
	 * @returns {XtwBody} the target body widget
	 */
	get body() {
		return this._body;
	}

	/**
	 * @returns {Boolean} the plain text flag
	 */
	get plain() {
		return this._plain;
	}

	/**
	 * @inheritdoc
	 * @override
     * @param {Boolean} success success flag
     * @param {Object} info optional info
	 */
	notify(success, info) {
		if ( success ) {
			this.body._nfyClipboard('OK', null, null);
		} else {
			if ( Validator.isString(info) && ('FORBIDDEN' === info) ) {
				this.log('Clipboard access forbidden!');
				this.body._nfyClipboard('FORBIDDEN', null, null);
			} else {
				this.log('Clipboard access failed!', info);
				this.body._nfyClipboard('FAILED', (info ? '' + info : null), null);
			}
		}
	}

	/**
	 * @inheritdoc
	 * @override
     * @param {ClipboardItem[]} items array of clipboard items
	 */
	onClipboardData(items) {
		// just forward this to the body widget
		this.body.onClipboardData(items, this);
	}
}

class XtwBodySelectionLogger extends LoggingBase {
	constructor() {
		super('widgets.xtw.XtwBodySelectionLogger');
	}
}


const EXCEL_BR = '<br style="mso-data-placement:same-cell;" />';

/**
 * class XtwBody - the body of an eXtended Table Widget (XTW)
 */
export default class XtwBody extends IBody {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor( properties ) {
		super('widgets.xtw.XtwBody');
		Utils.bindAll( this, [ "layout", "onReady", "onRender", "onSend" ] );
		this._updateUICallback = Utils.bind(this, this._updateUIState);
		this.xtwHead = null;
		this.ready = false;
		this.visWdt = 0;
		this.visHgt = 0;
		this.grpHgt = 0;
		this.fetchPnd = false;
		this.fetchRqu = null;
		this.fetchCph = null;
		this.nextFetchID = 10000000;
		this.fetchID = 0;
		this.clrVtg = null;
		this.clrHzg = null;
		this._focusHolder = null;
		this._editTarget = null;
		this._editRequest = null;
		this._lastScrollPos = null;
		this.rapFocus = false;
		this.pendingFocus = false;
		this.keyScrLocked = 0;
		this.noSortCols = new Set();
		// notification flags
		this._nfySuppressed = 0;
		// callback maps
		this[AFTER_MODELDATA_CALLBACKS] = new Map();
		this._ensuingModelDataCallbacks = new Map();
		this[AFTER_SCROLLING_CALLBACKS] = new Map();
		this.selClk = new JsPoint(0, 0);
		// get the RAP parent element
		const idw = properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject( idw );

		// create our "own" DOM element
		const body = document.createElement( 'div' );
		body.className = 'xtwbody';
		if ( Validator.is( body.dataset, "DOMStringMap" ) ) {
			const className = Validator.getClassName( this );
			if ( Validator.isString( className ) ) {
				body.dataset.class = className;
			}
		}
		const self = this;
		// create the row container
		const rowContainer = document.createElement( 'div' );
		rowContainer.className = 'xtwrowcnt';
		rowContainer.addEventListener('blur', (e) => {
			self.trace('FOCUS: row container lost focus to', e.relatedTarget);
		});
		rowContainer.addEventListener('focusout', (e) => {
			self.trace('FOCUS: focus out from row container', e.relatedTarget);
		});
		body.appendChild( rowContainer );
		// store elements
		this.rowContainer = rowContainer;
		this.tblBody = body;

		// add the main DOM element to the RAP parent
		this.parent.append( this.tblBody );
		// we need the resize listener
		this.parent.addListener( "Resize", this.layout );
		// activate "render" event
		rap.on( "render", this.onRender );

		// get custom widget data
		this.xtdTbl = null;
		const cwd = this.parent.getData( "pisasales.CSTPRP.CWD" ) || {};
		const grh = cwd.grh || DEF_HDR_HGT;
		const drh = cwd.drh || DEF_ROW_HGT;
		this.defGrh = grh;
		this._defRwh = drh;
		this._rtpRwh = drh;
		this.rtpVPad = 8; // must match the total vertical padding as defined for CSS class "div.xtwrtprowitem"
		// ID string
		this.wdgIdStr = cwd.idstr || '';
		if ( body.dataset ) {
			body.dataset.idstr = this.wdgIdStr;
		}
		// read flap settings provided by custom widget data
		this.flapSettings = {};
		this._setFlapSettings( cwd );

		// update CSS variables
		this.setCssVariables( body, cwd );

		// get selection mode
		const sel_mode = Number(cwd.selMode) || 0; 

		// temporary focus and selection info
		this.selInfo = null;

		// row selection handling support
		this._selUpdLock = false;
		this._selUpdTimer = null;

		// the array of DOM items for the visible rows, actually, the array will contain instances of class XRowItem
		this.rowItems = [];
		// create model instance
		Object.defineProperty(this, '_model', {
			value: new XtwModel( self, grh, drh, sel_mode ),
			writable: false,
			configurable: true,
			enumerable: false
		});
		// pending model operations
		this.pndOps = [];

		// row template mode
		this.rtpMode = false;
		this.syncClassWithState();
		this.rtpTgl = false;
		this.idManager = null;

		// we need the ID of our parent eXtended Table Widget
		const idp = cwd.idp || '';
		if ( idp ) {
			const xtw = XtwMgr.getInst().getXtdTbl( idp );
			if ( xtw ) {
				this.xtdTbl = xtw;
				xtw.setBodyWdg( this );
			}
		}

		// create scrolling extension
		this.xScrollExt = new XtwBodyScrollingExtension( this );

		// the selection logger
		this._xSelLogger = new XtwBodySelectionLogger();

		// at this time we cannot add the key event manager, it must be done in onRender()!
		this._keyHandler = null;

		// the focus holder for the body widget itself
		this._bodyFocusHolder = new XtwBodyFocusHolder(this);

		// clipboard access
		this._clipboardEnabled = !!cwd.clipboardEnabled;

		// dialog meta data
		this._metaData = cwd.metaData || null;

		// remaining initialization
		this.sortFlap = null;
		this._modalCnt = 0;
		this._clpTrigger = null;
		this._hotRow = ROWID_NONE;
		this._init( cwd.fnt || null );

		// activate "send" event
		rap.on('send', this.onSend);
	}

	/**
	 * @returns {XtwModel|null} the data model
	 */
	get model() {
		return this._model;
	}

	/**
	 * @returns {Number} the ID of the currently focused row
	 */
	get focusIdr() {
		const model = this.alive ? this.model : null;
		return (model instanceof XtwModel) ? model.focusedRow : ROWID_NONE;
	}

	/**
	 * @returns {Number} the model index of first UI item (the "top index")
	 */
	get topIndex() {
		return this.xScrollExt.topIdx;
	}

	/**
	 * gets & returns the "rendered" status of this item, which is true when
	 * this item has a valid HTML element and false otherwise
	 * @return {Boolean} true if element is present & valid, false otherwise
	 */
	get isRendered() {
		return this.element instanceof HTMLElement;
	}

	get isRowTpl() {
		if ( !Validator.isObjectPath( this, "this.xtdTbl.rowTpl" ) ) {
			return false;
		}
		return Validator.isBoolean( this.rtpMode ) && this.rtpMode;
	}

	get getRowTpl() {
		return this.isRowTpl ? this.xtdTbl.rowTpl : null;
	}

	/**
	 * @returns {HTMLElement} the table body DOM element
	 */
	get element() {
		return this.tblBody;
	}

	get clientRect() {
		if ( !this.isRendered ) {
			return void 0;
		}
		return this.element.getBoundingClientRect();
	}

	/**
	 * @returns {Number} the ID of the first visible column or -1 if there's no such column
	 */
	get firstVisibleColumnId() {
		return this.xtwHead instanceof XtwHead ?  this.xtwHead.firstVisibleColumnId : -1;
	}

	/**
	 * @returns {Number} the ID of the last visible column or -1 if there's no such column
	 */
	get lastVisibleColumnId() {
		return this.xtwHead instanceof XtwHead ?  this.xtwHead.lastVisibleColumnId : -1;
	}

	setTableBodyHeight( heightInPixels, setByHeader = false ) {
		if ( this.isRowTpl || !Validator.isPositiveNumber( heightInPixels ) ) {
			return false;
		}
		if ( this.visHgt === heightInPixels ) {
			return true;
		}
		[ this.tblBody, this.tblBody.parentElement, this.rowContainer ]
		.forEach( element => {
			if (  element instanceof HTMLElement  ) {
				HtmHelper.removeStyleProperty( element, "height" );
			}
		} );
		this.visHgt = heightInPixels;
		this.bodyClientHeight = heightInPixels;
		return true;
	}

	set bodyClientHeight( heightInPixels ) {
		if ( Validator.isObject( this.xtdTbl ) &&
			"bodyClientHeight" in this.xtdTbl ) {
			this.xtdTbl.bodyClientHeight = heightInPixels;
		}
	}

	get bodyClientHeight() {
		const bodyClientRect = this.clientRect;
		if ( !( bodyClientRect instanceof DOMRect ) ) {
			return void 0;
		}
		return bodyClientRect.height;
	}

	get rtpRowHeight() {
		const idManager = this.idManager;
		if ( !Validator.isObject( idManager ) ) {
			return void 0;
		}
		return Validator.isPositiveNumber( idManager.rowHeight ) ?
			idManager.rowHeight : void 0;
	}

	get rtpRwh() {
		if ( !this.isRowTpl ) {
			return this._rtpRwh;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ?
			rtpRowHeight : this._rtpRwh;
	}

	set rtpRwh( newValue ) {
		this._rtpRwh = newValue;
	}

	get defRwh() {
		if ( !this.isRowTpl ) {
			return this._defRwh;
		}
		const rtpRowHeight = this.rtpRowHeight;
		return Validator.isPositiveNumber( rtpRowHeight ) ? rtpRowHeight : this._defRwh;
	}

	set defRwh( newValue ) {
		this._defRwh = newValue;
	}

	/**
	 * @returns {Boolean} true if the list body has the RAP focus; false otherwise
	 */
	get hasRapFocus() {
		return this.alive && this.rapFocus;
	}

	/**
	 * @returns {FocusHolder} the current focus holder
	 */
	get focusHolder() {
		return this._focusHolder;
	}

	/**
	 * @returns {EditTarget} the current edit target
	 */
	get editTarget() {
		return this._editTarget;
	}

	/**
	 * @returns {EditRequest} the new edit request
	 */
	get editRequest() {
		return this._editRequest;
	}

	/**
	 * @returns {Boolean} the "clipboard enabled" state
	 */
	get clipboardEnabled() {
		return this._clipboardEnabled;
	}

	/**
	 * @returns {Object|null} dialog meta data object or null if no meta data available
	 */
	get metaData() {
		return this._metaData;
	}

	/**
	 * @returns {LoggingBase} the selection logger
	 */
	get xSelLogger() {
		return this._xSelLogger;
	}

	toggleClass() {
		if ( !this.isRendered ) {
			return false;
		}
		if ( this.element.classList.contains( TABLE_BODY_CLASS ) ) {
			return this.changeToRowTemplateClass();
		}
		return this.changeToTableClass();
	}

	syncClassWithState() {
		if ( !this.isRendered ) {
			return false;
		}
		if ( this.isRowTpl ) {
			return this.changeToRowTemplateClass();
		}
		return this.changeToTableClass();
	}

	changeToRowTemplateClass() {
		if ( Validator.isFunctionPath( this.xtdTbl,
				"xtdTbl.changeToRowTemplateClass" ) ) {
			this.xtdTbl.changeToRowTemplateClass();
		}
		if ( !this.isRendered ) {
			return false;
		}
		const bodyElement = this.element;
		bodyElement.classList.remove( TABLE_BODY_CLASS );
		bodyElement.classList.add( ROW_TEMPLATE_BODY_CLASS );
		if ( bodyElement.parentElement instanceof HTMLElement ) {
			bodyElement.parentElement.classList.remove( TABLE_BODY_CLASS );
			bodyElement.parentElement.classList.add( ROW_TEMPLATE_BODY_CLASS );
		}
		return true;
	}

	changeToTableClass() {
		if ( Validator.isFunctionPath( this.xtdTbl, "xtdTbl.changeToTableClass" ) ) {
			this.xtdTbl.changeToTableClass();
		}
		if ( !this.isRendered ) {
			return false;
		}
		const bodyElement = this.element;
		bodyElement.classList.remove( ROW_TEMPLATE_BODY_CLASS );
		bodyElement.classList.add( TABLE_BODY_CLASS );
		if ( bodyElement.parentElement instanceof HTMLElement ) {
			bodyElement.parentElement.classList.remove( ROW_TEMPLATE_BODY_CLASS );
			bodyElement.parentElement.classList.add( TABLE_BODY_CLASS );
		}
		return true;
	}

	/**
	 * called by the framework to destroy the widget
	 * @override
	 */
	destroy() {
		this.log('Passing away.');
		super.destroy();
	}

	/**
	 * @override
	 */
	doDestroy() {
		this._clearSelUpdTimer();
		this._fetchDropRqu();
		rap.off('send', this.onSend);
		if ( this.sortFlap ) {
			const sf = this.sortFlap;
			delete this.sortFlap;
			sf.destroy();
		}
		if ( this._keyHandler ) {
			this._keyHandler.destroy();
		}
		this._nfySuppressed = -1;
		this.removeContextMenuListener();
		delete this.flapSettings;
		this.xScrollExt.destroy();
		this.rowItems.forEach( ( ri ) => ri.destroy() );
		this.model.destroy();
		delete this._keyHandler;
		delete this._clpTrigger;
		delete this.pndOps;
		delete this.rcells;
		delete this.sortedColumns;
		delete this.idManager;
		delete this.clrHzg;
		delete this.clrVtg;
		delete this.clrRowHvrBgc;
		delete this.clrRowHvrTxc;
		delete this.clrRowSelBgc;
		delete this.clrRowSelTxc;
		delete this.clrRowSelBgcNfc;
		delete this.clrRowSelTxcNfc;
		delete this.xtwHead;
		delete this.xtdTbl;
		delete this.ready;
		delete this.selClk;
		delete this.xScrollExt;
		delete this.rowContainer;
		delete this.tblBody;
		delete this.model;
		super.doDestroy();
	}

	/**
	 * returns the table body widget
	 * @returns {XtwBody} this
	 */
	getXtwBody() {
		return this;
	}

	/**
	 * returns the table header widget
	 * @returns {XtwHead} the table header widget
	 */
	getXtwHead() {
		return this.xtwHead;
	}

	getHeight() {
		return this.grpHgt;
	}

	/**
	 * retrieves an UI row item
	 * @param {MRowItem | Number} row a data row item or a row ID
	 * @returns {XRowItem} the corresponding UI row item or null
	 */
	getXRowItem(row) {
		return this._getXRowItem(row);
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		// mark instance as "ready"
		this.ready = true;
		// add key event manager
		this._keyHandler = new XtwBodyKeyEventHandler(this);
		// update pending model data if required
		if ( this.pndOps.length > 0 ) {
			this._runPendingOps();
		}
		// update sort flap if required
		this._updateSortFlap( this.isRowTpl );
	}

	/**
	 * runs all pending operations
	 */
	_runPendingOps() {
		const ops = this.pndOps;
		this.pndOps = null;
		const cnt = ops.length;
		if ( cnt > 0 ) {
			const self = this;
			for ( let i=0 ; i < cnt ; ++i ) {
				const po = ops[i];
				if ( po instanceof PendingOp ) {
					const code = po.code;
					const args = po.args;
					args._silent = i < (cnt-1);
					switch ( code ) {
						case CODE_MODEL:
							self.modelCommitted(args);
							break;
						case CODE_INSERT:
							self.insertRow(args);
							break;
						case CODE_DELETE:
							self.deleteRows(args);
							break;
						default:
							break;
					}
				}
			}
		}
	}

	/**
	 * called by the framework in rendering phase
	 */
	onRender() {
		if ( this.alive && this.parent && (this.element instanceof HTMLElement) && (this.element.parentElement instanceof HTMLElement) ) {
			rap.off( "render", this.onRender ); // just once!
			this.onReady();
			this.layout();
			this._attachEventHandlers();
			this.ensureSelectionUpdate();
			if ( this.pendingFocus ) {
				this.setRapFocus(true);
				this.pendingFocus = false;
			}
		} else {
			if ( !this.alive ) {
				console.debug("Cannot render a dead instance. Sorry.");
				rap.off( "render", this.onRender ); // never again, please!
			} else if ( this.isTraceEnabled() ) {
				this.trace("Instance not yet connected to the DOM. Not yet ready!");
			}
		}
	}

	/**
	 * called by the framework in the "send" phase
	 */
	onSend() {
		if ( this.alive ) {
			this._fetchSendRqu();
		} else {
			this._fetchDropRqu();
		}
	}

	/**
	 * updates internal selection state and notifies the web server about possible selection changes
	 */
	ensureSelectionUpdate() {
		if ( this.selectionManager ) {
			this.adjustSelectionAfterInitialisation();
			this.selectionManager.informAboutRowSelection( -1, true );
		}
	}

	/**
	 * updates CSS properties as provided in custom widget data
	 * @param {HTMLElement} bodyElement main HTML element of the table body
	 * @param {*} cwd custom widget data
	 */
	setCssVariables( bodyElement, cwd ) {
		if ( !Validator.isObject( cwd ) || !( bodyElement instanceof HTMLElement ) ) {
			return false;
		}
		this.clrRowHvrBgc = { value: cwd.hvrbgc, property: ROW_HOVERED_BACKGROUND_COLOR };
		this.clrRowHvrTxc = { value: cwd.hvrtxc, property: ROW_HOVERED_TEXT_COLOR };
		this.clrRowSelBgc = { value: cwd.selbgc, property: ROW_SELECTED_BACKGROUND_COLOR };
		this.clrRowSelTxc = { value: cwd.seltxc, property: ROW_SELECTED_TEXT_COLOR };
		this.clrRowSelBgcNfc = { value: cwd.selbgcnfc, property: ROW_SELECTED_BACKGROUND_COLOR };
		this.clrRowSelTxcNfc = { value: cwd.seltxcnfc, property: ROW_SELECTED_TEXT_COLOR };

		[ this.clrRowHvrBgc, this.clrRowHvrTxc, this.clrRowSelBgc, this.clrRowSelTxc ].forEach( option => {
			if ( !Validator.isArray( option.value, 4 ) ||
				!Validator.isString( option.property ) ) {
				return;
			}
			bodyElement.style.setProperty( option.property, `rgba(${ option.value[0] },` +
				`${ option.value[1] },${ option.value[2] },${ option.value[3] })` );

		} );
		return true;
	}

	/**
	 * sets the current focus holder
	 * @param {FocusHolder} fh the current focus holder
	 */
	setFocusHolder(fh) {
		this._focusHolder = (fh instanceof FocusHolder) ? fh : null;
		if ( (this._modalCnt > 0) && (this._focusHolder instanceof FocusHolder) ) {
			this._focusHolder.restoreLock(this._modalCnt);
		}
	}

	/**
	 * removes the focus holder
	 * @param {FocusHolder} fh the focus holder to be removed
	 */
	removeFocusHolder(fh) {
		if ( (fh instanceof FocusHolder) && (this._focusHolder === fh) ) {
			this._focusHolder = null;
		}
	}

	/**
	 * sets a new current edit target
	 * @param {EditTarget} et the new active edit target
	 */
	setEditTarget(et) {
		if ( et instanceof EditTarget ) {
			if ( this.isTraceEnabled() ) {
				this.trace(`Setting edit target "${et.inputId}".`);
			}
			if ( et.isEditCtrl() && et.isOnDummyRow ) {
				et.setKeepForced(true);
			}
			this._editTarget = et;
			this.setFocusHolder(et);
			return true;
		}
		return false;
	}

	/**
	 * removes an edit target
	 * @param {EditTarget} et the edit target to be removed 
	 */
	removeEditTarget(et) {
		if ( et instanceof EditTarget ) {
			if ( this._editTarget && et.equals(this._editTarget) ) {
				if ( this.isTraceEnabled() ) {
					this.trace(`Removing edit target "${et.inputId}".`, Warner.getStack());
				}
				this.removeFocusHolder(this._editTarget);
				this._editTarget = null;
				return true;
			}
		}
		return false;
	}

	/**
	 * informs the current edit target about a "cancel" request
	 */
	cancelEditTarget() {
		if ( this._editTarget instanceof EditTarget ) {
			const et = this._editTarget;
			et.informAboutCancel();
		}
	}

	/**
	 * informs the current edit target about a "cancel" request
	 */
	saveEditTarget() {
		if ( this._editTarget instanceof EditTarget ) {
			const et = this._editTarget;
			et.informAboutSave();
		}
	}

	/**
	 * clears the current edit target
	 * @param {Boolean} nfy flag whether to notify the web server
	 */
	clearEditTarget(nfy = false) {
		if ( this._editTarget instanceof EditTarget ) {
			const et = this._editTarget;
			const iid = et.inputId;
			const idc = et.columnId;
			const idr = et.rowId;
			const ddn = et.droppedDown;
			et.restoreLock(0);
			et.informAboutContentChange();
			et.destroySelfAndRestoreCell();
			this.removeEditTarget(et);
			if ( nfy && Validator.isString(iid) && ddn ) {
				const par = { id: iid, idc: idc, idr: idr };
				this._nfySrv('forceDropDownClose', par, false);
			}
		}
	}

	/**
	 * clears the current edit request
	 */
	clearEditRequest() {
		if ( this._editRequest instanceof EditRequest ) {
			const er = this.editRequest;
			this._editRequest = null;
			er.destroy();
		}
	}

	/**
	 * sets all rows back to "not edited" status
	 */
	setAllRowsToUnedited() {
		this.model.clearEditedState();
		this.resetAllRows(true);
	}

	/**
	 * resets the UI status of all row items
	 */
	resetAllRows(clear) {
		this.rowItems.forEach((row) => {
			row.resetUIStatus(clear);
		});
	}

	/**
	 * marks rows as "edited"
	 * @param {*} args parameters
	 */
	markRowsAsEdited( args ) {
		const self = this;
		const param = args;
		if ( CallbackManager.hasCallBacks(self, AFTER_MODELDATA_CALLBACKS) ) {
			self.setupModelDataCallback('markRowsAsEdited-', () => self._markRowsAsEdited2(param), true);
		} else if ( CallbackManager.hasCallBacks(self, AFTER_SCROLLING_CALLBACKS) ) {
			self.setupAfterScrollViewUpdateCallback('markRowsAsEdited-', () => self._markRowsAsEdited2(param), true);
		} else {
			// no callback required
			self._markRowsAsEdited2(param);
		}
	}

	/**
	 * stage 2 of "mark rows as edited"
	 * @param {*} args parameters
	 */
	_markRowsAsEdited2(args) {
		const self = this;
		const edited = args.edited || [];
		const notEdited = args.notEdited || [];
		const model = this.model;
		let changed = false;
		edited.forEach( (idr) => {
			const dr = model.getDataRowModelItem(idr);
			if ( dr instanceof MDataRow ) {
				if ( !dr.edited ) {
					dr.edited = true;
					changed = true;
				}
			}
		} );
		notEdited.forEach( (idr) => {
			const dr = model.getDataRowModelItem(idr);
			if ( dr instanceof MDataRow ) {
				if ( dr.edited ) {
					dr.edited = false;
					changed = true;
				}
			}
		} );
		if ( changed ) {
			this.triggerUIRefresh(false);
		}
	}

	/**
	 * removes the current cell editor (if any)
	 * @param {*} args parameters
	 */
	removeCellEditor(args) {
		const et = this.editTarget;
		if ( et instanceof EditTarget ) {
			const idc = args.idc || 0;
			const idr = args.idr || 0;
			if ( (idc > 0) && (idr > 0) ) {
				if ( (et.columnId !== idc) || (et.rowId !== idr) ) {
					// don't touch the current edit target because it does not match!
					return;
				}
			}
			this._dropEditTarget();
		}
	}

	/**
	 * focuses a data cell and activates the cell editor
	 * @param {*} args parameters
	 */
	setCellEditor(args) {
		if ( this.isDebugEnabled() ) {
			this.debug('Set Cell Editor:', args);
		}
		const model = this.model;
		if ( this.isDebugEnabled() ) {
			const cur_cell = this._getXCellItem(model.focusedColumn, model.focusedRow);
			if ( cur_cell instanceof XCellItem ) {
				this.log(`>>> current cell ( idc=${cur_cell.idc}, idr=${cur_cell.idr} )`);
			}
		}
		const idc = args.idc || model.focusedColumn;
		if ( idc > 0 ) {
			const self = this;
			let rebound = false;
			const idr = args.idr || model.focusedRow;
			const mi = model.getDataRowModelItem(idr);
			if ( mi instanceof MRowItem ) {
				const et = this.editTarget;
				if ( (et instanceof EditTarget) && et.isKeepForced ) {
					if ( et.columnId === idc ) {
						const cell = this._getXCellItem(idc, idr);
						if ( cell instanceof XCellItem ) {
							const dropdown = !!args.openDropdown;
							if ( this.isDebugEnabled() ) {
								this.debug(`Rebinding current edit target to idc=${idc}, idr=${idr}.`);
							}
							// lock edit target
							et.lock();
							// update cell content if required
							let reset = false;
							const org = args.org !== undefined ? Validator.ensureString(args.org) : null;
							if ( org !== null ) {
								// set the data model back to the original value
								model.updateCellText(idc, idr, org);
								reset = true;
							}
							const ctt = args.ctt !== undefined ? Validator.ensureString(args.ctt) : null;
							if ( ctt !== null ) {
								// update the cell in the UI
								cell.setContentText(ctt);
							}
							// rebind the edit target
							self.ensureRowIsVisible(mi.flatIndex, () => {
								et.rebindToCell(cell);
								if ( reset ) {
									et.resetInput();
								}
								if ( dropdown ) {
									et.triggerDropdown();
								}
								et.release();
								if ( self.isDebugEnabled() ) {
									self.debug('Rebound done.');
								}
							});
							rebound = true;
						}
					}
				}
				if ( !rebound ) {
					const deferred = !!args.deferred;
					this.log(`>>> setCellEditor( idc=${idc}, idr=${idr} ) - deferred=${deferred}`);
					const func = () => {
						// drop current editor
						self._dropEditTarget();
						// make sure that the row is visible (vertical scrolling)!
						self.ensureRowIsVisible(mi.flatIndex, () => {
							self._setCellEditor2(args, idc, idr);
						});
					};
					if ( deferred ) {
						window.setTimeout(func, 0);
					} else {
						func();
					}
				}
			}
		}
	}

	/**
	 * phase 2 of "set cell editor"
	 * @param {*} args parameters
	 * @param {Number} idc column ID
	 * @param {Number} idr row ID
	 */
	_setCellEditor2(args, idc, idr) {
		const self = this;
		const cell = this._getXCellItem(idc, idr);
		if ( cell instanceof XCellItem ) {
			// drop current editor
			this._dropEditTarget();
			const org = args.org !== undefined ? Validator.ensureString(args.org) : null;
			if ( org !== null ) {
				// set the data model back to the original value
				this.model.updateCellText(idc, idr, org);
			}
			// ensure cell is really visible (horizontal scrolling)!
			this.ensureCellIsVisible(cell, () => {
				window.setTimeout(() => self._setCellEditor3(args, idc, idr, 0), 0);
			});
		}
	}

	/**
	 * phase 3 of "set cell editor"
	 * @param {*} args parameters
	 * @param {Number} idc column ID
	 * @param {Number} idr row ID
	 * @param {Number} att number of attempt
	 */
	_setCellEditor3(args, idc, idr, att) {
		if ( att === 0 ) {
			const rid = this._getRequestId(UI_SCROLL_REQUEST);
			if ( UIRefresh.getInstance().hasRequest(rid) ) {
				// not now!!!
				this.log('Found pending scroll request - cell editor deferred!');
				const self = this;
				const func = () =>self._setCellEditor3(args, idc, idr, att + 1);
				this.xScrollExt.afterUpdateCbk = func;
				return;
			}
		}
		const cell = this._getXCellItem(idc, idr);
		if ( cell instanceof XCellItem ) {
			const ctt = args.ctt !== undefined ? Validator.ensureString(args.ctt) : null;
			if ( ctt !== null ) {
				// update the cell in the UI
				cell.setContentText(ctt);
			}
			const org = args.org !== undefined ? Validator.ensureString(args.org) : null;
			const dropdown = !!args.openDropdown;
			const org_mode = cell.insertMode;
			cell.insertMode = !!args.mode;
			// finally, create new cell editor
			try {
				this.log(`Setting cell editor to cell ${idc}:${idr}.`);
				let dirty = null;
				if ( (ctt !== null) && (org !== null) ) {
					if ( ctt !== org ) {
						dirty = true;
					}
				}
				cell.enterEditingMode(null, org, dropdown, dirty);
				const et = this.editTarget;
				if ( et instanceof EditTarget ) {
					et.setKeepForced(!!args.keep);
				}
			} finally {
				cell.insertMode = org_mode;
			}
		}
	}
	
	restoreUserDefinedTableRowHeight(parameters) {
		// TODO
	}
	
	restoreBeforeRowHeightChangeUiStats(parameters) {
		// TODO
	}

	/**
	 * called by the backend to update access mode of cells
	 * @param {*} args argument object providing modified cells
	 */
	updateCellAcc(args) {
		const cells = args.cells || [];
		if ( Validator.isArray(cells, true) ) {
			const self = this;
			const model = self.model;
			cells.forEach((cp) => {
				if ( Validator.isPositiveInteger(cp.acc, true) ) {
					// update model cell
					const mr = model.getDataRowModelItem(cp.idr);
					if ( mr instanceof MDataRow ) {
						const mc = mr.getCell(cp.idc);
						if ( mc instanceof MCell ) {
							mc.getCtt().acc = cp.acc;
						}
					}
					// update UI cell
					const xc = self._getXCellItem(cp.idc, cp.idr);
					if ( xc instanceof XCellItem ) {
						xc.ctt.acc = cp.acc;
					}
				}
			})
		}
	}

	/**
	 * called by the web server to update cell data
	 * @param {*} args argument object providing new cell data
	 */
	refreshCells(args) {
		if ( this.alive  ) {
			const idc = args.idc;
			if ( Validator.isPositiveInteger(idc, false) ) {
				const cells = args.cells;
				if ( Validator.isArray(cells) ) {
					const model = this.model;
					model.refreshCells(cells);
					const self = this;
					const xrows = this.rowItems;
					xrows.forEach((xr) => {
						self._updRowItm(xr);
					})
				}
			}
		}
	}

	/**
	 * called by the parent table widget to notify about the RAP focus state
	 * @param {Boolean} rf "RAP focus" flag
	 */
	setRapFocus( rf ) {
		if ( this.alive ) {
			const eff_rf = !!rf;
			this.rapFocus = eff_rf;
			if ( this.tblBody ) {
				this.log(`RAP focus set to "${rf}".`);
				const body = this.tblBody;
				const prop_bgc = rf ? this.clrRowSelBgc : this.clrRowSelBgcNfc;
				const prop_txc = rf ? this.clrRowSelTxc : this.clrRowSelTxcNfc;
				[ prop_bgc, prop_txc ].forEach( prop => {
					if ( Validator.isString( prop.property ) && Validator.isArray( prop.value, 4 ) ) {
						body.style.setProperty( prop.property, UIUtil.getInstance().getCssRgb( prop.value ) );
					}
				} );
			}
			if ( this.ready ) {
				this.updateFocusedUI(this.rapFocus);
			} else if ( rf ) {
				this.pendingFocus = true;
			}
		}
	}

	/**
	 * updates the UI after focus changes
	 * @param {Boolean} setToFocused if true then the table body or a child element got the focus; if false the focus is lost
	 * @returns {Boolean} true if the focus change was handled; false otherwise
	 */
	updateFocusedUI(setToFocused) {
		if ( this.alive && this.ready && this.element ) {
			if ( setToFocused ) {
				this.element.classList.add( "rtp-focused" );
			}
			else {
				this.element.classList.remove( "rtp-focused" );
			}
			const et = this.editTarget;
			if ( (et instanceof EditTarget) && !et.alive ) {
				this.clearEditTarget();
			}
			const fh = this.focusHolder;
			if ( (fh instanceof FocusHolder) && fh.alive ) {
				if ( setToFocused ) {
					this.log('Setting focus to focus holder:', fh);
					fh.setFocus();
				} else {
					this.log('Removing focus from focus holder:', fh);
					fh.forceBlur(true);
				}
			} else {
				this._focusHolder = null;
				if ( setToFocused ) {
					this.log('Setting focus to table body.');
					this.element.parentElement.focus();
				}
			}
			return true;
		}
		return false;
	}

	/**
	 * called by web server, informs about editing permission
	 * @param {*} args JSON object providing required parameters
	 */
	setCellEditingPermission( args ) {
		if ( this.editTarget ) {
			const editingAllowed = args ? !!args.editingAllowed : false;
			this.editTarget.setEditingPermission(editingAllowed);
		}
	}

	/**
	 * updates a cell content in edit mode
	 * @param {*} args JSON object providing required parameters
	 */
	setCellEditingContent(args) {
		const colId = args.columnId || null;
		const rowId = args.rowId || null;
		const ctt = Validator.ensureString(args.content);
		if ( Validator.isStringOrNumber(colId) && Validator.isNumber(rowId) ) {
			// update data model and UI
			this.model.updateCellText(colId, rowId, ctt);
			const xc = this._getXCellItem(colId, rowId);
			if ( xc instanceof XCellItem ) {
				// update the cell
				xc.setContentText(ctt);
			}
			const et = this.editTarget;
			if ( et instanceof EditTarget ) {
				const iid = args.inputId || '';
				if ( Validator.isString(iid) && (et.inputId === iid) ) {
					// that's also for the current edit target!
					et.syncInputContentWithDropdown(args);
				}
			}
		}
	}

	/**
	 * sets the "overridden" original value
	 * @param {*} args JSON object providing required parameters
	 */
	setOvrOrgValue(args) {
		const et = this.editTarget;
		if ( et instanceof EditTarget ) {
			const idc = args.idc || 0;
			const idr = args.idr || 0;
			const iid = args.inputId || '';
			const org = args.org !== undefined ? Validator.ensureString(args.org) : null;
			if ( (et.columnId === idc) && (et.rowId === idr) && (!Validator.isString(iid) || (et.inputId === iid)) ) {
				et.setOvrOrgValue(org);
			}
		}
	}

	/**
	 * sets the current dropdown state
	 * @param {*} args JSON object providing required parameters
	 */
	setInputDropdownOpenState( args ) {
		const open = !!args.open;
		const cell = this._getXCellItem(args.idc, args.idr);
		if ( cell instanceof XCellItem ) {
			cell.droppedDown = open;
		}
		const et = this.editTarget;
		if ( et instanceof EditTarget ) {
			const iid = args.inputId || '';
			if ( Validator.isString(iid) && (et.inputId === iid) ) {
				const wdgId = args.dropDownWidget || '';
				this.log(`Set dropdown state for ${iid} to open=${open}.`);
				et.lock();
				try {
					et.setDropdownOpenState(open, wdgId);
				} finally {
					et.release();
				}
			}
		}
	}

	addContextMenuListener() {
		const successfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "contextmenu",
			functionName: "onNoMansLandContextMenu",
			element: this.rowContainer,
			useCapture: false
		} );
		return successfullyAdded;
	}

	removeContextMenuListener() {
		const successfullyRemoved = EventListenerManager.removeListener( this, "contextmenu", this.rowContainer );
		return successfullyRemoved;
	}

	/**
	 * called by the table header if the table is about to be changed (sorted, columns changed etc.)
	 * @param {Boolean} sort flag whether this is a sort operation
	 */
	onTableChange(sort) {
		this._createEditRequest(!!sort, 'sort');
	}

	/**
	 * creates a field menu
	 * @param {Boolean} useRowTemplate flag whether to check Row Template definition
	 * @param {Function} filter optional callback that can filter out items
	 * @param {Function} createMenuItem optional callback function to create a menu item descriptor
	 * @param {Function} doAfterCreatingMenuItems optional callback function that's called after all menu item descriptors have beed created
	 * @param {Boolean} useDescription flag whether to use the column descriptor instead of the column title
	 * @returns {MnuObj} the created menu
	 */
	 createFieldMenu( useRowTemplate, filter, createMenuItem, doAfterCreatingMenuItems, useDescription ) {
		const columnMap = new Map();
		if ( useRowTemplate ) {
			this._addRowTemplateDescriptorColumnsToMap( columnMap );
		}
		const menuItems = this.createFieldMenuItems(columnMap, filter, createMenuItem, useDescription );
		if ( !Validator.isArray( menuItems, false ) ) {
			return void 0;
		}
		const collator = new Intl.Collator();
		menuItems.sort( ( a, b ) => collator.compare( a._comp, b._comp ) );
		if ( Validator.isFunction( doAfterCreatingMenuItems ) ) {
			doAfterCreatingMenuItems( menuItems );
		}
		return new SearchableMenuObject( PSA.instance.getMnuMgr(), ++ID_FIELD_MENU, menuItems );
	}

	/**
	 * creates the menu items for a field menu
	 * @param {Map} columnMap a map of columns to create menu items from
	 * @param {Function} filterColumn optional callback that can filter out items
	 * @param {Function} createMenuItem optional callback function to create a menu item descriptor
	 * @param {Boolean} useDescription flag whether to use the column descriptor instead of the column title
	 * @returns {Array} the list of created menu items
	 */
	createFieldMenuItems( columnMap, filterColumn, createMenuItem, useDescription ) {
		if ( !Validator.isMap( columnMap ) || !Validator.isFunctionPath( this.xtwHead, "xtwHead.getColumns" ) ) {
			return void 0;
		}
		const columns = this.xtwHead.getColumns( false );
		if ( !Validator.isIterable( columns ) ) {
			return void 0;
		}
		if ( !Validator.isFunction( filterColumn ) ) {
			filterColumn = function ( column ) { return !!column.available; };
		}
		if ( !Validator.isFunction( createMenuItem ) ) {
			createMenuItem = function ( columnId, menuItemText, column ) {
				return new XtwMnuItm( columnId, menuItemText, false, column );
			};
		}
		const menuItems = [];
		columns.forEach( ( column ) => {
			const menuItem = this._createFieldMenuItemForColumn( {
				column: column,
				filterColumn: filterColumn,
				createMenuItem: createMenuItem,
				useDescription: useDescription,
				columnMap: columnMap,
				menuItems: menuItems
			} );
			if ( Validator.isObject( menuItem ) ) {
				menuItems.push( menuItem );
			}
		} );
		return menuItems.length > 0 ? menuItems : void 0;
	}

	_createFieldMenuItemForColumn( {
		column,
		filterColumn,
		createMenuItem,
		useDescription,
		columnMap,
		menuItems
	} ) {
		if ( !Validator.isObject( column ) || column.select || !filterColumn( column ) ) {
			return null;
		}
		const columnId = column.id;
		if ( !Validator.isPositiveInteger( columnId, false ) || !column.available || ((columnMap.size != 0) && !columnMap.has( columnId )) ) {
			return null;
		}
		const menuItemText = this._getTextFromColumn( column, useDescription );
		const menuItemIcon = ( useDescription ? null : column.image ) || null;
		if ( !Validator.isString( menuItemText ) && !menuItemIcon ) {
			return null;
		}
		// column is not filtered out -> create a menu item object
		const menuItem = createMenuItem( columnId, menuItemText, column );
		if ( menuItemIcon ) {
			menuItem.ttlImg = menuItemIcon;
		}
		// set a "compare" text
		menuItem._comp = Validator.isString( menuItemText ) ? menuItemText : `000-${ 1000000 + menuItems.length }`;
		return menuItem;
	}

	_addRowTemplateDescriptorColumnsToMap( columnMap ) {
		if ( !Validator.isMap( columnMap ) ) {
			return false;
		}
		const rowTemplateDescriptor = this._getRowTpl();
		if ( !Validator.isObject( rowTemplateDescriptor ) || !Validator.isIterable( rowTemplateDescriptor.columns ) ) {
			return false;
		}
		// get visible RTP columns
		rowTemplateDescriptor.columns.forEach( ( rowTemplateDescriptorColumn ) => {
			if ( !Validator.isObjectPath( rowTemplateDescriptorColumn, "rowTemplateDescriptorColumn.rtpCol" ) || !rowTemplateDescriptorColumn.rtpCol.rtpDscVis ) {
				return;
			}
			// ok, column is visible in RTP
			columnMap.set( rowTemplateDescriptorColumn.idc, rowTemplateDescriptorColumn.rtpCol );
		} );
		return true;
	}

	_getTextFromColumn( columnObject, useDescription = true ) {
		if ( !Validator.isObject( columnObject ) ) {
			return "";
		}
		let textToDisplay = !!useDescription && Validator.isString( columnObject.dsc ) ? columnObject.dsc : columnObject.menuText;
		if ( Validator.isString( textToDisplay ) ) {
			textToDisplay = textToDisplay.replace( /\n/g, " " ).replace( /<br>/g, " " ).replace( /<br\/>/g, " " );
			// TODO add maximal character count
			if ( textToDisplay.length > MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY ) {
				textToDisplay = textToDisplay.substring( 0, MENU_ITEM_MAX_CHARACTERS_TO_DISPLAY + 1 ).concat( "..." );
			}
		}
		return Validator.isString( textToDisplay ) ? textToDisplay : "";
	}

	addCustomEventHandlers() {
		const self = this;
		this.rowContainer.addEventListener('xcellclicked', (e) => {
			self.onCellClicked(e);
		});
		this.model.addColumnFocusListener( (model, idc, prev) => {
			self.onColumnFocus(model, idc, prev);
		});
		this.model.addRowFocusListener( (model, focus, prev, options) => {
			self.onRowFocus(model, focus, prev, options);
		});
		this.model.addSelectionListener( (model, selected, options) => {
			self.onSelectionChanged(model, selected, options);
		});
	}

	addToggleButton() {
		if ( Validator.is( this.toggleButton, "ViewToggleButton" ) ) {
			return;
		}
		this.toggleButton = new ViewToggleButton( this );
	}

	renderToggleButton() {
		if ( !Validator.is( this.toggleButton, "ViewToggleButton" ) ) {
			return;
		}
		this.toggleButton.render();
		if ( this.isRowTpl ) {
			this.toggleButton.changeToListIcon();
		} else {
			this.toggleButton.changeToTableIcon();
		}
	}

	destroyToggleButton() {
		if ( !Validator.is( this.toggleButton, "ViewToggleButton" ) ) {
			return;
		}
		this.toggleButton.destroy();
		this.toggleButton = void 0;
		delete this.toggleButton;
	}

	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		this._basicLayout();
		return this.syncClassWithState();
	}

	/**
	 * sets the table header widget
	 * @param {XtwHead} xth the table header widget
	 */
	setTblHead( xth ) {
		this.xtwHead = xth;
	}

	/**
	 * sets the widths of fixed and dynamic parts
	 * @param {Number} fxw width of fixed part
	 * @param {Number} dnw width of dynamic part
	 */
	setPartWdt( fxw, dnw ) {
		// TODO
	}

	/**
	 * sets the height of row template rows
	 * @param {Number} hgt new height of row template rows in pixels
	 */
	setRtpRwh( hgt ) {
		if ( typeof hgt === 'number' ) {
			this.rtpRwh = hgt;
		}
	}

	/**
	 * sets new overall vertical padding for row template rows
	 * @param {Number} vpad new overall vertical padding in pixels for row template rows
	 */
	setRtpVPad( vpad ) {
		if ( typeof vpad === 'number' ) {
			this.rtpVPad = vpad;
		}
	}

	setCssVariable( cssVariableName, cssVariableValue ) {
		if ( !Validator.isObject( this.xtdTbl ) ||
			!Validator.isFunction( this.xtdTbl.setCssVariable ) ) {
			return false;
		}
		return this.xtdTbl.setCssVariable( cssVariableName, cssVariableValue );
	}

	/**
	 * sets the rows to be updated
	 * @param {Array<Number>} rows an array providing the IDs of the rows to be updated
	 */
	setUpdRows( rows ) {
		this.model.modelUpdate( rows );
		this._updAllRowItems(null);
		this._nfySrv( 'rowsUpdated', { cnt: rows.length }, false );
	}

	/**
	 * rebuilds all row items due to changes in the table (columns etc.)
	 */
	rebuildAllRows() {
		const xth = this.xtwHead;
		if ( xth ) {
			const hsp = this._getEffHsp();
			this.rowItems.forEach( ( ri ) => ri.rebuild( xth, hsp ) );
		}
	}

	/**
	 * initializes the view mode; has no effect, if no row template is specified
	 * @param {Boolean} rtp flag, whether row template is initially visible
	 * @param {Boolean} tgl flag, whether the view mode can be toggled
	 */
	iniViewMode( rtp, tgl ) {
		if ( tgl ) {
			this.addToggleButton();
		} else {
			this.destroyToggleButton();
		}
		this.syncClassWithState();
		if ( this.rtpMode !== rtp ) {
			// we must re-create the UI
			this.rtpMode = rtp;
			this.syncClassWithState();
			if ( this.ready ) {
				if ( this.idManager ) {
					delete this.idManager;
					delete this.rcells;
					delete this.sortedColumns;
				}
				this.idManager = null;
				const eff_rtp = this.isRowTpl;
				const rh = eff_rtp ? this.rtpRwh : this.defRwh;
				const vp = eff_rtp ? this.rtpVPad : 0;
				const hgt = this.model.viewModeChanged( eff_rtp, rh, vp );
				this._rmvAllDomElm();
				this._updDomElm( this.visHgt );
				this._nfySrv( "toggleDisplay", {
					idr: 0,
					rel: false,
					height: this._getEffScrHeight( hgt ),
					mode: eff_rtp
				}, false );

				// trigger data update if required
				const vps = this.xScrollExt.vscPos;
				this.xScrollExt.reset(this.xScrollExt.hscPos);
				this.xScrollExt.triggerScrollUpd( this.xScrollExt.hscPos, vps );

				// update sort flap
				this._updateSortFlap( eff_rtp );
			}
		}
		this.ensureAutoFitOnTableColumns();
	}

	ensureAutoFitOnTableColumns() {
		if ( !Validator.isObject( this.xtdTbl ) ) {
			return false;
		}
		return this.xtdTbl.autoFitColumns();
	}

	/**
	 * called by the web server if the data model has been committed
	 * @param {Object} args new data model
	 */
	modelCommitted( args ) {
		if ( !this.alive ) {
			return;
		}
		if ( !this.ready ) {
			const idx = this.pndOps.findIndex( (po) => po.code === CODE_MODEL );
			if ( idx < 0 ) {
				// add new operation
				this.pndOps.push(PendingOp.newInstance(CODE_MODEL, args));
			} else {
				// overwrite existing operation!
				this.pndOps[idx] = PendingOp.newInstance(CODE_MODEL, args);
			}
			return;
		}
		const nfy = this._nfySuppressed;
		try {
			this.clearEditRequest();
			this._setNoNfyFocus(true);
			this._setNoNfySelection(true);
			const sort = args.sort || {};
			const sort_idc = sort.idc || -1;
			const sort_dir = !!sort.direction;
			if ( this.sortFlap ) {
				this.sortFlap.setSortColumn( sort_idc, sort_dir, false )
			} else if ( this.xtwHead ) {
				this.xtwHead.setSortColumn( sort_idc, sort_dir, false );
			}
			const rtp = this.isRowTpl;
			const rh = rtp ? this.rtpRwh : this.defRwh;
			const vp = rtp ? this.rtpVPad : 0;
			const self = this;
			const cbf = () => {
				// callback: update all row items
				self.xScrollExt.reset();
				self._setupRowItems();
			}
			const hgt = this.model.modelCommitted( args, rtp, rh, vp, cbf );
			const cnt = this.model.getItemCount();
			// drop any possibly pending fetch request
			this._fetchDropRqu();
			if ( this.logger.isDebugEnabled() ) {
				const items = args.model || [];
				this.log(`XTW ${this.wdgId} - model complete: got ${items.length} model items.`);
			}
			const silent = !!args._silent;
			if ( !silent ) {
				// notify web server
				this._nfyModelComplete(hgt, cnt, 'modelCommitted');
			}
		} finally {
			this._nfySuppressed = nfy;
		}
	}

	/**
	 * called by the backend if data were sorted
	 * @param {*} args sorted model data
	 */
	modelSorted(args) {
		if ( this.alive ) {
			if ( this.ready ) {
				this.debug('Got sorted data.');
				if ( this.isTraceEnabled() ) {
					this.trace(JSON.stringify(args));
				}
				const nfy = this._nfySuppressed;
				try {
					this._setNoNfyFocus(true);
					this._setNoNfySelection(true);
					const rtp = this.isRowTpl;
					const rh = rtp ? this.rtpRwh : this.defRwh;
					const vp = rtp ? this.rtpVPad : 0;
					const model = this.model;
					const self = this;
					const cbf = () => {
						self._setupRowItems();
					};
					const height = model.modelSorted(args, rtp, rh, vp, cbf);
					const count = model.getItemCount();
					this._updateRowsUI();
					const fcr = model.focusedRow;
					if ( fcr !== ROWID_NONE ) {
						const mi = model.getDataRowModelItem(fcr);
						if ( mi instanceof MRowItem ) {
							this.ensureRowIsVisible(mi.flatIndex, () => {
								const er = self.editRequest;
								if ( (er instanceof EditRequest) && er.valid && er.deferred ) {
									if ( this.isDebugEnabled() ) {
										this.debug(`Triggering edit request ${er.toString()}`);
									}
									setTimeout(() => {
										self._applyEditRequest(er);
									}, 1);
								} else {
									self.clearEditRequest();
								}
							});
						}
					}
					const silent = !!args._silent;
					if ( !silent ) {
						// notify web server
						this._nfyModelComplete(height, count, 'modelSorted');
					}
				} finally {
					this._nfySuppressed = nfy;
				}
			} else {
				this.modelCommitted(args);
			}
		}
	}

	/**
	 * deletes rows
	 * @param {*} args an argument object providing the IDs of the rows to be deleted
	 */
	deleteRows(args) {
		if ( !this.alive ) {
			return;
		}
		if ( !this.ready ) {
			this.pndOps.push(PendingOp.newInstance(code, args));
			return;
		}
		const rows = args.rows || [];
		if ( rows.length > 0 ) {
			const model = this.model;
			const fcr = model.focusedRow;
			const fix = fcr !== ROWID_NONE ? model.getFlatIndex(fcr) : -1;
			const sel = model.isOneSelected(rows);
			const rtp = this.isRowTpl;
			const rh = rtp ? this.rtpRwh : this.defRwh;
			const vp = rtp ? this.rtpVPad : 0;
			const hgt = model.deleteRows(rows, rtp, rh, vp);
			const cnt = model.getItemCount();
			this._setupRowItems();
			if ( (fix !== -1) && (rows.indexOf(fcr) !== -1) ) {
				// we've removed the focused row
				model.focusNextItem(fix, sel);
			}
			const silent = !!args._silent;
			if ( !silent ) {
				// notify web server
				this._nfyModelComplete(hgt, cnt, 'deleteRows');
			}
		}
	}

	/**
	 * inserts a row
	 * @param {*} args  an argument object providing the the row to be inserted
	 */
	insertRow(args) {
		if ( !this.alive ) {
			return;
		}
		if ( !this.ready ) {
			this.pndOps.push(PendingOp.newInstance(CODE_INSERT, args));
			return;
		}
		if ( Validator.isObject(args.row) && Validator.isPositiveInteger(args.row.idr) ) {
			const self = this;
			const model = this.model;
			const target = args.ref;
			const ti = model.isRealRowID(target) ? model.getDataRowModelItem(target) : model.getDummyRow();
			if ( ti instanceof MRowItem ) {
				// make sure the target row is visible
				this.ensureRowIsVisible(ti.flatIndex, () => self._doInsertRow(model, args));
			} else {
				// no target row?!
				this._doInsertRow(model, args);
			}
		}
	}

	/**
	 * effectively inserts the row
	 * @param {XtwModel} model the data model
	 * @param {*} args  an argument object providing the the row to be inserted
	 */
	_doInsertRow(model, args) {
		const rtp = this.isRowTpl;
		const rh = rtp ? this.rtpRwh : this.defRwh;
		const vp = rtp ? this.rtpVPad : 0;
		const hgt = model.insertRow(args, rtp, rh, vp);
		const cnt = model.getItemCount();
		this._setupRowItems();
		const focusRow = !!args.focusRow || (model.focusedRow === ROWID_NONE);
		if ( focusRow ) {
			const idr = args.row.idr;
			model.focusDataRow(idr);
			model.selectSingleRow(idr);
		}
		const silent = !!args._silent;
		if ( !silent ) {
			// notify web server
			this._nfyModelComplete(hgt, cnt, 'insertRow');
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 * @returns {Boolean}
	 */
	canHandleClipboard() {
		const et = this.editTarget;
		if ( et instanceof EditTarget ) {
			// the edit target is supposed to be focused
			return false;
		}
		return true;
	}

	/**
	 * @inheritdoc
	 * @override
     * @param {Boolean} plain optional flag whether to create plain text clipboard data
	 */
	copyToClipboard(plain = false) {
		if ( this.alive ) {
			const model = this.model;
			const xth = this.xtwHead;
			if ( (model instanceof XtwModel) && (xth instanceof XtwHead) ) {
				if ( this.clipboardEnabled ) {
					const cm = ClipboardMgr.getInstance();
					if ( cm.available ) {
						let ok = false;
						const table = document.createElement('table');
						const thead = document.createElement('thead');
						const tbody = document.createElement('tbody');
						table.appendChild(thead);
						table.appendChild(tbody);
						const ch = new XtwBodyClipboardHdl(this, plain);
						const cols = xth.getVisibleColumns();
						const lines = new TextBuilder('\n');
						ok = this._createClpHeader(lines, cols, thead);
						if ( ok ) {
							const self = this;
							const idrs = model.getSelection();
							if ( idrs.length > 0 ) {
								let row_ok = false;
								idrs.forEach( (idr) => {
									if ( self._createClpRow(lines, model, idr, cols, tbody) ) {
										row_ok = true;
									}
								});
								ok = row_ok;
							} else {
								ok = false;
							}
						}
						if ( ok ) {
							const div = document.createElement('div');
							if ( !plain ) {
								// add meta data comment
								const meta = Object.assign({ source: 'PisaSalesCRM' }, this.metaData || {});
								const mc = [];
								cols.forEach((c) => {
									if ( (c instanceof XtwCol) && c.alive && !c.select ) {
										mc.push(c.dsc);
									}
								});
								meta.columns = mc;
								div.appendChild(document.createComment(JSON.stringify(meta)));
								// add table element providing all data
								div.appendChild(table);
							}
							let data = null;
							if ( plain ) {
								// just simple "text/plain"
								const type = 'text/plain';		// always use "text/plain"!
								const text = lines.text;
								const blob = new Blob([text], {type: type});
								data = [ new ClipboardItem({[type]: blob}) ];
								if ( this.isDebugEnabled() ) {
									this.log('Clipboard data (text/plain)');
									this.log(text);
								}
							} else {
								// we provide both "text/html" and "text/plain"
								const type_html = 'text/html';
								const type_plain = 'text/plain';
								const text_html = '<html><body>' + div.innerHTML + '</body></html>';
								const text_plain = lines.text;
								const blob_html = new Blob([text_html], {type: type_html});
								const blob_text = new Blob([text_plain], {type: type_plain});
								data = [ new ClipboardItem( {
									[type_html]: blob_html,
									[type_plain]: blob_text
								}) ];
								if ( this.isDebugEnabled() ) {
									this.log('Clipboard data (text/plain)');
									this.log(text_plain);
									this.log('Clipboard data (text/html)');
									this.log(text_html);
								}
							}
							cm.copyToClipboard(ch, data);	// we're limited to exactly *one* clipboard item!
						} else {
							this._nfyClipboard('NODATA', null, null);
						}
					} else {
						this._nfyClipboard('NOTAVAILABLE', null, null);
					}
				} else {
					this._nfyClipboard('FORBIDDEN', null, null);
				}
			}
		}
	}

	/**
	 * sends a clipboard notification to the web server
	 * @param {String} code notification code
	 * @param {String} message optional message or clipboard data
	 * @param {Object} data optional notification data
	 */
	_nfyClipboard(code, message, data) {
		const par = { code: code };
		if ( Validator.isString(message) ) {
			par.message = message;
		}
		if ( data ) {
			par.data = data;
		}
		this._nfySrv('clipBoard', par, false);
	}

	/**
	 * created the clipboard header data
	 * @param {TextBuilder} lines the target text builder
	 * @param {XtwCol[]} cols columns
	 * @param {HTMLElement} thead table header element
	 * @returns {Boolean} success
	 */
	_createClpHeader(lines, cols, thead) {
		let ok = false;
		const im = ItmMgr.getInst();
		const self = this;
		const line = new TextBuilder('\t');
		const tr = document.createElement('tr');
		cols.forEach((col) => {
			if ( (col instanceof XtwCol) && col.alive && !col.select ) {
				const th = document.createElement('th');
				const ctt = col.ctt;
				self._setClpCell(im, ctt, th, line);
				tr.appendChild(th);
				ok = true;
			}
		});
		if ( ok ) {
			lines.append(line.text);
			thead.appendChild(tr);
		}
		return ok;
	}

	/**
	 * creates a clipboard data row
	 * @param {TextBuilder} lines the target text builder
	 * @param {XtwModel} model the data model
	 * @param {Number} idr row ID
	 * @param {XtwCol[]} cols columns
	 * @param {HTMLElement} tbody the table body element
	 * @returns {Boolean} success
	 */
	_createClpRow(lines, model, idr, cols, tbody) {
		let ok = false;
		if ( model.isRealRowID(idr) ) {
			const line = new TextBuilder('\t');
			const tr = document.createElement('tr');
			const mi = model.getDataRowModelItem(idr);
			if ( (mi instanceof MDataRow) && mi.alive ) {
				// meta data comment
				tr.appendChild(document.createComment(JSON.stringify({ idr: mi.idr, xid: mi.xid })));
				// cell data
				const im = ItmMgr.getInst();
				const self = this;
				cols.forEach((col) => {
					if ( (col instanceof XtwCol) && col.alive && !col.select ) {
						const td = document.createElement('td');
						const cell = mi.getCell(col.id);
						if ( (cell instanceof MCell) && cell.alive && !col.isBlob ) {
							const ctt = cell.ctt;
							self._setClpCell(im, ctt, td, line);
						} else {
							line.append('');
						}
						tr.appendChild(td);
					}
				});
				ok = true;
			}
			if ( ok ) {
				lines.append(line.text);
				tbody.appendChild(tr);
			}
		}
		return ok;
	}

	/**
	 * sets a clipboard data cell 
	 * @param {ItmMgr} im item manager
	 * @param {CellCtt} ctt cell content
	 * @param {HTMLElement} elm the target DOM element
	 * @param {TextBuilder} line target text builder
	 */
	_setClpCell(im, ctt, elm, line) {
		const txt = '' + ((ctt instanceof CellCtt) ? ctt.plainText : ctt.text);
		const has_br =  txt.indexOf('\n') !== -1;
		line.append(has_br ? Utils.replaceBreaks(txt, ' ') : txt) ;
		elm.innerHTML = has_br ? Utils.replaceBreaks(txt, EXCEL_BR) : txt;
		if ( ctt.prop ) {
			const prop = ctt.prop;
			if ( prop.bgc ) {
				im.setBkgClr(elm, prop.bgc, false);
			}
			if ( prop.txc ) {
				im.setFgrClr(elm, prop.txc);
			}
			if ( prop.font ) {
				im.setFnt(elm, prop.font);
			}
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} alt
	 * @param {Boolean} plain 
	 */
	pasteFromClipboard(alt = false, plain = false) {
		if ( this.alive ) {
			if ( alt ) {
				// we can go the direct way
				this._doPasteFromClipboard(!!plain);
			} else {
				// the tricky part :-)
				const ct = this._clpTrigger;
				if ( ct instanceof HTMLElement ) {
					this.log('Simulating a click event on the clipboard trigger element.');
					ct._psa_plain = !!plain;
					ct.click();
				}
			}
		}
	}

	/**
	 * triggers the "clipboard read" operation
	 * @param {Boolean} plain plain text flag
	 */
	_doPasteFromClipboard(plain) {
		const cm = ClipboardMgr.getInstance();
		if ( cm.available ) {
			const ch = new XtwBodyClipboardHdl(this, plain);
			cm.pasteFromClipboard(ch);
		} else {
			this._nfyClipboard('NOTAVAILABLE', null, null);
		}
	}

	/**
	 * 
	 * @param {ClipboardEvent} ce the clipboard event
	 */
	_onClipboardPaste(ce) {
		if ( this.alive && this.canHandleClipboard() ) {
			DomEventHelper.stopEvent(ce);
			this._doPasteFromClipboard(false);
		}
	}
	
	/**
	 * called after a simulated "click" on the clipboard trigger tag
	 * @param {MouseEvent} e the click event
	*/
	_onClipboardTrigger(e) {
		DomEventHelper.stopEvent(e);
		const ct = this._clpTrigger;
		const plain = (ct instanceof HTMLElement) ? !!ct._psa_plain : false;
		this._doPasteFromClipboard(plain);
	}

	/**
	 * called by the clipboard handler after data were successfully read from the clipboard
	 * @param {ClipboardItem[]} items clipboard data items
	 * @param {XtwBodyClipboardHdl} handler the clipboard handler
	 */
	onClipboardData(items, handler) {
		const plain = handler.plain;
		const type = this._getClipboardCtt(plain);
		const result = { item: null, type: null };
		const item = this._getClipboardItem(items, plain, result);
		if ( item instanceof ClipboardItem ) {
			const self = this;
			item.getType(result.type).then( (result) => {
				if ( result instanceof Blob ) {
					self._handleClipboardBLOB(result);
				}
			}).catch( (err) => {
				self.log('Clipboard access failed!', err);
				self._nfyClipboard('FAILED', (err ? '' + err : null), null);
			});
		} else {
			this.log(`No matching clipboard item for content type "${type}" found!`, items);
		}
	}

	/**
	 * returns the requested clipboard data content type
	 * @param {Boolean} plain plain text flag
	 * @returns {String} the content type
	 */
	_getClipboardCtt(plain) {
		return plain ? 'text/plain' : 'text/html';
	}

	/**
	 * retrieves the first clipboard item that provides data in the requested format
	 * @param {ClipboardItem[]} items clipboard data items
	 * @param {Boolean} plain plain text flag
	 * @param {Object} result a result object
	 * @returns {ClipboardItem|null} the first matching clipboard item or null if nothing found
	 */
	_getClipboardItem(items, plain, result) {
		const type = this._getClipboardCtt(plain);
		for ( const item of items ) {
			for ( const ctt of item.types ) {
				if ( type === ctt ) {
					result.type = type;
					result.item = item;
					return item;
				}
			}
		}
		return plain ? null : this._getClipboardItem(items, true, result);
	}

	/**
	 * processes a BLOB retrieved from clipboard
	 * @param {Blob} blob the BLOB
	 */
	_handleClipboardBLOB(blob) {
		const self = this;
		blob.text().then( (text) => {
			if ( Validator.isString(text) ) {
				self.log('Got data from clipboard', text);
			}
			self._sendClipboardData(text, blob.type);
		}).catch( (err) => {
			self.log('BLOB data access failed', err);
			self._nfyClipboard('FAILED', (err ? '' + err : null), null);
		});
	}

	/**
	 * sends clipboard text to the web server
	 * @param {String} text clipboard text
	 * @param {String} type text type
	 */
	_sendClipboardData(text, type) {
		if ( this.alive ) {
			const xth = this.xtwHead;
			const cols = xth.getVisibleColumns();
			const fields = [];
			for ( const col of cols ) {
				if ( (col instanceof XtwCol) && col.alive && !col.select && col.visible ) {
					fields.push(col.dsc);
				}
			}
			const data = { text: text, type: type, fields: fields };
			this._nfyClipboard('PASTE', null, data);
		}
	}

	/**
	 * sends a "model complete" notification to the web server
	 * @param {Number} hgt total height of all items
	 * @param {Number} cnt number of model items
	 * @param {String} opr operation
	 */
	_nfyModelComplete(hgt, cnt, opr) {
		this._nfySrv( 'modelComplete', {
			height: this._getEffScrHeight( hgt ),
			count: cnt,
			operation: Validator.ensureString(opr)
		},
		false );
	}

	/**
	 * forces an UI update
	 * @param {String} operation the operation that caused this call
	 * @param {Number} sx horizontal scroll position
	 * @param {Number} sy vertical scroll position
	 */
	_forceUIRefresh(operation, sx, sy ) {
		if ( this.alive ) {
			this.xScrollExt.reset();
			this._fetchDropRqu();
			this.xScrollExt.triggerScrollUpd( sx, sy );
			if ( operation === 'deleteRows' ) {
				const rid = this._getRequestId(UI_STATUS_REQUEST);
				this.triggerUIRefresh(false);
			}
		}
	}

	/**
	 * called by the web server to force a full UI refresh
	 */
	forceUIRefresh(args) {
		const operation = Validator.ensureString(args.operation);
		this.doForceUIRefresh(operation);
	}

	doForceUIRefresh(operation) {
		const hgt = this.visHgt;
		const sx = Math.max( this.xScrollExt.hscPos, 0 );
		const sy = Math.max( this.xScrollExt.vscPos, 0 );
		if ( this.isTraceEnabled() ) {
			this.trace( `XTW ${this.wdgId} - forced UI refresh: height=${hgt}px - hsc=${sx}, vsc=${sy}.` );
		}
		this._updDomElm( hgt );
		const self = this;
		UIRefresh.runDelayed( () => {
			let eff_sx = sx;
			let eff_sy = sy;
			try {
				if ( this._lastScrollPos !== null ) {
					const lp = this._lastScrollPos;
					if ( Number.isFinite(lp.x) ) {
						eff_sx = lp.x;
					}
					if ( Number.isFinite(lp.y) ) {
						eff_sy = lp.y;
					}
				}
				self._forceUIRefresh(operation, eff_sx, eff_sy);
			}
			finally {
				this._lastScrollPos = null;
			}
		} );
	}

	/**
	 * called by the web server in response to a fetch request
	 * @param {Object} args new data
	 */
	modelData( args ) {
		const focusRow = Validator.isObject( args ) ? args.focusRow : null;
		this._modelData( args );
		if ( Validator.isValidNumber( focusRow ) ) {
			this.model.focusDataRow(focusRow);
		}
		return true;
	}

	/**
	 * processes a "model data" response
	 * @param {*} args model data and more
	 * @returns {Boolean} true if successful; false otherwise
	 */
	_modelData( args ) {
		const xth = this.getXtwHead();
		if ( !this.alive || !(xth instanceof XtwHead) || !xth.alive ) {
			return false;
		}
		const model = this.model;
		if ( !(model instanceof XtwModel) ) {
			return false;
		}
		const id = args.id || 0;
		const items = args.items || [];
		const fetch_id = this.fetchID;
		const is_last = fetch_id === id;
		const et = this.editTarget;
		const er = (et instanceof EditTarget) ? et.createEditRequest() : this.editRequest;
		if ( this.isTraceEnabled() ) {
			this.trace(`entering _modelData() - last=${is_last}`);
		}
		if ( (et instanceof EditTarget) && !et.isKeepForced ) {
			// check the referenced model item
			if ( model.hasModelItem(et.rowId) ) {
				// that's valid, so keep the edit target
				this.trace('Current edit target is kept alive.');
				et.setKeepForced(true);
			} else {
				// the edit target points to an invalid model item, drop it
				this.trace('Current edit target is dropped and destroyed.');
				this._dropEditTarget();
			}
		}
		try {
			// let the model process this - we've got, what we've got :-)
			model.modelData(items);
		} finally {
			if ( is_last ) {
				const cf = this.fetchCph;
				this.fetchCph = null;
				if ( cf ) {
					cf();
				}
			}
		}
		if ( !is_last ) {
			this.log(`${this.wdgId} - processed older fetch request "${id}" (expected: "${fetch_id}") !`);
			return false;
		}
		this.log(`${this.wdgId} - processed fetch request "${id}".`);
		this.fetchID = 0;
		this._doAfterModelData();
		this._doEnsuingModelData();
		if ( er instanceof EditRequest ) {
			this._applyEditRequest(er);
		}
		if ( this.isTraceEnabled() ) {
			this.trace('leaving _modelData()');
		}
		return true;
	}

	/**
	 * called be the header widget if one or more columns were changed (new titles etc.)
	 */
	onColumnChanged() {
		if ( this.sortFlap ) {
			this.sortFlap.updateFieldMenu();
		}
	}

	/**
	 * called after the user has changed a column width by dragging the column
	 * border in the table/excel view/display
	 * @param {XtwCol} column the affected column
	 * @param {Number} newWidth new column width
	 * @param {Number} widthDifference the difference of the width
	 * @return {Boolean} whether or not the process was carried out successfully
	 * (as requested/intended)
	 */
	onColumnWidth( column, newWidth, widthDifference ) {
		if ( this.isRowTpl || !this.isRendered ) {
			return false;
		}
		if ( !Validator.is( column, "XtwCol" ) ||
			!Validator.isPositiveNumber( newWidth ) ||
			!Validator.isValidNumber( widthDifference, false ) ) {
			return false;
		}
		const success = this.applyToAllValidRows( xRowItem => {
			if ( !Validator.isFunction( xRowItem.onColumnWidth ) ) {
				return false;
			}
			return xRowItem.onColumnWidth( column, newWidth, widthDifference ) != false;
		} );
		return success;
	}

	allRowItemsHaveCell( cellId ) {
		if ( !Validator.isString( cellId ) && !Validator.isValidNumber( cellId ) ) {
			return false;
		}
		const success = this.applyToAllValidRows( xRowItem => {
			if ( !Validator.isFunctionPath( xRowItem, "xRowItem.cells.hasObj" ) ) {
				return false;
			}
			return xRowItem.cells.hasObj( cellId );
		} );
		return success;
	}

	reactToColumnVisibilityChange( xtwCol, widthDifference ) {
		if ( !(xtwCol instanceof XtwCol) || !Validator.isValidNumber( widthDifference ) ) {
			return false;
		}
		if ( widthDifference > 0 && !this.allRowItemsHaveCell( xtwCol.id ) ) {
			this.rebuildAllRows();
		}
		this.onColumnWidth( xtwCol, xtwCol.getWidth(), widthDifference );
		return true;
	}

	/**
	 * moves all of the cells (precisely their elements) from the first column
	 * to a position exactly before or after the corresponding cells in the second column
	 * @param {XtwCol} firstColumn the first column (the one whose cells should be moved and should change their places)
	 * @param {XtwCol} secondColumn the second column (the one whose cells keep their place)
	 * @param {Boolean} before indicates whether the moved column should be inserted before the second column
	 * @return {Boolean} true if the movement was successful, false otherwise
	 */
	moveFirstColumnToSecond( firstColumn, secondColumn, before = true ) {
		if ( [ firstColumn, secondColumn ].some( column => (
				!( column instanceof XtwCol ) ||
				!Validator.isPositiveInteger( column.id )
			) ) || firstColumn === secondColumn ) {
			return false;
		}
		const firstColumnId = firstColumn.id;
		const secondColumnId = secondColumn.id;
		let fixedWidthDifference = 0;
		let dynamicWidthDifference = 0;
		let ignoreFixedFlag = false;
		if ( firstColumn.fix === secondColumn.fix ) {
			ignoreFixedFlag = true;
		} else if ( firstColumn.fix ) {
			fixedWidthDifference -= firstColumn.width;
			dynamicWidthDifference += firstColumn.width;
		} else {
			fixedWidthDifference += firstColumn.width;
			dynamicWidthDifference -= firstColumn.width;
		}
		const success = this.applyToAllValidRows( xRowItem => {
			if ( !(xRowItem instanceof XRowItem) ) {
				return false;
			}
			return xRowItem.moveFirstCellToSecond(
				firstColumnId,
				secondColumnId,
				fixedWidthDifference,
				dynamicWidthDifference,
				ignoreFixedFlag,
				before
			) !== false;
		} );
		return success;
	}

	/**
	 * performs a callback function on every row item (XRowItem) that is
	 * "attached" to this item; also previously validates the row item if not
	 * differently specified/requested
	 * @param {Function} callbackFunction the callback function
	 * @param {Boolean} validate whether or not every row item should be
	 * validated before applying the callback function with the row item as
	 * parameter
	 * @return {Boolean} whether or not the process was carried out successfully
	 * (as requested/intended)
	 */
	applyToAllValidRows( callbackFunction, validate = true ) {
		if ( !Validator.isFunction( callbackFunction ) ) {
			return false;
		}
		let atLeastOneRowProcessed = false;
		const xRowItems = this.rowItems;
		xRowItems.forEach( (xr) => {
			if ( (xr instanceof XRowItem) && xr.alive ) {
				let res = callbackFunction(xr);
				if ( res ) {
					atLeastOneRowProcessed = true;
				}
			}
		});
		return atLeastOneRowProcessed;
	}

	onNoMansLandContextMenu( domEvent ) {
		if ( Validator.isObject( domEvent ) && Validator.isString( domEvent.inputId ) ) {
			return;
		}
		if ( domEvent instanceof MouseEvent ) {
			domEvent.stopPropagation();
			domEvent.preventDefault();
		}
		const parameters = XtwUtils.getCoordinateParameters( domEvent, this.rowContainer );
		this._nfySrv( "contextMenu", parameters );
	}

	/**
	 * @returns {Number} the full width, required to show a data row without scrolling
	 */
	_getFullWidth() {
		let fxw = 0;
		let dnw = 0;
		const lim = this.rowItems.length;
		for ( let i = 0;
			( ( fxw === 0 ) || ( dnw === 0 ) ) && ( i < lim ); ++i ) {
			const ri = this.rowItems[ i ];
			fxw = Math.max( fxw, ri.getWdtFix() );
			dnw = Math.max( dnw, ri.getWdtDyn() );
		}
		return ( fxw > 0 ) && ( dnw > 0 ) ? ( fxw + dnw ) : 0;
	}

	/**
	 * called if a group is expanded or collapsed
	 * @param {MGroup} the group model item; may be null
	 */
	onGroupExpanded( mi ) {
		// this is similar to modeCommitted()
		const rtp = this.isRowTpl;
		const rh = rtp ? this.rtpRwh : this.defRwh;
		const vp = rtp ? this.rtpVPad : 0;
		// let the model update itself
		const hgt = this.model.modelGroupExpanded( rtp, rh, vp );
		const cnt = this.model.getItemCount();

		// update all row items - but keep scrolling position
		const vps = this.xScrollExt.vscPos;
		this.xScrollExt.reset();
		this.xScrollExt.triggerScrollUpd( 0, vps );

		if ( mi && mi.isGroupHead() && !mi.isDefault() ) {
			// send additional notification
			this._nfySrv( 'groupExpanded', { dsc: mi.getGrpDsc(), collapsed: mi.isCollapsed() }, false );
		}
		// notify web server
		this._nfySrv( 'modelComplete', { height: this._getEffScrHeight( hgt ), count: cnt }, false );
		this.setCssPyjamaLines();
	}

	setCssPyjamaLines() {
		if ( this.isRowTpl || !this.isRendered ) {
			return false;
		}
		if ( this.element.classList.contains( "row-template" ) || !this.element.classList.contains( "table" ) ) {
			return false;
		}
		const rowContainerElement = (this.rowContainer instanceof HTMLElement) ? this.rowContainer : ((this.element.childElementCount <= 0) ? null : this.element.children[ 0 ]);
		if ( !( rowContainerElement instanceof HTMLElement ) || !rowContainerElement.classList.contains( "xtwrowcnt" )  ) {
			return false;
		}
		let stripeCounter = 1;
		for ( let row of rowContainerElement.children ) {
			row.classList.remove( "pyjama-1" );
			row.classList.remove( "pyjama-2" );
			if ( row.classList.contains( "group-header" ) ) {
				stripeCounter = 2;
				continue;
			}
			if ( !row.classList.contains( "xtwrowitem" ) ) {
				continue;
			}
			row.classList.add( `pyjama-${ stripeCounter }` );
			stripeCounter = stripeCounter === 1 ? 2 : 1;
		}
		return true;
	}

	removeEmptyRowsFromRowContainer() {
		if ( !this.isRendered ) {
			return false;
		}
		const rowContainerElement = this.rowContainer instanceof HTMLElement ?
			this.rowContainer : this.element.childElementCount <= 0 ?
			void 0 : this.element.children[ 0 ];
		if ( !( rowContainerElement instanceof HTMLElement ) ||
			!rowContainerElement.classList.contains( "xtwrowcnt" ) ||
			!Validator.isIterable( rowContainerElement.children ) ) {
			return false;
		}
		const visibleRows = [ ...rowContainerElement.children ];
		if ( !( this.emptyRowsInvisibleContainer instanceof HTMLElement ) ) {
			this.emptyRowsInvisibleContainer = window.document.createElement( "div" );
		}
		const invisibleRows = [ ...this.emptyRowsInvisibleContainer.children ];
		for ( let invisibleRow of invisibleRows ) {
			const rowChildren = HtmHelper.getAllLevelChildren( invisibleRow );
			if ( rowChildren.length <= 0 ) {
				continue;
			}
			rowContainerElement.appendChild( invisibleRow );
		}
		for ( let visibleRow of visibleRows ) {
			const rowChildren = HtmHelper.getAllLevelChildren( visibleRow );
			if ( rowChildren.length > 0 ) {
				continue;
			}
			this.emptyRowsInvisibleContainer.appendChild( visibleRow );
		}
		return true;
	}

	/**
	 * @override
	 * @inheritdoc
	 */
	toggleSelectAll() {
		// this is either a "select all" or an "unselect all" request
		if ( this.alive ) {
			const all = this.model.toggleSelectAll();
			if ( all ) {
				// all rows selected --> fetch all data! (otherwise clipboard operations will not work properly)
				this._fetchAll(false, null);
			}
		}
	}

    /**
     * handles a keyboard event
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} press flag whether the key is pressed ('keydown') or released ('keyup')
     * @returns {Boolean} true if the keyboard event was handled; false otherwise
     */
	onKeyEvent(e, press) {
		// just forward to keyboard event handler
		return this._keyHandler.onKeyEvent(e, press);
	}

	/**
	 * checks whether the column of the given cell is visible
	 * @param {XCellItem} cell the cell
	 * @returns {Boolean} true if cell's column is visible; false otherwise
	 */
	isCellColumnVisible(cell) {
		if ( this.alive && (cell instanceof XCellItem) ) {
			return this.xtwHead.isColumnVisible(cell.column);
		}
		return false;
	}

	/**
	 * ensures that a row is visible
	 * @param {Number} rix row index
	 * @param {Function} cbf callback function
	 * @returns {Boolean} true if the view was actually scrolled vertically; false if no scrolling was done
	 */
	ensureRowIsVisible(rix, cbf) {
		let scrolled = false;
		const tix = this.topIndex;
		const vc = this._getEffVisibleRows();
		if ( (vc > 0) && ((rix < tix) || (rix >= (tix + vc))) ) {
			// we must scroll
			let dist = 0;
			let up = false;
			if ( rix < tix ) {
				// upwards: we can use the new index as is
				dist = tix - rix;
				up = true;
			} else {
				// downwards: try to keep the scrolling distance as small as possible
				dist = rix - tix - vc + 1;
			}
			if ( dist !== 0 ) {
				this._lockKeyScr();		// set a "key scroll" lock!
				let has_deferred = false;
				if ( typeof cbf === 'function' ) {
					// register callback function
					this.setupAfterScrollViewUpdateCallback("ensureRowIsVisible-", cbf);
					has_deferred = true;
				}
				scrolled = this.xtdTbl.triggerVScroll(dist, up, true);
				if ( has_deferred && !scrolled ) {
					CallbackManager.deleteCallbacksWithPrefix({
						instance: this,
						callbackMapName: AFTER_SCROLLING_CALLBACKS,
						prefix: "ensureRowIsVisible-",
						forceDeleteAll: true
					});
				}
			}
		}
		if ( !scrolled && (typeof cbf == 'function') ) {
			// invoke callback function immediately
			cbf();
		}
		return scrolled;
	}

	/**
	 * ensures that the specified cell is visible
	 * @param {XCellItem} cell the cell
	 * @param {Function} cbf callback function
	 */
	ensureCellIsVisible(cell, cbf) {
		if ( cell instanceof XCellItem ) {
			const column = cell.column;
			if ( column instanceof XtwCol ) {
				const xr = cell.row;
				this._fixXRowItem(xr);
				const mi = xr.item;
				if ( mi instanceof MRowItem ) {
					try {
						this._lockKeyScr();
						// make sure that the column is visible
						this.xtwHead.scrollToColumn(column);
						// make sure that the row is visible
						this.ensureRowIsVisible(mi.flatIndex, cbf);
					} finally {
						this._releaseKeyScr(true);
					}
				}
			}
		}
	}

	/**
	 * locks selection updates
	 */
	_lockSelUpd() {
		this.xSelLogger.trace('LOCK selection update');
		this._selUpdLock = true;
		this._clearSelUpdTimer();
	}
	
	/**
	 * clears the "selection update" timer
	 */
	_clearSelUpdTimer() {
		if ( this._selUpdTimer ) {
			this.xSelLogger.trace('CLEAR selection update timer');
			const timer = this._selUpdTimer;
			this._selUpdTimer = null;
			clearTimeout(timer);
		}
	}

	/**
	 * releases the selection update lock, triggers the update if required
	 */
	_releaseSelUpd() {
		this.xSelLogger.trace('RELEASE selection update');
		this._selUpdTimer = null;
		this._selUpdLock = false;
		if ( this.alive && this.selInfo && (this.selInfo.notify !== false) ) {
			this.xSelLogger.trace('trigger UI refresh');
			this.triggerUIRefresh(false);
		}
	}

	/**
	 * triggers the release of the selection lock
	 * @param {boolean} [now=false] flag whether to force an immediate release
	 */
	_triggerSelUpdRelease(now = false) {
		if ( !this._selUpdTimer ) {
			this.xSelLogger.trace('TRIGGER selection update release');
			const self = this;
			this._selUpdTimer = setTimeout(() => {
				self._releaseSelUpd();
			}, now ? 0 : KEYUP_DELAY);
		}
	}

	/**
	 * @returns {Boolean} true if selection updates are currently locked; false otherwise
	 */
	_isSelUpdLocked() {
		return this._selUpdLock;
	}

    /**
	 * @inheritdoc
	 * @override
     * handles relevant "arrow" key events
	 * @param {Boolean} press flag whether the key pressed ("keydown") or released ("keyup")
     * @param {KeyboardEvent} e the keyboard event
     * @param {Boolean} shift "shift" modifier flag
     * @param {Boolean} ctrl "ctrl" modifier flag
     * @param {Boolean} arrow "arrow key" flag
     * @param {Boolean} page "page up/down" flag
     * @param {Boolean} dir direction flag
     */
	onArrowKey(press, e, shift, ctrl, arrow, page, dir) {
		const logger = this.xSelLogger;
		const si = this.selInfo || { focus: this.focusIdr };
		const et = this.editTarget || null;
		if ( e instanceof KeyboardEvent ) {
			if ( e.altKey || e.metaKey ) {
				e.stopImmediatePropagation();
				return;
			}
		}
		if ( press ) {
			si.notify = false;
			this.selInfo = si;
			if ( logger.isTraceEnabled() ) {
				logger.trace('DOWN: new selection info', si);
			}
		} else if ( this.selInfo && (si.notify === false) ) {
			// do this only if there was already a "selInfo" object!
			si.notify = true;
			if ( logger.isTraceEnabled() ) {
				logger.trace('UP: current selection info', si);
			}
		}
		if ( et instanceof EditTarget ) {
			if ( !ctrl && !shift && et.needsArrowKey(false, dir, e) ) {
				// the current input element wants this key
				e.__psa_leave_it = true;
				if ( logger.isTraceEnabled() ) {
					logger.trace('keyboard event left unhandled due to required by current edit target.');
				}
				return;
			}
		}
		if ( press ) {
			if ( !this._isKeyScrLocked() ) {
				BscMgr.getInstance().setFocusHolder(this._bodyFocusHolder);
				this._lockSelUpd();
				const model = this.model;
				const fci = model.focusedRow;
				const cmi = (fci !== ROWID_NONE) ? model.getDataRowModelItem(fci) : null;
				const tix = this.topIndex;
				const cix = (cmi !== null) ? cmi.flatIndex : tix;
				const vc = this._getEffVisibleRows();
				let nix = -1;
				let force = false;
				if ( fci !== ROWID_NONE ) {
					nix = cix;
					if ( arrow || (vc < 3) ) {
						nix += dir ? -1 : 1;
					} else if ( page ) {
						nix += dir ? -(vc-2) : (vc-2);
					}
				} else {
					nix = tix;
					force = true;
				}
				nix = Math.max(0, Math.min(nix, model.itemCount-1));
				if ( logger.isTraceEnabled() ) {
					logger.trace(`fci=${fci}; cix=${cix}; nix=${nix}; force=${force}`);
				}
				if ( force || (nix !== cix) ) {
					// go on
					const nmi = model.getModelItem(cix, nix);
					if ( nmi instanceof MRowItem ) {
						// focus & selection
						const idr = nmi.getRowID();
						// always set the focus to the new row
						model.focusDataRow(idr);
						if ( logger.isTraceEnabled() ) {
							logger.trace(`focus set to row ${idr}; verify: ${model.focusedRow}`);
						}
						if ( !model.selectionNone ) {
							if ( shift || !ctrl ) {
								// set selection
								const single = model.selectionSingleForced || model.selectionSingle;
								if ( single || !shift || !cmi || !cmi.isSelected ) {
									// set single selection
									if ( idr !== ROWID_DUMMY ) {
										model.selectSingleRow(idr, true);
									} else if ( !model.selectionForced && !model.selectionSingleForced ) {
										model.unselectAll();
									}
								} else {
									// expand or reduce selection
									const cmi_deselect = (cmi instanceof MRowItem) ? (model.isRowSelected(cmi.idr) && model.isRowSelected(nmi.idr)) : false;
									const rows = [];
									const beg = Math.min(cix, nix);
									const end = Math.max(cix, nix);
									for ( let idx=beg ; idx <= end ; ++idx ) {
										const ri = model.getModelItem(cix, idx);
										if ( (ri instanceof MRowItem) && !ri.isGroupHead() ) {
											rows.push(ri.getRowID());
										}
									}
									if ( rows.length > 0 ) {
										model.selectRows(rows, !cmi_deselect);
									}
									if ( cmi_deselect ) {
										model.unselectSingle(cmi.idr, true);
									}
								}
							}
						}
						this._lockKeyScr();
						const scrolled = this.ensureRowIsVisible(nix, null);
						if ( et ) {
							this._editRequest = EditRequest.createInstance(et.columnId, idr, false, et.isKeepForced);
							this.clearEditTarget();
							if ( scrolled ) {
								this.log('Scrolled with active input field...');
							} else {
								this.log('Selection moved with active input field...');
							}
						} else {
							const er = this.editRequest || null;
							if ( er instanceof EditRequest ) {
								// create an updated edit request
								this._editRequest = EditRequest.createInstance(er.columnId, idr, er.insertMode, er.isKeepForced);
								if ( this.isDebugEnabled() ) {
									this.log(`Current edit request updated to ${this.editRequest.toString()}.`);
								}
								er.destroy();
							}
						}
					}
				}
			}
		} else {
			try {
				this._triggerSelUpdRelease(false);
				this._handleEditRequest();
			} finally {
				this._releaseKeyScr(true);
				BscMgr.getInstance().removeFocusHolder(this._bodyFocusHolder);
			}
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} press 
	 * @param {KeyboardEvent} e 
	 * @param {Boolean} shift 
	 * @param {Boolean} ctrl 
	 */
	onTabKey(press, e, shift, ctrl) {
		try {
			if ( !this.isRowTpl && press && !ctrl && !DomEventHelper.isProcessed(e) ) {
				const fci = this.model.focusedColumn;
				if ( fci <= 0 ) {
					// we have no column focus - we jump into the first column
					this.setCellEditor({ idc: this._getFirstColumnID(), idr: this._getCurrentDataRowID() });
				} else {
					// ok, move cell focus
					const idr = this._getCurrentDataRowID();
					const cell = this._getXCellItem(fci, idr);
					if ( cell instanceof XCellItem ) {
						// forward to cell item
						cell.onInputTab(e);
					}
				}
			}
		} finally {
			DomEventHelper.stopEvent(e);
		}
	}

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} press 
	 * @param {KeyboardEvent} e 
	 * @param {Boolean} shift 
	 * @param {Boolean} ctrl 
     * @returns {Boolean} true if the keyboard event was handled; false otherwise
	 */
    onSpaceKey(press, e, shift, ctrl) {
		let handled = false;
		if ( press && !shift && !ctrl ) {
			const et = this.editTarget;
			let editable = null;
			if ( (et instanceof EditTarget) && et.isCheckbox() ) {
				editable = et.editable;
			} else {
				const model = this.model;
				const xc = this._getXCellItem(model.focusedColumn, model.focusedRow);
				if ( (xc instanceof XCellItem) && xc.isLogicDataType ) {
					editable = xc.checkbox;
				}
			}
			if ( editable instanceof AbstractEditable ) {
				// it must be a checkbox...
				DomEventHelper.stopEvent(e);
				editable.onCheckboxSpace(e);
				handled = true;
			}
		}
		return handled;
    }

	/**
	 * @inheritdoc
	 * @override
	 * @param {Boolean} press 
	 * @param {KeyboardEvent} e 
     * @returns {Boolean} true if the keyboard event was handled; false otherwise
	 */
	onContextMenuKey(press, e) {
		if ( e instanceof Event ) {
			const tgt = e.target;
			if ( (tgt instanceof HTMLInputElement) || (tgt instanceof HTMLTextAreaElement) ) {
				// stop propagation and let the default occur - browser's context menu of input elements
				e.stopImmediatePropagation();
				// do nothing else!
				return;
			}
			if ( press ) {
				// in order to prevent RAP of stumbling in, we must stop any further propagation
				DomEventHelper.stopEvent(e);
				const model = this.model;
				const param = { idc: model.focusedColumn, idr: model.focusedRow };
				let xe = null;
				const xr = this._getXRowItem(param.idr);
				if ( xr instanceof XRowItem ) {
					const xc = this._getXCellItem(param.idc, param.idr);
					if ( xc instanceof XCellItem ) {
						xe = xc.element;
					} else {
						xe = xr.element;
					}
				}
				if ( !(xe instanceof HTMLElement) ) {
					xe = null;
					const rows = this.rowItems;
					for ( let i=rows.length-1 ; (xe === null) && (i >= 0) ; --i ) {
						const r = rows[i];
						if ( r.isValidModelItem(r.item) ) {
							xe = r.element;
						}
					}
				}
				if ( !(xe instanceof HTMLElement) ) {
					xe = tgt;
				}
				XtwUtils.getCoordinateParameters(e, xe, param);
				this.onCellContextMenu(param);
			} else {
				DomEventHelper.stopEvent(e);
			}
		}
	}

	/**
	 * called on "cell clicked" events
	 * @param {CustomEvent} e the "cell clicked" event
	 */
	onCellClicked(e) {
		if ( HtmHelper.isChildOf(e.target, this.element) ) {
			// forward to model
			this.model.onCellClicked(e);
			// check column and row IDs - if provided
			const detail = e.detail || {};
			if ( Validator.isInteger(detail.colId) && Validator.isInteger(detail.rowId) && !detail.shiftPressed ) {
				// notify web server
				const si = this.selInfo || {};
				si.focus = detail.rowId;
				si.idc = detail.colId;
				si.notify = true;
				this.selInfo = si;
				this._nfySrv('cellClicked', { idc: detail.colId, idr: detail.rowId }, false);
			}
		} else {
			this.warn('Invalid "cell clicked" event received!');
		}
	}

    /**
     * called if the column focus has changed
     * @param {XtwModel} model the data model
     * @param {Number} idc ID of the currently focused column
     * @param {Number} prev ID of the previously focused column
     */
	onColumnFocus(model, idc, prev) {
		if ( this.model === model ) {
			const fcr = model.focusedRow;
			const xri = this._getXRowItem(fcr);
			if ( xri != null ) {
				// just trigger an UI refresh
				this.triggerUIRefresh(false);
			}
		}
	}

    /**
     * called if the the row focus has changed
     * @param {XtwModel} model the data model
     * @param {Number} focus ID of currently focused row
     * @param {Number} prev ID of previously focused row
     * @param {Object} options more options
     */
	onRowFocus(model, focus, prev, options) {
		const logger = this.xSelLogger;
		if ( this.model === model ) {
			if ( this._canNfyFocus() ) {
				logger.trace('Trigger UI refresh for focus IDR=', focus);
				// trigger UI update
				const si = this.selInfo || {};
				si.focus = focus;
				si.notify = !!options.notify;
				this.selInfo = si;
				this.triggerUIRefresh(false);
			} else if ( this.selInfo ) {
				// just store the focused row
				logger.trace('Just storing focus IDR=', focus);
				this.selInfo.focus = focus;
			} else {
				logger.trace('Ignoring focus idr=', focus);
			}
			const idc = model.focusedColumn;
			if ( idc !== -1 ) {
				const xc = this._getXCellItem(idc, focus);
				if ( xc instanceof XCellItem ) {
					xc.setCellFocus();
				}
			}
		} else {
			this.warn('Invalid row focus notification!');
		}
    }

    /**
     * called if the the selection has changed
     * @param {XtwModel} model the data model
     * @param {Number[]} selected IDs of rows that were selected
     * @param {Object} options more options
     */
	onSelectionChanged(model, selected, options) {
		if ( this.model === model ) {
			if ( this._canNfySelection() ) {
				this.trace('Selection changed. Selected:', selected);
				// trigger UI update
				const si = this.selInfo || { };
				si.selected = selected;
				si.focus = this.focusIdr;
				si.notify = !!options.notify;
				this.selInfo = si;
				this.triggerUIRefresh(false);
			} else if ( this.selInfo ) {
				// just store the current selection and focus
				this.selInfo.selected = selected;
				this.selInfo.focus = this.focusIdr;
			}
		} else {
			this.warn('Invalid selection changed notification!');
		}
    }

	onCellContextMenu(parameters) {
		let now = true;
		const si = this.selInfo || { notify: false };
		if ( si.notify ) {
			const rid = this._getRequestId(UI_REFRESH_REQUEST);
			if ( UIRefresh.getInstance().hasRequest(rid) ) {
				// an UI refresh is pending!
				si.ctxMenu = true;
				si.ctxParameters = parameters;
				now = false;
			}
		}
		if ( now ) {
			// trigger context menu right now
			si.ctxMenu = false;
			this._nfySrv('contextMenu', parameters);
		}
	}

	/**
	 * triggers an UI state refresh
	 * @param {Boolean} sync flag whether to do the UI refresh synchronously
	 */
	triggerUIRefresh(sync) {
		this.checkAlive();
		if ( !this.dying ) {
			if ( sync ) {
				// do it right now
				this._updateUICallback();
			} else {
				// trigger UI update
				const rid = this._getRequestId(UI_REFRESH_REQUEST);
				if ( !UIRefresh.getInstance().hasRequest(rid) ) {
					UIRefresh.getInstance().addRequest(rid, this._updateUICallback);
				} else {
					this.trace(`Refresh request "${rid}" already pending.`);
				}
			}
		} else {
			this.log('Instance is dying. No UI state refresh scheduled.');
		}
	}

	/**
	 * called if the user has clicked a cell with a hyperlink
	 * @param {XCellItem} cell the cell
	 * @param {Number} idc column ID
	 * @param {Number} idr row ID
	 */
	onHyperlink(cell, idc, idr ) {
		if ( this.alive ) {
			if ( cell instanceof XCellItem ) {
				this.ensureCellIsVisible(cell, () => {});
			}
			const par = {};
			par.idc = idc || 0;
			par.idr = idr || 0;

			const options = {
				hsc: this.xScrollExt.hscPos,
				vsc: this.xScrollExt.vscPos,
				fcr: this.model.focusedRow
			};
	
			par.options = options;
			this._nfySrv( 'linkClicked', par, true );
		}
	}

	/**
	 * called after the user has changed the height of a row
	 * @param {Number} newRowHeight the new row height
	 */
	onRowHeight( newRowHeight, notify = true ) {
		this.nullifyBeforeRowHeightChangeUiStats();
		const previousRowHeight = this.definedRowHeight;
		if ( !this.updateRowHeightValues( newRowHeight ) ) {
			return false;
		}
		this._rmvAllDomElm();
		this._updDomElm( this.visHgt );
		this.updateWidgetVerticalSelection();
		this.forceSyncHorizontalScrolling();
		this.setCssPyjamaLines();
		if ( !notify ) {
			return true;
		}
		if ( newRowHeight < previousRowHeight ) {
			this.saveBeforeRowHeightChangeUiStats();
		}
		this._nfySrv( "tableRowHeight", { rowHeight: Math.round( newRowHeight ) }, false );
		return true;
	}

	get definedRowHeight() {
		if ( Validator.isObject( this.model ) && Validator.isPositiveNumber( this.model.defRwh ) ) {
			return Number( this.model.defRwh );
		}
		if ( Validator.isPositiveNumber( this._defRwh ) ) {
			return Number( this._defRwh );
		}
		return void 0;
	}

	get widgetVerticalSelectionTopIndex() {
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) || !Validator.isPositiveNumber( widgetVerticalSelection._selection ) ) {
			return void 0;
		}
		return Number( widgetVerticalSelection._selection );
	}

	updateWidgetVerticalSelection() {
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) || !Validator.isFunction( widgetVerticalSelection.setSelection ) || !Validator.isPositiveNumber( widgetVerticalSelection._selection ) ) {
			return false;
		}
		widgetVerticalSelection.setSelection( widgetVerticalSelection._selection );
		return true;
	}

	setWidgetVerticalSelection( selectionIndex ) {
		if ( !Validator.isPositiveNumber( selectionIndex ) ) {
			return false;
		}
		const widgetVerticalSelection = this.widgetVerticalSelection;
		if ( !Validator.isObject( widgetVerticalSelection ) || !Validator.isFunction( widgetVerticalSelection.setSelection ) ) {
			return false;
		}
		widgetVerticalSelection.setSelection( selectionIndex );
		return true;
	}

	updateRowHeightValues( newRowHeight ) {
		if ( !Validator.isPositiveNumber( newRowHeight ) ) {
			return false;
		}
		this._defRwh = newRowHeight;
		const model = this.model;
		model.setRowHeight(newRowHeight);
		const cssVariableSet = this.setCssVariable( ROW_HEIGHT_CSS_VARIABLE, `${ newRowHeight }px` );
		return cssVariableSet;
	}

	restoreBeforeRowHeightChangeUiStats() {
		if ( !Validator.isObject( this.beforeRowHeightChangeUiStats ) ) {
			return false;
		}
		let somethingSet = false;
		const verticalScrolling = this.beforeRowHeightChangeUiStats.verticalScrolling;
		if ( Validator.isPositiveNumber( verticalScrolling ) && verticalScrolling !== this.widgetVerticalSelectionTopIndex ) {
			this.setWidgetVerticalSelection( verticalScrolling );
			somethingSet = true;
		}
		const horizontalScrolling = this.beforeRowHeightChangeUiStats.horizontalScrolling;
		if ( Validator.isPositiveNumber( horizontalScrolling ) && horizontalScrolling !== this.hscPos ) {
			this._scrHorz( horizontalScrolling );
			somethingSet = true;
		}
		this.nullifyBeforeRowHeightChangeUiStats();
		return somethingSet;
	}

	saveBeforeRowHeightChangeUiStats() {
		this.beforeRowHeightChangeUiStats = {
			verticalScrolling: this.widgetVerticalSelectionTopIndex,
			horizontalScrolling: Number( this.hscPos )
		};
	}

	nullifyBeforeRowHeightChangeUiStats() {
		this.beforeRowHeightChangeUiStats = void 0;
		delete this.beforeRowHeightChangeUiStats;
	}

	/**
	 * initiates a row height adjustment operation
	 * @param {MouseEvent} e the mouse event
	 * @param {XRowItem} row the affected row
	 * @param {HTMLElement} elm the "hot" element
	 */
	onRowDrag( e, row, elm ) {
		if ( this.xtdTbl ) {
			this.xtdTbl.onRowDrag( e, row, elm );
		}
	}

	restoreUserDefinedTableRowHeight( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const rowHeight = Number( parameters.rowHeight );
		if ( !Validator.isPositiveInteger( rowHeight, false ) ) {
			return false;
		}
		this.setupModelDataCallback( "restoreUserDefinedTableRowHeight-", () => {
			this.onRowHeight( rowHeight, false );
		} );
		return true;
	}

	/**
	 * sets or clears the current "hot row"
	 * @param {Number} idr affected row ID
	 * @param {Boolean} hot flag whether the given row is considered to be the current "hot row"
	 * @param {Number} idx row item's index
	 */
	setHotRow(idr, hot, idx) {
		if ( this.alive && (idr !== ROWID_NONE) ) {
			let nfy = false;
			if ( hot && (this._hotRow !== idr) ) {
				this._hotRow = idr;
				nfy = true;
			} else if ( !hot && (this._hotRow === idr) ) {
				this._hotRow = ROWID_NONE;
				nfy = true;
			}
			if ( nfy ) {
				this._nfySrv('hotRow', { idr: idr, hot: hot }, false);
				if ( this.xtdTbl instanceof XtwTbl ) {
					if ( this._hotRow !== ROWID_NONE ) {
						let start = false;
						const vc = this._getEffVisibleRows();
						if ( (vc > 0) && (idx >= vc) && (this.xtdTbl instanceof XtwTbl) ) {
							// we're on the last visible row --> start auto scrolling
							start = true;
						}
						if ( start ) {
							// auto scrolling only downwards 
							this.trace('Triggering auto scrolling.');
							this.xtdTbl.startAutoScroll(false);
						} else {
							this.trace('Stopping auto scrolling');
							this.xtdTbl.stopAutoScroll();
						}
					}
				}
			}
			if ( nfy && (this._hotRow !== ROWID_NONE) ) {
				const vc = this._getEffVisibleRows();
				if ( (vc > 0) && (idx >= vc) && (this.xtdTbl instanceof XtwTbl) ) {
					// we're on the last row --> auto scrolling only downwards 
					this.xtdTbl.startAutoScroll(false);
				}
			}
		}
	}

	
	/**
	 * one time initialization
	 * @param {Object} fnt the default body font
	 */
	_init( fnt ) {
		const elm = this.tblBody;
		if ( fnt ) {
			ItmMgr.getInst().setFnt( elm, fnt );
		}
		let vgc = null;
		let hgc = null;
		if ( this.xtdTbl ) {
			vgc = this.xtdTbl.clrVtg || null;
			hgc = this.xtdTbl.clrHzg || null;
		}
		this.clrVtg = vgc;
		this.clrHzg = hgc;
	}

	/**
	 * adjusts the basic layout
	 */
	_basicLayout() {
		if ( !this.ready ) {
			return;
		}
		const area = this.parent.getClientArea();
		const wdt = area[ 2 ] || 0;
		const hgt = area[ 3 ] || 0;
		const ohg = this.visHgt;
		this.tblBody.style.left = '0';
		this.tblBody.style.top = '0';
		this.tblBody.style.width = wdt + 'px';
		this.rowContainer.style.width = wdt + 'px';
		if ( this.isRowTpl ) {
			this.tblBody.style.height = hgt + 'px';
			this.rowContainer.style.height = hgt + 'px';
			this.visHgt = hgt;
		} else {
			this.setTableBodyHeight( hgt );
		}
		this.visWdt = wdt;
		if ( this.tblBody.parentElement instanceof HTMLElement ) {
			HtmHelper.removeStyleProperty( this.tblBody.parentElement, "top" );
		}
		this._updDomElm( hgt );
		this._updateRowsUI();
		this.renderToggleButton();
		if ( this.isTraceEnabled() && ( hgt > ohg ) ) {
			this.trace( `XTW ${this.wdgId} - height increased from ${ohg}px to ${hgt}px.` );
		}
	}

	/**
	 * attaches special event handlers
	 */
	_attachEventHandlers() {
		const self = this;
		const tblBody = this.tblBody;
		const parentElement = tblBody.parentElement;
		if ( parentElement instanceof HTMLElement ) {
			new ExternalEventsTransferManager( this, parentElement );
			parentElement.classList.add( "xtwbody-container" );
			parentElement.classList.remove( "rtp-body-container" );
			if ( parentElement.dataset instanceof DOMStringMap ) {
				parentElement.dataset.class = "XtwBody container";
				parentElement.dataset.idstr = this.wdgIdStr;
			}
		}
		this.addContextMenuListener();
		this.addCustomEventHandlers();
		tblBody.addEventListener('mousedown', (evt) => self._stopMouseEvent(evt), false);
		tblBody.addEventListener('mouseup', (evt) => self._stopMouseEvent(evt), false);
		tblBody.addEventListener('paste', (ce) => self._onClipboardPaste(ce), false);

		// create special <a> tag to trigger clipboard read access
		const clp_tag = document.createElement('a');
		clp_tag.href = '#';
		clp_tag.style.display = 'none';
		clp_tag.addEventListener('click', (e) => self._onClipboardTrigger(e), true);
		tblBody.appendChild(clp_tag);
		this._clpTrigger = clp_tag;

		// hook in RAP drag'n'drop handling
		const rap_wdg = rwt.remote.ObjectRegistry.getObject(this.wdgId);
		if ( rap_wdg && (typeof rap_wdg.addEventListener === 'function') ) {
			rap_wdg.addEventListener('dragout', (re) => self._onDragOut(re), self);
			rap_wdg.addEventListener('dragdrop', (re) => self._onDragDrop(re), self);
			rap_wdg.addEventListener('dragend', (re) => self._onDragEnd(re), self);
		}
	}

	/**
	 * called if a drag'n'drop operation leaves this instance
	 * @param {*} re a RAP event describing the drag operation
	 */
	_onDragOut(re) {
		this._stopAutoScroll();
	}

	/**
	 * called if drag'n'drop data were dropped
	 * @param {*} re a RAP event describing the drag operation
	 */
	_onDragDrop(re) {
		this._stopAutoScroll();
	}

	_stopAutoScroll() {
		if ( this.xtdTbl instanceof XtwTbl ) {
			this.trace('Auto scrolling forced to stop.');
			this.xtdTbl.stopAutoScroll();
		}
	}

	/**
	 * called after a drag'n'drop operation was finished
	 * @param {*} re a RAP event describing the 
	 */
	_onDragEnd(re) {
		if ( this.alive ) {
			try {
				const de = re.getDomEvent();
				if ( de instanceof Event ) {
					const dt = de.target;
					const tbody = this.tblBody;
					if ( (dt instanceof HTMLElement) && HtmHelper.isSameOrChildOf(dt, tbody) ) {
						let elm = dt;
						let row = null;
						while ( elm && !row && (elm !== tbody) ) {
							if ( elm.classList.contains(XROWITEM_CLASS) ) {
								row = elm;
								elm = null;
							} else {
								elm = elm.parentElement;
							}
						}
						if ( row != null ) {
							const mi = row.__mi;
							if ( mi instanceof MRowItem ) {
								// hit!
								// we must *set* this value and then trigger a notification in order to force RAP to send a request to the backend
								const par = { idr: mi.idr };
								rap.getRemoteObject(this).set('dropRow', par);
								this._nfySrv('dropRow', par, false);
							}
						}
				}
				}
			} catch ( err ) {
				this.warn('ERROR handling drag end:', err);
			}
		}
	}

	/**
	 * 
	 * @param {MouseEvent} evt the mouse event
	 */
	_stopMouseEvent(evt) {
		if ( this.editTarget instanceof EditTarget ) {
			// we have an active editor!
			evt.stopImmediatePropagation();
		}
	}

	/**
	 * updates flap settings
	 * @param {Object} cwd custom widget data
	 */
	_setFlapSettings( cwd ) {
		const fs = this.flapSettings;
		fs.canSort = !!cwd.canSort;
		fs.clrBkgNormal = cwd.flapClrBkgNormal || null;
		fs.clrTxtNormal = cwd.flapClrTxtNormal || null;
		fs.clrBkgActive = cwd.flapClrBkgActive || null;
		fs.clrTxtActive = cwd.flapClrTxtActive || null;
		fs.txtSortLabel = cwd.flapSortLabel || 'Order by:'
		fs.txtNotSorted = cwd.flapNotSortedText || '&lt;not sorted&gt;';
		const nsc = cwd.noSortCols || '';
		if ( Validator.isString( nsc ) ) {
			const set = this.noSortCols;
			nsc.split( ',' ).forEach( ( cn ) => {
				set.add( cn );
			} );
		}
	}

	/**
	 * updates the sort flap
	 * @param {Boolean} rtp row template flag
	 */
	_updateSortFlap( rtp ) {
		if ( rtp && this.flapSettings.canSort ) {
			if ( !this.sortFlap ) {
				// create sort flap
				const sf = new XtwSortFlap( this, this.tblBody, this.flapSettings );
				this.sortFlap = sf;
			}
		} else {
			// drop sort flap if present
			if ( this.sortFlap ) {
				const sf = this.sortFlap;
				this.sortFlap = null;
				sf.destroy();
			}
		}
	}

	_getRowTpl() {
		let rtp = null;
		if ( this.xtdTbl && this.xtdTbl.hasRowTpl ) {
			rtp = this.xtdTbl.getRowTpl();
		}
		return rtp;
	}

	/**
	 * calculates the page scroll distance
	 * @param {Boolean} up direction flag
	 * @param {Boolean} wheel flag whether a "mouse wheel" event is being processed
	 * @returns {Number} the page scroll distance in pixels
	 */
	 getPageScrollDist( up, wheel ) {
		// forward to data model
		const cnt = wheel ? Math.min( this.rowItems.length, 1 ) : this.rowItems.length;
		return this.model.getPageScrollDist( up, this.xScrollExt.topIdx, cnt, this.isRowTpl );
	}

	forceSyncHorizontalScrolling() {
		this.xScrollExt._scrHorz( this.xScrollExt.rquHsc );
		return true;
	}

	forceSyncHorizontalScrollbar() {
		const widgetHorizontalSelection = this.xScrollExt.widgetHorizontalSelection;
		if ( !Validator.isObject( widgetHorizontalSelection ) ) {
			return false;
		}
		let operationCount = 0;
		if ( Validator.isFunction( widgetHorizontalSelection.setSelection ) ) {
			widgetHorizontalSelection.setSelection( widgetHorizontalSelection._selection );
			operationCount++;
		}
		if ( Validator.isFunction( widgetHorizontalSelection._renderThumb ) ) {
			widgetHorizontalSelection._renderThumb();
			operationCount++;
		}
		return operationCount == 2;
	}

    /**
     * scrolls the table view
     * @param {boolean} dist scrolling distance: true: a page; false: a single line
     * @param {boolean} dir scrolling direction: true: upwards; false: downwards
	 * @override
     */
	scroll(dist, dir) {
		this.log(`Keyboard scrolling: ${dist} / ${dir}.`);
		// TODO: page scroll
		this.xtdTbl.triggerVScroll(1, dir, false);
    }

	/**
	 * scrolls the view vertically be the specified number of rows
	 * @param {Number} rows rows to scroll
	 * @param {Number} tix the new top index
	 */
	scrollBy(rows, tix) {
		if ( rows !== 0 ) {
			this.log(`Triggering scrolling by ${rows} rows.`);
			const et = this.editTarget;
			if ( et instanceof EditTarget ) {
				et.applyChanges();
				this._createEditRequest(true, 'scroll');
			}
			// make sure, that model items are valid
			this._fetchData(() => {
				// and update the view
				this._doScrollBy(rows);
			});
		}
	}

	/**
	 * internal callback method, scrolls the view vertically
	 * @param {Number} rows rows to scroll
	 */
	_doScrollBy(rows) {
		// const self = this;
		const et = this.editTarget;
		const keep_et = (et instanceof EditTarget) && et.isKeepForced;
		if ( keep_et ) {
			et.lock();
		}
		try {
			this._doScrollBy2(rows, !keep_et);
		} finally {
			if ( keep_et && et.alive ) {
				et.release();
			}
		}
	}

	/**
	 * internal callback method, scrolls the view vertically - runs "unconnected"
	 * @param {Number} rows rows to scroll
	 * @param {Boolean} he flag whether to handle a pending edit request
	 */
	_doScrollBy2(rows, he) {
		try {
			this.log(`DO SCROLL BY: Performing scrolling by ${rows} rows.`);
			const orc = this.rowItems.length;
			this._trimRowItems();
			const nrc = this.rowItems.length;
			let dr = [];
			let append = false;
			if ( rows > 0 ) {
				// scrolling downwards
				append = true;
				// drop first row items
				dr = this.rowItems.splice(0, rows);
				// update row indices
				this._updateRowIndices();
			} else {
				// scrolling upwards
				append = false;
				// drop last rows
				dr = this.rowItems.splice(rows, -rows);
			}
			if ( dr.length > 0 ) {
				dr.forEach((xr) => xr.destroy());
			}
			this._createXRowItems(nrc, append);
			if ( nrc < orc ) {
				this._createXRowItems(orc, true);
			}
			// force an update of all row items
			this._setupRowItems();
			this._updateRowsUI();
			if ( he && !this._isKeyScrLocked() ) {
				// handle edit request if required
				this._handleEditRequest();
			}
			if ( this._isSelUpdLocked() ) {
				this._triggerSelUpdRelease(true);
			}
		} finally {
			this._releaseKeyScr(false);
		}
	}

	/**
	 * restores vertical scroll position
	 * @param {Number} tix previous top index
	 * @param {Number} sx previous horizontal scroll position
	 * @param {Number} sy previous vertical scroll position
	 */
	_restoreVScroll(tix, sx, sy) {
		const vrc = this._getEffVisibleRows();
		const mic = this.model.getItemCount();
		const pos = Math.max(Math.min(sy, mic - vrc), 0);
		if ( this.isDebugEnabled() ) {
			this.log(`Restoring vertical scroll position. previous=${sy}, new=${pos}, tix=${tix}.`);
		}
		this.xScrollExt.reset();
		this.xScrollExt.triggerScrollUpd(sx, pos);
	}
	
	/**
	 * fixes a row item; there's sometimes a situation that the model is rebuilt while
	 * UI should be updated; thus a row item may still refer to a dead model item while
	 * a new model item is already available
	 * @param {XRowItem} xr the row item
	 */
	_fixXRowItem(xr) {
		const mi = xr.item;
		if ( (mi instanceof MRowItem) && !mi.alive ) {
			// this may be the case if there's a new model that's not yet fully processed
			const new_mi = this.model.getModelItem(0, mi.flatIndex);
			if ( (new_mi instanceof MRowItem) && new_mi.alive ) {
				// lucky us we git a valid model item :-)
				xr.setModelItem(this.xtwHead, new_mi, false);
			}
		}
	}

	/**
	 * retrieves an UI row item
	 * @param {MRowItem | Number} row a data row item or a row ID
	 * @returns {XRowItem} the corresponding UI row item or null
	 */
	_getXRowItem(row) {
		let xr = null;
		let rix = -1;
		if ( row instanceof MRowItem ) {
			rix = row.flatIndex;
		} else {
			const eff_row = (typeof row === 'number') ? row : Number.parseInt(row);
			if ( !Number.isNaN(eff_row) ) {
				const mr = this.model.getDataRowModelItem(eff_row);
				if ( mr instanceof MRowItem ) {
					rix = mr.flatIndex;
				}
			}
		}
		if ( rix !== -1 ) {
			rix -= this.xScrollExt.topIdx;
			if ( (rix >= 0) && (rix < this.rowItems.length) ) {
				xr = this.rowItems[rix];
			}
		}
		return xr;
	}

	/**
	 * retrieves an UI cell item
	 * @param {String | Number} col column ID
	 * @param {MRowItem | Number} row a data row item or a row ID
	 * @returns {XCellItem} the UI cell item or null
	 */
	_getXCellItem(col, row) {
		const xr = this._getXRowItem(row);
		if ( xr instanceof XRowItem ) {
			return xr.getCell(col);
		}
		return null;
	}

	/**
	 * retrieves the number of effectively visible row items;
	 * see also de.pisa.webcli.cstwdg.xtdtbl.impl.XtwTbl._updVertScbRng()
	 * @returns {Number} the number of effectively visible row items
	 */
	_getEffVisibleRows() {
		// we have one row item "in spare", and we don't want partially visible items, so we subtract 2
		return Math.max(0, this.rowItems.length - ROW_ITEMS_SPARE);
	}

	/**
	 * retrieves the first visible column
	 * @returns {Number} the ID of the first visible column
	 */
	_getFirstColumnID() {
		if ( this.alive && this.xtwHead ) {
			const col = this.xtwHead.firstVisibleColumn;
			if ( col instanceof XtwCol ) {
				return col.id;
			}
		}
		return 0;
	}

	/**
	 * returns the ID of the current column; that is the ID of the currently focused column, if available; otherwise
	 * the ID of the first visible data column is returned
	 * @returns {Number} the ID of the current column
	 */
	_getCurrentColumnID() {
		const idc = this.model.focusedColumn;
		return (idc > 0) ? idc : this._getFirstColumnID();
	}
	
	/**
	 * retrieves the DI of the first visible data row
	 * @returns {Number} the ID of the first visible data row
	 */
	_getFirstDataRowID() {
		const cnt = this.rowItems.length;
		for ( let i=0 ; i < cnt ; ++i ) {
			const xr = this.rowItems[i];
			if ( xr instanceof XRowItem ) {
				const mi = xr.item;
				if ( (mi instanceof MRowItem) && mi.alive && mi.isDataRow() ) {
					return mi.idr;
				}
			}
		}
		return ROWID_NONE;
	}

	/**
	 * returns the ID of the current data row; that is the ID of the currently focused data row, if available;
	 * otherwise the ID of the first data row is returned
	 * @returns {Number} the ID of the current data row
	 */
	_getCurrentDataRowID() {
		const idr = this.model.focusedRow;
		return (idr != ROWID_NONE) ? idr : this._getFirstDataRowID();
	}

	/**
	 * drops the current edit target (if there's one)
	 */
	_dropEditTarget() {
		const et = this.editTarget;
		if ( et instanceof EditTarget ) {
			try {
				this.debugStackTrace('DROP edit target');
				et.restoreLock(0);
				et.destroySelfAndRestoreCell();
			} finally {
				this.removeEditTarget(et);
			}
		}
	}

	/**
	 * updates the current edit target and created an edit request if specified
	 * @param {Boolean} create flag whether to create an edit request from current edit target
	 * @param {String} operation name of operation (DEBUG output only)
	 */
	_createEditRequest(create, operation ) {
		this.clearEditRequest();
		const et = this.editTarget;
		if ( et instanceof EditTarget ) {
			et.informAboutContentChange();
			if ( create ) {
				const er = et.createEditRequest();
				er.deferred = true;
				this._editRequest = er;
				this._dropEditTarget();
				if ( this.isDebugEnabled() ) {
					this.debug(`Triggering "${operation}" operation with edit request ${er.toString()}.`);
				}
			}
		}
	}

	/**
	 * handles an edit request after a scrolling and/or key navigation operation
	 */
	_handleEditRequest() {
		try {
			const er = this.editRequest;
			if ( (er instanceof EditRequest) && er.valid ) {
				const model = this.model;
				const row = model.getDataRowModelItem(er.rowId);
				if ( row instanceof MRowItem ) {
					const xc = this._getXCellItem(er.columnId, row);
					if ( xc instanceof XCellItem ) {
						if ( this.isDebugEnabled() ) {
							this.log(`Edit request for cell ${er.columnId}@${er.rowId}.`);
						}
						this.clearEditTarget();
						xc.createAndFocusInputField(null);
						this._nfySrv('cellClicked', { idc: xc.idc, idr: row.idr }, false);
					}
				}
			}
		} finally {
			this.clearEditRequest();
		}		
	}

	/**
	 * directly applies the give edit request
	 * @param {EditRequest} er the edit request
	 */
	_applyEditRequest(er) {
		try {
			if ( (er instanceof EditRequest) && er.valid ) {
				if ( this.isDebugEnabled() ) {
					this.log('Applying edit request:', er.toString());
				}
				const args = { idc: er.columnId, idr: er.rowId, mode: er.insertMode, openDropdown: er.dropdown, keep: er.isKeepForced };
				if ( this.isDebugEnabled() ) {
					this.debug(`calling this.setCellEditor(${JSON.stringify(args)}).`);
				}
				er.invalidate();
				this.setCellEditor(args);
			}
		} finally {
			this.clearEditRequest();
		}
	}

	/**
	 * checks whether vertical scrolling is possible
	 * @param {Number} dist the scrolling distance
	 * @param {Boolean} up direction flag
	 * @returns {Boolean} true if scrolling is possible; false otherwise
	 */
	canScrollBy(dist, up) {
		if ( !this.alive ) {
			return false;
		}
		if ( (this.rowItems.length - ROW_ITEMS_SPARE) > this.model.getItemCount() ) {
			// no scrolling in this case!
			return false;
		}
		// no further checks so far
		return true;
	}

	/**
	 * called on mouse wheel events
	 * @param {MouseEvent} e the mouse wheel event
	 */
	onTblMouseWheel( e ) {
		this.xScrollExt.onTblMouseWheel(e);
	}

	/**
	 * called if the user scrolls vertically
	 * @param {Number} pos current scrolling position
	 */
	onVScroll( pos ) {
		if ( this.visHgt && ( this.xScrollExt.vscPos !== pos ) ) {
			this.log(`VSCROLL request: scrolling to position ${pos}.`);
			this._setLastScrollPos(null, pos);
			this.xScrollExt.triggerScrollUpd( this.xScrollExt.hscPos, pos );
		}
	}

	/**
	 * called if the user scrolls horizontally
	 * @param {Number} pos current scrolling position
	 */
	onHScroll( pos ) {
		if ( this.isRowTpl ) {
			return;
		}
		if ( this.visWdt && ( this.xScrollExt.hscPos !== pos ) ) {
			this.log(`HSCROLL request: scrolling to position ${pos}.`);
			this._setLastScrollPos(pos, null);
			this.xScrollExt.triggerScrollUpd( pos, this.xScrollExt.vscPos );
		}
	}

	/**
	 * forces the last scrolling position to have the give value
	 * @param {Boolean} vert flag whether to force the last vertical (true) or horizontal (false) scrolling position
	 * @param {Number} pos new value
	 */
	forceLastScrollPos(vert, pos) {
		this._setLastScrollPos(vert ? null : pos, vert ? pos : null);
	}

	/**
	 * resets the horizontal scrolling position so that full refresh is forced
	 * the next time a horizontal scrolling is triggered
	 */
	resetHScroll() {
		if ( this.alive && !this.isRowTpl ) {
			this.xScrollExt.resetHScroll();
		}
	}

	onHorizontalScrollbarHidden() {
		this.onHScroll(0);
		return true;
	}

	onHorizontalScrollbarShown() {
		return 0;
	}

	onVerticalScrollbarHidden() {
		this.onVScroll(0);
		return true;
	}

	onVerticalScrollbarShown() {
		return true;
	}

	/**
	 * called by table widget XtwTbl after a scrollbar was clicked
	 */
	afterSBClick() {
		if ( this.alive ) {
			const et = this.editTarget;
			if ( et instanceof EditTarget ) {
				et.setFocus();
			}
		}
	}

	_setLastScrollPos(cx, cy) {
		if ( this._lastScrollPos == null ) {
			this._lastScrollPos = new JsPoint(0, 0);
			// we invalidate both values here!
			this._lastScrollPos.x = null;
			this._lastScrollPos.y = null;
		}
		if ( Number.isFinite(cx) ) {
			this._lastScrollPos.x = cx;
		}
		if ( Number.isFinite(cy) ) {
			this._lastScrollPos.y = cy;
		}
	}

	/**
	 * calculates the effective scrolling height
	 * @param {Number} hgt height in pixels of all visible model items
	 * @returns {Number} the required scrolling height in pixels
	 */
	_getEffScrHeight( hgt ) {
		let eff_hgt = hgt;
		if ( hgt > this.visHgt ) {
			const rtp = this.isRowTpl;
			const rh = rtp ? this.rtpRwh : this.defRwh;
			eff_hgt += rh;
		}
		return eff_hgt;
	}

	/**
	 * calculates the number of visible DOM items
	 * @param {Number} hgt total height in pixels
	 * @param {Boolean} rtp row template flag
	 * @returns {Number} the number of DOM items that are required to cover the whole body area
	 */
	_getDomElmCnt( hgt, rtp ) {
		const tix = Math.max( this.xScrollExt.topIdx, 0 );
		const lim = this.model.getItemCount();
		const vp = rtp ? this.rtpVPad : 0;
		const drh = rtp ? ( this.rtpRwh + vp ) : this.defRwh;
		let cnt = 0;
		let shg = 0;
		while ( shg < hgt ) {
			const idx = tix + cnt;
			if ( idx < lim ) {
				// use exact height of model item
				const modelItem = this.model.getModelItem( tix, idx );
				if ( !rtp || modelItem.isGroupHead() ) {
					shg += modelItem.getHeight();
				} else {
					shg += drh;
				}
			} else {
				// beyond the data model - use default row height
				shg += drh;
			}
			++cnt;
		}
		if ( shg > hgt ) {
			++cnt;
		}
		return Math.max( cnt, 1 );
	}

	/**
	 * updates the visible row items so that the whole area is covered
	 * @param {Number} hgt total height in pixels
	 */
	_updDomElm( hgt ) {
		this.setTableBodyHeight( hgt );
		if ( this.rowContainer ) {
			const rtp = this.isRowTpl;
			if ( rtp && !this.idManager ) {
				delete this.rcells;
				delete this.sortedColumns;
				const idm = new XtwRtpIdManager( this, true );
				this.idManager = idm;
				idm.initialize();
			}
			this._adjustRowsToMatchDisplay( hgt );
		}
	}

	/**
	 * @deprecated
	 * @param {Function} f function to be called
	 */
	doWithFrozenFocus(f) {
		console.warn(`Deprecated method called!`, Warner.getFullFunctionName());
		return f();
	}

	_adjustRowsToMatchDisplay( height ) {
		if ( !Validator.isValidNumber( height ) ) {
			return false;
		}
		const desiredFinalNumberOfRowItems = this._getDomElmCnt( height, this.isRowTpl );
		if ( desiredFinalNumberOfRowItems === this.rowItems.length ) {
			return true;
		}
		try {
			if ( desiredFinalNumberOfRowItems < this.rowItems.length ) {
				return this._removeXRowItems( desiredFinalNumberOfRowItems );
			}
			// numberOfRowItems > this.rowItems.length
			return this._createXRowItems( desiredFinalNumberOfRowItems, true );
		} finally {
			const par = { count: desiredFinalNumberOfRowItems };
			this._nfySrv( 'visibleItems', par, false );
		}
	}

	_removeXRowItems( desiredFinalNumberOfRowItems ) {
		if ( !Validator.isValidNumber( desiredFinalNumberOfRowItems ) ) {
			return false;
		}
		while ( this.rowItems.length > desiredFinalNumberOfRowItems ) {
			const rowItem = this.rowItems.pop();
			rowItem.destroy();
		}
		return true;
	}

	_createXRowItems( numberOfRowItems, append = true ) {
		if ( !Validator.isValidNumber( numberOfRowItems ) ) {
			return false;
		}
		const rowContainer = this.rowContainer;
		const isRowTemplate = this.isRowTpl;
		const topIndex = Math.max( this.xScrollExt.topIdx, 0 );
		const modelItemCount = this.model.getItemCount();
		const defaultRowHeight = isRowTemplate ? this.rtpRwh : this.defRwh;
		if ( this.isDebugEnabled() ) {
			const to_create = numberOfRowItems - this.rowItems.length;
			this.log(`Creating ${to_create} row items; append=${append}; tix=${topIndex}; mic=${modelItemCount}`);
		}
		if ( append ) {
			while ( this.rowItems.length < numberOfRowItems ) {
				const index = this.rowItems.length;
				const modelIndex = topIndex + index;
				const modelItem = modelIndex < modelItemCount ? this.model.getModelItem( topIndex, modelIndex ) : null;
				const rowHeight = modelItem && ( !isRowTemplate || modelItem.isGroupHead() ) ? modelItem.getHeight() : defaultRowHeight;
				const xRowItem = new XRowItem( this, index, rowHeight, isRowTemplate, this.rtpRwh );
				this.rowItems.push( xRowItem );
				rowContainer.appendChild( xRowItem.getDomElement() );
				this._updRowItm( xRowItem );
			}
		} else {
			const count = numberOfRowItems - this.rowItems.length;
			if ( count > 0 ) {
				const new_items = [];
				for ( let index=0 ; index < count ; ++index ) {
					const modelIndex = topIndex + index;
					const modelItem = modelIndex < modelItemCount ? this.model.getModelItem( topIndex, modelIndex ) : null;
					const rowHeight = modelItem && ( !isRowTemplate || modelItem.isGroupHead() ) ? modelItem.getHeight() : defaultRowHeight;
					const xRowItem = new XRowItem( this, index, rowHeight, isRowTemplate, this.rtpRwh );
					new_items.push(xRowItem);
				}
				// insert newly created row items at the beginning of the array...
				this.rowItems.splice(0, 0, ...new_items);
				// ...insert the DOM elements at top of the row container...
				for ( let i=new_items.length -1 ; i >= 0 ; --i ) {
					rowContainer.insertBefore(new_items[i].getDomElement(), rowContainer.firstChild);
				}
				// ...and update the indices of the other elements...
				this._updateRowIndices();
				// ...and update the new row items
				const self = this;
				new_items.forEach((xr) => self._updRowItm(xr));
			}

		}
		return true;
	}

	/**
	 * updates the indices of all row items
	 */
	_updateRowIndices() {
		const ri = this.rowItems;
		for ( let index=ri.length-1 ; index >= 0 ; --index ) {
			ri[index].idx = index;
			ri[index]._setDebugInfo();
		}
	}

	/**
	 * drops all trailing row items with no model item
	 */
	_trimRowItems() {
		const ri = this.rowItems;
		const rc = ri.length;
		let stop = false;
		let dr = [];
		for ( let i=rc-1 ; !stop && (i >= 0) ; --i ) {
			const xr = ri[i];
			if ( !(xr.item instanceof MRowItem) ) {
				dr.push(xr);
			} else {
				stop = true;
			}
		}
		if ( dr.length > 0 ) {
			ri.splice(-dr.length, dr.length);
			dr.forEach((xr) => xr.destroy());
		}
	}

	isValidModelItem( modelItem ) {
		return [ "MGroup", "MDataRow", "MRowItem" ].some( className => Validator.is( modelItem, className ) );
	}

	/**
	 * removes all DOM items
	 */
	_rmvAllDomElm() {
		while ( this.rowItems.length > 0 ) {
			const ri = this.rowItems.pop();
			ri.destroy();
		}
	}

	/**
	 * creates a render request ID from a name
	 * @param {String} name the actual request name
	 * @returns the render request ID
	 */
	_getRequestId(name) {
		return this.wdgId + '-' + name;
	}

	/**
	 * locks scrolling by arrow key
	 */
	_lockKeyScr() {
		++this.keyScrLocked;
	}

	/**
	 * releases scrolling by arrow keys
	 */
	_releaseKeyScr(force = false) {
		if ( force ) {
			this.keyScrLocked = 0;
		} else if ( this.keyScrLocked > 0 ) {
			--this.keyScrLocked;
		}
	}

	/**
	 * @returns {Boolean} true if scrolling by arrow keys is currently locked; false otherwise
	 */
	_isKeyScrLocked() {
		return this.keyScrLocked > 0;
	}

	/**
	 * checks whether a notification flag is set
	 * @param {Number} flag the flag to be checked
	 * @returns {Boolean} if the specified flag is set; false otherwise
	 */
	_hasNfyFlag(flag) {
		return (this._nfySuppressed & flag) === flag;
	}

	/**
	 * sets or clears a notification flag
	 * @param {Number} flag the flag to be set or cleared
	 * @param {Boolean} set indicates whether the given flag should be set (true) or cleared (false)
	 */
	_setNfyFlag(flag, set) {
		if ( set ) {
			this._nfySuppressed |= flag;
		} else {
			this._nfySuppressed &= ~flag;
		}
	}

	/**
	 * @returns {Boolean} true if focus notifications are allowed; false otherwise
	 */
	_canNfyFocus() {
		return !this._hasNfyFlag(NO_NFY_FOCUS);
	}

	/**
	 * @returns {Boolean} true if selection notifications are allowed; false otherwise
	 */
	_canNfySelection() {
		return !this._hasNfyFlag(NO_NFY_SELECTION);
	}

	/**
	 * sets or clears the "no focus notification" flag
	 * @param {Boolean} set indicates whether to set or clear the flag
	 */
	_setNoNfyFocus(set) {
		this._setNfyFlag(NO_NFY_FOCUS, set);
	}

	/**
	 * sets or clears the "no selection notification" flag
	 * @param {Boolean} set indicates whether to set or clear the flag
	 */
	_setNoNfySelection(set) {
		this._setNfyFlag(NO_NFY_SELECTION, set);
	}

	/**
	 * @returns {Number} the effective horizontal scrolling position
	 */
	_getEffHsp() {
		return Math.max(this.xScrollExt.hscPos, 0);
	}

	/**
	 * updates the UI of all rows
	 */
	_updateRowsUI() {
		this.setCssPyjamaLines();
		const hsp = this._getEffHsp();
		this.rowItems.forEach( (ri) => ri.updateUIStatus(hsp) );
	}

	/**
	 * updates the UI according to current row focus and selection state
	 */
	_updateUIState() {
		if ( this.alive ) {
			try {
				this.log('update UI state callback function');
				this._updateRowsUI();
				if ( !this._isSelUpdLocked() ) {
					if ( this.selInfo && (this.selInfo.notify !== false) ) {
						// notify web server
						const si = this.selInfo;
						this.selInfo = null;
						const par = {};
						par.idc = Validator.isPositiveInteger(si.idc) ? si.idc : -1;
						par.foc = Validator.isInteger(si.focus) ? si.focus : -1;
						if ( typeof si.selected !== 'undefined' ) {
							par.sel = si.selected || [];
							par.selChanged = true;	// inform backend that a selection change occurred
						} else {
							par.sel = [];			// backend needs this!
							par.selChanged = false;	// inform backend that NO selection change occurred
						}
						this.xSelLogger.trace('Sending "selChange" notification:', par);
						this._nfySrv('selChange', par);
						if ( (si.ctxMenu === true) && si.ctxParameters ) {
							this._nfySrv('contextMenu', si.ctxParameters);
						}
					} else {
						this.xSelLogger.trace('No selection info available. No notification is sent.');
					}
				} else {
					this.xSelLogger.trace('selection update locked - no selection sent.');
				}
				this._doAfterScrollViewUpdate();
			} finally {
				this._releaseKeyScr(false);
			}
		}
	}

	/**
	 * ensures that all row items have an up-to-date reference to the correct model item
	 */
	_setupRowItems() {
		const tix = Math.max( this.xScrollExt.topIdx, 0 );
		const count = this.rowItems.length;
		const model = this.model;
		const xth = this.xtwHead;
		for ( let i=0 ; i < count ; ++i ) {
			const idx = tix + i;
			const ri = this.rowItems[i];
			ri.idx = i;
			const mi = model.getModelItem(tix, tix + i);
			ri.setModelItem(xth, mi, true);
		}
	}

	/**
	 * updates a row item
	 * @param {XRowItem} ri the row item to be updated
	 */
	_updRowItm( ri ) {
		const tix = this.xScrollExt.topIdx;
		const hsp = this._getEffHsp();
		const mix = tix + ri.getIdx();
		const modelItem = this.model.getModelItem( tix, mix );
		ri.setModelItem( this.xtwHead, modelItem, false );
		ri.updateUIStatus(hsp);
	}

	/**
	 * updates all row items
	 * @param {Function} cf callback function, may be null
	 */
	_updAllRowItems(cf) {
		const self = this;
		this._fetchData( () => {
			// update all row items
			self._updAllRowItems2(cf);
		} );
	}
	
	/**
	 * updates all row items, stage 2
	 * @param {Function} cf callback function, may be null
	 */
	_updAllRowItems2(cf) {
		const self = this;
		UIRefresh.getInstance().addRequest(this._getRequestId(UI_ROWUPDATE_REQUEST), () => {
			self._updAllRowItems3(cf);
		});
	}

	/**
	 * updates all row items, stage 3
	 * @param {Function} cf callback function, may be null
	 */
	_updAllRowItems3(cf) {
		if ( this.alive ) {
			// const self = this;
			const et = this.editTarget;
			try {
				try {
					if ( et instanceof EditTarget ) {
						et.lock();
					}
					this._updAllRowItems4();
				} finally {
					this._releaseKeyScr(false);
					this._releaseEditTarget();
				}
			} finally {
				if ( cf ) {
					cf();
				}
			}
		}
	}

	/**
	 * updates all row items, stage 4
	 */
	_updAllRowItems4() {
		const self = this;
		this.rowItems.forEach( ( ri ) => self._updRowItm( ri ) );
		this._doAfterModelData();
	}

	/**
	 * releases (unlocks) the current edit target
	 */
	_releaseEditTarget() {
		const et = this.editTarget;
		if ( et instanceof EditTarget ) {
			et.release();
			const row = this.model.getDataRowModelItem(et.rowId);
			if ( !(row instanceof MRowItem) ) {
				et.destroySelfAndRestoreCell();
			}
		}
	}

	/**
	 * fetches rows from model
	 * @param {Number} tix top index
	 * @param {Number} cnt number of rows to be fetched
	 * @param {Function} cf callback function to be called on completion
	 */
	_fetchRows(tix, cnt, cf) {
		const rqu_lst = this.model.fetchData(tix, cnt);
		if ( rqu_lst !== null ) {
			// we must send a request to the web server
			this._fetchTriggerRqu( rqu_lst, cf );
		} else {
			// immediate update possible
			cf();
		}

	}

	/**
	 * fetches data from model to be displayed in the UI; this may require a "data request" to be sent to the web server
	 * @param {Function} cf callback function to be called on completion
	 */
	_fetchData( cf ) {
		this._fetchRows(this.xScrollExt.topIdx, this.rowItems.length, cf);
	}

	/**
	 * fetches all rows from backend
	 * @param {Boolean} flag whether to update all row items
	 * @param {Function} cf callback function to be called on completion
	 */
	_fetchAll(upd, cf) {
		const model = this.model;
		const self = this;
		this._fetchRows(0, model.itemCount, () => {
			if ( upd ) {
				// update all row items
				self._updAllRowItems2(cf);
			} else if ( cf ) {
				// call callback function immediately
				cf();
			}
		} );
	}

	/**
	 * triggers a fetch request
	 * @param {Array} rqu_lst fetch request list
	 * @param {Function} cf callback function to be called on completion
	 */
	_fetchTriggerRqu( rqu_lst, cf ) {
		if ( !this.fetchRqu ) {
			const id = ++this.nextFetchID;
			const frq = {};
			frq.cf = cf;
			frq.rqus = [];
			frq.id = id;
			this.fetchRqu = frq;
		}
		// add request list to the list of request lists :-)
		this.fetchRqu.rqus.push( rqu_lst );
		// transfer "after scrolling" callbacks, if any
		CallbackManager.transferCallbacks(this, AFTER_SCROLLING_CALLBACKS, AFTER_MODELDATA_CALLBACKS);
		if ( !this.fetchPnd ) {
			this.fetchPnd = true;
			// force a notification so that this.onSend() can actually send the fetch request
			this._nfySrv('trigger', {}, false);
		}
	}

	/**
	 * sends the fetch request to the web server
	 */
	_fetchSendRqu() {
		if ( this.alive && this.fetchPnd && this.fetchRqu ) {
			const frq = this.fetchRqu;
			this.fetchRqu = null;
			this.fetchPnd = false;
			// we must store the completion handler until this one is answered by the web server
			this.fetchCph = frq.cf;
			const par = {};
			par.rqus = frq.rqus;
			par.fcr = this.model.focusedRow;
			this.fetchID = par.id = frq.id;
			if ( this.logger.isDebugEnabled() ) {
				this.log(`${this.wdgId} - Sending fetch request "${this.fetchID}", focused row: ${par.fcr} ...`);
			}
			// sent fetch request to web server
			this._nfySrv( 'modelFetch', par, true );
		} else {
			this._fetchDropRqu();
		}
	}

	/**
	 * drops a pending fetch request
	 */
	_fetchDropRqu() {
		if ( this.fetchRqu ) {
			if ( this.alive && this.logger.isDebugEnabled() ) {
				this.log(`${this.wdgId} - Dropping pending fetch request "${this.fetchRqu.id}" (current fetchID=${this.fetchID})...`);
			}
			this.fetchRqu = null;
			this.fetchCph = null;
			this.fetchPnd = false;
		}
	}


	_doAfterModelData() {
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: AFTER_MODELDATA_CALLBACKS
		} );
	}

	_doEnsuingModelData() {
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: "_ensuingModelDataCallbacks"
		} );
	}

	_doAfterScrollViewUpdate() {
		return CallbackManager.executeCallbacks( {
			instance: this,
			callbackMapName: AFTER_SCROLLING_CALLBACKS
		} );
	}

	setupModelDataCallback( prefix, callback, deleteRightAfterExecution = true ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: AFTER_MODELDATA_CALLBACKS,
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: true,
			canBeDeletedByOthers: true
		} );
	}

	setupEnsuingModelDataCallback( prefix, callback, deleteRightAfterExecution = true ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: "_ensuingModelDataCallbacks",
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: true,
			canBeDeletedByOthers: true
		} );
	}

	setupAfterScrollViewUpdateCallback( prefix, callback, deleteRightAfterExecution = true ) {
		return CallbackManager.setupCallback( {
			instance: this,
			callbackMapName: AFTER_SCROLLING_CALLBACKS,
			prefix: prefix,
			callback: callback,
			deleteRightAfterExecution: deleteRightAfterExecution,
			deleteOthersWithSamePrefix: true,
			canBeDeletedByOthers: true
		} );
	}

	setRowSelectedFlag( parameters ) {
		if ( !Validator.isObject( parameters ) || !Validator.isBoolean( parameters.select ) ) {
			return false;
		}
		if ( this.xSelLogger.isTraceEnabled() ) {
			this.xSelLogger.trace(`Changing row selection:`, parameters);
		}
		return parameters.select ? this.selectDataRow( parameters.rowId, false ) : this.deselectDataRow( parameters.rowId, false );
	}

	setRowFocusedFlag( parameters ) {
		if ( !Validator.isObject( parameters ) || !Validator.isBoolean( parameters.focus ) ) {
			return false;
		}
		if ( this.xSelLogger.isTraceEnabled() ) {
			this.xSelLogger.trace('Forced focused row:', parameters);
		}
		return parameters.focus ? this.focusDataRow( parameters.rowId ) : this.unfocusDataRow( parameters.rowId );
	}

	updateSelectedRowsUi() {
		console.warn(`Deprecated method called!`, Warner.getFullFunctionName());
		return true;
	}

    /**
     * selects a row
     * @param {Number} idr ID of row that's selected
     * @param {Boolean} notify "notify web server" flag
     */
	selectDataRow( idr, notify ) {
		if ( this.selInfo && !notify ) {
			// there's a pending selection info!
			this.selInfo.selected = [ idr ];
		}
		return this.model.selectDataRow( idr, notify );
	}

	selectDataRows( idrList, updateUi = true ) {
		return this._selectOrDeselectDataRows( {
			idrList: idrList,
			updateUi: updateUi,
			select: true
		} );
	}

	deselectDataRow( idr, notify ) {
		return this.model.deselectDataRow( idr, notify );
	}

	deselectDataRows( idrList, updateUi = true ) {
		return this._selectOrDeselectDataRows( {
			idrList: idrList,
			updateUi: updateUi,
			select: false
		} );
	}

	focusDataRow( idr ) {
		return this.model.focusDataRow( idr );
	}

	unfocusDataRow( idr ) {
		return this.model.unfocusDataRow( idr );
	}

	_selectOrDeselectDataRows( { idrList, updateUi = true, select = true } ) {
		console.warn(`Deprecated method called!`, Warner.getFullFunctionName());
		return false;
	}

	adjustSelectionAfterInitialisation() {
		console.warn(`Deprecated method called!`, Warner.getFullFunctionName());
		return false;
	}

	/**
	 * clears all row selections
	 */
	clearAllSel(args) {
		const notify = args && ((typeof args.notify) === 'boolean') ? args.notify : false;
		this.model.unselectAll(notify);
	}

	onDataSave( args ) {
		this.saveEditTarget();
		this.clearEditTarget();
		this.setAllRowsToUnedited();
		this._updateRowsUI();
	}

	onDataCancel( args ) {
		const sx = Math.max(this.xScrollExt.hscPos, 0);
		const sy = Math.max(this.xScrollExt.vscPos, 0);
		const tix = this.topIndex;
		const self = this;
		this.cancelEditTarget();
		this._dropEditTarget();
		this.setAllRowsToUnedited();
		this.model.modelInvalidate();
		this.setupModelDataCallback( "onDataCancel-", () => {
			self.resetAllRows(false);
			self._restoreVScroll(tix, sx, sy);
		} );
	}

	onModalDialog( args ) {
		const open = !!args.opened;
		if ( open ) {
			++this._modalCnt;
		} else {
			--this._modalCnt;
			if ( this._modalCnt < 0 ) {
				this.warn('Modal window counter set to -1!');
				this._modalCnt = 0;
			}
		}
		if ( this.isDebugEnabled() ) {
			this.log(`Modal window: ${open ? 'OPEN' : 'CLOSED'} -  modal window counter = ${this._modalCnt}.`);
		}
		const fh = this.focusHolder;
		if ( fh instanceof FocusHolder ) {
			if ( open )			 {
				fh.lock();
			} else if ( fh.locked ) {
				fh.release();
			}
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 * @param {Boolean} bsc flag whether to force a block screen request
	 */
	_nfySrv( code, par, bsc ) {
		this.trace(`XtwBody#_nfySrv code: "${ code }"`);
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			if ( bsc ) {
				PSA.getInst().setBscRqu();
			}
			rap.getRemoteObject( this ).notify( "PSA_XTW_BDY_NFY", param );
		}
	}

	/** register custom widget type */
	static register() {
		console.debug( 'Registering custom widget XtwBody.' );
		rap.registerTypeHandler( 'psawidget.XtwBody', {
			factory: function ( properties ) {
				return new XtwBody( properties );
			},
			destructor: 'destroy',
			properties: [ 'rtpRwh', 'rtpVPad', 'updRows', 'cellEditingPermission', 'cellEditingContent', 'ovrOrgValue', 'inputDropdownOpenState' ],
			methods: [
				'modelCommitted', 'modelSorted', 'modelData', 'setRowSelectedFlag', 'deleteRows', 'insertRow',
				'setRowFocusedFlag', 'clearAllSel', 'forceUIRefresh', 'onDataSave',
				'onDataCancel', 'onModalDialog', 'markRowsAsEdited',
				'setCellEditor', 'removeCellEditor', 'restoreUserDefinedTableRowHeight',
				'restoreBeforeRowHeightChangeUiStats',
				'updateCellAcc', 'refreshCells'
			],
			events: [ "PSA_XTW_BDY_NFY" ]
		} );
	}

}

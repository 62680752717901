import PSA from '../../psa';
import ItmMgr from '../../gui/ItmMgr';
import EventListenerManager from '../../utils/EventListenerManager';
import ExternalEventsTransferManager from '../../utils/ExternalEventsTransferManager';
import Warner from '../../utils/Warner';
import Validator from '../../utils/Validator';

/**
 * class DtrPck - client side implementation of DtrPck widget
 */
export default class DtrPck {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization properties
	 */
	constructor( properties ) {
		this._psa = PSA.getInst();
		// setup this instance
		this._psa.bindAll( this, [ "layout", "onReady", "onSend", "onRender" ] );
		this.wdgId = null;
		this.dpkWdg = null;
		this.oriVert = false;
		this.hasRng = false;
		this.datBeg = null;
		this.datEnd = null;
		this.visBeg = null;
		this.simSel = false;
		this.hghDteArr = [];
		this.lzySetRng = null;
		this.parent = rap.getObject( properties.parent );
		this.element = document.createElement( 'div' );
		const idw = this.parent.getData( "pisasales.CSTPRP.IDW" );
		if ( idw ) {
			this.wdgId = "dpk" + idw;
			this.element.id = this.wdgId;
			this.element.className = 'dpkhost';
		}
		this.element.style.position = 'absolute';
		this.element.style.cursor = 'default';
		this.element.style.overflow = 'hidden';
		this.parent.append( this.element );
		this.itmMgr = ItmMgr.getInst();
		this.dpkWdg = this._init( this.element, this.parent.getData( "pisasales.CSTPRP.JSP" ), true );
		this.parent.addListener( "Resize", this.layout );
		this.inUserAction = true;
		// activate "render" event
		rap.on( "render", this.onRender );
	}

	onReady() {
		this.ready = true;
		// update the layout
		this.layout();
		this._nfySrv( "WDGINI", true );
		if ( this.lzySetRng ) {
			this.lzySetRng();
		}
		this.onDrawPicker();
	}

	onRender() {
		if ( this.element && this.element.parentNode ) {
			rap.off( "render", this.onRender );
			this.onReady();
		}
	}

	onSend() {
		// do nothing so far...
	}

	layout() {
		if ( this._isRdy() ) {
			const area = this.parent.getClientArea();
			this.element.style.left = '0px';
			this.element.style.top = '0px';
			this.element.style.width = area[ 2 ] + 'px';
			this.element.style.height = area[ 3 ] + 'px';
		}
	}

	destroy() {
		this.ready = false;
		this._cleanup( true );
		this.parent = null;
		this.itmMgr = null;
	}

	onDrawPicker() {
		if ( this._isRdy() ) {
			if ( this.oriVert ) {
				// no positioning
			}
			if ( this.dpkWdg.calendars.length > 0 ) {
				const cl = this.dpkWdg.calendars;
				const c = cl[ 0 ];
				const beg = new Date( c.year, c.month, 1 );
				if ( !this.visBeg || !this.itmMgr.isEquDates( this.visBeg, beg ) ) {
					this.visBeg = beg;
					this._nfyPrd( beg, cl.length );
				}
			}
			this._hghDts();
		}
	}

	onDrawPickerDebounced() {
		if ( !this.onDrawPickerDebounced.initialized ) {
			const self = this;
			this.onDrawPickerDebounced = this._psa.debounce( () => self.onDrawPicker(), 200 );
			this.onDrawPickerDebounced.initialized = true;
		}
		return this.onDrawPickerDebounced;
	}

	onSelectPicker( date ) {
		if ( this._isRdy() ) {
			if ( this.simSel ) {
				this._nfySel( date, null );
			} else {
				this.datBeg = new Date( date.getTime() );
			}
		}
	}

	onMouseDownPicker( e ) {
		if ( this._isRdy() ) {
			this.datBeg = null;
			this.datEnd = null;
		}
	}

	onMouseUpPicker( e ) {
		if ( this._isRdy() ) {
			if ( this.datBeg !== null ) {
				const tgt = e.target || e.srcElement;
				if ( tgt ) {
					const mgr = this.itmMgr;
					if ( !mgr.hasCssCls( tgt, 'is-disabled' ) ) {
						if ( mgr.hasCssCls( tgt, 'pika-button' ) && !mgr.hasCssCls( tgt, 'is-empty' ) ) {
							const end = new Date( tgt.getAttribute( 'data-pika-year' ), tgt.getAttribute( 'data-pika-month' ), tgt.getAttribute( 'data-pika-day' ) );
							if ( !mgr.isEquDates( this.datBeg, end ) ) {
								this.datEnd = end;
							}
						}
					}
				}
				if ( this.datEnd !== null ) {
					if ( this.datEnd < this.datBeg ) {
						//swap!!!
						constd = this.datEnd;
						this.datEnd = this.datBeg;
						this.datBeg = d;
					}
					const range = {};
					range.beg = this.datBeg;
					range.end = this.datEnd;
					this._nfySel( null, range );
				} else {
					this._nfySel( this.datBeg, null );
				}
				this.datBeg = null;
				this.datEnd = null;
			}
		}
	}

	onMouseOutPicker( e ) {
		if ( this._isRdy() ) {
			if ( !this.dpkWdg.el.contains( e.relatedTarget ) ) {
				// out of widget...
				this.datBeg = null;
				this.datEnd = null;
			}
		}
	}

	setPar( jsp ) {
		try {
			this._lockUserAction();
			const upd = this.element && jsp[ "update" ];
			if ( upd ) {}
			if ( this.element ) {}
		} finally {
			this._unlockUserAction();
		}
	}

	setRng( args ) {
		if ( args.beg && args.end ) {
			const range = {};
			range.beg = new Date( args.beg );
			range.end = new Date( args.end );
			if ( this._isRdy() ) {
				this._doSetRng( args.date, range );
			} else {
				this.lzySetRng = function() {
					this._doSetRng( args.date, range );
					this.lzySetRng = null;
				};
			}
		}
	}

	setHghDts( args ) {
		if ( this._isRdy() ) {
			try {
				this._lockUserAction();
				const dteArr = args.array;
				if ( dteArr ) {
					this.hghDteArr = dteArr;
				}
				this._hghDts();
			} finally {
				this._unlockUserAction();
			}
		}
	}

	rfrLay( args ) {
		if ( this._isRdy() ) {
			try {
				this._lockUserAction();
				this.onDrawPicker();
			} finally {
				this._unlockUserAction();
			}
		}
	}

	_init( div, jsp, csp ) {
		// create date picker widget
		this.blurAllEditors();
		this.focusElement( div );
		let nmn = 1;
		if ( jsp && ( typeof jsp.numElm === 'number' ) ) {
			nmn = jsp.numElm;
		}
		const dpo = {};
		dpo.bound = false;
		dpo.container = div;
		dpo.defaultDate = new Date();
		dpo.setDefaultDate = true;
		dpo.showWeekNumber = true;
		dpo.numberOfMonths = nmn;
		dpo.firstDay = 1;
		dpo.yearRange = 100;
		dpo.showDaysInNextAndPreviousMonths = true;
		if ( jsp && this._psa.isStr( jsp.cssCls ) ) {
			dpo.theme = jsp.cssCls;
		}
		if ( jsp && this._psa.isStr( jsp.usrLng ) ) {
			if ( jsp.usrLng !== this._psa.usrLng ) {
				// store the language
				this._psa.usrLng = jsp.usrLng;
				// and setup "moment" library
				moment.locale( jsp.usrLng );
			}
		}
		const i18n = {};
		i18n.months = moment.months();
		i18n.weekdays = moment.weekdays();
		const wds = [];
		const owd = moment.weekdaysShort();
		for ( let i = 0; i < owd.length; ++i ) {
			let nam = owd[ i ];
			if ( nam.endsWith( '.' ) ) {
				nam = nam.substr( 0, nam.length - 1 );
			}
			wds.push( nam );
		}
		i18n.weekdaysShort = wds;
		dpo.i18n = i18n;
		this.oriVert = ( nmn > 1 ) && ( jsp.ori === 'vert' );
		const self = this;
		dpo.orientation = this.oriVert ? 'vertical' : 'horizontal';
		dpo.onDraw = function() {
			if ( self.inUserAction ) {
				self.onDrawPickerDebounced();
			}
		};
		dpo.onSelect = function( date ) {
			if ( self.inUserAction ) {
				window.setTimeout( function() {
					self.onSelectPicker( date );
				}, 0 );
			}
		};
		const picker = new Pikaday( dpo );
		const originalOnClickFunction = picker._onClick;
		picker._onClick = ( evt ) => {
			self.focusElement( picker.el );
			return originalOnClickFunction( evt );
		};
		const originalOnInputClickFunction = picker._onInputClick;
		picker._onInputClick = () => {
			self.focusElement( picker.el );
			return originalOnInputClickFunction();
		};
		const originalOnMouseDownFunction = picker._onMouseDown;
		picker._onMouseDown = ( evt ) => {
			self.focusElement( picker.el );
			return originalOnMouseDownFunction( evt );
		};
		const elm = picker.el;
		this.focusElement( elm );
		this.addKeyListeners( elm );
		const sim = !!jsp.simSel;
		this.simSel = sim;
		if ( !sim ) {
			self._onMD = function( e ) {
				self.focusElement( elm );
				self.onMouseDownPicker( e );
			}
			self._onMU = function( e ) {
				self.onMouseUpPicker( e );
			}
			self._onMO = function( e ) {
				self.onMouseOutPicker( e );
			}
			elm.addEventListener( 'mousedown', self._onMD, true );
			elm.addEventListener( 'mouseup', self._onMU, true );
			elm.addEventListener( 'mouseout', self._onMO, true );
		}
		div.appendChild( elm );
		if ( csp ) {
			this.setPar( jsp );
		}
		if ( Validator.isString(jsp.wdgNfo) ) {
			// create "today" button
			const btn = document.createElement('div');
			btn.className = 'dpkbtn';
			const span = document.createElement('span');
			span.className = 'dpkbtn';
			span.innerText = jsp.wdgNfo;
			span.addEventListener('click', (e) => {
				self._onTodayClicked();
			});
			btn.appendChild(span);
			div.appendChild(btn);
		}
		return picker;
	}

	_cleanup( full ) {
		if ( full ) {
			if ( this.element && this.dpkWdg ) {
				const elm = this.dpkWdg.el;
				elm.removeEventListener( 'mousedown', this._onMD, true );
				elm.removeEventListener( 'mouseup', this._onMU, true );
				elm.removeEventListener( 'mouseout', this._onMO, true );
				this.element.removeChild( elm );
			}
			this.dpkWdg = null;
			this.element = null;
		}
	}

	_isRdy() {
		return this.ready && this.element;
	}

	_doSetRng( date, range ) {
		try {
			this._lockUserAction();
			this._setVisRng( date, range );
		} finally {
			this._unlockUserAction();
		}
	}

	_onTodayClicked() {
		const now = new Date();
		const range = {};
		range.beg = now;
		range.end = now;
		this._doSetRng(now, range);
	}

	_setVisRng( date, range ) {
		if ( this._isRdy() ) {
			const pck = this.dpkWdg;
			if ( range ) {
				if ( range.beg.getTime() !== range.end.getTime() ) {
					this.hasRng = true;
					pck.setStartRange( range.beg );
					pck.setEndRange( range.end );
				} else {
					this.hasRng = false;
					pck.setStartRange( null );
					pck.setEndRange( null );
				}
				const sel = date || range.beg;
				pck.setDate( sel );
			} else {
				if ( this.hasRng ) {
					this.hasRng = false;
					pck.setStartRange( null );
					pck.setEndRange( null );
					pck.draw( true );
				}
			}
		}
	}

	_hghDts() {
		const pckGrp = this.dpkWdg;
		const dteArr = this.hghDteArr;
		if ( dteArr && pckGrp && pckGrp.el ) {
			const dayBts = pckGrp.el.querySelectorAll( 'button.pika-day' );
			this._rstDtsHgh( dayBts );
			this._setDtsHgh( dayBts, dteArr );
		}
	}

	_setDtsHgh( dayBts, dteArr ) {
		for ( let i = ( dteArr.length - 1 ); i >= 0; i-- ) {
			let d = new Date( dteArr[ i ] );
			this._hghDte( dayBts, d );
		}
	}

	_hghDte( dayBts, dte ) {
		for ( let i = ( dayBts.length - 1 ); i >= 0; i-- ) {
			let dayBtn = dayBts[ i ];
			if ( dayBtn ) {
				if ( dayBtn.getAttribute( 'data-pika-year' ) == dte.getFullYear() &&
					dayBtn.getAttribute( 'data-pika-month' ) == dte.getMonth() &&
					dayBtn.getAttribute( 'data-pika-day' ) == dte.getDate() ) {
					dayBtn.classList.add( 'pisa-events-day' );
				}
			}
		}
	}

	_rstDtsHgh( dayBts ) {
		for ( let i = ( dayBts.length - 1 ); i >= 0; i-- ) {
			let dayBtn = dayBts[ i ];
			if ( dayBtn ) {
				dayBtn.classList.remove( 'pisa-events-day' );
			}
		}
	}

	_nfySel( date, range ) {
		const par = {};
		if ( date ) {
			par.date = date;
		}
		if ( range ) {
			par.range = range;
		}
		this._nfySrv( "WDGSEL", par );
		if ( !this.simSel ) {
			const self = this;
			window.setTimeout( function() {
				self._setVisRng( date, range );
			}, 0 );
		}
	}

	_nfyPrd( beg, nmn ) {
		const par = {};
		par.beg = beg;
		par.nmn = nmn;
		this._nfySrv( "DSPPRD", par );
	}

	_nfySrv( code, par ) {
		if ( this.ready ) {
			this._psa.setBscRqu();
			if ( pisasales.ScrMen ) {
				pisasales.ScrMen.static.closeAllMenus();
			}
			const param = {};
			param[ "cod" ] = code;
			param[ "par" ] = par;
			rap.getRemoteObject( this ).notify( "DTRPCK_NFY", param );
		}
	}

	/**
	 * Locks user action handling, i.e. autonomous pikaday actions (draw, select)
	 * will not be processed until interaction is unlocked. The purpose is to avoid
	 * weird behavior when such actions are triggered during server response execution
	 * and the timeout of the pikaday callbacks isn't long enough.
	 */
	_lockUserAction() {
		this.inUserAction = false;
	}

	/**
	 * Unlocks user action handling, i.e. autonomous pikaday actions (draw, select)
	 * will now be processed as authentic user interactions.
	 */
	_unlockUserAction() {
		this.inUserAction = true;
	}

	blurAllEditors() {
		return this._psa.blurAllEditors();
	}

	focusElement( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		element.setAttribute( "tabindex", "1" );
		element.focus();
		return true;
	}

	addKeyListeners( element ) {
		if ( !( element instanceof HTMLElement ) ) {
			return false;
		}
		element.tabIndex = 1;
		const keydownListenerSuccessfullyAdded = EventListenerManager.addListener( {
			instance: this,
			eventName: "keydown",
			functionName: "onPickerKeydown",
			callBackPrefix: "Picker",
			element: element,
			useCapture: true
		} );
		new ExternalEventsTransferManager( this, element );
		delete this.addKeyListeners;
		return keydownListenerSuccessfullyAdded;
	}

	onPickerKeydown( domEvent ) {
		Warner.traceIf( true );
	}

	/** register custom widget type */
	static register() {
		console.debug( 'Registering custom widget DtrPck.' );
		rap.registerTypeHandler( "psawidget.DtrPck", {
			factory: function( properties ) {
				return new DtrPck( properties );
			},
			destructor: "destroy",
			methods: [ "setRng", "setPar", "rfrLay", "setHghDts" ],
			events: [ "DTRPCK_NFY" ]
		} );
	}
}

console.debug( 'widgets/dtrpck/DtrPck.js loaded.' );

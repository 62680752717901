import Validator from "../../utils/Validator";
import AbstractTabItem from "./AbstractTabItem";
import GroupItem from "./GroupItem";

/**
 * tab widget's tab item class
 */
export default class TabItem extends AbstractTabItem {

    /**
     * constructs a new instance
     * @param {*} args arguments
     */
    constructor(args) {
        super(args.id || 0, 'widgets.tabwdg.TabItem');
        const title = args.title || '';
        const tooltip = args.tooltip || '';
        const group = args.group || '';
        const icon = args.icon || null;
        const width = args.width || 0;
        if ( !Validator.isString(title) ) {
            this.throwError('Invalid arguments', args);
        }
        this._title = title;
        this._groupName = Validator.isString(group) ? group : null;
        this._tooltip = tooltip;
        this._icon = Validator.isObject(icon) ? icon : null;
        this._tabGroup = null;
        this._textWidth = Validator.isPositiveInteger(width, true) ? width : 0;
        this._marked = false;
    }

    /**
     * @inheritdoc
     * @override
     * @returns {String}
     */
    get className() {
        return 'TabItem';
    }

    /**
     * @override
     * @inheritdoc
     * @returns {String}
     */
    toString() {
        return `TabItem #${this.id} - "${this.title}" (${this.tms})`;
    }

    /**
     * @returns {String|null} the group identifier of the group to which this item belongs
     */
    get groupName() {
        return this._groupName;
    }

    /**
     * @returns {String} the tab item's title
     */
    get title() {
        return this._title;
    }

    /**
     * sets a new title
     * @param {String} t new title text
     */
    set title(t) {
        if ( !Validator.isString(t) ) {
            this.throwError('Invalid title!', t);
        }
        if ( this._title !== t ) {
            this._title = t;
            // force recalculation of widths
            this._textWidth = 0;
            this.itemWidth = 0;
        }
    }

    /**
     * @returns {String} the tooltip text
     */
    get tooltip() {
        return this._tooltip;
    }

    /**
     * @param {String} t new tooltip text
     */
    set tooltip(t) {
        this._tooltip = t;
    }

    /**
     * @returns {Boolean} true if an icon is present
     */
    get hasIcon() {
        return !!this._icon;
    }

    /**
     * @return {Object|null} the icon object
     */
    get icon() {
        return this._icon;
    }

    /**
     * sets a new icon or removes the current icon
     * @param {Object|null} i new icon descriptor or null
     */
    set icon(i) {
        this._icon = i || null;
        // force recalculation of item width
        this.itemWidth = 0;
    }

    /**
     * @returns {GroupItem} the tab group to which this item belongs
     */
    get tabGroup() {
        return this._tabGroup;
    }

    /**
     * sets the tab group
     * @param {GroupItem|null} the tab group
     */
    set tabGroup(tg) {
        this._tabGroup = tg instanceof GroupItem ? tg : null;
    }

    /**
     * @returns {Number} the calculated text width of this item
     */
    get textWidth() {
        return this._textWidth;
    }

    /**
     * sets the calculated text width
     * @param {Number} w new calculated text width
     */
    set textWidth(w) {
        this._textWidth = w || 0;
    }

    /**
     * @returns {Boolean} true if this item has a calculated text width
     */
    get hasTextWidth() {
        return this._textWidth > 0;
    }

    /**
     * @returns {Boolean} the current "marked" state
     */
    get marked() {
        return this._marked;
    }

    /**
     * sets the "marked" state
     * @param {Boolean} m new marked state
     */
    set marked(m) {
        this._marked = !!m;
    }

    /**
     * @override
     */
	doDestroy() {
        super.doDestroy();
    }

    /**
     * creates a clone
     * @returns {TabItem} the cloned instance
     */
    clone() {
        const args = { 
            id: this.id,
            title: this.title,
            group: this.groupName,
            icon: this.icon,
            width: this.textWidth
        };
        return new TabItem(args);
    }

}
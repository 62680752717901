import ThemeMgr, { DEFAULT_TOOLTIP_SHOW_DELAY } from "../../gui/ThemeMgr";
import Color from "../../utils/Color";
import Validator from "../../utils/Validator";

/** common padding */
const TW_TAB_PADDING = 4;
/** width of close button (including left margin) */
const TW_CLOSE_WIDTH = 22;
/*** width of tab icon */
const TW_ICON_WIDTH = 20;
/** an extra width to get more space */
const TW_EXTRA_WIDTH = 7;
/** width of spacer element */
const TW_SPACER_WIDTH = 3;
/** minimal tab item width */
const TW_TAB_MIN_WIDTH = 80;
/** maximal tab item width */
const TW_TAB_MAX_WIDTH = 200;
/** additional text width offset */
const TW_TAB_TEXT_OFFS = 14;
/** tooltip element's vertical offset */
const TW_TTP_OFFSET = 4;
/** the fixed menu ID */
const TAB_MENU_ID = 70000000;

/** content type */
const DRAG_DATA_TYPE = 'application/x-pisa-webclient';
/** drop data prefix */
const DROP_DATA_PREFIX = 'ptg'


/**
 * tab widget's property class
 */
export default class TWProps {

    /**
     * constructs a new instance
     * @param {*} cwd custom widget data
     */
    constructor(cwd) {
        this._closeBtn = !!cwd.closeBtn;
        this._dropSupport = !!cwd.dropSupport;
        this._tabGrouping = false;
        this._noOverflow = false;
        this._bkgColor = null;
        const tmo = ThemeMgr.getInstance().getProp('tooltipShowDelay');
        this._ttpDelay = Validator.isPositiveInteger(tmo) ? tmo : DEFAULT_TOOLTIP_SHOW_DELAY;
    }

    /**
     * @returns {Boolean} true if tab items should have a close button; false otherwise
     */
    get closeBtn() {
        return this._closeBtn;
    }

    /**
     * @returns {Boolean} true if drop operations should be supported; false otherwise
     */
    get dropSupport() {
        return this._dropSupport;
    }

    /**
     * @returns {Boolean} true if the tab grouping feature is enabled; false otherwise
     */
    get tabGrouping() {
        return this._tabGrouping;
    }

    /**
     * @returns {Boolean} true if "no overflow" is active, i.e., hidden tabs should be closed immediately; false otherwise
     */
    get noOverflow() {
        return this._noOverflow;
    }

    /**
     * @returns {Color | null} the background color
     */
    get bkgColor() {
        return this._bkgColor;
    }

    /**
     * @returns {Number} the tooltip show delay in milliseconds
     */
    get ttpDelay() {
        return this._ttpDelay;
    }

    /**
     * sets the "tab grouping" feature flag
     * @param {Boolean} tg new value
     */
    setTabGrouping(tg) {
        this._tabGrouping = !!tg;
    }

    /**
     * sets the "no overflow" flag
     * @param {Boolean} no new value
     */
    setNoOverflow(no) {
        this._noOverflow = !!no;
    }

    /**
	 * sets new background color
	 * @param {*} args color properties
     */
    setBkgColor(args) {
        if ( Validator.isArray(args, 4) ) {
            this._bkgColor = Color.fromRgba(args);
        } else {
            this._bkgColor = null;
        }
    }
}

export { TW_TAB_PADDING, TW_CLOSE_WIDTH, TW_ICON_WIDTH, TW_EXTRA_WIDTH, TW_SPACER_WIDTH, 
            TW_TAB_MIN_WIDTH, TW_TAB_MAX_WIDTH, TW_TAB_TEXT_OFFS, TW_TTP_OFFSET, TAB_MENU_ID,
            DRAG_DATA_TYPE, DROP_DATA_PREFIX };


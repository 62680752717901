import TabItem from "./TabItem";

/**
 * a helper class to create a menu item from a tab item 
 */
export default class TabMenuItem {

    /**
     * constructs a new instance
     * @param {TabItem} ti the tab item
     */
    constructor(ti) {
        if ( !(ti instanceof TabItem) || !ti.alive ) {
            throw new Error('Invalid tab item!');
        }
        this.id = ti.id;
        this.txt = ti.title;
        this.img = null;
        this.ttlImg = ti.icon;
        this.ina = false;
		this.stc = false;
		this.ena = true;
		this.chk = ti.active && ti.tabGroup.active;
		this.items = null;
		this.rgtclk = false;
        this._tabItem = ti;
    }

    /**
     * @returns {TabItem} the associated tab item
     */
    get tabItem() {
        return this._tabItem;
    }
}
import Validator from "../../utils/Validator";
import AbstractTabItem from "./AbstractTabItem";
import TabItem from "./TabItem";

export default class GroupItem extends AbstractTabItem {

    /**
     * constructs a new instance
     * @param {Number} id group ID
     * @param {String|null} name group name
     * @param {TabItem} item the initial tab item
     * @param {String} ln logger name
     */
    constructor(id, name, item, ln) {
        super(id, ln);
        if ( !(item instanceof TabItem) ) {
            this.throwError('Invalid tab item!', item);
        }
        this._name = Validator.isString(name) ? name : null;
        this._items = new Map();
        this._currentItem = null;
        // add initial tab item
        this.addItem(item, true);
    }

    /**
     * @inheritdoc
     * @override
     * @returns {String}
     */
    get className() {
        return 'GroupItem';
    }

    /**
     * @override
     * @inheritdoc
     * @returns {String}
     */
    toString() {
        const ci = this.currentItem;
        return `${this.className} #${this.id} - {${((ci instanceof TabItem) ? ci.toString() : '-')}}`;
    }

    /**
     * @returns {String|null} the group name
     */
    get name() {
        return this._name;
    }

    /**
     * @returns {Map<Number, TabItem>} the tab items map
     */
    get items() {
        return this._items;
    }

    /**
     * @returns {Boolean} true if this tab group is empty; false otherwise
     */
    get empty() {
        return !this.alive || !(this.items.size > 0);
    }

    /**
     * @returns {TabItem} the currently active tab item
     */
    get currentItem() {
        return this._currentItem;
    }

    /**
     * @returns {Boolean} the visibility state
     */
    get visible() {
        // instances of this class are never visible!
        return false;
    }

    /**
     * @returns {Number} the ID of the current tab item
     */
    get _currentID() {
        const ci = this.currentItem;
        return ci instanceof TabItem ? ci.id : 0;
    }

    /**
     * @override
     */
    doDestroy() {
        try {
            const items = this.items;
            items.forEach(item => item.destroy());
            items.clear();
        } finally {
            this._items = null;
            super.doDestroy();
        }
    }

    /**
     * checks whether the given tab item is valid; throws an error if not
     * @param {TabItem} item the tab item to be checked
     */
    checkTabItem(item) {
        if ( !(item instanceof TabItem) || (item.tabGroup !== this) ) {
            this.throwError('Invalid tab item!', item);
        }
    }
    
    /**
     * adds a tab item to this tab group
     * @param {TabItem} item the tab item to be added
     * @param {Boolean} sci flag whether to make the newly added item the current item
     */
    addItem(item, sci) {
        if ( this.alive ) {
            if ( !(item instanceof TabItem) || this.items.has(item.id) || item.tabGroup ) {
                this.throwError('Invalid tab item', item);
            }
            item.tabGroup = this;
            this.items.set(item.id, item);
            if ( sci ) {
                this.setCurrentItem(item);
            }
        }
    }

    /**
     * removes a tab item
     * @param {TabItem} item the item to be removed
     */
    rmvItem(item) {
        if ( this.alive ) {
            this.checkTabItem(item);
            const new_current = item === this.currentItem;
            this.items.delete(item.id);
            if ( !this.empty ) {
                if ( new_current ) {
                    const newest = AbstractTabItem.findNewest(this.items.values());
                    if ( newest instanceof TabItem ) {
                        this.setCurrentItem(newest);
                    }
                }
            } else {
                // the last item was removed --> we'll die
                this.destroy();
            }
        }
    }

    /**
     * sets the current tab item
     * @param {TabItem} item the tab item to become the current item
     */
    setCurrentItem(item) {
        this.checkTabItem(item);
        const ci = this.currentItem;
        if ( item !== ci ) {
            if ( ci instanceof TabItem ) {
                ci.setActive(false);
            }
            item.setActive(true);
            this._currentItem = item;
        }
    }

    /**
     * removes all tab items from this group; does not destroy tab items
     */
    clearItems() {
        this.items.forEach(ti => ti.tabGroup = null);
        this._currentItem = null;
        this.items.clear();
    }

    /**
     * @inheritdoc
     * @override
     * @param {Boolean} active 
     */
    setActive(active) {
        super.setActive(active);
        if ( active ) {
            const ci = this.currentItem;
            if ( ci instanceof TabItem ) {
                ci.setActive(true);
            }
        }
    }

    /**
     * called if the properties of a tab item were changed
     * @param {TabItem} item the tab item
     */
    tabItemUpdated(item) {
        // abstract
    }

    /**
     * updates the UI layout
     */
    updateLayout() {
        // nothing to do
    }

    /**
     * compares two group items
     * @param {GroupItem} g1 group item 1
     * @param {GroupItem} g2 group item 2
     * @returns {Number} a value less/greater than zero if group item 1 is considered to be less/greater than group item 2;
     *                      zero if both group items are considered to be equal
     */
    static compareGroups(g1, g2) {
        if ( g1.visible === g2.visible ) {
            const diff = g2.tms - g1.tms;
            return diff !== 0 ? diff : g2.iid - g1.iid;
        }
        return g1.visible ? -1 : 1;
    }

}
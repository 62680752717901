import MenuHandler from "../../gui/menu/MenuHandler";

export default class TabEvents extends MenuHandler {
    
    /**
     * constructs a new instance
     * @param {String} ln logger name
     */
    constructor(ln) {
        super(ln || 'widgets.tabwdg.TabWidget');
    }

    /**
     * called if the close button of a tab group was clicked
     * @param {Number} group group identifier
     * @param {Number} id ID of current tab item
     */
    onTabClose(group, id) {
        // abstract
    }

    /**
     * called if a tab group was activated
     * @param {Number} group group identifier
     * @param {Number} id ID of current tab item
     */
    onTabActivate(group, id) {
        // abstract
    }

    /**
     * called on a context menu request
     * @param {Number} group group ID
     */
    onTabContextMenu(group) {
        // abstract
    }

    /**
     * called if a tab group double clicked
     * @param {Number} group group identifier
     * @param {Number} id ID of current tab item
     */
    onTabDblClick(group) {
        // abstract
    }

    /**
     * called if a group drag operation has been started
     * @param {Number} group group identifier
     */
    onStartGroupDrag(group) {
        // abstract
    }

    /**
     * called if a group drag operation has been terminated
     * @param {Number} group group identifier
     */
    onEndGroupDrag(group) {
        // abstract
    }
}
import LoggingBase from "../../base/loggingbase";
import CkEdt5V2 from "./CkEdt5V2";

export default class CkEdt5Register extends LoggingBase {

    /**
     * constructs a new instance
     */
    constructor() {
        super('widgets.ckedt5v2.CkEdt5Register');
        this._register = new Map();
    }

    /**
     * returns the singleton instance
     * @returns {CkEdt5Register} the singleton instance
     */
    static getInstance() {
        return singleton;
    }

    /**
     * @returns {Map<String,CkEdt5V2>} the editor register
     */
    get register() {
        return this._register;
    }

    /**
     * adds an editor instance to the register
     * @param {CkEdt5V2} editor the editor instance to be added
     */
    addEditor(editor) {
        if ( editor instanceof CkEdt5V2 ) {
            this.register.set(editor.wdgId, editor);
        }
    }

    /**
     * removes an editor instance from the register
     * @param {CkEdt5V2} editor  the editor instance to be removed
     */
    rmvEditor(editor) {
        if ( editor instanceof CkEdt5V2 ) {
            this.register.delete(editor.wdgId);
        }
    }

    /**
     * blurs all known CKE5 editor instances
     * @returns {Boolean} always true :-)
     */
    blurAllEditors() {
        const reg = this.register;
        reg.forEach((editor) => editor.blur());
        return true;
    }
}

const singleton = new CkEdt5Register();

import LoggingBase from "../../base/loggingbase";
import Validator from "../../utils/Validator";

/** internal counter to avoid equal timestamp values */
let TMS_COUNT = 0;

/** abstract tab item base class */
export default class AbstractTabItem extends LoggingBase {

    /**
     * constructs a new instance
     * @param {Number} id item identifier
     * @param {String} ln logger name
     */
    constructor(id, ln) {
        super(ln || 'widgets.tabwdg.AbstractTabItem');
        if ( !Validator.isPositiveInteger(id, false) ) {
            this.throwError('Missing or invalid item ID!', id);
        }
        this._id = id;
        this._active = false;
        this._itemWidth = 0;
        this._tms = AbstractTabItem._newTms();
    }

    /**
     * @returns {String} the class name
     */
    get className() {
        return 'AbstractTabItem';
    }

    /**
     * @override
     * @inheritdoc
     * @returns {String}
     */
    toString() {
        return `${this.className} #${this.id} (${this.tms})`;
    }

    /**
     * @returns {Number} the item ID
     */
    get id() {
        return this._id;
    }

    /**
     * @returns {Boolean} true if this is the active item
     */
    get active() {
        return this.alive && this._active;
    }

    /**
     * returns the width in pixels of this item;
     * @return {Number} the required width in pixels
     */
    get itemWidth() {
        return this._itemWidth;
    }

    /**
     * sets a new width of this item;
     * @param {Number} the new width in pixels
     */
    set itemWidth(width) {
        if ( Validator.isNumber(width) ) {
            this._itemWidth = width;
        }
    }

    /**
     * @returns {Number} the last activation timestamp
     */
    get tms() {
        return this._tms;
    }

    /**
     * @override
     * @inheritdoc
     */
    doDestroy() {
        this._itemWidth = 0;
        this._tms = 0;
        this._active = false;
        super.doDestroy();
    }

    /**
     * changes the "active" state
     * @param {Boolean} active flag whether to set the active or normal state
     */
    setActive(active) {
        if ( this.alive ) {
            // set "active" state
            this._active = active;
            if ( active ) {
                // record the timestamp
                this._tms = AbstractTabItem._newTms();
                if ( this.isDebugEnabled() ) {
                    this.debug('Item activated:', this.toString(), new Date(this.tms).toISOString());
                }
            }
        }
    }

    /**
     * compares the activation timestamps this instance and the given item
     * @param {AbstractTabItem} item the tab item to compare this instance with
     * @returns a value less than zero if this is considered to be less than the given item; a value greater than zero
     *          if this item considered to be greater than the given item; zero if this item is considered to be equal to the given item
     */
    compareTo(item) {
        return AbstractTabItem.compareItems(this, item);
    }

    /**
     * compares the activation timestamps of two abstract tab items
     * @param {AbstractTabItem} item1 the first item
     * @param {AbstractTabItem} item2 the second item
     * @returns a value less than zero if item 1 is considered to be less than item 2; a value greater than zero
     *          if item 1 is considered to be greater than item 2; zero if both items are considered to be equal
     */
    static compareItems(item1, item2) {
        return item1.tms - item2.tms;
    }

    /**
     * creates a new activation timestamp
     * @returns {Number} a new activation timestamp
     */
    static _newTms() {
        return (++TMS_COUNT) + Date.now();
    }

    /**
     * finds the "newest" item within an item collection; the "newest" item is the most recently
     * activated item
     * @param {AbstractTabItem[]} values item collection
     * @returns {AbstractTabItem|null} the newest item or null if the item collection did not provide at least one tab item
     */
    static findNewest(values) {
        let newest = null;
        for ( let item of values ) {
            if ( item instanceof AbstractTabItem ) {
                if ( !newest ) {
                    newest = item;
                } else {
                    if ( item.compareTo(newest) > 0 ) {
                        newest = item;
                    }
                }
            }
        }
        return newest;
    }

}
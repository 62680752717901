import ItmMgr from "../../gui/ItmMgr";
import MnuObj from "../../gui/menu/MnuObj";
import PSA from "../../psa";
import Color from "../../utils/Color";
import DomEventHelper from "../../utils/DomEventHelper";
import HtmHelper from "../../utils/HtmHelper";
import Utils from "../../utils/Utils";
import Validator from "../../utils/Validator";
import AbstractTabItem from "./AbstractTabItem";
import GroupItem from "./GroupItem";
import TWProps, { DRAG_DATA_TYPE, DROP_DATA_PREFIX, TAB_MENU_ID } from "./TWProps";
import TabEvents from "./TabEvents";
import TabGroup from "./TabGroup";
import TabItem from "./TabItem";
import TabMenuItem from "./TabMenuItem";

const BE_NOTIFICATION = 'TABWIDGET_NFY';
const NFY_TABSELECTED = 'tabSelected';
const NFY_TABCLOSE = 'tabClose';
const NFY_TABDBLCLICK = 'tabDblClick';
const NFY_MENUITEM = 'menuItem';

/** width of "more" button, keep in sync. with CSS! */
const MORE_WIDTH = 22;
/** the "more" button icon */
const MORE_ICON = { cssCls: 'far', icoNam: 'fa-ellipsis-h', icoSiz: 17, icoClr: null, fntNam: null };
/** CSS class of "more" button  */
const MORE_CLASS = 'mainTabOverflowBtn';

let NEXT_TAB_ID = 10000000;

/**
 * class TabWidget - implements the JS side of the new Tab Widget
 */
export default class TabWidget extends TabEvents {

    /**
     * constructs a new instance
     * @param {*} properties custom widget properties
     */
    constructor(properties) {
        super('widgets.tabwdg.TabWidget');
		Utils.bindAll(this, [ 'onReady', 'onRender', 'layout' ]);
		this._ready = false;
		this._pndItems = [];
		this._isGrouped = false;
		this._activeGroup = null;
		this._wdgWidth = 0;
		this._wndMenu = [];
		this._draggedGroup = null;
		this._targetGroup = null;
		this._menuOpt = new Map();
		// get the RAP parent element
		const idw = properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject(idw);
		// create our DOM element
		const de = this._createDOMElement();
		this._element = de;
		const tc = this._createTabContainer();
		this._container = tc;
		const fe = this._createFiller();
		this._filler = fe;
		const mb = this._createMoreButton();
		fe.appendChild(mb);
		this._moreBtn = mb;
		tc.appendChild(fe);
		de.appendChild(tc);
		// get custom widget data and create property object
		const cwd = this.parent.getData('pisasales.CSTPRP.CWD') || {};
		this._props = new TWProps(cwd);
		// create tab groups map
		this._tabGroups = new Map();
		// create tab items map
		this._tabItems = new Map();
		// the "more" items array
		this._moreItems = new Array();
		// add the main DOM element to the RAP parent
		this.parent.append(this.element);
		// add the resize listener
		this.parent.addListener('Resize', this.layout);
		// activate "render" event
		rap.on('render', this.onRender);
    }

	/**
	 * @returns {Boolean} true if the instance is "ready", i.e., fully initialized; false otherwise
	 */
	get ready() {
		return this.alive && this._ready && this.hasElement;
	}

	/**
	 * @returns {HTMLElement|null} the main DOM element
	 */
	get element() {
		return this._element;
	}

	/**
	 * @returns {Boolean} true if the DOM element is valid; false otherwise
	 */
	get hasElement() {
		return (this._element instanceof HTMLElement);
	}

	/**
	 * @returns {HTMLElement} the tab container element
	 */
	get container() {
		return this._container;
	}

	/**
	 * @returns {HTMLElement} the "filler" element
	 */
	get filler() {
		return this._filler;
	}

	/**
	 * @returns {HTMLElement} the "more" button element
	 */
	get moreBtn() {
		return this._moreBtn;
	}

	/**
	 * @returns {TWProps} tab widget properties
	 */
	get props() {
		return this._props;
	}

	/**
	 * @returns {Map<Number, TabGroup>} the tab items map
	 */
	get tabGroups() {
		return this._tabGroups;
	}

	/**
	 * @returns {Map<Number, TabItem>} the tab items map
	 */
	get tabItems() {
		return this._tabItems;
	}

	/**
	 * @returns {Array<TabGroup>} the "more items" array
	 */
	get moreItems() {
		return this._moreItems;
	}

	/**
	 * @returns {Boolean} true if grouping is active; false otherwise
	 */
	get isGrouped() {
		return this._isGrouped;
	}

	/**
	 * @return {TabGroup|null} the currently active group
	 */
	get activeGroup() {
		return this._activeGroup;
	}

	/**
	 * @returns {TabItem|null} the currently active tab item
	 */
	get currentItem() {
		const ag = this.activeGroup;
		return ag instanceof TabGroup ? ag.currentItem : null;
	}

	/**
	 * @returns {Number} thw width in pixels of the tab widget
	 */
	get wdgWidth() {
		return this._wdgWidth;
	}

	/**
	 * @returns {Object[]} array of Window menu items
	 */
	get wndMenu() {
		return this._wndMenu;
	}

	/**
	 * @returns {Map<Number, String>} the menu option map
	 */
	get menuOpt() {
		return this._menuOpt;
	}

	/**
	 * @returns {TabGroup|null} the dragged group
	 */
	get draggedGroup() {
		return this._draggedGroup;
	}

	/**
	 * @return {TabGroup|null} the drop target group
	 */
	get targetGroup() {
		return this._targetGroup;
	}

    /**
     * called to destroy an instance
     * @override
     */
    doDestroy() {
		try {
			this._ready = false;
			this._moreItems = [];
			this._wndMenu = [];
			this._draggedGroup = null;
			this._targetGroup = null;
			this._menuOpt.clear();
			this.tabGroups.forEach(group => group.destroy());
			this.tabGroups.clear();
			this.tabItems.clear();
			this._moreBtn = null;
			this._filler = null;
			this._container = null;
			this._element = null;
			const de = this.element;
			HtmHelper.rmvDomElm(de);
		} finally {
			super.doDestroy();
		}
    }

	/**
	 * called to mark this instance as "ready"
	 */
	onReady() {
		this._ready = this.hasElement;
		if ( this._ready ) {
			if ( this._pndItems.length > 0 ) {
				const self = this;
				this._pndItems.forEach((pi) => self._createTabItem(pi));
			}
			delete this._pndItems;
			this._updateUI();
		}
	}

	/**
	 * called by RAP in "render" phase
	 */
	onRender() {
		rap.off('render', this.onRender); // just once!
		this.onReady();
		this.layout();
	}

	/**
	 * called by RAP on resize events
	 */
	layout() {
		if ( this.ready ) {
			const area = this.parent.getClientArea();
			const style = this.element.style;
			const width = Number(area[2]);
			this._wdgWidth = width;
			style.left = '0';
			style.top = '0';
			style.width = `${width}px`;
			style.height = `${area[3]}px`;
			this._updateGroupsVisibility();
		}
	}

	/**
	 * sets new background color
	 * @param {*} args color properties
	 */
	setBkgColor(args) {
		this.props.setBkgColor(args);
		if ( this.ready ) {
			this._updateUI();
		}
	}

	/**
	 * adds a new tab item
	 * @param {*} args arguments
	 */
	addItem(args) {
		if ( this.isDebugEnabled() ) {
			this.debug('addItem', args);
		}
		if ( this.ready ) {
			// create tab item right now
			this._createTabItem(args);
		} else {
			// postpone until render phase
			this._pndItems.push(args);
		}
	}

	/**
	 * removes a tab item
	 * @param {*} args arguments
	 */
	rmvItem(args) {
		if ( this.isDebugEnabled() ) {
			this.debug('rmvItem', args);
		}
		const id = args.id || 0;
		if ( this.alive && Validator.isPositiveNumber(id) ) {
			if ( this.ready ) {
				const ti = this.tabItems.get(id);
				if ( ti instanceof TabItem ) {
					const tg = ti.tabGroup;
					const was_active = tg === this.activeGroup;
					this.tabItems.delete(id);
					tg.rmvItem(ti);
					if ( tg.empty ) {
						this._removeFromMore(tg);
						this.tabGroups.delete(tg.id);
						if ( was_active ) {
							const newest = AbstractTabItem.findNewest(this.tabGroups.values());
							if ( newest instanceof TabGroup ) {
								const nti = newest.currentItem;
								if ( nti instanceof TabItem ) {
									this._activateTabItem(nti.id, true);
								}
							}
						}
					}
					this._updateGroupsVisibility();
				}
			} else {
				const idx = this._getPendingIdx(id);
				if ( idx >= 0 ) {
					this._pndItems.splice(idx, 1);
				}
			}
		}
	}

	/**
	 * activates a tab item
	 * @param {*} args arguments
	 */
	activateItem(args) {
		if ( this.isDebugEnabled() ) {
			this.debug('activateItem', args);
		}
		const id = args.id || 0;
		const activate = !!args.active;
		if ( Validator.isPositiveNumber(id) && activate ) {
			if ( this.ready ) {
				this._activateTabItem(id, false);	// that's from the backend, so no feedback!
			} else {
				const item = this._getPendingItem(id);
				if ( item ) {
					item.active = true;
				}
			}
		}
	}

	/**
	 * updates a tab item
	 * @param {*} args arguments
	 */
	updateTabItem(args) {
		if ( this.isDebugEnabled() ) {
			this.debug('updateTabItem', args);
		}
		const id = args.id || 0;
		if ( Validator.isPositiveNumber(id) ) {
			if ( this.ready ) {
				const ti = this.tabItems.get(id);
				if ( ti instanceof TabItem ) {
					let layout = false;
					if ( Validator.isString(args.title) ) {
						ti.title = args.title;
						layout = true;
					}
					if ( args.tooltip !== undefined ) {
						ti.tooltip = args.tooltip;
					}
					if ( args.icon !== undefined ) {
						ti.icon = args.icon || null;
						layout = true;
					}
					// apply changes!
					ti.tabGroup.tabItemUpdated(ti);
					if ( layout ) {
						this._updateGroupsVisibility();
					}
				}
			} else {
				// handle "early bird" request
				const item = this._getPendingItem(id);
				if ( item ) {
					if ( Validator.isString(args.title) ) {
						item.title = args.title;
					}
					if ( args.tooltip !== undefined ) {
						item.tooltip = args.tooltip;
					}
					if ( args.icon !== undefined ) {
						item.icon = args.icon || null;
					}
				}
			}
		}
	}

	markTabItem(args) {
		if ( this.isDebugEnabled() ) {
			this.debug('markTabItem', args);
		}
		const id = args.id || 0;
		if ( Validator.isPositiveNumber(id) ) {
			const ti = this.tabItems.get(id);
			if ( ti instanceof TabItem ) {
				ti.marked = !!args.marked;
					// apply changes!
					ti.tabGroup.tabItemUpdated(ti);
			}
		}
	}

	/**
	 * sets the window menu
	 * @param {Object} args menu structure
	 */
	setMenu(args) {
		this._wndMenu = [];
		if ( args && args.id && args.items && args.items.length && (args.items.length > 0) ) {
			// that's (hopefully) a menu structure
			this._wndMenu = Array.from(args.items);
			// append a separator
			this._wndMenu.push( { id: -1, stc:true } );
		}
	}

	/**
	 * sets menu options
	 * @param {Objet} args arguments
	 */
	setMenuInfo(args) {
		if ( Validator.isObject(args) ) {
			const map = this.menuOpt;
			Object.getOwnPropertyNames(args).forEach(name => {
				if ( Validator.isString(name) ) {
					const v = args[name];
					if ( Validator.isInteger(v) ) {
						map.set(name, v);
					}
				}
			});
		}
	}

	/**
	 * sets the tab grouping feature on or off
	 * @param {Boolean} args new tab grouping setting sent by the backend
	 */
	setTabGrouping(args) {
		const tg = !!args;
		if ( this.props.tabGrouping !== tg ) {
			this.props.setTabGrouping(tg);
			if ( this.ready && (this.tabGroups.size > 0) ) {
				this._updateGroupsVisibility();
			}
		}
	}

	/**
	 * sets the "no overflow" feature on or off
	 * @param {Boolean} args new "no overflow" setting sent by the backend
	 */
	setNoOverflow(args) {
		const no = !!args;
		if ( this.props.noOverflow !== no ) {
			this.props.setNoOverflow(no);
			if ( this.ready && (this.tabGroups.size > 0) ) {
				this._updateGroupsVisibility();
			}
		}
	}

	/**
	 * updates the menu
	 * @param {*} args arguments sent by the backend
	 */
	updateMenu(args) {
		const menu = this.wndMenu;
		if ( menu instanceof MnuObj ) {
			const id = args.id || 0;
			if ( Validator.isPositiveInteger(id) ) {
				// update the menu items
				menu.updMnuItm(id, args);
			}
		}
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {Number} id 
	 * @param {MnuObj} menu 
	 * @param {Number} what 
	 */
	onMenuItem(id, menu, what) {
		menu.lock(false);
		if ( this.alive && Validator.isPositiveInteger(id) ) {
			const ti = this.tabItems.get(id);
			if ( ti instanceof TabItem ) {
				// tab item found --> activate it
				this._activateTabItem(ti.id, true);
			} else {
				// NO tab item found --> send menu notification to the backend
				this._notifyBE(NFY_MENUITEM, { id: id }, true);
			}
		}
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {MnuObj} menu 
	 */
	onMenuClose(menu) {
		// we just drop the menu
		menu.lock(false);
		menu.destroy();
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {Number} group 
	 * @param {Number} id 
	 */
	onTabActivate(group, id) {
		const dg = this.draggedGroup;
		if ( !(dg instanceof TabGroup) ) {
			// activate the given group / item if we're *not* in a group drag operation
			this._doActivateTabItem(group, id, true);
		}
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {Number} group 
	 * @param {Number} id 
	 */
	onTabClose(group, id) {
		const tg = this.tabGroups.get(group);
		if ( tg instanceof TabGroup ) {
			const param = { id: id };
			this._notifyBE(NFY_TABCLOSE, param, false);
		}
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {Number} group 
	 */
	onTabContextMenu(group) {
		const tg = this.tabGroups.get(group);
		if ( tg instanceof TabGroup ) {
			const ci = tg.currentItem;
			if ( (ci instanceof TabItem) && (!ci.active || !tg.active) ) {
				this.onTabActivate(tg.id, ci.id);
			}
			const groups = Array.from(this.tabGroups.values());
			this._showTabMenu(groups, tg.element);
		}
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {Number} group 
	 * @param {Number} id 
	 */
	onTabDblClick(group, id) {
		const tg = this.tabGroups.get(group);
		if ( tg instanceof TabGroup ) {
			const param = { id: id };
			this._notifyBE(NFY_TABDBLCLICK, param, false);
		}
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {Number} group 
	 */
	onStartGroupDrag(group) {
		if ( this.alive ) {
			const tg = this.tabGroups.get(group);
			if ( tg instanceof TabGroup ) {
				const ci = tg.currentItem;
				if ( (ci instanceof TabItem) && (ci !== this.currentItem) ) {
					this._doActivateTabItem(tg.id, ci.id, true);
				}
				this._draggedGroup = tg;
				if ( this.isDebugEnabled() ) {
					this.debug('Dragging started: group #' + group);
				}
			}
		}
	}

	/**
	 * @override
	 * @inheritdoc
	 * @param {Number} group 
	 */
	onEndGroupDrag(group) {
		if ( this.alive ) {
			if ( this.isDebugEnabled() ) {
				this.debug('End Group drag.', group);
			}
			this._draggedGroup = null;
		}
	}

	/**
	 * creates the main DOM element
	 * @returns {HTMLElement} the main DOM element
	 */
	_createDOMElement() {
		const de = document.createElement('div');
		de.className = 'pisaTabWidget';
		const self = this;
		de.addEventListener('dragenter', (e) => {
			self._onGroupDrag(e, true);
		}, true);
		de.addEventListener('dragover', (e) => {
			self._onGroupDrag(e, false);
		}, true);
		de.addEventListener('dragend', (e) => {
			self._onDragEnd(e);
		}, true);
		de.addEventListener('drop', (e) => {
			self._onGroupDrop(e);
		}, true);
		return de;
	}

	/**
	 * creates the tab container element
	 * @returns {HTMLElement} the tab container element
	 */
	_createTabContainer() {
		const tc = document.createElement('div');
		tc.className = 'pisaTabContainer';
		return tc;
	}

	/**
	 * creates the "filler" element
	 * @returns {HTMLElement} the "filler" element
	 */
	_createFiller() {
		const fe = document.createElement('div');
		fe.classList.add('ptgNormal');
		fe.classList.add('ptgFiller');
		fe.dataset.ptgFiller = 'true';
		return fe;
 	}

	/**
	 * checks whether the give element is the "filler" element
	 * @param {HTMLElement} element the DOM element
	 * @returns {Boolean} true if the given element is the "filler" element; false otherwise
	 */
	_isFiller(element) {
		if ( this.alive ) {
			return (element instanceof HTMLElement) && (element === this.filler);
		}
		return false;
	}

	/**
	 * creates the "more" button
	 * @returns {HTMLElement} the "more" button element
	 */
	_createMoreButton() {
		const mb = document.createElement('div');
		mb.classList.add(MORE_CLASS);
		mb.classList.add('ptwMoreBtn');
		mb.style.display = 'none';
		const mi = ItmMgr.getInst().creDscIco(MORE_ICON, null, false);
		mb.appendChild(mi);
		const self = this;
		mb.addEventListener('click', (e) => {
			self._onMoreBtn(e);
		});
		return mb;
	}

	/**
	 * creates a new tab item and a tab group if required
	 * @param {*} args arguments
	 */
	_createTabItem(args) {
		if ( this.isDebugEnabled() ) {
			this.debug('_createTabItem', args);
		}
		const id = args.id || 0;
		if ( Validator.isPositiveNumber(id) ) {
			if ( this.tabItems.has(id) ) {
				this.throwError('Duplicate tab item ID!', id);
			}
			const ag = this.activeGroup;
			const activate = !!args.active || !(ag instanceof TabGroup);
			const item = new TabItem(args);
			this.tabItems.set(id, item);
			const gn = item.groupName;
			let group = null;
			if ( this.isGrouped && Validator.isString(gn) ) {
				// find tab group by name
				group = this._findGroupByName(gn);
			}
			if ( !(group instanceof TabGroup) ) {
				// create new group
				const gid = ++NEXT_TAB_ID;
				group = new TabGroup(this, gid, gn, this.props, item);
				this.tabGroups.set(gid, group);
				this.container.insertBefore(group.element, this.filler);
				group.showGroup(true);
				group.updateLayout();
			} else {
				group.addItem(item);
			}
			if ( activate ) {
				this._activateTabItem(id, true);
			}
			this._updateGroupsVisibility();
		} else {
			this.throwError('Invalid arguments!', args);
		}
	}

	/**
	 * retrieves the tab group that belongs to the given DOM element
	 * @param {HTMLElement} element the DOM element
	 * @returns {TabGroup|null} element'S tab group or null if the given element does not refer to a tab group
	 */
	_getElementGroup(element) {
		if ( this.alive && (element instanceof HTMLElement) && Validator.isTrue(element.dataset.ptg) ) {
			const id = Number(element.dataset.gid);
			if ( Validator.isPositiveInteger(id) ) {
				const tg = this.tabGroups.get(id);
				return tg instanceof TabGroup ? tg : null;
			}
		}
		return null;
	}

	/**
	 * activates a tab item
	 * @param {Number} id ID of tab item
	 * @param {Boolean} feedback flag whether to send activation feedback to the backend
	 */
	_activateTabItem(id, feedback) {
		if ( this.alive ) {
			const ti = this.tabItems.get(id);
			if ( ti instanceof TabItem ) {
				if ( !ti.active || !ti.tabGroup.active ) {
					this._doActivateTabItem(ti.tabGroup.id, ti.id, feedback);
					this._updateGroupsVisibility();
				}
			}
		}
	}

	/**
	 * finally activates a tab item
	 * @param {Number} gid group ID
	 * @param {Number} id tab item ID
	 * @param {Boolean} feedback flag whether to send activation feedback to the backend
	 */
	_doActivateTabItem(gid, id, feedback) {
		if ( this.alive ) {
			const tg = this.tabGroups.get(gid);
			const ti = this.tabItems.get(id);
			if ( (tg instanceof TabGroup) && (ti instanceof TabItem) && (tg === ti.tabGroup) ) {
				if ( this.isDebugEnabled() ) {
					this.debug(`Activating tab item "${ti.title}" (#${ti.id}), feedback=${feedback}.`);
				}
				const pg = this.activeGroup;
				if ( pg instanceof TabGroup ) {
					pg.setActive(false);
				}
				if ( !tg.visible ) {
					tg.showGroup(true);
				}
				this._activeGroup = tg;
				tg.setCurrentItem(ti);
				tg.setActive(true);
				this._removeFromMore(tg);
				this._updateGroupsUI();
				const param = { id: id, feedback: feedback };
				this._notifyBE(NFY_TABSELECTED, param, false);
			} else {
				this.warn(`Cannot find tab group and/or tab item for gid=${gid} and id=${id}!`);
			}

		}
	}

	/**
	 * removes a tab group from "more items" array
	 * @param {TabGroup} group the group to be removed from "more items" array
	 */
	_removeFromMore(group) {
		if ( this.alive ) {
			const mi = this.moreItems;
			if ( mi.length > 0 ) {
				const tg = group;
				const idx = mi.findIndex(g => g.id === tg.id);
				if ( idx >= 0 ) {
					// found in "more" items; remove it there
					mi.splice(idx, 1);
				}
			}
		}
	}

	/**
	 * retrieves the index of a pending tab item descriptor
	 * @param {Number} id tab item ID
	 * @returns {Number} the item index or -1 if not found
	 */
	_getPendingIdx(id) {
		const pi = this._pndItems;
		if ( (pi != null) && (pi.length > 0) ) {
			return pi.findIndex(item => id === item.id);
		}
		return -1;
	}

	/**
	 * retrieves a pending tab item descriptor
	 * @param {Number} id tab item ID
	 * @returns {Object|null} the tab item descriptor or null if not found
	 */
	_getPendingItem(id) {
		const idx = this._getPendingIdx(id)
		if ( idx >= 0 ) {
			return this._pndItems[idx];
		}
		return null;
	}

	/**
	 * sends a notification to the backend
	 * @param {String} code notification code
	 * @param {*} par notification parameters
	 * @param {Boolean} bsc block screen request flag
	 */
	_notifyBE(code, par, bsc) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			if ( bsc ) {
				PSA.getInst().setBscRqu();
			}
			rap.getRemoteObject(this).notify(BE_NOTIFICATION, param);
		}
	}

	/**
	 * updates the UI if properties were changed
	 */
	_updateUI() {
		if ( this.ready ) {
			const de = this.element;
			const fe = this.filler;
			const bgc = this.props.bkgColor;
			if ( bgc instanceof Color ) {
				de.style.backgroundColor = bgc.stringify();
				fe.style.backgroundColor = bgc.stringify();
			} else {
				de.style.backgroundColor = '';
				fe.style.backgroundColor = '';
			}
		}
	}

	/**
	 * updates the UI of all groups, especially the "before" group
	 */
	_updateGroupsUI() {
		const ag = this.activeGroup;
		const id = ag instanceof TabGroup ? ag.id : 0;
		const groups = this.tabGroups;
		const keys = groups.keys();
		let before = null;
		let found = false;
		for ( let key = keys.next() ; Validator.isPositiveInteger(key.value, false) ; key = keys.next() ) {
			const g = groups.get(key.value);
			if ( id === g.id ) {
				found = true;
				const sibling  = g.element.previousSibling;
				if ( sibling instanceof HTMLElement ) {
					// during group drag we'll have not the correct tab group order so we use the DOM element to get the "before" group
					const bg = this._getElementGroup(sibling);
					if ( (bg instanceof TabGroup) && bg.visible ) {
						// override "before" unconditionally!
						before = bg;
					}
				}
			} else {
				g.setActive(false);
				if ( !found && !before && g.visible ) {
					before = g;
				}
			}
		}
		if ( before instanceof TabGroup ) {
			before.setBefore();
		}
	}

	_updateTabGrouping(max_width) {
		const enabled = this.props.tabGrouping;
		if ( this._isGrouped && !enabled ) {
			// we must "explode" all groups!
			this._explodeGroups();
		}
		if ( enabled ) {
			const cb = this.props.closeBtn;
			let all_width = 0;
			this.tabItems.forEach(ti => {
				const tg = ti.tabGroup;
				if ( tg instanceof TabGroup ) {
					all_width += TabGroup.calculateItemWidth(ti, tg.text, cb);
				}
			});
			if ( (all_width <= max_width) && this._isGrouped ) {
				// explode all groups
				this._explodeGroups();
			} else if ( (all_width > max_width) && !this._isGrouped ) {
				// group items if possible
				this._groupItems();
			}
		}
	}

	/**
	 * searches for a tab group by group name
	 * @param {String} name group name
	 * @returns {TabGroup} the matching group or null if not found
	 */
	_findGroupByName(name) {
		if ( Validator.isString(name) ) {
			const groups = Array.from(this.tabGroups.values());
			return groups.find(g => name === g.name);
		}
		return null;
	}

	/**
	 * re-creates tab groups
	 * @param {Boolean} grouping grouping flag
	 */
	_recreateTabGroups(grouping) {
		// clear and delete current groups
		this.tabGroups.forEach(tg => {
			tg.clearItems();
			tg.destroy();
		});
		// clear current group containers
		this.tabGroups.clear();
		this._moreItems = [];
		// create new groups
		const self = this;
		this.tabItems.forEach(ti => {
			const gn = ti.groupName;
			ti.setActive(false);
			let group = grouping ? self._findGroupByName(gn) : null;
			if ( group instanceof TabGroup ) {
				// found an existing group - just add the item
				group.addItem(ti, false);
			} else {
				// create new group
				const gid = ++NEXT_TAB_ID;
				group = new TabGroup(this, gid, gn, self.props, ti);
				self.tabGroups.set(gid, group);
				self.container.insertBefore(group.element, self.filler);
				group.showGroup(true);
				group.updateLayout();
			}
		});
	}

	/**
	 * re-activates a tab item after a grouping operation
	 * @param {TabItem|null} ai the tab item to be activated
	 */
	_reactivateTabItem(ai) {
		if ( ai instanceof TabItem ) {
			// re-activate last active tab item
			this._doActivateTabItem(ai.tabGroup.id, ai.id, false);
		} else {
			// activate first suitable tab item
			const group = this.tabGroups.values().next().value;
			if ( group instanceof TabGroup ) {
				const ti = group.currentItem;
				if ( ti instanceof TabItem ) {
					this._doActivateTabItem(group.id, ti.id, true);
				}
			}
		}
	}

	/**
	 * explodes current tab groups
	 */
	_explodeGroups() {
		const ai = this.currentItem;
		this._isGrouped = false;
		// re-create tab groups without grouping
		this._recreateTabGroups(false);
		// re-activate tab item
		this._reactivateTabItem(ai);
	}

	/**
	 * tries to group matching items
	 */
	_groupItems() {
		const ai = this.currentItem;
		let grouped = false;
		// check whether at least two tab items refer to the same group
		const items = Array.from(this.tabItems.values());
		const set = new Set();
		let hit = false;
		for ( let i=0 ; !hit && (i < items.length) ; ++i ) {
			const ti = items[i];
			const gn = ti.groupName;
			if ( Validator.isString(gn) ) {
				hit = set.has(ti.groupName);
				if ( !hit ) {
					set.add(ti.groupName);
				}
			}
		}
		set.clear();
		if ( hit ) {
			// at least two tab items refer to the same group - re-create tab groups with grouping
			this._recreateTabGroups(true);
			grouped = true;
		}
		if ( grouped ) {
			this._isGrouped = true;
			// re-activate tab item
			this._reactivateTabItem(ai);
		}
	}

	/**
	 * updates the visibility of tab groups
	 */
	_updateGroupsVisibility() {
		if ( this.alive && this.ready && (this.tabGroups.size > 0) ) {
			const width = this.wdgWidth;
			if ( width > 0 ) {
				const max_width = width;
				const available_width = max_width - MORE_WIDTH;
				// handle tab grouping if required
				this._updateTabGrouping(max_width);
				// get required width of all visible groups
				const mg = this.moreItems;
				const groups = [];
				let current_width = 0;
				this.tabGroups.forEach(g => {
					if ( g.visible ) {
						groups.push(g);
						current_width += g.itemWidth;
					}
				});
				// sort by last activation, newest first
				groups.sort(GroupItem.compareGroups);
				// check width limits
				if ( current_width > max_width ) {
					// we must hide some groups
					// get the groups that remain visible
					const visible_groups = [];
					let used_width = 0;
					let done = false;
					while ( !done && (groups.length > 0) ) {
						const group = groups.shift();
						const group_width = group.itemWidth;
						if ( (used_width + group_width) <= available_width ) {
							used_width += group_width;
							visible_groups.push(group);
						}
						else {
							groups.splice(0, 0, group);
							done = true;
						}
					}
					// verify that the currently active group is on top of "visible_groups"
					if ( (visible_groups.length === 0) || !(visible_groups[0].active) ) {
						// that's weird yet possible - move the active group on top of the visible groups
						const ag = this.activeGroup;
						visible_groups.splice(0, 0, ag);
						used_width += ag.itemWidth;
						// and drop groups from the bottom until it fits
						while ( (visible_groups.length > 1) && (used_width > available_width) ) {
							const group = visible_groups.pop()
							used_width -= group.itemWidth;
							groups.push(group);
						}
					}
					// hide the groups that cannot be shown at the moment - those are now in "groups"
					while ( groups.length > 0 ) {
						const group = groups.shift();
						mg.push(group);
						group.showGroup(false);
					}
					if ( mg.length > 1 ) {
						// sort "more items" array
						mg.sort(GroupItem.compareGroups);
					}
					// show more button
					this.moreBtn.style.display = '';
				} else {
					// may be, we can show one or more hidden groups
					if ( mg.length > 0 ) {
						const show_groups = [];
						let done = false;
						while ( !done && (mg.length > 0) ) {
							const group = mg.shift();
							const group_width = group.itemWidth;
							const limit = mg.length > 0 ? available_width : max_width;
							if ( (current_width + group_width) <= limit ) {
								current_width += group_width;
								show_groups.push(group);
							} else {
								mg.splice(0, 0, group);
								done = true;
							}
						}
						while ( show_groups.length > 0 ) {
							const mg = show_groups.shift();
							const group = this.tabGroups.get(mg.id);
							if ( group instanceof TabGroup ) {
								group.showGroup(true);
								group.updateLayout();
							}
						}
					}
					// set more button's visibility
					this.moreBtn.style.display = mg.length === 0 ? 'none' : '';
				}
				if ( this.props.noOverflow && (mg.length > 0) )  {
					const lg = mg[mg.length-1];
					const mi = [];
					lg.items.forEach(ti => {
						if ( !ti.marked ) {
							mi.push(ti)
						}
					});
					if ( mi.length > 0 ) {
						if ( mi.length > 1 ) {
							mi.sort((i1,i2) => i2.tms - i1.tms);	// reverse!
						}
						// trigger "close" request of the oldest item
						const ci = mi[0];
						this.onTabClose(ci.tabGroup.id, ci.id);
					};
				}
				this._updateGroupsUI();
			}
		}
	}

	/**
	 * called if the "more" button was clicked
	 */
	_onMoreBtn(event) {
		DomEventHelper.stopEvent(event);
		if ( this.moreItems.length > 0 ) {
			// we must render a menu
			this._showTabMenu(this.moreItems, this.moreBtn);
		}
	}

    /**
     * common handling of all drag events
     * @param {DragEvent} e the drag event
     * @param {Boolean} pv flag whether "preventDefault" mus be called
     */
	_onAllDragEvents(e, pv) {
		if ( pv ) {
			e.preventDefault();
		}
		e.stopImmediatePropagation();
		e.dataTransfer.dropEffect = 'move';
	}
	
	/**
	 * retrieves the target tab group
	 * @param {DragEvent} e the drag event
	 * @returns {TabGroup|null} the target tab group or null
	 */
	_getDragTargetGroup(e) {
		const de = this.element;
		let tg = null;
		let te = e.target;
		while ( (te instanceof HTMLElement) && (te !== de) && !(tg instanceof TabGroup) ) {
			tg = this._getElementGroup(te);
			te = te.parentElement;
		}
		return (tg instanceof TabGroup) ? tg : null;
	}

	/**
	 * checks whether a drag operation targets the "filler" element
	 * @param {DragEvent} e the drag event
	 * @returns {Boolean} true if the drag event targets the "filler" element; false otherwise
	 */
	_isDragTargetFiller(e) {
		return this._isFiller(e.target);
	}

	/**
	 * handles "dragenter" and "dragover" events
	 * @param {*} e the drag event
	 * @param {*} enter flag whether a "dragenter" event is handled
	 */
	_onGroupDrag(e, enter) {
		this._onAllDragEvents(e, true);
		if ( this.alive && enter ) {
			const dg = this.draggedGroup;
			const container = this.container;
			if ( (dg instanceof TabGroup) && (container instanceof HTMLElement) ) {
				if ( this._isDragTargetFiller(e) ) {
					if ( this.isTraceEnabled() ) {
						this.trace('Drag enter', 'Dragged group: ', ((dg instanceof TabGroup) ? `#${dg.id}` : 'none'), 'Target is the "filler" element.');
					}
					container.insertBefore(dg.element, this.filler);
					this._updateGroupsUI();
				} else {
					const tg = this._getDragTargetGroup(e);
					if ( (tg !== this._targetGroup) && (tg !== dg) ) {
						this._targetGroup = tg;
						if ( this.isTraceEnabled() ) {
							this.trace('Drag enter.', 'Dragged group: ', ((dg instanceof TabGroup) ? `#${dg.id}` : 'none'), 'Target group: ', ((tg instanceof TabGroup) ? `#${tg.id}` : 'none'));
						}
						container.insertBefore(dg.element, tg.element);
						this._updateGroupsUI();
					}
				}
			} else {
				this._targetGroup = null;
			}
		}
	}

	/**
	 * handles "drop" events
	 * @param {DragEvent} e the drag event
	 */
	_onGroupDrop(e) {
		try {
			this._onAllDragEvents(e, true);
			const data = e.dataTransfer.getData(DRAG_DATA_TYPE);
			if ( this.alive && Validator.isString(data) && data.startsWith(DROP_DATA_PREFIX) ) {
				this.trace('Got valid drop data:', data);
				const dg = this.draggedGroup;
				if ( dg instanceof TabGroup ) {
					// apply new groups UI --> re-create group map
					const container = this.container;
					const groups = [];
					// get current groups in DOM element order
					for ( let child of container.childNodes ) {
						const tg = this._getElementGroup(child);
						if ( tg instanceof TabGroup ) {
							groups.push(tg);
						}
					}
					// re-fill the map with groups in current DOM element order
					const tgm = this.tabGroups;
					tgm.clear();
					groups.forEach(g => tgm.set(g.id, g));
					// update the UI
					this._updateGroupsUI();
					// clean-up ---> _onDragEnd() should not touch tab groups!
					this._draggedGroup = null;
					if ( this.isTraceEnabled() ) {
						this.trace('new tab group map:', this.tabGroups);
					}
				}
			} else {
				if ( this.isDebugEnabled() ) {
					this.debug('Cannot handle drop data:', data);
				}
			}
		} finally {
			this._targetGroup = null;
		}
	}
	
    /**
     * handles "dragend" events
     * @param {DragEvent} e the drag event
     */
    _onDragEnd(e) {
		try {
			if ( this.isDebugEnabled() ) {
				this.debug('Drag end.');
			}
			e.stopImmediatePropagation();
			if ( this.alive && this.hasElement ) {
				const dg = this.draggedGroup;
				if ( dg instanceof TabGroup ) {
					// drag operation ended without drop - restore previous tab groups
					const ai = this.currentItem;
					const filler = this.filler;
					const container = this.container;
					this.tabGroups.forEach(g => {
						container.insertBefore(g.element, filler);
					});
					this._reactivateTabItem(ai);
				}
			}
		} finally {
			this._draggedGroup = null;
			this._targetGroup = null;
		}
    }

	/**
	 * updates the Window menu item descriptors according the current number of tab items
	 * @param {any[]} items Window menu item descriptors
	 */
	_updateMenuItems(items) {
		const map = this.menuOpt;
		if ( (map.size > 0) && (items.length > 0) ) {
			const count = this.tabItems.size;
			items.forEach(item => {
				const dsc = item.dsc;
				if ( Validator.isString(dsc) && map.has(dsc) ) {
					const limit = map.get(dsc);
					item.ena = count >= limit;
				}
			});
		}
	}

	/**
	 * shows a tab menu
	 * @param {Array<TabGroup>} groups the tab groups to be rendered in that menu
	 * @param {HTMLElement} element the reference element
	 */
	_showTabMenu(groups, element) {
		if ( this.alive ) {
			const self = this;
			const items = (this.wndMenu.length > 0) ? Array.from(this.wndMenu) : [];
			this._updateMenuItems(items);
			groups.forEach(group => self._createMenuItems(items, group));
			const mmg = PSA.getInst().getMnuMgr();
			const menu = mmg.createMenu(TAB_MENU_ID, items);
			menu.lock(true);
			mmg.showMenu(menu, { element: element, rect: null, outside: true }, this, true, true);
		}
	}

	/**
	 * creates menu items from a tab group
	 * @param {Array<TabMenuItem} items the menu item array
	 * @param {TabGroup} group the tab group
	 */
	_createMenuItems(items, group) {
		for ( let ti of group.items.values() ) {
			items.push(new TabMenuItem(ti));
		}
	}


	/** register custom widget type */
	static register() {
		console.debug('Registering custom widget TabWidget.');
		rap.registerTypeHandler( 'psawidget.TabWidget', {
			factory: function ( properties ) {
				return new TabWidget( properties );
			},
			destructor: 'destroy',
			properties: [ 'bkgColor', 'menu', 'menuInfo', 'tabGrouping', 'noOverflow' ],
			methods: [ 'addItem', 'rmvItem', 'activateItem', 'updateTabItem', 'markTabItem', 'updateMenu' ],
			events: [ BE_NOTIFICATION ]
		} );
	}
}
